import { Outlet } from "@tanstack/react-router"
import { CookieConsent } from "features/registration/cookie-consent"
import { StandaloneWelcomeModal } from "features/standalone-onboarding/standalone-welcome-modal"
import { useDeviceInfo } from "features/user-profile/hooks/use-device-info"
import { AnimatePresence } from "framer-motion"
import { useIsPrivateMode } from "hooks/use-is-private-mode"
import { LocalStorage } from "local-storage"
import { createPortal } from "react-dom"
import { SkeletonTheme } from "react-loading-skeleton"
import { useAuth } from "react-oidc-context"
import { useAppStore } from "store"
import {
    InfoToaster,
    NotificationToaster,
} from "../components/controls/toaster"
import { NotificationProvider } from "./notification-provider"

export const ProviderOutlet = () => {
    const { isStandalone, isStandaloneLoading, isDesktop } = useDeviceInfo()
    const { isPrivate, isLoading: isPrivateModeCheckLoading } =
        useIsPrivateMode()
    const closeModal = useAppStore(store => store.closeModal)
    const isModalVisible = useAppStore(store => store.isModalVisible)
    const auth = useAuth()

    const isUserMode =
        auth.isAuthenticated &&
        LocalStorage.getAccountTypeOrDefault() === "User" &&
        !isStandalone &&
        !isStandaloneLoading

    const isIncognitoMode = isPrivate && !isPrivateModeCheckLoading

    const shouldOpenStandaloneWelcome =
        isModalVisible.StandaloneWelcome.visible &&
        !isDesktop &&
        (isUserMode ||
            (isIncognitoMode &&
                LocalStorage.getAccountTypeOrDefault() === "User"))

    return (
        <>
            <SkeletonTheme baseColor="#3C375C" highlightColor="#FFFFFF0F">
                <CookieConsent />

                <StandaloneWelcomeModal
                    open={shouldOpenStandaloneWelcome}
                    onOpenChange={() => closeModal("StandaloneWelcome")}
                />

                <NotificationProvider>
                    {createPortal(<NotificationToaster />, document.body)}
                    {createPortal(<InfoToaster />, document.body)}

                    <AnimatePresence
                        initial={false}
                        // custom={getNavigationDirection(navigationType)}
                        key="page-animate"
                    >
                        <Outlet />
                    </AnimatePresence>
                </NotificationProvider>
            </SkeletonTheme>
        </>
    )
}
