import { FC } from "react"

import { faCrown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQueryClient, useSuspenseInfiniteQuery } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import {
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
} from "common/popover"
import { listFirstUsersInfiniteQueryOptions } from "hooks/use-list-first-users"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./top-user-area-tile.css"

export type TopUserAreaTileModel = {
    profileId: UserProfileId
}

export const TopUserAreaTile: FC<TopUserAreaTileModel> = ({ profileId }) => {
    const myProfileIdQuery = useMyUserProfileId()
    const profileQuery = useListUserProfileById(profileId)
    const profile = profileQuery.data?.data

    const queryClient = useQueryClient()

    const firstUsersQuery = useSuspenseInfiniteQuery(
        listFirstUsersInfiniteQueryOptions(queryClient),
    )

    const firstUserIds = firstUsersQuery.data.pages.flatMap(page =>
        page.data.map(item => item.id),
    )
    //TODO: consider moving this computation into cache
    const isFirstAdopter =
        profileQuery.isSuccess &&
        profile &&
        firstUserIds.includes(profile.userId)

    if (!profileQuery.isSuccess || !profile) return
    return (
        <div className={styles.wrapper}>
            {isFirstAdopter && (
                <Popover>
                    <PopoverTrigger asChild>
                        <div className={styles.firstAdopterIconWrapper}>
                            <FontAwesomeIcon
                                icon={faCrown}
                                className={styles.firstAdopterIcon}
                            />
                        </div>
                    </PopoverTrigger>
                    <PopoverContent
                        className={styles.popoverContent}
                        side="top"
                    >
                        <PopoverArrow />
                        <p
                            style={{
                                fontSize: vars.font.size.xs,
                                fontWeight: 700,
                            }}
                        >
                            First 1K Member – One of our earliest supporters!
                        </p>
                    </PopoverContent>
                </Popover>
            )}
            <Link
                to={
                    myProfileIdQuery.data === profile.id
                        ? "/app/my-profile"
                        : "/app/user-profile/$id"
                }
                params={{ id: profile.id }}
            >
                <LazyLoadImage
                    src={`${profile.imageUrl}?height=${116}`}
                    className={styles.image}
                />
            </Link>
            <p
                style={{
                    margin: "0 4px 0 4px",
                    fontSize: vars.font.size.regular,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    msTextOverflow: "ellipsis",
                }}
            >
                {profile.displayName ?? profile.profileName}
            </p>
        </div>
    )
}

export const TopUserAreaLoadingTile = () => (
    <div className={styles.wrapper}>
        <Skeleton
            baseColor="#3C375C"
            width={116}
            height={116}
            borderRadius={16}
        />
        <Skeleton
            baseColor="#3C375C"
            width={80}
            height={18}
            borderRadius={24}
        />
    </div>
)
