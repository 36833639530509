import { createFileRoute } from "@tanstack/react-router"
import { notValidRouteBeforeLoad } from "utils/router"

export const Route = createFileRoute("/_authenticated/app/text-edit/")({
    component: RouteComponent,
    beforeLoad: notValidRouteBeforeLoad,
})

function RouteComponent() {
    return <></>
}
