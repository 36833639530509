import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { UserProfile } from "../../../../api/api-models"
import {
    UserSearchResultLoadingTile,
    UserSearchResultTile,
    UserSearchResultTileTextModel,
} from "./user-search-result-tile"

import Skeleton from "react-loading-skeleton"
import * as styles from "./user-search-result-tile-list.css"

export type UsersSearchResultTileListModel = {
    profiles: UserProfile[]
    text: UserSearchResultTileTextModel

    isLoading?: boolean
    loadingItemsAmount?: number
}

export const UserSearchResultTileList: FC<
    UsersSearchResultTileListModel
> = model => (
    <div className={styles.list}>
        {pipe(
            model.profiles,
            A.map(profile => (
                <UserSearchResultTile
                    key={profile.id}
                    profileId={profile.id}
                    text={model.text}
                />
            )),
        )}
        {model.isLoading && <UserSearchResultTileListLoading />}
    </div>
)

export const UserSearchResultTileListLoading: FC = () => (
    <>
        <UserSearchResultLoadingTile />
        <UserSearchResultLoadingTile />
        <UserSearchResultLoadingTile />
        <UserSearchResultLoadingTile />
    </>
)

export const UserSearchResultSectionLoading: FC = () => (
    <>
        <Skeleton
            style={{ marginBottom: 12 }}
            width={140}
            height={26}
            borderRadius={24}
        />

        <div className={styles.list}>
            <UserSearchResultTileListLoading />
        </div>
    </>
)
