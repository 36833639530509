import { AnimatePresence, motion } from "framer-motion"
import { FC } from "react"

import { MyProfileAvatar } from "../my-profile-avatar"

import { useMyUserProfile } from "hooks/use-my-user-profiles"
import * as styles from "../my-user-profile-page.css"

type MyUserProfileToolbarModel = {
    isVisible: boolean
    isAuthorizedAccount: boolean
}

export const MyUserProfileToolbar: FC<MyUserProfileToolbarModel> = model => {
    const myProfileQuery = useMyUserProfile(model.isAuthorizedAccount)

    return (
        <AnimatePresence key="my-profile-toolbar-animate">
            {model.isVisible === false && (
                <motion.div
                    key="my-profile-toolbar"
                    className={styles.toolbar}
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                >
                    <div className={styles.toolbarProfileContainer}>
                        <MyProfileAvatar
                            avatarSize={32}
                            isAuthorizedAccount={model.isAuthorizedAccount}
                        />

                        <p className={styles.toolbarUsername}>
                            {myProfileQuery.isSuccess ? (
                                (myProfileQuery.data?.displayName ??
                                myProfileQuery.data?.profileName)
                            ) : (
                                <></>
                            )}
                        </p>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
