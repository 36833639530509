import {
    faHeart,
    faMessage,
    faShare,
    faCircleStar as lightStar,
} from "@fortawesome/pro-light-svg-icons"
import {
    faHeart as faHeartSolid,
    faCircleStar as solidStar,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { motion } from "framer-motion"
import { FC } from "react"

import {
    ContentPaymentState,
    MonetizationOption,
} from "../../../../api/api-models"
import { AvatarModel } from "../../../../components/avatars/avatar"
import { AvatarLoading } from "../../../../components/avatars/avatar-loading"

import { Link } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { useTranslation } from "react-i18next"
import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { postTimeInfo } from "utils/time"
import * as styles from "./user-details.css"

export type UserDetailsModel = {
    loc: { payed: string }
    className?: string
    avatarSize?: AvatarModel["size"]
    contentLastChangedAt?: string
    profileId?: UserProfileId
    votes: number
    comments: number
    sharedCount?: number
    liked: boolean
    monetization: MonetizationOption
    contentPaymentState: ContentPaymentState
    isBuyContentAvailable?: boolean

    onShareClicked: () => void
    onPurchaseClicked: () => void

    onCommentDetailsClicked: () => void
    onLikeChangedClicked: () => void
    onLikeDetailsClicked: () => void
}
export const UserDetails: FC<UserDetailsModel> = ({
    className = "",
    loc,
    avatarSize,
    contentLastChangedAt,
    liked,
    votes,
    comments,
    sharedCount = 0,
    monetization,
    contentPaymentState,
    isBuyContentAvailable,
    profileId,

    onPurchaseClicked,
    onLikeChangedClicked,
    onCommentDetailsClicked,
    onShareClicked,
    onLikeDetailsClicked,
}) => {
    const { t, i18n } = useTranslation("common")
    const intl = useIntl()
    const myProfileIdQuery = useMyUserProfileId()
    const profileQuery = useListUserProfileById(profileId)

    const profile = profileQuery.data?.data

    const paymentRequired =
        !!contentPaymentState && contentPaymentState.type === "Required"

    return (
        <div className={classNames(styles.wrapper, className)}>
            {profileQuery.isSuccess && profile && (
                <Link
                    to={
                        myProfileIdQuery.data === profile.id
                            ? "/app/my-profile"
                            : "/app/user-profile/$id"
                    }
                    params={{ id: profile.id }}
                >
                    <div className={styles.content}>
                        <AvatarWithBadge
                            profileId={profile.id}
                            className={styles.avatar}
                            src={profile.imageUrl}
                            size={avatarSize}
                            styleState={profile.isLive ? "isLive" : "None"}
                        />

                        <div>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    color: "white",
                                }}
                            >
                                {profile.displayName}
                            </p>

                            {contentLastChangedAt && (
                                <p
                                    style={{
                                        marginTop: "2px",
                                        fontSize: vars.font.size.xs,
                                        color: "white",
                                        opacity: 0.75,
                                    }}
                                >
                                    {postTimeInfo({
                                        date: contentLastChangedAt,
                                        i18nLang: i18n.language,
                                        t,
                                        detailed: true,
                                    })}
                                </p>
                            )}
                        </div>
                    </div>
                </Link>
            )}

            <div className={styles.icons}>
                {onPurchaseClicked &&
                    monetization.type === "SubscriptionOnly" && (
                        <div
                            className={classNames(
                                styles.lines,
                                !isBuyContentAvailable && styles.disabled,
                            )}
                            onClick={() =>
                                paymentRequired && isBuyContentAvailable
                                    ? onPurchaseClicked()
                                    : {}
                            }
                        >
                            {paymentRequired && (
                                <>
                                    <FontAwesomeIcon
                                        className={styles.icon}
                                        icon={lightStar}
                                        color="white"
                                    />

                                    <p
                                        style={{
                                            fontSize: vars.font.size.xs,
                                            color: "white",
                                        }}
                                    >
                                        {intl.formatNumber(
                                            monetization.amount,
                                            {
                                                style: "currency",
                                                currency: monetization.currency,
                                                currencyDisplay: "symbol",
                                            },
                                        )}
                                    </p>
                                </>
                            )}
                            {!paymentRequired && (
                                <>
                                    <FontAwesomeIcon
                                        className={styles.icon}
                                        icon={solidStar}
                                        color="white"
                                    />

                                    <p
                                        style={{
                                            fontSize: vars.font.size.xs,
                                            color: "white",
                                        }}
                                    >
                                        {loc.payed}
                                    </p>
                                </>
                            )}
                        </div>
                    )}

                {liked ? (
                    <div className={styles.lines}>
                        <motion.div
                            key="like-heart"
                            className={styles.lines}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.14,
                                ease: [0, 0.71, 0.2, 1.01],
                                scale: {
                                    type: "spring",
                                    damping: 5,
                                    stiffness: 100,
                                    restDelta: 0.025,
                                },
                            }}
                        >
                            <FontAwesomeIcon
                                className={styles.icon}
                                style={{ color: vars.color.liveColor.hex }}
                                icon={faHeartSolid}
                                onClick={onLikeChangedClicked}
                            />
                        </motion.div>
                        <p
                            style={{
                                fontSize: vars.font.size.xs,
                                color: "white",
                                pointerEvents: votes <= 0 ? "none" : "inherit",
                            }}
                            onClick={onLikeDetailsClicked}
                        >
                            {votes}
                        </p>
                    </div>
                ) : (
                    <div className={styles.lines}>
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={faHeart}
                            onClick={onLikeChangedClicked}
                        />
                        <p
                            style={{
                                fontSize: vars.font.size.xs,
                                color: "white",
                                pointerEvents: votes <= 0 ? "none" : "inherit",
                            }}
                            onClick={onLikeDetailsClicked}
                        >
                            {votes}
                        </p>
                    </div>
                )}

                <div onClick={onCommentDetailsClicked} className={styles.lines}>
                    <FontAwesomeIcon
                        className={styles.iconMessage}
                        icon={faMessage}
                    />
                    <p
                        style={{
                            fontSize: vars.font.size.xs,
                            color: "white",
                        }}
                    >
                        {comments}
                    </p>
                </div>

                <div className={styles.lines}>
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={faShare}
                        onClick={onShareClicked}
                    />
                    <p
                        style={{
                            fontSize: vars.font.size.xs,
                            color: "white",
                        }}
                    >
                        {sharedCount}
                    </p>
                </div>
            </div>
        </div>
    )
}

export const UserDetailsLoading = () => (
    <div className={styles.wrapper}>
        <div className={styles.content}>
            <AvatarLoading size={36} />
            <Skeleton width={75} height={18} borderRadius={24} />
        </div>
    </div>
)
