import { queryOptions, useQuery } from "@tanstack/react-query"

import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { StaticQueryKeys } from "../api/query-keys"

const getMyProfiles = async () => {
    const profilesRes = await userProfileSelectorClient.getMyProfiles(undefined)
    return {
        profiles: profilesRes.data,
    }
}

export type ProfilesResponse = Awaited<ReturnType<typeof getMyProfiles>>

type MyUserProfilesQueryOptionsParams<TReturn = ProfilesResponse> = {
    select?: (data: ProfilesResponse) => TReturn
    enabled?: boolean
}

export const myUserProfilesQueryOptions = <TReturn = ProfilesResponse>(
    params: MyUserProfilesQueryOptionsParams<TReturn>,
) =>
    queryOptions({
        queryFn: getMyProfiles,
        queryKey: [StaticQueryKeys.MY_PROFILES],
        select: params.select,
        enabled: params.enabled,
    })

export const useMyUserProfiles = <TReturn = ProfilesResponse>(
    select?: (data: ProfilesResponse) => TReturn,
    enabled?: boolean,
) => {
    return useQuery(myUserProfilesQueryOptions({ select, enabled }))
}

export const useMyUserProfile = (enabled?: boolean) =>
    useMyUserProfiles(data => data.profiles.at(0), enabled)

export const useMyUserProfileId = (enabled?: boolean) =>
    useMyUserProfiles(data => data.profiles.at(0)?.id, enabled)
