import { Zodios } from "@zodios/core"

import { API_URL } from "../../envs"
import {
    corsFetchHandler,
    tokenHandlerForCreate,
    validationErrorHandler,
} from "../api-utils"
import { paymentCreatorApi } from "../creators/payment-creator-api"
import { paymentSelectorApi } from "../selectors/payment-selector-api"

// creator client
export const paymentCreatorClient = new Zodios(
    `${API_URL}/payment-creator`,
    paymentCreatorApi,
)

paymentCreatorClient.use(tokenHandlerForCreate)
paymentCreatorClient.use(corsFetchHandler)
paymentCreatorClient.use(validationErrorHandler)

// selector client
export const paymentSelectorClient = new Zodios(
    `${API_URL}/payment-selector`,
    paymentSelectorApi,
)

paymentSelectorClient.use(tokenHandlerForCreate)
paymentSelectorClient.use(corsFetchHandler)
paymentSelectorClient.use(validationErrorHandler)
