import classNames from "classnames"

import { UserProfileId } from "api/branded-types"
import { useUser } from "hooks/use-user"
import { useUserProfile } from "hooks/use-user-profile"
import { ProfileDataLoading } from "./my-user-profile-data"

import { FirstAdopterBadge } from "features/first-adopters/first-adopters-badge"
import { useTranslation } from "react-i18next"
import * as styles from "../user-profile-page.css"
import { UserProfileLocation } from "./user-profile-location"
import { UserProfileName } from "./user-profile-name"

export type UserProfilePageStatusModel = {
    profileId?: UserProfileId
}

export const UserProfilePageStatus = (model: UserProfilePageStatusModel) => {
    const { t } = useTranslation("userProfile")
    const { t: tCommon } = useTranslation("common")

    const userProfileQuery = useUserProfile(model.profileId)
    const userQuery = useUser(userProfileQuery.data?.userId)

    return (
        <>
            {userProfileQuery.isSuccess ? (
                <div className={styles.userStatusesWrapper}>
                    <div className={styles.profileNameSectionStatusContainer}>
                        <div className={styles.rows}>
                            <UserProfileName {...model} />
                            {userProfileQuery.data?.isLive && (
                                <div
                                    className={classNames(
                                        styles.chip,
                                        styles.playState,
                                        styles.noMargin,
                                    )}
                                >
                                    {t("live.text")}
                                </div>
                            )}
                        </div>
                        <FirstAdopterBadge profileId={model.profileId} />
                    </div>
                    <div className={styles.rows}>
                        <p className={styles.textLight}>
                            <small>
                                {userProfileQuery.data?.profileName}
                                &nbsp;&#x2022;&nbsp;
                                {userQuery.data?.isOnline
                                    ? tCommon("online")
                                    : tCommon("offline")}
                            </small>
                        </p>
                    </div>
                    <UserProfileLocation profileId={model.profileId} />
                </div>
            ) : (
                <ProfileDataLoading />
            )}
        </>
    )
}
