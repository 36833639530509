import { useQueries, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import classNames from "classnames"
import { PostAreaTile } from "features/feed/areas/post/post-area-tile"
import { VideoAreaTile } from "features/feed/areas/videos/video-area-tile"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { isEmpty } from "fp-ts/string"
import { assetsQueryOptions } from "hooks/use-asset"
import { useImagePostsSearch } from "hooks/use-image-post-search"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useLiveEventsSearch } from "hooks/use-live-event-search"
import { useTextPostsSearch } from "hooks/use-text-post-search"
import { useUserProfilesSearch } from "hooks/use-user-profiles-search"
import { useVideosSearch } from "hooks/use-videos-search"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"
import { fill } from "utils/array"
import { ResultNotFoundPlaceholderView } from "./result-not-found-placeholder-view"
import { SectionWithTitle } from "./section-with-title"
import { UserSearchResultTileList } from "./users/tile/user-search-result-tile-list"
import { PostLoadingTile } from "./videos/post-loading-tile"

import {
    LiveEventSearchResultLoadingTile,
    LiveEventSearchResultTile,
} from "./live-events/live-event-search-result-tile"
import * as styles from "./search-page-all-tab-content.css"
import { SearchPageTab } from "./store/search-store"

type SearchPageAllTabContentModel = {
    profileId?: UserProfileId
    searchKey: string
    debouncedSearchKey: string
    setSearchTab: (tab: SearchPageTab) => void
}

export const SearchPageAllTabContent: FC<SearchPageAllTabContentModel> = ({
    searchKey,
    debouncedSearchKey,
    profileId,
    setSearchTab,
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation(["search"])
    const queryClient = useQueryClient()

    const userProfilesSearchQuery = useUserProfilesSearch({
        searchKey: debouncedSearchKey,
        profileId,
    })

    const creatorIds =
        userProfilesSearchQuery.data?.pages
            .flatMap(page => page.data)
            .map(item => item.id) ?? []

    const userDependandQueriesEnabled =
        (!!debouncedSearchKey && !userProfilesSearchQuery.isLoading) ||
        !debouncedSearchKey

    const videosQuerySearchQuery = useVideosSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })
    const liveEventQuerySearchQuery = useLiveEventsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })
    const imagePostsQuerySearchQuery = useImagePostsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })
    const textPostsQuerySearchQuery = useTextPostsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })

    const isSearchKeyEmpty = isEmpty(debouncedSearchKey)

    const imageProfileIdsPages =
        imagePostsQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const imageProfileQueries = useQueries({
        queries: imageProfileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfImageProfileQueriesLoading = imageProfileQueries.some(
        query => query.isLoading,
    )

    const imagePostResourceAssetRefsPages = pipe(
        imagePostsQuerySearchQuery.data?.pages ?? [],

        A.map(page =>
            pipe(
                page.data,
                A.filter(post => post.type === "Image"),
                A.map(post => post.imageRef),
            ),
        ),
    )

    const imageAssetQueries = useQueries({
        queries: imagePostResourceAssetRefsPages.map((page, idx) =>
            assetsQueryOptions({
                pageNumber: idx,
                queryClient,
                resources: page,
            }),
        ),
    })

    const oneOfImageAssetQueriesLoading = imageAssetQueries.some(
        query => query.isLoading,
    )

    const videoProfileIdsPages =
        videosQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const videoProfileQueries = useQueries({
        queries: videoProfileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfVideoProfileQueriesLoading = videoProfileQueries.some(
        query => query.isLoading,
    )

    const textProfileIdsPages =
        textPostsQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const textProfileQueries = useQueries({
        queries: textProfileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfTextProfileQueriesLoading = textProfileQueries.some(
        query => query.isLoading,
    )

    const liveEventProfileIdsPages =
        liveEventQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const liveEventProfileQueries = useQueries({
        queries: liveEventProfileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfLiveEventProfileQueriesLoading = liveEventProfileQueries.some(
        query => query.isLoading,
    )

    const hasAvailableUserProfiles =
        (userProfilesSearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !userProfilesSearchQuery.isPending &&
        userProfilesSearchQuery.isSuccess

    const hasAvailableVideos =
        (videosQuerySearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !videosQuerySearchQuery.isPending &&
        videosQuerySearchQuery.isSuccess

    const hasAvailableLiveEvents =
        (liveEventQuerySearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !liveEventQuerySearchQuery.isPending &&
        liveEventQuerySearchQuery.isSuccess

    const hasAvailableImagePosts =
        (imagePostsQuerySearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !imagePostsQuerySearchQuery.isPending &&
        imagePostsQuerySearchQuery.isSuccess

    const hasAvailableTextPosts =
        (textPostsQuerySearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !textPostsQuerySearchQuery.isPending &&
        textPostsQuerySearchQuery.isSuccess

    const isPending =
        liveEventQuerySearchQuery.isPending ||
        videosQuerySearchQuery.isPending ||
        userProfilesSearchQuery.isPending ||
        imagePostsQuerySearchQuery.isPending ||
        textPostsQuerySearchQuery.isPending

    const isNoResult =
        !isSearchKeyEmpty &&
        !hasAvailableImagePosts &&
        !hasAvailableTextPosts &&
        !hasAvailableLiveEvents &&
        !hasAvailableUserProfiles &&
        !hasAvailableVideos &&
        !isPending

    const showUserProfilesTitle =
        hasAvailableUserProfiles || userProfilesSearchQuery.isPending
    const showLiveStreamTitle =
        hasAvailableLiveEvents || liveEventQuerySearchQuery.isPending

    const showVideosTitle =
        hasAvailableVideos || videosQuerySearchQuery.isPending

    const showImagePostsTitle =
        hasAvailableImagePosts || imagePostsQuerySearchQuery.isPending

    const showTextPostsTitle =
        hasAvailableTextPosts || textPostsQuerySearchQuery.isPending

    const videosState = match(videosQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const imagePostsState = match(imagePostsQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const textPostsState = match(textPostsQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)
    const liveEventsState = match(liveEventQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const showImagePostsLoadingState =
        imagePostsState === "Loading" ||
        oneOfImageAssetQueriesLoading ||
        oneOfImageProfileQueriesLoading
    const showVideoLoadingState =
        videosState === "Loading" || oneOfVideoProfileQueriesLoading
    const showTextPostLoadingState =
        textPostsState === "Loading" || oneOfTextProfileQueriesLoading
    const showLiveEventLoadingState =
        liveEventsState === "Loading" || oneOfLiveEventProfileQueriesLoading
    return (
        <>
            {
                //? PROFILE LIST
            }
            <SectionWithTitle
                className={classNames(
                    showUserProfilesTitle ? styles.section : "",
                )}
                title={
                    isSearchKeyEmpty
                        ? t("tabs.all.default.profile.title")
                        : t("tabs.all.active.profile.title")
                }
                showTitle={showUserProfilesTitle}
                action={{
                    text: t("tabs.all.active.profile.action"),
                    onClick: () =>
                        isSearchKeyEmpty
                            ? navigate({
                                  to: "/app/search/profiles",
                              })
                            : setSearchTab("Profile"),
                }}
            >
                <UserSearchResultTileList
                    isLoading={userProfilesSearchQuery.isLoading}
                    profiles={pipe(
                        userProfilesSearchQuery?.data?.pages ?? [],
                        A.flatMap(page => page.data),
                        A.takeLeft(4),
                    )}
                    text={{
                        action: t("tabs.profile.action"),
                    }}
                />
            </SectionWithTitle>
            {
                //? VIDEOS
            }
            <SectionWithTitle
                className={classNames(showVideosTitle ? styles.section : "")}
                title={
                    isSearchKeyEmpty
                        ? t("section.video.titleA")
                        : t("section.video.titleB")
                }
                showTitle={showVideosTitle}
                action={{
                    text: t("tabs.all.active.video.action"),
                    onClick: isSearchKeyEmpty
                        ? () =>
                              navigate({
                                  to: "/app/search/videos",
                              })
                        : () => setSearchTab("Video"),
                }}
            >
                <div className={styles.postContentSection}>
                    {!videoProfileQueries.at(0)?.isLoading &&
                        videosQuerySearchQuery.data?.pages
                            .at(0)
                            ?.data.slice(0, 4)
                            .map(video => (
                                <VideoAreaTile
                                    loc={{
                                        title: t("section.video.exclusive"),
                                    }}
                                    key={video.id}
                                    videoId={video.id}
                                />
                            ))}

                    {showVideoLoadingState &&
                        fill(2, idx => <PostLoadingTile key={idx} />)}
                </div>
            </SectionWithTitle>
            {
                //? IMAGE POSTS
            }
            <SectionWithTitle
                className={classNames(
                    showImagePostsTitle ? styles.section : "",
                )}
                title={
                    isSearchKeyEmpty
                        ? t("section.image.titleA")
                        : t("section.image.titleB")
                }
                showTitle={showImagePostsTitle}
                action={{
                    text: t("tabs.all.active.video.action"),
                    onClick: isSearchKeyEmpty
                        ? () =>
                              navigate({
                                  to: "/app/search/image-posts",
                              })
                        : () => setSearchTab("Image"),
                }}
            >
                <div className={styles.postContentSection}>
                    {!imageProfileQueries.at(0)?.isLoading &&
                        imagePostsQuerySearchQuery.data?.pages
                            .at(0)
                            ?.data.slice(0, 4)
                            .map(postit => (
                                <PostAreaTile
                                    key={postit.id}
                                    loc={{
                                        title: t("section.image.exclusive"),
                                    }}
                                    postId={postit.id}
                                />
                            ))}

                    {showImagePostsLoadingState &&
                        fill(2, idx => <PostLoadingTile key={idx} />)}
                </div>
            </SectionWithTitle>
            {
                //? TEXT POSTS
            }
            <SectionWithTitle
                className={classNames(showTextPostsTitle ? styles.section : "")}
                title={
                    isSearchKeyEmpty
                        ? t("section.text.titleA")
                        : t("section.text.titleB")
                }
                showTitle={showTextPostsTitle}
                action={{
                    text: t("tabs.all.active.video.action"),
                    onClick: isSearchKeyEmpty
                        ? () =>
                              navigate({
                                  to: "/app/search/text-posts",
                              })
                        : () => setSearchTab("Text"),
                }}
            >
                <div className={styles.postContentSection}>
                    {!textProfileQueries.at(0)?.isLoading &&
                        textPostsQuerySearchQuery.data?.pages
                            .at(0)
                            ?.data.slice(0, 4)
                            .map(postit => (
                                <PostAreaTile
                                    key={postit.id}
                                    loc={{
                                        title: t("section.text.exclusive"),
                                    }}
                                    postId={postit.id}
                                />
                            ))}

                    {showTextPostLoadingState &&
                        fill(2, idx => <PostLoadingTile key={idx} />)}
                </div>
            </SectionWithTitle>
            {
                //? LIVE EVENTS
            }
            <SectionWithTitle
                className={classNames(
                    showLiveStreamTitle ? styles.section : "",
                )}
                title={
                    isSearchKeyEmpty
                        ? t("tabs.all.default.liveStream.title")
                        : t("tabs.all.active.liveStream.title")
                }
                showTitle={showLiveStreamTitle}
                action={{
                    text: t("tabs.all.active.liveStream.action"),
                    onClick: () =>
                        isSearchKeyEmpty
                            ? navigate({
                                  to: "/app/search/live-events",
                              })
                            : setSearchTab("LiveEvent"),
                }}
            >
                <div className={styles.list}>
                    {!liveEventProfileQueries.at(0)?.isLoading &&
                        liveEventQuerySearchQuery.data?.pages
                            .at(0)
                            ?.data.slice(0, 4)
                            .map(event => (
                                <LiveEventSearchResultTile
                                    key={event.id}
                                    liveEventId={event.id}
                                    stateText={"Live"}
                                />
                            ))}
                    {showLiveEventLoadingState &&
                        fill(4, idx => (
                            <LiveEventSearchResultLoadingTile key={idx} />
                        ))}
                </div>
            </SectionWithTitle>

            {/* No Results Found */}
            {isNoResult && (
                <div className={styles.section}>
                    <ResultNotFoundPlaceholderView searchKey={searchKey} />
                </div>
            )}
        </>
    )
}
