import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "common/button"
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "common/dialog"
import { FC } from "react"
import { vars } from "theme/variables.css"
import { usePaymentModalStore } from "./store/payment-modal-store"

import { calc } from "@vanilla-extract/css-utils"
import { NAVIGATION_MARGIN_TOP } from "theme/global.css"
import * as styles from "./proceed-with-payment-modal.css"

export type ProceedWithPaymentModalModel = {
    loc: {
        title: string
        descriptionA: string
        descriptionB: string
        descriptionC: string
        action: string
    }
}

export const ProceedWithPaymentModal: FC<ProceedWithPaymentModalModel> = ({
    loc,
}) => {
    const url = usePaymentModalStore(store => store.proceedWithPaymentUrl)
    const setUrl = usePaymentModalStore(store => store.setProceedWithPaymentUrl)
    const open = !!url
    const onClosed = () => setUrl(undefined)
    return (
        <Dialog open={open} onOpenChange={open => !open && onClosed()}>
            <DialogContent className={styles.content}>
                <DialogClose
                    style={{
                        top: calc.add(
                            "env(safe-area-inset-top, 0)",
                            NAVIGATION_MARGIN_TOP,
                        ),
                    }}
                    asChild
                >
                    <FontAwesomeIcon
                        className={styles.closeIcon}
                        icon={faXmark}
                    />
                </DialogClose>
                <DialogHeader className={styles.header}>
                    <DialogTitle>{loc.title}</DialogTitle>
                    <DialogDescription>
                        {loc.descriptionA}{" "}
                        <span
                            style={{
                                fontSize: vars.font.size.regular,
                                fontWeight: 700,
                            }}
                        >
                            {loc.descriptionB}
                        </span>{" "}
                        {loc.descriptionC}
                    </DialogDescription>
                </DialogHeader>
                <Button
                    full
                    variant="dark"
                    onClick={() => {
                        window.open(url, "_blank")
                        onClosed()
                    }}
                >
                    {loc.action}
                </Button>
            </DialogContent>
        </Dialog>
    )
}
