import { QueryClient } from "@tanstack/react-query"
import { createRootRouteWithContext, useNavigate } from "@tanstack/react-router"
import { NotFoundPage } from "features/routing/not-found-page"
import { RootBoundary } from "features/routing/root-boundary"
import { ProviderOutlet } from "providers/provider-outlet"
import { lazy, Suspense } from "react"
import { AuthContextProps } from "react-oidc-context"
import { FileRouteTypes } from "routeTree.gen"

interface MyRouterContext {
    auth: AuthContextProps
    queryClient: QueryClient
}

const isLocalDevMode =
    import.meta.env.DEV && import.meta.url.includes("https://localhost:3000")

const TanStackRouterDevtools = isLocalDevMode
    ? lazy(async () => {
          const module = await import("@tanstack/router-devtools")

          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
          return { default: module.TanStackRouterDevtools }
      })
    : () => null // Render nothing in production

export const Route = createRootRouteWithContext<MyRouterContext>()({
    component: () => (
        <>
            <ProviderOutlet />
            <Suspense>
                <TanStackRouterDevtools />
            </Suspense>
        </>
    ),
    notFoundComponent: () => <NotFoundPage />,
    errorComponent: errorProps => <RootBoundary {...errorProps} />,
})

export type NavigateFunction = ReturnType<typeof useNavigate>
export type Route = FileRouteTypes["to"]
export type FullRoute = `${string}${Route}${string}`
