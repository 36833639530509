import { useGesture } from "@use-gesture/react"
import {
    AnimatePresence,
    HTMLMotionProps,
    motion,
    useMotionValue,
} from "framer-motion"
import { FC, useRef, useState } from "react"
import { vars } from "theme/variables.css"
import * as styles from "./image-view.css"

type ImageViewModel = HTMLMotionProps<"img">

export const ImageView: FC<ImageViewModel> = ({ id, ...props }) => {
    const layoutId = `image-view-${id}`
    const ref = useRef<HTMLImageElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const scale = useMotionValue(1)
    const x = useMotionValue(0)
    const y = useMotionValue(0)
    const opacity = useMotionValue(0)

    const getImageBounds = () => {
        const imageBounds = ref.current?.getBoundingClientRect()

        const containerBounds = containerRef.current?.getBoundingClientRect()

        if (!containerBounds || !imageBounds) {
            return { left: 0, right: 0, top: 0, bottom: 0 }
        }

        const leftBound = ((imageBounds.width - containerBounds.width) / 2) * -1
        const rightBound = (imageBounds.width - containerBounds.width) / 2
        const topBound = ((imageBounds.height - window.innerHeight) / 2) * -1
        const bottomBound = (imageBounds.height - window.innerHeight) / 2

        return {
            left: Math.min(leftBound, 0),
            right: Math.max(rightBound, 0),
            top: Math.min(topBound, 0),
            bottom: Math.max(bottomBound, 0),
        }
    }

    const adjustImageAfterPinchOut = () => {
        const imageBounds = ref.current?.getBoundingClientRect()
        const containerBounds = containerRef.current?.getBoundingClientRect()

        if (!imageBounds || !containerBounds) return
        //X Axis
        if (imageBounds.left > containerBounds.left) {
            const offset = containerBounds.x - imageBounds.x
            x.set(x.get() + offset)
        } else if (imageBounds.right < containerBounds.right) {
            const offset = containerBounds.right - imageBounds.right
            x.set(x.get() + offset)
        }
        //Y Axis
        if (imageBounds.top > containerBounds.top) {
            const offset = containerBounds.y - imageBounds.y
            y.set(y.get() + offset)
        } else if (imageBounds.bottom < containerBounds.bottom) {
            const offset = containerBounds.bottom - imageBounds.bottom
            y.set(y.get() + offset)
        }
    }

    const adjustImageAfterDragEnd = () => {
        const imageBounds = ref.current?.getBoundingClientRect()
        const containerBounds = containerRef.current?.getBoundingClientRect()

        if (!imageBounds || !containerBounds) return
        const imageExceedsScreen = window.innerWidth < imageBounds.width

        if (!imageExceedsScreen) {
            x.set(0)
        } else if (imageBounds.left > containerBounds.left) {
            const offset = containerBounds.x - imageBounds.x
            x.set(x.get() + offset)
        } else if (imageBounds.right < containerBounds.right) {
            const offset = containerBounds.right - imageBounds.right
            x.set(x.get() + offset)
        }
        if (imageBounds.bottom < containerBounds.bottom) {
            const offset = containerBounds.bottom - imageBounds.bottom
            y.set(y.get() + offset)
        }
    }

    const closeImage = () => {
        scale.jump(1)
        x.jump(0)
        y.jump(0)
        setOpened(false)
    }

    useGesture(
        {
            onDrag: ({ offset: [dx, dy] }) => {
                x.set(dx)
                y.set(dy)
            },

            onPinch: ({ offset: [s] }) => {
                scale.set(s)
            },
            onPinchEnd: adjustImageAfterPinchOut,
            onDragEnd: adjustImageAfterDragEnd,
        },
        {
            target: ref,
            eventOptions: { passive: false },
            drag: {
                from: () => [x.get(), y.get()],
                bounds: getImageBounds,
                rubberband: true,
            },
            pinch: {
                scaleBounds: { min: 1, max: 2.5 },
                from: () => [scale.get(), 0],
            },
        },
    )

    const [opened, setOpened] = useState(false)

    return (
        <div>
            <motion.img
                {...props}
                onClick={e => {
                    props.onClick && props.onClick(e)
                    setOpened(true)
                }}
                layoutId={layoutId}
            />
            <AnimatePresence>
                {opened && (
                    <>
                        <motion.div
                            initial={{
                                zIndex: 0,
                                opacity: 0,
                            }}
                            animate={{
                                zIndex: 1000,
                                opacity: 1,
                            }}
                            exit={{
                                zIndex: 0,
                                opacity: 0,
                            }}
                            transition={{
                                duration: 0.15,
                                type: "tween",
                                ease: "easeInOut",
                            }}
                            className={styles.hidingElement}
                            style={{
                                opacity,
                            }}
                        />

                        <motion.div
                            initial={{
                                backgroundColor: `rgba(${vars.color.primary.contrastRgb}, 0)`,
                            }}
                            animate={{
                                backgroundColor: `rgba(${vars.color.primary.contrastRgb}, 0.4)`,
                            }}
                            exit={{
                                backgroundColor: `rgba(${vars.color.primary.contrastRgb}, 0)`,
                            }}
                            className={styles.backdrop}
                            onClick={closeImage}
                        >
                            <div
                                className={styles.container}
                                ref={containerRef}
                            >
                                <motion.img
                                    {...props}
                                    layoutId={layoutId}
                                    ref={ref}
                                    onClick={e => e.stopPropagation()}
                                    onPan={(_, info) => {
                                        const imageBounds =
                                            ref.current?.getBoundingClientRect()
                                        if (!imageBounds) return

                                        const yDistance = info.offset.y
                                        const normalized =
                                            (yDistance * 2) / imageBounds.height

                                        opacity.set(1 - normalized)
                                    }}
                                    onPanEnd={(_, info) => {
                                        const imageBounds =
                                            ref.current?.getBoundingClientRect()
                                        if (!imageBounds) return

                                        const isDistanceLongEnough =
                                            info.offset.y >=
                                            (100 * scale.get()) / 2
                                        const isPanningTopBound =
                                            imageBounds.y >= 0 ||
                                            (imageBounds.y < 0 &&
                                                imageBounds.y > -1)

                                        const shouldCloseImage =
                                            isDistanceLongEnough &&
                                            isPanningTopBound

                                        if (shouldCloseImage) {
                                            closeImage()
                                        } else {
                                            opacity.set(1)
                                        }
                                    }}
                                    transition={{
                                        duration: 0.15,
                                        type: "tween",
                                        ease: "easeInOut",
                                    }}
                                    className={styles.image}
                                    style={{
                                        x,
                                        y,
                                        scale,
                                    }}
                                />
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </div>
    )
}
