import { calc } from "@vanilla-extract/css-utils"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useAsset } from "hooks/use-asset"
import { useUserProfile } from "hooks/use-user-profile"
import { FC } from "react"
import { isImageAsset } from "utils/asset"
import { useProfileStatistics } from "../hooks/use-profile-statistic"
import { UserProfilePageStatusModel } from "./user-profile-page-status"

import { SvgImage } from "common/svg-image"
import * as styles from "../user-profile-page.css"

export const UserProfileName: FC<UserProfilePageStatusModel> = model => {
    const userProfileQuery = useUserProfile(model.profileId)
    const myProfileStatistics = useProfileStatistics(model.profileId)

    const currentTierLogoAssetQuery = useAsset({
        resource: myProfileStatistics.data?.currentTier.logoRef,
    })

    const currentTierLogoImageAsset = pipe(
        currentTierLogoAssetQuery.data,
        O.fromNullable,
        O.chain(O.fromPredicate(isImageAsset)),
    )
    return (
        <div className={styles.profileNameContainer}>
            <h1
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textWrap: "nowrap",
                    maxWidth: calc.subtract("100dvw", calc.add("30px", "40px")),
                }}
            >
                {userProfileQuery.data?.displayName ??
                    userProfileQuery.data?.profileName}
            </h1>
            {O.isSome(currentTierLogoImageAsset) ? (
                <SvgImage
                    width={currentTierLogoImageAsset.value.resolution.width}
                    height={currentTierLogoImageAsset.value.resolution.height}
                    src={currentTierLogoImageAsset.value.source}
                    className={styles.tierIcon}
                />
            ) : (
                <div style={{ width: 16, height: 16 }} />
            )}
        </div>
    )
}
