import { useTranslation } from "react-i18next"
import { ContentBlockedModal } from "./modals/content-blocked-modal"
import { ContentReviewModal } from "./modals/content-review-modal"
import { usePostitModerationStore } from "./store/postit-moderation-store"

export const ContentModerationModalProvider = () => {
    const postitModerationStore = usePostitModerationStore()

    const { t } = useTranslation("modal")

    return (
        <>
            <ContentBlockedModal
                open={postitModerationStore.blockedModalOpen}
                onOpenChange={postitModerationStore.setBlockedModalOpen}
                actionNavigateOptions={{
                    to:
                        postitModerationStore.content?.type === "Text"
                            ? "/app/text-edit/$id"
                            : "/app/image-edit/$id",
                    params: { id: postitModerationStore.content?.id },
                }}
                reasons={postitModerationStore.moderationData?.categories ?? []}
                loc={{
                    title: t("contentModeration.blocked.title"),
                    descriptionFirst: t(
                        "contentModeration.blocked.descriptionFirst",
                    ),
                    descriptionSecond: t(
                        "contentModeration.blocked.descriptionSecond",
                    ),
                    descriptionThird: t(
                        "contentModeration.blocked.descriptionThird",
                    ),
                    reasons: t("contentModeration.blocked.reasons"),
                    action: t("contentModeration.blocked.action"),
                    policyAction: t("contentModeration.blocked.policyAction"),
                }}
            />
            <ContentReviewModal
                open={postitModerationStore.reviewModalOpen}
                onOpenChange={postitModerationStore.setReviewModalOpen}
                reasons={postitModerationStore.moderationData?.categories ?? []}
                loc={{
                    title: t("contentModeration.review.title"),
                    descriptionFirst: t(
                        "contentModeration.review.descriptionFirst",
                    ),
                    descriptionSecond: t(
                        "contentModeration.review.descriptionSecond",
                    ),
                    reasons: t("contentModeration.review.reasons"),
                    action: t("contentModeration.review.action"),
                    policyAction: t("contentModeration.review.policyAction"),
                    disclaimerFirst: t(
                        "contentModeration.review.disclaimerFirst",
                    ),
                    disclaimerSecond: t(
                        "contentModeration.review.disclaimerSecond",
                    ),
                }}
            />
        </>
    )
}
