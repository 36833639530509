import { makeApi } from "@zodios/core"
import z from "zod"

import {
    ApiError,
    SubscribeUserProfileRequestBody,
    UpdateUserProfile,
    UpdateUserSubscriptionMonetization,
    UserProfile,
    UserProfileStatistic,
    UserSubscriptionMonetization,
} from "../api-models"
import { Url, UserId, UserName, UserProfileId } from "../branded-types"

export const userProfileCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/user/:id/profile",
        alias: "createProfile",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    username: UserName,
                }),
            },
        ],
        response: z.object({
            data: UserProfile,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/profile/:id",
        alias: "changeProfileById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: UpdateUserProfile.optional(),
            },
        ],
        response: z.object({
            data: UserProfile,
            uploads: z.object({
                image: Url,
            }),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/profile/:id/follow",
        alias: "followUserProfile",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    profileId: UserProfileId,
                }),
            },
        ],
        response: z.object({
            data: UserProfileStatistic,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/profile/:id/unfollow",
        alias: "unfollowUserProfile",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    profileId: UserProfileId,
                }),
            },
        ],
        response: z.object({
            data: UserProfileStatistic,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/profile/:id/subscribe",
        alias: "subscribeUserProfile",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: SubscribeUserProfileRequestBody,
            },
        ],
        response: z.object({
            data: Url,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/profile/:id/unsubscribe",
        alias: "unsubscribeUserProfile",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    profileId: UserProfileId,
                }),
            },
        ],
        response: z.object({
            data: UserProfileStatistic,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/points/reset/:points",
        alias: "resetPointSystem",
        parameters: [
            {
                name: "points",
                type: "Path",
                schema: z.number(),
            },
        ],
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "put",
        path: "/v1/profile/monetization",
        alias: "updateSubscriptionMonetization",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: UpdateUserSubscriptionMonetization,
            },
        ],
        response: z.object({
            data: UserSubscriptionMonetization,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/profile/monetization",
        alias: "deleteSubscriptionMonetization",
        response: z.object({
            data: UserSubscriptionMonetization,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
