import { InfiniteScroll } from "common/infinite-scroll"
import { FC } from "react"
import { ResultNotFoundPlaceholderView } from "./result-not-found-placeholder-view"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { UserProfileId } from "api/branded-types"
import { PostAreaTile } from "features/feed/areas/post/post-area-tile"
import { isEmpty } from "fp-ts/string"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useTextPostsSearch } from "hooks/use-text-post-search"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"
import { fill } from "utils/array"
import { PostLoadingTile } from "./videos/post-loading-tile"

type SearchPageTextTabContentModel = {
    profileId?: UserProfileId
    creatorIds: UserProfileId[]
    searchKey: string
    debouncedSearchKey: string
    className?: string
    userDependandQueriesEnabled: boolean
}

export const SearchPageTextTabContent: FC<SearchPageTextTabContentModel> = ({
    searchKey,
    debouncedSearchKey,
    profileId,
    creatorIds,

    className,
    userDependandQueriesEnabled,
}) => {
    const { t } = useTranslation(["search"])

    const textPostsQuerySearchQuery = useTextPostsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })

    const queryClient = useQueryClient()

    const profileIdsPages =
        textPostsQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const isSearchKeyEmpty = isEmpty(debouncedSearchKey)

    const hasAvailableTextPosts =
        (textPostsQuerySearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !textPostsQuerySearchQuery.isPending &&
        textPostsQuerySearchQuery.isSuccess

    const isNoResultTextPost = !isSearchKeyEmpty && !hasAvailableTextPosts

    const textPostsState = match(textPostsQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const showLoadingState =
        textPostsQuerySearchQuery.isFetchingNextPage ||
        textPostsQuerySearchQuery.isLoading ||
        oneOfProfileQueriesLoading

    return (
        <InfiniteScroll
            disabled={!textPostsQuerySearchQuery.hasNextPage}
            state={textPostsState}
            threshold="100px"
            onLoadRequested={textPostsQuerySearchQuery.fetchNextPage}
        >
            <div className={className}>
                {textPostsQuerySearchQuery?.data?.pages.map(
                    (page, idx) =>
                        !profileQueries.at(idx)?.isLoading &&
                        page.data.map(postit => (
                            <PostAreaTile
                                key={postit.id}
                                loc={{
                                    title: t("section.text.exclusive"),
                                }}
                                postId={postit.id}
                            />
                        )),
                )}
                {showLoadingState &&
                    fill(2, idx => <PostLoadingTile key={idx} />)}
            </div>
            {isNoResultTextPost && (
                <ResultNotFoundPlaceholderView searchKey={searchKey} />
            )}
        </InfiniteScroll>
    )
}
