import {
    ImagePostitUpdated,
    PostitCreated,
    PostitModerationData,
    TextPostitUpdated,
} from "api/api-models"
import { create } from "zustand"

type State = {
    blockedModalOpen: boolean
    reviewModalOpen: boolean

    content?: PostitCreated | TextPostitUpdated | ImagePostitUpdated
    moderationData?: PostitModerationData
}

type Actions = {
    setBlockedModalOpen: (v: State["blockedModalOpen"]) => void
    setReviewModalOpen: (v: State["reviewModalOpen"]) => void

    setContent: (v: State["content"]) => void
    setModerationData: (v: State["moderationData"]) => void
}

const initialState: State = {
    blockedModalOpen: false,
    reviewModalOpen: false,
    content: undefined,
    moderationData: undefined,
}

export const usePostitModerationStore = create<Actions & State>()(set => ({
    ...initialState,

    setBlockedModalOpen: v =>
        v === true ? set({ blockedModalOpen: v }) : set(initialState),
    setReviewModalOpen: v =>
        v === true ? set({ reviewModalOpen: v }) : set(initialState),

    setContent: v => set({ content: v }),
    setModerationData: v => set({ moderationData: v }),
}))
