import { faCrown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQueryClient, useSuspenseInfiniteQuery } from "@tanstack/react-query"
import { UserProfileId } from "api/branded-types"
import { listFirstUsersInfiniteQueryOptions } from "hooks/use-list-first-users"
import { FC } from "react"
import { Avatar, AvatarModel } from "./avatar"

import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import * as styles from "./avatar-with-badge.css"

type AvatarWithBadgeModel = AvatarModel & { profileId?: UserProfileId }

export const AvatarWithBadge: FC<AvatarWithBadgeModel> = ({
    profileId,
    ...rest
}) => {
    const queryClient = useQueryClient()

    const userProfileQuery = useListUserProfileById(profileId)
    const profile = userProfileQuery.data?.data
    const firstUsersQuery = useSuspenseInfiniteQuery(
        listFirstUsersInfiniteQueryOptions(queryClient),
    )

    const firstUserIds = firstUsersQuery.data.pages.flatMap(page =>
        page.data.map(item => item.id),
    )
    //TODO: consider moving this computation into cache
    const isFirstAdopter =
        userProfileQuery.isSuccess &&
        profile &&
        firstUserIds.includes(profile.userId)

    return (
        <div className={styles.container}>
            <Avatar {...rest} />
            {isFirstAdopter && (
                <div
                    className={styles.badgeContainer}
                    style={{
                        width: rest.size ? Math.floor(rest.size * 0.4) : "40%",
                        height: rest.size ? Math.floor(rest.size * 0.4) : "40%",
                    }}
                >
                    <FontAwesomeIcon
                        icon={faCrown}
                        className={styles.icon}
                        style={{
                            width: rest.size
                                ? Math.floor(rest.size * 0.2)
                                : "50%",
                        }}
                    />
                </div>
            )}
        </div>
    )
}
