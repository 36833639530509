import { PaymentId } from "api/branded-types"
import { create } from "zustand"

type State = {
    successPaymentModalOpen: boolean
    failedPaymentModalOpen: boolean
    proceedWithPaymentUrl?: string
    proceedWithSubscriptionUrl?: string
    walletPaymentDetails:
        | {
              open: true
              walletPaymentDetailsId: PaymentId
          }
        | { open: false }
}

type Actions = {
    setSuccessPaymentModalOpen: (v: State["successPaymentModalOpen"]) => void
    setFailedPaymentModalOpen: (v: State["failedPaymentModalOpen"]) => void
    setProceedWithPaymentUrl: (v: State["proceedWithPaymentUrl"]) => void
    setWalletPaymentDetailsOpen: (v: State["walletPaymentDetails"]) => void
    setProceedWithSubscriptionUrl: (
        v: State["proceedWithSubscriptionUrl"],
    ) => void
}

export const usePaymentModalStore = create<State & Actions>(set => ({
    failedPaymentModalOpen: false,
    setFailedPaymentModalOpen: v => set({ failedPaymentModalOpen: v }),
    successPaymentModalOpen: false,
    setSuccessPaymentModalOpen: v => set({ successPaymentModalOpen: v }),
    proceedWithPaymentUrl: undefined,
    setProceedWithPaymentUrl: v => set({ proceedWithPaymentUrl: v }),
    proceedWithSubscriptionUrl: undefined,
    setProceedWithSubscriptionUrl: v => set({ proceedWithSubscriptionUrl: v }),
    walletPaymentDetails: { open: false },
    setWalletPaymentDetailsOpen: v => set({ walletPaymentDetails: v }),
}))
