import classNames from "classnames"
import {
    ComponentPropsWithoutRef,
    CSSProperties,
    ElementRef,
    forwardRef,
} from "react"
import * as styles from "./content.css"

type ContentModel = {
    fullscreen?: boolean
    wrapperClassName?: string
    wrapperStyles?: CSSProperties
}

// TODO needs refactoring for better usage and maintainability
export const Content = forwardRef<
    ElementRef<"div">,
    ComponentPropsWithoutRef<"div"> & ContentModel
>(
    (
        {
            className,
            fullscreen,
            children,
            wrapperClassName,
            wrapperStyles,
            ...props
        },
        ref,
    ) => (
        <div
            ref={ref}
            {...props}
            className={classNames(
                styles.content,
                fullscreen ? styles.fullscreenWrapper : "",
                wrapperClassName,
            )}
            {...(wrapperStyles ? { style: wrapperStyles } : {})}
        >
            <div
                className={classNames(
                    styles.contentInner,
                    fullscreen ? styles.fullscreen : "",
                    className,
                )}
            >
                {children}
            </div>
        </div>
    ),
)
