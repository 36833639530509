/* eslint-disable no-useless-escape */

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"

import { listPayments } from "data-flow/payment"
import { QueryKeys } from "../api/query-keys"

export const PAYMENTS_PAGINATION_AMOUNT = 50
const queryKey = QueryKeys.payments()

export const usePayments = () => {
    const queryClient = useQueryClient()
    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) => listPayments({ pageParam, queryClient }),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAYMENTS_PAGINATION_AMOUNT)
                return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAYMENTS_PAGINATION_AMOUNT
                : -1
        },
    })
}
