import { faOctagonExclamation } from "@fortawesome/pro-light-svg-icons"
import {
    useIsMutating,
    useMutation,
    useQueryClient,
    useSuspenseQuery,
} from "@tanstack/react-query"
import { OptionButton } from "components/buttons/options-button"
import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"
import { identity, pipe } from "fp-ts/function"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import {
    ShareContentId,
    Url,
    UserProfileId,
    VideoId,
} from "../../api/branded-types"
import { createShareTokenTask } from "../../api/clients/auth-api-client"
import { QueryKeys, StaticQueryKeys } from "../../api/query-keys"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { CommentOverviewModal } from "../../components/comments/comment-overview-modal"
import { PlayStateAdt } from "../../components/controls/play-state-control"
import { NewFeatureComingSoonModal } from "../../components/modals/new-feature-coming-soon-modal"
import { ShareOverviewAlertModal } from "../../components/modals/share-overview-alert-modal"
import { VoterOverviewModal } from "../../components/modals/voter-overview-modal"
import { createShareUrl } from "../../data-flow/sharing"
import { useMe } from "../../hooks/use-me"
import { useMyUserProfiles } from "../../hooks/use-my-user-profiles"
import { isNotDefined } from "../../utils/object"
import { DeletePostDialog } from "./components/delete-post-dialog"
import { PostConsumerActionsModal } from "./components/post-consumer-actions-modal"
import { ContentPayModal } from "./content-pay-modal"
import { VideoConsumerControlWithPlayer } from "./controls/video-consumer-with-video-details-control"
import { useDeleteVideoMutation } from "./hooks/use-delete-video-mutation"
import { useMyResourceVote } from "./hooks/use-my-resource-vote"
import { useResourceComments } from "./hooks/use-resource-comments"
import { useResourceVotes } from "./hooks/use-resource-votes"
import { useRevokeVoteMutation } from "./hooks/use-revoke-vote-mutation"
import { useVoteMutation } from "./hooks/use-vote-mutation"

import classNames from "classnames"
import { Loading } from "common/loading"
import * as A from "fp-ts/Array"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { getIsAuthorizedAccount } from "../../api/api-utils"
import { Content } from "../../common/content"
import { Footer } from "../../common/footer"
import { Page } from "../../common/page"
import {
    UserDetails,
    UserDetailsLoading,
} from "../feed/areas/user/user-details"

import { VideoDetailsLoading } from "./details/video-details"
import { videoByIdQueryOptions } from "./hooks/use-video-by-id"

import { faBagShopping } from "@fortawesome/pro-solid-svg-icons"
import { useParams, useRouter } from "@tanstack/react-router"
import {
    BuyVideoByIdRequestBody,
    SubscribeUserProfileRequestBody,
} from "api/api-models"
import { shareLinkClient } from "api/clients/share-link-api-client"
import { videoCreatorClient } from "api/clients/video-api-client"
import { AlertSection } from "common/alert-section"
import { Button } from "common/button"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { useIsPaymentActivated } from "features/payment/hooks/use-is-payment-activated"
import { useReportStore } from "features/report/report-store"
import { creatorReferenceStrategy } from "features/strategies/user/content-reference-strategy"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"
import { FullRoute } from "routes/__root"

import { userProfileCreatorClient } from "api/clients/user-profile-api-client"
import {
    PaymentStatus,
    SubscriptionStatus,
} from "features/payment/store/payment-messaging-store"
import { usePaymentModalStore } from "features/payment/store/payment-modal-store"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useIntl } from "react-intl"
import { vars } from "theme/variables.css"
import { OwnPostConsumerActionsModal } from "./components/own-post-consumer-actions-modal"
import * as styles from "./video-consumer-page.css"

type BuyVideoByIdMutationParams = BuyVideoByIdRequestBody & { id: VideoId }

type SubscribeUserMutationParams = {
    redirects: SubscribeUserProfileRequestBody["redirects"]
    idToSubscribe: UserProfileId
}

type BuildPaymentRedirectUrlModel = {
    status: PaymentStatus
    videoId: VideoId
}

const buildPaymentRedirectUrl = (
    model: BuildPaymentRedirectUrlModel,
): FullRoute =>
    `${location.origin}/paymentCallback.html?payment_status=${model.status}&payment_type=video&payment_content_id=${model.videoId}`

type BuildSubscriptionRedirectUrlModel = {
    status: SubscriptionStatus
    profileId: UserProfileId
    videoId: VideoId
}

const buildSubscriptionRedirectUrl = (
    model: BuildSubscriptionRedirectUrlModel,
): FullRoute =>
    `${location.origin}/paymentCallback.html?subscription_status=${model.status}&subscription_type=video&subscription_profile_id=${model.profileId}&subscription_content_id=${model.videoId}`

export const VideoConsumerPage: FC = () => {
    const { t } = useTranslation(["videoViewer"])
    const router = useRouter()
    const [isShareLinkInCreation, setIsShareLinkInCreation] = useState(false)
    const {
        accountType,
        ensureUserPreventDefaultCallback,
        ensureUserCallback,
    } = useOnlyRegisteredUser()
    const { t: tCommon } = useTranslation(["common"])
    const intl = useIntl()
    const params = useParams({ from: "/_authenticated/app/video/viewer/$id" })

    const setReportContentOpen = useReportStore(
        store => store.setReportContentOpen,
    )

    const meQuery = useMe()
    const videoQuery = useSuspenseQuery(videoByIdQueryOptions(params.id))
    const video = videoQuery.data

    const contentCreatorProfileQuery = useListUserProfileById(video.creatorId)

    const profile = contentCreatorProfileQuery.data?.data

    const isPaymentActivatedQuery = useIsPaymentActivated(
        profile?.userId,
        profile?.id,
    )

    const isBuyContentAvailable = isPaymentActivatedQuery.data?.isAvailable

    const [isMonetizeProcessInProgress, setMonetizeProcessInProgress] =
        useState(false)

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const { mutate: subscribeUserProfile } = useMutation({
        mutationFn: (param: SubscribeUserMutationParams) =>
            userProfileCreatorClient.subscribeUserProfile(
                { redirects: param.redirects },
                { params: { id: param.idToSubscribe } },
            ),

        onError: err => {
            console.error(err)
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faOctagonExclamation}
                        iconColor="danger"
                        text={tCommon("subscribe.error.toast")}
                    />
                ),
            })
        },

        onSuccess: ({ data }) => {
            setSubscriptionUrl(data)
        },
    })

    const [likingOverviewOpen, setLikingOverviewOpen] = useState(false)

    const [deleteVideoDialogVisible, setDeleteVideoDialogVisible] =
        useState(false)

    const [commentOverviewOpen, setCommentOverviewOpen] = useState(false)

    const [actionsOpen, setActionsOpen] = useState(false)

    const [newFeatureModalOpen, setNewFeatureModalOpen] = useState(false)

    const [playState, setPlayState] = useState(PlayStateAdt.of.None({}))
    const [shareUrl, setShareUrl] = useState<undefined | string>()
    const [showShareModal, setShowShareModal] = useState(false)
    const [isVideoPayModalOpen, showVideoPayModal] = useState(false)

    const userId = meQuery.data?.user.id

    const myProfilesQuery = useMyUserProfiles(identity, isAuthorizedAccount)

    const myProfiles = myProfilesQuery.isSuccess
        ? myProfilesQuery.data.profiles
        : []

    const myProfileId = myProfiles.at(0)?.id

    const videoCreatorId = video.creatorId

    const videoCommentsQuery = useResourceComments({
        contentId: params.id,
        contentType: "Video",
    })

    const videoVotesQuery = useResourceVotes({
        contentId: params.id,
        contentType: "Video",
    })

    const voter = useMemo(
        () =>
            creatorReferenceStrategy({
                accountType,
                userId,
                userProfileId: myProfileId,
            }),
        [accountType, myProfileId, userId],
    )

    const myVideoVote = useMyResourceVote({
        voteResourceRef: {
            contentId: params.id,
            contentType: "Video",
        },
        voter,
    })

    const totalComments = videoCommentsQuery.data?.pages.at(0)?.totalCount ?? 0
    const totalVotes = videoVotesQuery.data?.pages.at(0)?.totalCount ?? 0

    const isLiked = myVideoVote?.data?.isLiked ?? false
    const voteId = myVideoVote.data?.voteId ?? O.none

    const { mutate: deleteVideoMutation } = useDeleteVideoMutation()

    const { mutate: vote } = useVoteMutation()
    const { mutate: revokeVote } = useRevokeVoteMutation()

    const votingUpMutates = useIsMutating({
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_UP],
    })
    const votingDownMutates = useIsMutating({
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_DOWN],
    })

    const isVoting = votingDownMutates !== 0 || votingUpMutates !== 0

    const queryClient = useQueryClient()

    const { toast } = useInfoToast()

    const setPaymentUrl = usePaymentModalStore(
        store => store.setProceedWithPaymentUrl,
    )
    const setSubscriptionUrl = usePaymentModalStore(
        store => store.setProceedWithSubscriptionUrl,
    )
    const setWalletPaymentDetailsOpen = usePaymentModalStore(
        store => store.setWalletPaymentDetailsOpen,
    )

    const { mutate: buyVideoById } = useMutation({
        mutationFn: ({ redirects, id }: BuyVideoByIdMutationParams) =>
            videoCreatorClient.buyVideoById({ redirects }, { params: { id } }),
        onSuccess: ({ data: locationUrl }) => {
            setPaymentUrl(locationUrl)
        },
        onError: () => {
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faBagShopping}
                        iconColor="danger"
                        text={t("purchase.toast.error.buy")}
                    />
                ),
            })
        },
        onSettled: () => {
            console.error("I am settled")
            //Does not take any effect somhow
            queryClient.invalidateQueries({
                queryKey: QueryKeys.payments(),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.payableVideo(params.id),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.video(params.id),
            })
            setMonetizeProcessInProgress(false)
        },
    })

    //TODO: rework this to show modal immediately after click
    const shareVideo = useCallback(() => {
        setIsShareLinkInCreation(true)

        pipe(
            createShareTokenTask(
                {
                    contents: [
                        {
                            contentType: "Video",
                            contentFilter: ShareContentId.parse(params.id),
                        },
                    ],
                },
                {},
            ),
            TE.bimap(
                error => {
                    setIsShareLinkInCreation(false)
                    console.error("Error share", error)
                },
                ({ data }) => {
                    shareLinkClient
                        .createShortenedLink({
                            link: createShareUrl(data),
                        })
                        .then(({ shortURL }) => {
                            setShareUrl(shortURL)
                            setShowShareModal(true)
                            setIsShareLinkInCreation(false)
                        })
                        .catch(() => {
                            setIsShareLinkInCreation(false)
                            toast({
                                toastContent: (
                                    <InfoToastContent
                                        icon={faOctagonExclamation}
                                        iconColor="danger"
                                        text={tCommon("share.error.title")}
                                    />
                                ),
                            })
                        })
                },
            ),
        )()
    }, [params.id])

    const isMyContent = useCallback(() => {
        if (!videoCreatorId) return
        if (isNotDefined(myProfileId)) return

        return videoCreatorId === myProfileId
    }, [videoCreatorId, myProfileId])

    const backgroundImageUrl = video.previewImageUrl
        ? `${video.previewImageUrl}?height=${window.innerHeight * 2}`
        : ""

    return (
        <Page>
            <NavigationBackButton />
            <Loading
                open={isShareLinkInCreation}
                message={tCommon("loading")}
            />
            <Loading
                open={isMonetizeProcessInProgress}
                message={tCommon("loading")}
            />

            <OptionButton
                onClicked={ensureUserPreventDefaultCallback(video.creatorId)(
                    () => setActionsOpen(true),
                )}
            />

            <Content className={styles.content} fullscreen>
                <LazyLoadImage
                    src={backgroundImageUrl}
                    width={window.innerWidth}
                    height={window.innerHeight}
                    className={styles.backgroundImage}
                />
                <div className={styles.overlay} />
                {isBuyContentAvailable &&
                    contentCreatorProfileQuery.isSuccess &&
                    profile && (
                        <ContentPayModal
                            open={isVideoPayModalOpen}
                            text={{
                                purchase: {
                                    amount: intl.formatNumber(
                                        videoQuery.data.monetization.type ===
                                            "SubscriptionOnly"
                                            ? videoQuery.data.monetization
                                                  .amount
                                            : 0,
                                        {
                                            style: "currency",
                                            currency: "EUR",
                                            currencyDisplay: "symbol",
                                        },
                                    ),
                                },
                                subscribe: {
                                    action: t("subscribe.action", {
                                        name:
                                            profile.displayName ??
                                            profile.profileName,
                                    }),
                                    title: t("subscribe.title"),
                                },
                                terms: t("terms.text"),
                            }}
                            user={{
                                imageUrl: profile?.imageUrl,
                                name:
                                    profile.displayName ?? profile.profileName,
                                isLive: profile.isLive,
                            }}
                            profileId={video.creatorId}
                            content={{
                                imageUrl:
                                    video.previewImageUrl ??
                                    Url.parse("areas/img_bg_0"),
                                title: video.title,
                                type: "Video",
                            }}
                            onCanceled={() => showVideoPayModal(false)}
                            purchaseActionDisabled={isMonetizeProcessInProgress}
                            onPurchased={() => {
                                if (
                                    video.monetization.type !==
                                    "SubscriptionOnly"
                                )
                                    return

                                if (isNotDefined(myProfileId)) return
                                showVideoPayModal(false)
                                setMonetizeProcessInProgress(true)

                                buyVideoById({
                                    redirects: {
                                        succeeded: Url.parse(
                                            buildPaymentRedirectUrl({
                                                status: "succeeded",
                                                videoId: video.id,
                                            }),
                                        ),
                                        failed: Url.parse(
                                            buildPaymentRedirectUrl({
                                                status: "failed",
                                                videoId: video.id,
                                            }),
                                        ),
                                        canceled: Url.parse(
                                            buildPaymentRedirectUrl({
                                                status: "canceled",
                                                videoId: video.id,
                                            }),
                                        ),
                                        pending: Url.parse(
                                            buildPaymentRedirectUrl({
                                                status: "pending",
                                                videoId: video.id,
                                            }),
                                        ),
                                    },
                                    id: params.id,
                                })
                            }}
                            onSubscribed={() => {
                                if (!myProfileId) return

                                subscribeUserProfile({
                                    idToSubscribe: video.creatorId,
                                    redirects: {
                                        succeeded: Url.parse(
                                            buildSubscriptionRedirectUrl({
                                                status: "succeeded",
                                                profileId: video.creatorId,
                                                videoId: video.id,
                                            }),
                                        ),
                                        failed: Url.parse(
                                            buildSubscriptionRedirectUrl({
                                                status: "failed",
                                                profileId: video.creatorId,
                                                videoId: video.id,
                                            }),
                                        ),
                                        canceled: Url.parse(
                                            buildSubscriptionRedirectUrl({
                                                status: "canceled",
                                                profileId: video.creatorId,
                                                videoId: video.id,
                                            }),
                                        ),
                                        pending: Url.parse(
                                            buildSubscriptionRedirectUrl({
                                                status: "pending",
                                                profileId: video.creatorId,
                                                videoId: video.id,
                                            }),
                                        ),
                                    },
                                })
                                showVideoPayModal(false)
                            }}
                        />
                    )}

                <NewFeatureComingSoonModal
                    open={newFeatureModalOpen}
                    onOpenChange={setNewFeatureModalOpen}
                    text={{
                        title: tCommon("newFeatureComingSoon.title"),
                        description: tCommon(
                            "newFeatureComingSoon.description",
                        ),
                    }}
                />
                {pipe(
                    myProfiles,
                    A.map(profile => profile.id),
                    profileIds =>
                        pipe(
                            videoCreatorId,
                            O.fromNullable,
                            O.chain(creatorId =>
                                pipe(
                                    profileIds,
                                    A.findFirst(id => id === creatorId),
                                    a => a,
                                ),
                            ),
                            O.fold(
                                () => (
                                    <PostConsumerActionsModal
                                        open={actionsOpen}
                                        onOpenChange={setActionsOpen}
                                        onReportClicked={ensureUserCallback(
                                            video.creatorId,
                                        )(() => {
                                            setActionsOpen(false)
                                            setReportContentOpen({
                                                open: true,
                                                content: {
                                                    contentId: video.id,
                                                    contentType: "Video",
                                                },
                                                contentOwner: video.creatorId,
                                            })
                                        })}
                                        text={{ report: t("content.report") }}
                                    />
                                ),
                                () => (
                                    <OwnPostConsumerActionsModal
                                        type="Video"
                                        open={actionsOpen}
                                        dismiss={() => setActionsOpen(false)}
                                        onEditClicked={ensureUserCallback(
                                            video.creatorId,
                                        )(() => setActionsOpen(false))}
                                        onDeleteClicked={ensureUserCallback(
                                            video.creatorId,
                                        )(() => {
                                            setActionsOpen(false)
                                            setDeleteVideoDialogVisible(true)
                                        })}
                                    />
                                ),
                            ),
                        ),
                )}

                <VoterOverviewModal
                    voteContentRef={{
                        contentType: "Video",
                        contentId: params.id,
                    }}
                    title={t("likes.title")}
                    open={likingOverviewOpen}
                    onOpenChange={setLikingOverviewOpen}
                    onUserClicked={() => {
                        setLikingOverviewOpen(false)
                    }}
                />
                <DeletePostDialog
                    open={deleteVideoDialogVisible}
                    onSubmit={() => {
                        if (isNotDefined(myProfileId)) return

                        deleteVideoMutation({
                            id: params.id,
                            profileId: myProfileId,
                        })
                        setDeleteVideoDialogVisible(false)
                        router.history.back()
                    }}
                    onOpenChange={setDeleteVideoDialogVisible}
                    text={{
                        cancel: t("dialog.cancel"),
                        submit: t("dialog.submit"),
                        title: t("dialog.title"),
                        description: t("dialog.description"),
                    }}
                />

                <CommentOverviewModal
                    text={{
                        inputPlaceholder: t("comments.inputPlaceholder"),
                        title: count =>
                            count === 1
                                ? t("comments.title.single")
                                : t("comments.title.multiple", { count }),
                        noComments: t("comments.noComments"),
                        empty: {
                            title: t("comments.empty.title"),
                            text: t("comments.empty.text"),
                        },
                    }}
                    open={commentOverviewOpen}
                    onOpenChange={setCommentOverviewOpen}
                    resourceOwnerId={videoCreatorId}
                    commentResourceRef={{
                        contentType: "Video",
                        contentId: params.id,
                    }}
                />

                <ShareOverviewAlertModal
                    open={showShareModal}
                    text={{
                        title: t("video.title"),
                        share: {
                            title: t("video.share.title"),
                            description: t("video.share.description"),
                        },
                        confirm: t("video.confirm"),
                    }}
                    onOpenChange={setShowShareModal}
                    shareUrl={O.fromNullable(shareUrl)}
                />

                <VideoConsumerControlWithPlayer
                    text={{ seeMore: tCommon("more") }}
                    playState={playState}
                    onChanged={PlayStateAdt.matchStrict({
                        None: () => setPlayState(PlayStateAdt.as.Playing({})),
                        Pausing: () =>
                            setPlayState(PlayStateAdt.as.Playing({})),
                        Playing: () =>
                            setPlayState(PlayStateAdt.as.Pausing({})),
                        Ended: () => {},
                    })}
                    onError={console.error}
                    onPurchaseClicked={ensureUserCallback(video.creatorId)(
                        () =>
                            !isMyContent() &&
                            contentCreatorProfileQuery.isSuccess &&
                            profile &&
                            userId !== profile.userId
                                ? showVideoPayModal(true)
                                : undefined,
                    )}
                />

                {video.paymentState.type === "Pending" && (
                    <>
                        <div className={styles.videoBottomOverlay} />

                        <AlertSection
                            className={styles.paymentNotFinishedBanner}
                        >
                            <div
                                className={styles.paymentNotFinishedBannerInner}
                            >
                                <p style={{ fontSize: vars.font.size.s }}>
                                    Payment is in progress, finish the payment
                                    first.
                                </p>
                                <Button
                                    variant="light"
                                    className={styles.bannerButton}
                                    onClick={() =>
                                        video.paymentState.type === "Pending" &&
                                        setWalletPaymentDetailsOpen({
                                            open: true,
                                            walletPaymentDetailsId:
                                                video.paymentState.paymentId,
                                        })
                                    }
                                >
                                    Show details
                                </Button>
                            </div>
                        </AlertSection>
                    </>
                )}
            </Content>
            <Footer className={styles.footer}>
                {contentCreatorProfileQuery.isSuccess && profile && (
                    <UserDetails
                        loc={{ payed: "payed" }}
                        contentLastChangedAt={video.lastChangedAt}
                        avatarSize={36}
                        profileId={video.creatorId}
                        isBuyContentAvailable={isBuyContentAvailable}
                        contentPaymentState={video.paymentState}
                        votes={totalVotes}
                        comments={totalComments}
                        onLikeChangedClicked={ensureUserCallback(
                            video.creatorId,
                        )(() => {
                            if (isVoting) return
                            if (myVideoVote.isFetching) return
                            if (!myVideoVote.isSuccess) return
                            if (!voter) return

                            if (!myVideoVote.data.isLiked) {
                                vote({
                                    voter,
                                    resourceOwnerId: video.creatorId,
                                    voteResourceRef: {
                                        contentType: "Video",
                                        contentId: params.id,
                                    },
                                })
                            } else if (O.isSome(voteId)) {
                                revokeVote({
                                    voter,
                                    voteResourceRef: {
                                        contentType: "Video",
                                        contentId: params.id,
                                    },
                                    voteId: voteId.value,
                                })
                            }
                        })}
                        monetization={video.monetization}
                        liked={isLiked}
                        onCommentDetailsClicked={ensureUserCallback(
                            video.creatorId,
                        )(() => setCommentOverviewOpen(true))}
                        onShareClicked={ensureUserCallback(video.creatorId)(
                            () => shareVideo(),
                        )}
                        onLikeDetailsClicked={() => {
                            if (totalVotes === 0) return

                            setLikingOverviewOpen(true)
                        }}
                        onPurchaseClicked={ensureUserCallback(video.creatorId)(
                            () =>
                                !isMyContent() && userId !== profile?.userId
                                    ? showVideoPayModal(true)
                                    : undefined,
                        )}
                    />
                )}
                {videoQuery.isLoading && <UserDetailsLoading />}
            </Footer>
        </Page>
    )
}

export const VideoConsumerPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content className={classNames(styles.content, styles.contentLoading)}>
            <VideoDetailsLoading className={styles.userDetailsLoading} />
        </Content>
        <Footer>
            <UserDetailsLoading />
        </Footer>
    </Page>
)

export default VideoConsumerPage
