import { NavigateOptions } from "@tanstack/react-router"
import { PaymentResourceRef } from "api/api-models"
import { absurd } from "fp-ts/function"

export const paymentContentRouteStrategy = (
    ref: PaymentResourceRef,
): NavigateOptions => {
    switch (ref.contentType) {
        case "LiveEvent": {
            return {
                to: "/app/live-event/viewer/$id",
                params: { id: ref.contentId },
            }
        }
        case "Postit": {
            return {
                to: "/app/postit/viewer/$id",
                params: { id: ref.contentId },
            }
        }
        case "Video": {
            return {
                to: "/app/video/viewer/$id",
                params: { id: ref.contentId },
            }
        }
        case "Profile": {
            return {
                to: "/app/user-profile/$id",
                params: { id: ref.contentId },
            }
        }
        default: {
            absurd(ref)
            return {}
        }
    }
}
