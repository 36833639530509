import classNames from "classnames"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"
import * as styles from "./footer.css"

export const Footer = forwardRef<
    ElementRef<"div">,
    ComponentPropsWithoutRef<"div">
>(({ className, children, ...props }, ref) => (
    <div ref={ref} {...props} className={classNames(styles.footer, className)}>
        {children}
    </div>
))
