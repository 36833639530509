import { createFileRoute } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import { UserOverviewPageLoading } from "features/feed/areas/user/user-loading-overview-page"

export const Route = createFileRoute(
    "/_authenticated/app/followings/overview/$id",
)({
    pendingComponent: UserOverviewPageLoading,
    params: {
        parse: params => ({
            id: UserProfileId.parse(params.id),
        }),
    },
    loader: () => {},
})
