import { createFileRoute } from "@tanstack/react-router"
import { VideoId } from "api/branded-types"
import { FallbackPending } from "features/routing/fallback-pending"

export const Route = createFileRoute("/_authenticated/app/video-edit/$id")({
    params: {
        parse: params => ({
            id: VideoId.parse(params.id),
        }),
    },
    loader: () => {},
    pendingComponent: FallbackPending,
})
