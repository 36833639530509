import { QueryClient, queryOptions } from "@tanstack/react-query"
import { Video } from "api/api-models"
import { mapToMatchFilterDc } from "api/api-utils"
import { VideoId } from "api/branded-types"
import { videoSelectorClient } from "api/clients/video-api-client"
import { QueryKeys } from "api/query-keys"

export const listVideos = async (ids: VideoId[], queryClient: QueryClient) => {
    const uniqueIds = Array.from(new Set(ids))

    const videosRes = await videoSelectorClient.listVideos({
        filter: `{${mapToMatchFilterDc("id", uniqueIds)}}`,
        paging: {
            type: "Index",
            direction: "After",
            index: -1,
            limit: uniqueIds.length,
        },
    })
    videosRes.data.forEach(data =>
        queryClient.setQueryData<{ data: Video; error: undefined }>(
            QueryKeys.video(data.id),
            { data, error: undefined },
        ),
    )
    return videosRes.data
}

export const listVideosQueryOptions = (
    pageNumber: number,
    ids: VideoId[],
    queryClient: QueryClient,
) =>
    queryOptions({
        queryKey: QueryKeys.listVideos(pageNumber, ids),
        queryFn: () => listVideos(ids, queryClient),
        enabled: ids.length > 0,
    })
