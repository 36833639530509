import { createFileRoute } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import { UserProfilePageLoading } from "features/user-profile/user-profile-page"

export const Route = createFileRoute(
    "/_authenticated/app/_tab-bar-layout/user-profile/$id",
)({
    pendingComponent: UserProfilePageLoading,
    params: {
        parse: params => ({
            id: UserProfileId.parse(params.id),
        }),
    },
    loader: () => {},
})
