import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"
import { pipe } from "fp-ts/function"
import { FC, useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ShareContentId, Url, UserProfileId } from "../../api/branded-types"
import { createShareTokenTask } from "../../api/clients/auth-api-client"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { ShareOverviewAlertModal } from "../../components/modals/share-overview-alert-modal"
import { FollowStateAdt } from "../../data-flow/follower"
import { createShareUrl } from "../../data-flow/sharing"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { useIsAlreadySubscribing } from "../../hooks/use-subscribers"
import { isNotDefined } from "../../utils/object"
import {
    ProfileDescription,
    ProfileDescriptionLoading,
} from "./components/profile-description"
import {
    ProfileStatistics,
    ProfileStatisticsLoading,
} from "./components/profile-statistics"
import { UserProfilePageActions } from "./components/user-profile-page-actions"
import { UserProfilePagePostContent } from "./components/user-profile-page-post-content"
import { UserProfilePageStatus } from "./components/user-profile-page-status"
import { UserProfileToolbar } from "./components/user-profile-toolbar"
import { UserProfilePageFollowButton } from "./following/user-profile-page-follow-button"
import { useFollowUserProfileMutation } from "./hooks/use-follow-user-profile-mutation"
import { useUnfollowUserProfileMutation } from "./hooks/use-unfollow-user-profile-mutation"
import {
    SubscribeButton,
    SubscriptionStateAdt,
} from "./subscription/subscribe-button"

import { faOctagonExclamation } from "@fortawesome/pro-light-svg-icons"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useParams } from "@tanstack/react-router"
import { SubscribeUserProfileRequestBody } from "api/api-models"
import { shareLinkClient } from "api/clients/share-link-api-client"
import { userProfileCreatorClient } from "api/clients/user-profile-api-client"
import { QueryKeys, StaticQueryKeys } from "api/query-keys"
import { Loading } from "common/loading"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { NewFeatureComingSoonModal } from "components/modals/new-feature-coming-soon-modal"
import { useIsSubscriptionSupported } from "features/payment/hooks/use-is-subscription-supported"
import { useProfileSubscriptionMonetization } from "features/payment/hooks/use-profile-subscription-monetization"
import { SubscriptionStatus } from "features/payment/store/payment-messaging-store"
import { usePaymentModalStore } from "features/payment/store/payment-modal-store"
import { useReportStore } from "features/report/report-store"
import { useInView } from "framer-motion"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"
import { useUserProfile } from "hooks/use-user-profile"
import { useIntl } from "react-intl"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Skeleton from "react-loading-skeleton"
import { FullRoute } from "routes/__root"
import {
    getIsAuthorizedAccount,
    withAuthorizedAccess,
} from "../../api/api-utils"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { TabsListLoading } from "../../components/tabs/tabs"
import { getSafeArea } from "../../utils/constant"
import { PostLoadingTile } from "../search/videos/post-loading-tile"
import { ProfileDataLoading } from "./components/my-user-profile-data"
import { ProfileActionsModal } from "./components/profile-actions-modal"
import { useIsFollowingByProfileId } from "./hooks/use-following-state-by-profile-id"
import { SubscriptionModal } from "./subscription/subscription-modal"
import * as styles from "./user-profile-page.css"

type SubscribeUserMutationParams = {
    redirects: SubscribeUserProfileRequestBody["redirects"]
    idToSubscribe: UserProfileId
}

type BuildPaymentRedirectUrlModel = {
    status: SubscriptionStatus
    profileId: UserProfileId
}

const buildSubscriptionRedirectUrl = (
    model: BuildPaymentRedirectUrlModel,
): FullRoute =>
    `${location.origin}/paymentCallback.html?subscription_status=${model.status}&subscription_type=profile&subscription_profile_id=${model.profileId}`

export const UserProfilePage: FC = () => {
    const params = useParams({
        from: "/_authenticated/app/_tab-bar-layout/user-profile/$id",
    })
    const intl = useIntl()

    const queryClient = useQueryClient()

    const profileSubscriptionMonetizationQuery =
        useProfileSubscriptionMonetization(params.id)
    const subscriptionSupportedQuery = useIsSubscriptionSupported(params.id)

    const {
        accountType,
        ensureUser,
        ensureUserCallback,
        ensureUserPreventDefault,
    } = useOnlyRegisteredUser()

    const setSubscriptionUrl = usePaymentModalStore(
        store => store.setProceedWithSubscriptionUrl,
    )

    const [isNewFeatureComingSoonOpen, setNewFeatureComingSoonOpen] =
        useState(false)
    const setReportUserOpen = useReportStore(store => store.setReportUserOpen)
    const { t } = useTranslation(["userProfile"])
    const [isShareLinkInCreation, setIsShareLinkInCreation] = useState(false)
    const { toast } = useInfoToast()

    const { t: tCommon } = useTranslation(["common"])

    const { mutate: onFollowed } = useFollowUserProfileMutation()
    const { mutate: onUnfollowed } = useUnfollowUserProfileMutation()

    const { mutate: unsubscribeUserProfile } = useMutation({
        mutationFn: (profileId: UserProfileId) => {
            return userProfileCreatorClient.unsubscribeUserProfile(
                { profileId },
                { params: { id: params.id } },
            )
        },

        onMutate: ({}) => {
            const prevSubKey = QueryKeys.hasProfileSubscription(params.id)
            queryClient.cancelQueries({ queryKey: prevSubKey })

            const prevSubs = queryClient.getQueryData<boolean>(prevSubKey)
            queryClient.setQueryData(prevSubKey, false)

            return { prevSubs }
        },

        onError: (err, _vars, context) => {
            if (context?.prevSubs) {
                queryClient.setQueryData(
                    QueryKeys.hasProfileSubscription(params.id),
                    context.prevSubs,
                )
            }
            console.error(err)
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faOctagonExclamation}
                        iconColor="danger"
                        text={tCommon("unsubscribe.error.toast")}
                    />
                ),
            })
        },

        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.hasProfileSubscription(params.id),
            })
            queryClient.invalidateQueries({
                queryKey: [StaticQueryKeys.PAYABLE_POSTIT],
            })
            queryClient.invalidateQueries({
                queryKey: [StaticQueryKeys.PAYABLE_VIDEO],
            })
        },
    })

    const { mutate: subscribeUserProfile } = useMutation({
        mutationFn: (param: SubscribeUserMutationParams) =>
            userProfileCreatorClient.subscribeUserProfile(
                { redirects: param.redirects },
                { params: { id: param.idToSubscribe } },
            ),

        onError: err => {
            console.error(err)
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faOctagonExclamation}
                        iconColor="danger"
                        text={tCommon("subscribe.error.toast")}
                    />
                ),
            })
        },

        onSuccess: ({ data }) => {
            setSubscriptionUrl(data)
        },
    })

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const { data: myProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const imageRef = useRef(null)
    const isInView = useInView(imageRef, {
        margin: `${window.innerWidth * -0.2 - getSafeArea("top")}px`,
    })

    const isFollowingProfileQuery = useIsFollowingByProfileId({
        myProfileId,
        profileId: params.id,
        queryClient,
    })

    const queryLoadedFollowState = isFollowingProfileQuery.isSuccess
        ? isFollowingProfileQuery.data
            ? FollowStateAdt.as.Following({})
            : FollowStateAdt.as.IgnoringFollow({})
        : FollowStateAdt.as.NotPossibleFollow({})

    const queryFollowState = isFollowingProfileQuery.isLoading
        ? FollowStateAdt.as.LoadingFollow({})
        : queryLoadedFollowState

    const followingState = isNotDefined(myProfileId)
        ? FollowStateAdt.as.NotPossibleFollow({})
        : queryFollowState

    const [profileActionsModalOpen, setProfileActionsModalOpen] =
        useState(false)
    const [showShareModal, setShowShareModal] = useState(false)
    const [shareUrl, setShareUrl] = useState<string>()

    const [subscriptionSelectorOpen, setSubscriptionSelectorOpen] =
        useState(false)

    const isSubscribingPageQuery = useIsAlreadySubscribing({
        myId: myProfileId,
        idToSubscribe: params.id,
    })

    const userProfileQuery = useUserProfile(params.id)

    const showSubscriptionLoading =
        isSubscribingPageQuery.isLoading ||
        profileSubscriptionMonetizationQuery.isLoading ||
        subscriptionSupportedQuery.isLoading

    const shareMyProfile = useCallback(() => {
        setIsShareLinkInCreation(true)

        pipe(
            createShareTokenTask(
                {
                    contents: [
                        {
                            contentType: "UserProfile",
                            contentFilter: ShareContentId.parse(params.id),
                        },
                    ],
                },
                {},
            ),
            TE.bimap(
                error => {
                    setIsShareLinkInCreation(false)
                    console.error("Error share", error)
                },
                ({ data }) => {
                    shareLinkClient
                        .createShortenedLink({
                            link: createShareUrl(data),
                        })
                        .then(({ shortURL }) => {
                            setShareUrl(shortURL)
                            setShowShareModal(true)
                            setIsShareLinkInCreation(false)
                        })
                        .catch(() => {
                            setIsShareLinkInCreation(false)
                            toast({
                                toastContent: (
                                    <InfoToastContent
                                        icon={faOctagonExclamation}
                                        iconColor="danger"
                                        text={tCommon("share.error.title")}
                                    />
                                ),
                            })
                        })
                },
            ),
        )()
    }, [params.id, isAuthorizedAccount])

    return (
        <>
            <NewFeatureComingSoonModal
                open={isNewFeatureComingSoonOpen}
                onOpenChange={() => setNewFeatureComingSoonOpen(false)}
                text={{
                    title: tCommon("newFeatureComingSoon.title"),
                    description: tCommon("newFeatureComingSoon.description"),
                }}
            />

            <ProfileActionsModal
                open={profileActionsModalOpen}
                dismiss={() => setProfileActionsModalOpen(false)}
                onReportClicked={() => {
                    setProfileActionsModalOpen(false)
                    setReportUserOpen({ open: true, profileId: params.id })
                }}
                text={{ report: t("report") }}
            />

            <ShareOverviewAlertModal
                open={showShareModal}
                text={{
                    title: t("shareProfile.title"),
                    confirm: t("shareProfile.confirm"),
                    share: {
                        title: t("shareProfile.share.title"),
                        description: t("shareProfile.share.description"),
                    },
                }}
                onOpenChange={setShowShareModal}
                shareUrl={O.fromNullable(shareUrl)}
            />

            {userProfileQuery.isSuccess &&
                profileSubscriptionMonetizationQuery.isSuccess && (
                    //TODO: localize
                    <SubscriptionModal
                        open={subscriptionSelectorOpen}
                        text={{
                            user: {
                                info: "Subscription for",
                                description: `For ${intl.formatNumber(
                                    profileSubscriptionMonetizationQuery.data
                                        .price.amount,
                                    {
                                        style: "currency",
                                        currency: "EUR",
                                        currencyDisplay: "symbol",
                                    },
                                )} per month, cancel anytime`,
                            },
                            subscription: {
                                info: "Exclusive access to premium content",
                                description:
                                    "Subscribers receive exclusive access to high-quality content that is only available to subscribers.",
                                ok: "Subscribe now",
                                disclaimer:
                                    "By tapping Subscribe now, you agree to Seemee's Terms of Service and Privacy Policy. This Seemee subscription automatically renews monthly, and you will be notified in advance if the monthly amount increases. Cancel anytime in your subscription settings.",
                            },
                        }}
                        user={{
                            imageUrl: userProfileQuery.data.imageUrl,
                            isLive: userProfileQuery.data.isLive,
                            name:
                                userProfileQuery.data.displayName ??
                                userProfileQuery.data.profileName,
                        }}
                        onCanceled={() => setSubscriptionSelectorOpen(false)}
                        onConfirmed={() => {
                            if (!myProfileId) return

                            subscribeUserProfile({
                                idToSubscribe: params.id,
                                redirects: {
                                    succeeded: Url.parse(
                                        buildSubscriptionRedirectUrl({
                                            status: "succeeded",
                                            profileId: params.id,
                                        }),
                                    ),
                                    failed: Url.parse(
                                        buildSubscriptionRedirectUrl({
                                            status: "failed",
                                            profileId: params.id,
                                        }),
                                    ),
                                    canceled: Url.parse(
                                        buildSubscriptionRedirectUrl({
                                            status: "canceled",
                                            profileId: params.id,
                                        }),
                                    ),
                                    pending: Url.parse(
                                        buildSubscriptionRedirectUrl({
                                            status: "pending",
                                            profileId: params.id,
                                        }),
                                    ),
                                },
                            })

                            setSubscriptionSelectorOpen(false)
                        }}
                    />
                )}
            <Page className={styles.page}>
                <NavigationBackButton />
                <UserProfileToolbar
                    profileId={params.id}
                    isVisible={isInView}
                />
                <Loading
                    open={isShareLinkInCreation}
                    message={tCommon("loading")}
                />

                {isInView && <div className={styles.overlay} />}

                <UserProfilePageActions
                    profileId={params.id}
                    onActionsClicked={() => setProfileActionsModalOpen(true)}
                    onShareClicked={ensureUserCallback(myProfileId)(
                        shareMyProfile,
                    )}
                    onMessageClicked={ensureUserPreventDefault(myProfileId)}
                />

                <Content fullscreen>
                    <div ref={imageRef}>
                        <LazyLoadImage
                            width={window.innerWidth}
                            height={window.innerWidth}
                            className={styles.profileImage}
                            src={
                                userProfileQuery.isSuccess
                                    ? `${userProfileQuery.data.imageUrl}?width=${window.innerWidth * 2}`
                                    : ""
                            }
                        />
                    </div>

                    <div className={styles.content}>
                        <div className={styles.wrapper}>
                            <UserProfilePageStatus profileId={params.id} />

                            <ProfileStatistics
                                id={params.id}
                                text={{
                                    posts: tCommon("posts"),
                                    followers: tCommon("followers"),
                                    following: tCommon("following"),
                                }}
                            />

                            <ProfileDescription
                                profile={userProfileQuery.data}
                                loc={{
                                    moreExternalLinks: count =>
                                        ` ${t("moreExternalLinks", { count })}`,
                                }}
                            />

                            <div className={styles.profileActionsWrapper}>
                                <UserProfilePageFollowButton
                                    profileId={params.id}
                                    followingState={followingState}
                                    text={{
                                        follow: tCommon("follow"),
                                        unfollow: tCommon("unfollow"),
                                    }}
                                    onUnfollowClicked={ensureUserCallback(
                                        myProfileId,
                                    )(() => {
                                        if (!myProfileId) return
                                        if (!userProfileQuery.isSuccess) return

                                        onUnfollowed({
                                            profileId: userProfileQuery.data.id,
                                            myProfileId,
                                        })
                                    })}
                                    onFollowClicked={ensureUserCallback(
                                        myProfileId,
                                    )(() => {
                                        if (!myProfileId) return
                                        if (!userProfileQuery.isSuccess) return

                                        onFollowed({
                                            profileId: userProfileQuery.data.id,
                                            myProfileId,
                                        })
                                    })}
                                />
                                {showSubscriptionLoading && (
                                    <SubscribeButton
                                        text={{
                                            subscribe: t(
                                                "subscription.subscribe",
                                                {
                                                    amount: intl.formatNumber(
                                                        0,
                                                        {
                                                            style: "currency",
                                                            currency: "EUR",
                                                        },
                                                    ),
                                                },
                                            ),
                                            unSubscribe: t(
                                                "subscription.unSubscribe",
                                            ),
                                        }}
                                        subscriptionState={SubscriptionStateAdt.as.Loading(
                                            {},
                                        )}
                                        onSubscribeClicked={() => {}}
                                        onUnSubscribeClicked={() => {}}
                                    />
                                )}
                                {isSubscribingPageQuery.isSuccess &&
                                    subscriptionSupportedQuery.isSuccess &&
                                    subscriptionSupportedQuery.data
                                        .isAvailable &&
                                    profileSubscriptionMonetizationQuery.isSuccess &&
                                    profileSubscriptionMonetizationQuery.data
                                        .interval !== "None" && (
                                        <SubscribeButton
                                            text={{
                                                subscribe: t(
                                                    "subscription.subscribe",
                                                    {
                                                        amount: intl.formatNumber(
                                                            profileSubscriptionMonetizationQuery
                                                                .data.price
                                                                .amount,
                                                            {
                                                                style: "currency",
                                                                currency: "EUR",
                                                            },
                                                        ),
                                                    },
                                                ),
                                                unSubscribe: t(
                                                    "subscription.unSubscribe",
                                                ),
                                            }}
                                            subscriptionState={
                                                isSubscribingPageQuery.data
                                                    ? SubscriptionStateAdt.as.ActiveSubscription(
                                                          {},
                                                      )
                                                    : SubscriptionStateAdt.as.NoSubscription(
                                                          {},
                                                      )
                                            }
                                            onSubscribeClicked={withAuthorizedAccess(
                                                {
                                                    onAuthorized: () =>
                                                        setSubscriptionSelectorOpen(
                                                            true,
                                                        ),
                                                    onUnauthorized:
                                                        ensureUser(myProfileId),
                                                    accountType,
                                                },
                                            )}
                                            onUnSubscribeClicked={() => {
                                                if (!myProfileId) return

                                                unsubscribeUserProfile(
                                                    myProfileId,
                                                )
                                            }}
                                        />
                                    )}
                            </div>

                            <div className={styles.divider} />

                            <UserProfilePagePostContent
                                profileId={params.id}
                                followingState={followingState}
                                loc={{
                                    title: t("post.exclusive"),
                                }}
                                text={{
                                    followCTA: {
                                        title: t("followCTA.title"),
                                        subtitle: t("followCTA.subtitle"),
                                    },
                                    videoEmpty: t("video.empty"),
                                }}
                            />
                        </div>
                    </div>
                </Content>
            </Page>
        </>
    )
}

export const UserProfilePageLoading: FC = () => (
    <Page className={styles.page}>
        <NavigationBackButton />
        <UserProfilePageActions
            onMessageClicked={() => {}}
            onShareClicked={() => {}}
            onActionsClicked={() => {}}
        />
        <Content fullscreen>
            <Skeleton width="100dvw" height="100dvw" />
            <div className={styles.content}>
                <div className={styles.wrapper}>
                    <ProfileDataLoading />
                    <ProfileStatisticsLoading />
                    <ProfileDescriptionLoading />
                    <div style={{ marginTop: 24 }} className={styles.divider} />
                    <div style={{ marginTop: 24 }}>
                        <TabsListLoading />
                    </div>
                    <div className={styles.loadingContentContainer}>
                        <PostLoadingTile />
                        <PostLoadingTile />
                    </div>
                </div>
            </div>
        </Content>
    </Page>
)

export default UserProfilePage
