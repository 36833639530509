import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogHeader,
    AlertDialogTitle,
} from "common/alert-dialog"
import { FC } from "react"

import classNames from "classnames"
import * as styles from "./loading.css"

type LoadingModel = {
    open?: boolean
    title?: string
    message?: string
    className?: string
}

export const Loading: FC<LoadingModel> = model => (
    <AlertDialog open={model.open}>
        <AlertDialogContent
            className={classNames(styles.content, model.className)}
        >
            <AlertDialogHeader>
                <div className={styles.headerContent}>
                    <FontAwesomeIcon
                        icon={faSpinnerThird}
                        className={styles.loadingIcon}
                    />
                    <div className={styles.textContent}>
                        <AlertDialogTitle>{model.title}</AlertDialogTitle>

                        <AlertDialogDescription className={styles.description}>
                            {model.message}
                        </AlertDialogDescription>
                    </div>
                </div>
            </AlertDialogHeader>
        </AlertDialogContent>
    </AlertDialog>
)
