import { FC } from "react"
import { ResultNotFoundPlaceholder } from "./placeholder/result-not-found-placeholder"

import * as styles from "./result-not-found-placeholder-view.css"

export const ResultNotFoundPlaceholderView: FC<{ searchKey: string }> = ({
    searchKey,
}) => (
    <ResultNotFoundPlaceholder
        title={`No results for "${searchKey}"`}
        description="Check the spelling or start a new search"
        className={styles.emptyContentPlaceholder}
    />
)
