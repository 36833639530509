import { InfiniteScroll } from "common/infinite-scroll"
import { FC } from "react"
import { ResultNotFoundPlaceholderView } from "./result-not-found-placeholder-view"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { UserProfileId } from "api/branded-types"
import { VideoAreaTile } from "features/feed/areas/videos/video-area-tile"
import { isEmpty } from "fp-ts/string"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useVideosSearch } from "hooks/use-videos-search"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"
import { fill } from "utils/array"
import { PostLoadingTile } from "./videos/post-loading-tile"

type SearchPageVideoTabContentModel = {
    profileId?: UserProfileId
    creatorIds: UserProfileId[]
    searchKey: string
    debouncedSearchKey: string
    className?: string
    userDependandQueriesEnabled: boolean
}

export const SearchPageVideoTabContent: FC<SearchPageVideoTabContentModel> = ({
    searchKey,
    debouncedSearchKey,
    profileId,
    creatorIds,
    className,
    userDependandQueriesEnabled,
}) => {
    const { t } = useTranslation(["search"])

    const videosQuerySearchQuery = useVideosSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })

    const queryClient = useQueryClient()

    const profileIdsPages =
        videosQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const isSearchKeyEmpty = isEmpty(debouncedSearchKey)

    const hasAvailableVideos =
        (videosQuerySearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !videosQuerySearchQuery.isPending &&
        videosQuerySearchQuery.isSuccess

    const isNoResultVideo =
        !isSearchKeyEmpty &&
        !hasAvailableVideos &&
        !videosQuerySearchQuery.isPending

    const videosState = match(videosQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const showLoadingState =
        videosQuerySearchQuery.isFetchingNextPage ||
        videosQuerySearchQuery.isLoading ||
        oneOfProfileQueriesLoading

    return (
        <InfiniteScroll
            disabled={!videosQuerySearchQuery.hasNextPage}
            state={videosState}
            threshold="100px"
            onLoadRequested={videosQuerySearchQuery.fetchNextPage}
        >
            <div className={className}>
                {videosQuerySearchQuery.data?.pages.map(
                    (page, idx) =>
                        !profileQueries.at(idx)?.isLoading &&
                        page.data.map(video => (
                            <VideoAreaTile
                                loc={{
                                    title: t("section.video.exclusive"),
                                }}
                                key={video.id}
                                videoId={video.id}
                            />
                        )),
                )}
                {showLoadingState &&
                    fill(2, idx => <PostLoadingTile key={idx} />)}
            </div>
            {isNoResultVideo && (
                <ResultNotFoundPlaceholderView searchKey={searchKey} />
            )}
        </InfiniteScroll>
    )
}
