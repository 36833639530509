import { faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC } from "react"

import { Avatar } from "../../../../components/avatars/avatar"

import { Link } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { AvatarLoading } from "../../../../components/avatars/avatar-loading"
import * as styles from "./user-list-item.css"

export type UserListItemModel = {
    className?: string
    avatarSize?: number
    profileId: UserProfileId
    onClicked?: () => void
}
export const UserListItem: FC<UserListItemModel> = ({
    className = "",
    avatarSize,
    profileId,
    onClicked,
}) => {
    const myProfileIdQuery = useMyUserProfileId()
    const profileQuery = useListUserProfileById(profileId)

    const profile = profileQuery.data?.data

    return (
        profileQuery.isSuccess &&
        profile && (
            <Link
                to={
                    myProfileIdQuery.data === profileId
                        ? "/app/my-profile"
                        : "/app/user-profile/$id"
                }
                params={{ id: profileId }}
                className={styles.linkWrapper}
            >
                <div
                    className={classNames(styles.wrapper, className)}
                    onClick={onClicked}
                >
                    <Avatar
                        src={profile.imageUrl}
                        size={avatarSize}
                        styleState={profile.isLive ? "isLive" : "None"}
                    />
                    <div style={{ width: "100%" }}>
                        <p
                            style={{
                                fontSize: vars.font.size.m,
                                color: "var(--text-color, white)",
                                textAlign: "left",
                            }}
                        >
                            {profile.displayName}
                        </p>
                    </div>

                    <div>
                        <FontAwesomeIcon
                            className={styles.navButton}
                            icon={faAngleRight}
                            color="var(--nav-icon-color, white)"
                        />
                    </div>
                </div>
            </Link>
        )
    )
}

export const UserWithBadgeListItem: FC<UserListItemModel> = ({
    className = "",
    avatarSize,
    profileId,
    onClicked,
}) => {
    const myProfileIdQuery = useMyUserProfileId()
    const profileQuery = useListUserProfileById(profileId)

    const profile = profileQuery.data?.data

    return (
        profileQuery.isSuccess &&
        profile && (
            <Link
                to={
                    myProfileIdQuery.data === profileId
                        ? "/app/my-profile"
                        : "/app/user-profile/$id"
                }
                params={{ id: profileId }}
                className={styles.linkWrapper}
            >
                <div
                    className={classNames(styles.wrapper, className)}
                    onClick={onClicked}
                >
                    <AvatarWithBadge
                        profileId={profileId}
                        src={profile.imageUrl}
                        size={avatarSize}
                        styleState={profile.isLive ? "isLive" : "None"}
                    />
                    <div style={{ width: "100%" }}>
                        <p
                            style={{
                                fontSize: vars.font.size.m,
                                color: "var(--text-color, white)",
                                textAlign: "left",
                            }}
                        >
                            {profile.displayName}
                        </p>
                    </div>

                    <div>
                        <FontAwesomeIcon
                            className={styles.navButton}
                            icon={faAngleRight}
                            color="var(--nav-icon-color, white)"
                        />
                    </div>
                </div>
            </Link>
        )
    )
}

export type UserListLoadingItemModel = {
    className?: string
    avatarSize?: string
}

//TODO: baseColor here is improvised, ask design to add proper color
export const UserListLoadingItem = (model: UserListLoadingItemModel) => (
    <div className={classNames(styles.wrapper, model.className)}>
        <div className={styles.loadingContent}>
            <AvatarLoading baseColor="#D5D6D8" size={40} />
            <div>
                <Skeleton
                    containerClassName="flex-fit"
                    baseColor="#D5D6D8"
                    width={224}
                    height={15}
                    borderRadius={24}
                />
                <Skeleton
                    containerClassName="flex-fit"
                    baseColor="#D5D6D8"
                    width={164}
                    height={15}
                    borderRadius={24}
                />
            </div>
        </div>
    </div>
)
