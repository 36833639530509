import { AnimatePresence, motion } from "framer-motion"
import { FC } from "react"

import { Avatar } from "../../../components/avatars/avatar"
import { AvatarLoading } from "../../../components/avatars/avatar-loading"

import { UserProfileId } from "api/branded-types"
import { useUserProfile } from "hooks/use-user-profile"
import * as styles from "../user-profile-page.css"

type UserProfileToolbarModel = {
    isVisible: boolean
    profileId?: UserProfileId
}

export const UserProfileToolbar: FC<UserProfileToolbarModel> = model => {
    const userProfileQuery = useUserProfile(model.profileId)

    return (
        <AnimatePresence key="profile-toolbar-animate">
            {model.isVisible === false && (
                <motion.div
                    key="profile-toolbar"
                    className={styles.toolbar}
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                >
                    <div className={styles.toolbarProfileContainer}>
                        {userProfileQuery.isSuccess ? (
                            <Avatar
                                src={
                                    userProfileQuery.data.imageUrl ??
                                    "/images/default_avatar.jpg"
                                }
                                size={32}
                                styleState={
                                    userProfileQuery.data.isLive
                                        ? "isLive"
                                        : "None"
                                }
                            />
                        ) : (
                            <AvatarLoading />
                        )}
                        <p className={styles.toolbarUsername}>
                            {userProfileQuery.isSuccess ? (
                                (userProfileQuery.data.displayName ??
                                userProfileQuery.data.profileName)
                            ) : (
                                <></>
                            )}
                        </p>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
