import * as O from "fp-ts/Option"
import { FC, useState } from "react"

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "common/alert-dialog"
import { Button } from "common/button"
import { Textarea } from "common/textarea"
import { isDefined } from "../../utils/object"
import { ShareOverviewModal } from "./share-content-overview-modal"

export type ShareOverviewAlertModalModal = {
    text: {
        title: string
        share: {
            title: string
            description: string
        }
        confirm: string
    }
    open: boolean
    shareUrl: O.Option<string>
    onOpenChange: (v: boolean) => void
}

export const ShareOverviewAlertModal: FC<ShareOverviewAlertModalModal> = ({
    text,
    open,
    shareUrl,
    onOpenChange,
}) => {
    const [showShareUrl, setShowShareUrl] = useState(false)

    return (
        <>
            <ShareOverviewModal
                open={open}
                title={text.title}
                canShare={isDefined(navigator.canShare)}
                onShareToClicked={() => {
                    if (O.isNone(shareUrl)) return

                    navigator.share({
                        title: text.share.title,
                        url: shareUrl.value,
                    })
                }}
                onCopyLinkClicked={() => setShowShareUrl(true)}
                onOpenChange={onOpenChange}
            />

            <AlertDialog
                open={showShareUrl}
                onOpenChange={open => {
                    if (!open) {
                        if (O.isSome(shareUrl))
                            navigator.clipboard.writeText(shareUrl.value)

                        setShowShareUrl(false)
                    } else setShowShareUrl(true)
                }}
            >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>{text.title}</AlertDialogTitle>
                        <AlertDialogDescription>
                            {text.share.description}
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <Textarea
                        defaultValue={O.isSome(shareUrl) ? shareUrl.value : ""}
                        style={{
                            background: "transparent",
                            height: 100,
                            WebkitUserSelect: "all",
                            MozUserSelect: "all",
                            msUserSelect: "text",
                            userSelect: "all",
                        }}
                    />
                    <AlertDialogFooter>
                        <AlertDialogAction asChild>
                            <Button variant="control" full>
                                {text.confirm}
                            </Button>
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}
