import { useDeviceInfo } from "features/user-profile/hooks/use-device-info"
import { FC, PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

const Paragraph: FC<PropsWithChildren> = ({ children }) => (
    <p
        style={{
            margin: 0,
            marginTop: 4,
            fontSize: "16px",
            lineHeight: 1.2,
        }}
    >
        {children}
    </p>
)

export const Heading: FC<PropsWithChildren> = ({ children }) => (
    <h1
        style={{
            margin: "10px 0 16px",
            fontSize: "24px",
            fontWeight: "bold",
        }}
    >
        {children}
    </h1>
)

export const Intro: FC = () => {
    const { isApple, isAndroid } = useDeviceInfo()
    const { t } = useTranslation(["modal"])

    return (
        <>
            {(isApple || isAndroid) && (
                <div style={{ textAlign: "center" }}>
                    <Heading>
                        {isApple
                            ? t("instructions.platform.apple.title")
                            : t("instructions.platform.android.title")}
                    </Heading>

                    <Paragraph>
                        {isApple
                            ? t("instructions.platform.apple.paragraph")
                            : t("instructions.platform.android.paragraph")}
                    </Paragraph>
                </div>
            )}
        </>
    )
}
