import {
    faBan,
    faMessage,
    faOctagonExclamation,
    faXmark,
} from "@fortawesome/pro-light-svg-icons"
import { faHexagonCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import { getIsAuthorizedAccount } from "api/api-utils"
import { PaymentId } from "api/branded-types"
import { paymentCreatorClient } from "api/clients/payment-api-client"
import { QueryKeys } from "api/query-keys"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerTitle,
} from "common/drawer"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useMe } from "hooks/use-me"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { usePaymentById } from "hooks/use-payment-by-id"
import { LocalStorage } from "local-storage"
import { FC, useMemo } from "react"
import { paymentCheckoutLinkByIdQueryOptions } from "../hooks/use-get-payment-checkout-link"

import classNames from "classnames"
import { Button } from "common/button"
import { Avatar } from "components/avatars/avatar"
import { AvatarLoading } from "components/avatars/avatar-loading"
import { useListPostitById } from "features/postit/hooks/use-list-postit-by-id"
import { paymentContentRouteStrategy } from "features/strategies/payment/content-reference-strategy"
import { useListVideoById } from "features/video/hooks/use-list-video-by-id"
import { useListLiveEvent } from "hooks/use-list-live-event"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { usePaymentModalStore } from "../store/payment-modal-store"
import { formatPaymentDate } from "../wallet-payment-item"
import * as styles from "./wallet-payment-details-modal.css"

export const WalletPaymentDetailsModal: FC = () => {
    const { t } = useTranslation(["settingsWallet"])

    const walletPaymentDetails = usePaymentModalStore(
        store => store.walletPaymentDetails,
    )
    const setWalletPaymentDetails = usePaymentModalStore(
        store => store.setWalletPaymentDetailsOpen,
    )

    const paymentQuery = usePaymentById(
        walletPaymentDetails.open
            ? walletPaymentDetails.walletPaymentDetailsId
            : undefined,
    )

    const queryClient = useQueryClient()

    const videoQuery = useListVideoById(
        paymentQuery.data?.contentRef.contentType === "Video"
            ? paymentQuery.data.contentRef.contentId
            : undefined,
    )
    const postitQuery = useListPostitById(
        paymentQuery.data?.contentRef.contentType === "Postit"
            ? paymentQuery.data.contentRef.contentId
            : undefined,
    )
    const liveEventQuery = useListLiveEvent(
        paymentQuery.data?.contentRef.contentType === "LiveEvent"
            ? paymentQuery.data.contentRef.contentId
            : undefined,
    )

    const content =
        videoQuery.data?.data ||
        postitQuery.data?.data ||
        liveEventQuery.data?.data

    const contentTitle = content?.title

    const contentQuerySuccess =
        videoQuery.isSuccess ||
        postitQuery.isSuccess ||
        liveEventQuery.isSuccess
    const contentQueryLoading =
        videoQuery.isLoading ||
        postitQuery.isLoading ||
        liveEventQuery.isLoading

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const { data: myProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const isCurrentProfileContentOwner =
        paymentQuery.data?.contentOwnerId === myProfileId

    const profileId = isCurrentProfileContentOwner
        ? paymentQuery.data?.customerId
        : paymentQuery.data?.contentOwnerId

    const profileQuery = useListUserProfileById(profileId)
    const profile = profileQuery.data?.data

    const status = paymentQuery.data?.state

    const { toast } = useInfoToast()

    const { mutate: cancelPayment } = useMutation({
        mutationFn: (paymentId: PaymentId) =>
            paymentCreatorClient.cancelPaymentById(undefined, {
                params: { id: paymentId },
            }),

        onSettled: (_data, _err, paymentId) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.payment(paymentId),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.payments(),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.myPayments(),
            })
        },
        onSuccess: () =>
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faHexagonCheck}
                        iconColor="success"
                        text={t(
                            "payment.detailsModal.cancelPayment.success.title",
                        )}
                    />
                ),
            }),
        onError: () =>
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faBan}
                        iconColor="danger"
                        text={t(
                            "payment.detailsModal.cancelPayment.failure.title",
                        )}
                    />
                ),
            }),
    })
    const checkoutLinkQuery = useQuery(
        paymentCheckoutLinkByIdQueryOptions({
            paymentId: walletPaymentDetails.open
                ? walletPaymentDetails.walletPaymentDetailsId
                : undefined,
            enabled:
                paymentQuery.isSuccess && paymentQuery.data.state === "Pending",
        }),
    )

    const closeModal = () => setWalletPaymentDetails({ open: false })

    return (
        <Drawer open={walletPaymentDetails.open} onClose={closeModal}>
            <DrawerContent className={styles.wrapper} handle={false}>
                <DrawerClose asChild className={styles.closeIcon}>
                    <FontAwesomeIcon icon={faXmark} />
                </DrawerClose>

                <DrawerTitle className={styles.title}>{t("title")}</DrawerTitle>
                <DrawerDescription />
                {paymentQuery.isLoading && <WalletPaymentDetailsModalLoading />}
                {paymentQuery.isSuccess && (
                    <>
                        <div className={styles.content}>
                            <div className={styles.contentOwnerDetailsWrapper}>
                                <div className={styles.profileInfo}>
                                    {profileQuery.isLoading && (
                                        <>
                                            <AvatarLoading
                                                baseColor="#D5D6D8"
                                                size={80}
                                            />
                                            <Skeleton
                                                baseColor="#D5D6D8"
                                                width={240}
                                                height={24}
                                                borderRadius={
                                                    vars.measurement.radius.xl
                                                }
                                            />
                                        </>
                                    )}
                                    {profileQuery.isSuccess && profile && (
                                        <>
                                            <Avatar
                                                src={profile.imageUrl}
                                                size={80}
                                            />
                                            <p className={styles.profileName}>
                                                {profile.profileName}
                                            </p>
                                        </>
                                    )}
                                    {
                                        //TODO: add failure state here
                                    }
                                </div>
                                <div className={styles.purchaseInfo}>
                                    {
                                        //TODO: when this info is available - bring it back
                                    }
                                    {/* <p className={styles.purchaseType}>
                                Individual purchase
                            </p> */}
                                    {contentQuerySuccess && (
                                        <Link
                                            {...paymentContentRouteStrategy(
                                                paymentQuery.data.contentRef,
                                            )}
                                            onClick={closeModal}
                                            className={styles.purchasedContent}
                                        >
                                            {contentTitle}
                                        </Link>
                                    )}
                                    {contentQueryLoading && (
                                        <Skeleton
                                            baseColor="#D5D6D8"
                                            width={240}
                                            height={36}
                                            borderRadius={
                                                vars.measurement.radius.sm
                                            }
                                        />
                                    )}
                                    {
                                        //TODO: add failure state here
                                    }
                                    <p className={styles.purchasePrice}>
                                        {paymentQuery.data.price.amount}{" "}
                                        {paymentQuery.data.price.currency}
                                    </p>
                                </div>
                            </div>
                            <div className={styles.infoSectionWrapper}>
                                <div
                                    className={classNames(
                                        styles.infoSectionItem,
                                        styles.infoSectionStatusItem,
                                    )}
                                >
                                    <p className={styles.infoSectionItemTitle}>
                                        {t("status")}
                                    </p>
                                    <div className={styles.statusContainer}>
                                        <p
                                            className={classNames(
                                                styles.infoSectionItemDescription,
                                                styles.status,
                                                status
                                                    ? status === "Succeeded"
                                                        ? styles.textSuccess
                                                        : status === "Failed"
                                                          ? styles.textDanger
                                                          : status === "Pending"
                                                            ? styles.textWarning
                                                            : styles.textTertiary
                                                    : "",
                                            )}
                                        >
                                            {paymentQuery.data.state}
                                        </p>
                                        {status === "Pending" && (
                                            <div
                                                className={
                                                    styles.paymentPendingActions
                                                }
                                            >
                                                <Button
                                                    className={
                                                        styles.paymentAction
                                                    }
                                                    variant="light"
                                                    disabled={
                                                        !checkoutLinkQuery.isSuccess
                                                    }
                                                    //TODO: refactor to be handled in separate window
                                                    onClick={() => {
                                                        if (
                                                            !checkoutLinkQuery.isSuccess
                                                        )
                                                            return
                                                        closeModal()
                                                        window.open(
                                                            checkoutLinkQuery.data,
                                                            "_blank",
                                                        )
                                                    }}
                                                >
                                                    {t(
                                                        "payment.detailsModal.actions.completePayment",
                                                    )}
                                                </Button>
                                                <Button
                                                    className={classNames(
                                                        styles.paymentAction,
                                                        styles.paymentActionDanger,
                                                    )}
                                                    variant="light"
                                                    onClick={() => {
                                                        if (
                                                            !walletPaymentDetails.open
                                                        )
                                                            return
                                                        closeModal()
                                                        cancelPayment(
                                                            walletPaymentDetails.walletPaymentDetailsId,
                                                        )
                                                    }}
                                                >
                                                    {t(
                                                        "payment.detailsModal.actions.cancelPayment",
                                                    )}
                                                </Button>
                                            </div>
                                        )}
                                        {status === "Failed" && (
                                            <p
                                                className={
                                                    styles.paymentNotProcessed
                                                }
                                            >
                                                {t("payment.failure")}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className={styles.infoSectionItem}>
                                    <p className={styles.infoSectionItemTitle}>
                                        {t("payment.dateTime")}
                                    </p>

                                    <p
                                        className={
                                            styles.infoSectionItemDescription
                                        }
                                    >
                                        {formatPaymentDate(
                                            new Date(
                                                paymentQuery.data.lastChangedAt,
                                            ),
                                        )}
                                    </p>
                                </div>
                                <div className={styles.infoSectionItem}>
                                    <p className={styles.infoSectionItemTitle}>
                                        {t("payment.transactionId")}
                                    </p>
                                    <p
                                        className={
                                            styles.infoSectionItemDescription
                                        }
                                    >
                                        {paymentQuery.data.id}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <DrawerFooter className={styles.footer}>
                            <div className={styles.buttonGroupWrapper}>
                                <Button
                                    full
                                    className={classNames(
                                        styles.button,
                                        styles.groupButton,
                                    )}
                                    variant="secondary"
                                    disabled={!profileId}
                                    asChild
                                >
                                    <Link
                                        to="/app/message/profile/$id"
                                        params={{
                                            id: profileId!,
                                        }}
                                        onClick={closeModal}
                                    >
                                        <FontAwesomeIcon
                                            className={styles.buttonIcon}
                                            icon={faMessage}
                                        />
                                        <p className={styles.buttonText}>
                                            {t("send")}
                                        </p>
                                    </Link>
                                </Button>

                                {profileQuery.isLoading && (
                                    <Skeleton
                                        baseColor="#D5D6D8"
                                        width="100%"
                                        height={56}
                                        borderRadius={
                                            vars.measurement.radius.md
                                        }
                                        style={{
                                            marginTop: 12,
                                        }}
                                    />
                                )}
                                {profileQuery.isSuccess && profile && (
                                    <Button
                                        full
                                        className={classNames(
                                            styles.button,
                                            styles.groupButton,
                                        )}
                                        variant="secondary"
                                        disabled={!profileId}
                                        asChild
                                    >
                                        <Link
                                            to={"/app/user-profile/$id"}
                                            params={{
                                                id: profileId!,
                                            }}
                                            onClick={closeModal}
                                        >
                                            <Avatar
                                                src={profile.imageUrl}
                                                size={24}
                                            />

                                            <p className={styles.buttonText}>
                                                {t("showProfile")}
                                            </p>
                                        </Link>
                                    </Button>
                                )}
                                {
                                    //TODO: add failure state here
                                }
                            </div>

                            <Button
                                full
                                variant="secondary"
                                className={classNames(
                                    styles.button,
                                    styles.reportButton,
                                )}
                                disabled
                            >
                                <FontAwesomeIcon
                                    className={classNames(
                                        styles.buttonIcon,
                                        styles.reportButtonIcon,
                                    )}
                                    icon={faOctagonExclamation}
                                />
                                <p className={styles.buttonText}>
                                    {t("report")}
                                </p>
                            </Button>
                        </DrawerFooter>
                    </>
                )}
                {
                    //TODO: add failure state here (possibly with refresh button)
                }
            </DrawerContent>
        </Drawer>
    )
}

const WalletPaymentDetailsModalLoading = () => {
    return (
        <>
            <div className={styles.content}>
                <div className={styles.contentOwnerDetailsWrapper}>
                    <div className={styles.profileInfo}>
                        <AvatarLoading baseColor="#D5D6D8" size={80} />
                        <Skeleton
                            baseColor="#D5D6D8"
                            width={240}
                            height={24}
                            borderRadius={vars.measurement.radius.xl}
                        />
                    </div>
                    <Skeleton
                        baseColor="#D5D6D8"
                        width={240}
                        height={36}
                        borderRadius={vars.measurement.radius.sm}
                    />
                    <Skeleton
                        baseColor="#D5D6D8"
                        width={80}
                        height={18}
                        borderRadius={vars.measurement.radius.xl}
                    />
                </div>
                <Skeleton
                    baseColor="#D5D6D8"
                    width="100%"
                    height={128}
                    borderRadius={vars.measurement.radius.md}
                    style={{ marginTop: 28 }}
                />
                <Skeleton
                    baseColor="#D5D6D8"
                    width="100%"
                    height={112}
                    borderRadius={vars.measurement.radius.lg}
                    style={{ marginTop: 12 }}
                />

                <Skeleton
                    baseColor="#D5D6D8"
                    width="100%"
                    height={56}
                    borderRadius={vars.measurement.radius.md}
                    style={{ marginTop: 12, marginBottom: 40 }}
                />
            </div>
        </>
    )
}
