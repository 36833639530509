import detectIncognito from "detectincognitojs"
import { useEffect, useState } from "react"

export const useIsPrivateMode = () => {
    const [isPrivate, setIsPrivate] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function checkIncognito() {
            try {
                const result = await detectIncognito()
                setIsPrivate(result.isPrivate)
            } catch (error) {
                setIsPrivate(false)
            } finally {
                setIsLoading(false)
            }
        }

        checkIncognito()
    }, [])

    return { isPrivate, isLoading }
}
