import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogTitle,
} from "common/dialog"
import { FC } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { vars } from "theme/variables.css"
import * as styles from "./find-out-more-modal.css"

export type FindOutMoreModalModel = {
    loc: {
        title: string
        description: string
        info: string
        readMore: string
    }
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const FindOutMoreModal: FC<FindOutMoreModalModel> = ({
    loc,
    open,
    onOpenChange,
}) => {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={styles.content}>
                <FontAwesomeIcon
                    icon={faXmark}
                    className={styles.closeIcon}
                    onClick={() => onOpenChange(false)}
                />
                <div className={styles.mainContentWrapper}>
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.l,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            {loc.title}
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.xxxxl,
                                fontWeight: 500,
                            }}
                        >
                            {loc.description}
                        </DialogDescription>
                    </div>
                    <LazyLoadImage
                        width={window.innerWidth - 52 * 2}
                        height={(window.innerWidth - 52 * 2) * 0.855}
                        className={styles.image}
                        loading="eager"
                        src="/images/find-out-more-about-seemee.png"
                    />
                    <p
                        style={{
                            fontSize: vars.font.size.m,
                            fontWeight: 400,
                            margin: "28px 4px 10px",
                        }}
                    >
                        {loc.info}
                    </p>
                </div>
                <DialogFooter className={styles.footer}>
                    <Button
                        className={styles.button}
                        variant="medium"
                        disabled
                        asChild
                        onClick={() => {
                            onOpenChange(false)
                        }}
                    >
                        <Link
                            to="/app/settings/rewards"
                            search={{ currentTab: "get-started" }}
                        >
                            {loc.readMore}
                        </Link>
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
