import { Url } from "api/branded-types"
import classNames from "classnames"
import { ComponentPropsWithoutRef, FC, useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useAssetData } from "../hooks/use-asset"
import * as styles from "./image.css"
//TODO: refactor into separate components (image and svg handling)
/**
 * @param src - The url for asset data (blob)
 */
export const SvgImage: FC<
    ComponentPropsWithoutRef<"img"> & {
        src?: Url
        color?: string
    }
> = ({ src, color, className, ...props }) => {
    const imageUrl = props.width
        ? `${src}?width=${props.width}`
        : props.height
          ? `${src}?height=${props.height}`
          : src

    const imageType = "Svg"

    const assetDataQuery = useAssetData(src, imageUrl as Url)
    const [imageSrc, setImageSrc] = useState<string>("")

    useEffect(() => {
        let objectUrl: string | null = null

        if (assetDataQuery.isSuccess && assetDataQuery.data) {
            const blob = assetDataQuery.data // URL returned by useAssetData

            blob.text()
                .then(svgContent => {
                    // Wrap with <svg> tags if necessary
                    const svgContentWithColor = color
                        ? replaceFillContent(svgContent, color)
                        : svgContent
                    let svgText = svgContentWithColor
                    if (!svgContent.includes("<svg")) {
                        svgText = `<svg width="${props.width}" height="${props.height}" xmlns="http://www.w3.org/2000/svg">${svgContentWithColor}</svg>`
                    }
                    // Create a new Blob from the modified SVG content
                    const svgBlob = new Blob([svgText], {
                        type: "image/svg+xml",
                    })
                    objectUrl = URL.createObjectURL(svgBlob)
                    setImageSrc(objectUrl)
                })
                .catch(error => {
                    console.error("Error reading SVG Blob:", error)
                    //todo: add fallback url
                    setImageSrc("")
                })
        } else {
            //todo: add fallback url
            setImageSrc("")
        }

        return () => {
            if (objectUrl) URL.revokeObjectURL(objectUrl)
        }
    }, [
        assetDataQuery.isSuccess,
        assetDataQuery.data,
        imageType,
        props.width,
        props.height,
    ])

    return (
        <div className={styles.container}>
            <LazyLoadImage
                src={imageSrc}
                className={classNames(className)}
                {...props}
            />
        </div>
    )
}

const replaceFillContent = (inputString: string, newContent: string) =>
    inputString.replace(/fill="[^"]*"/g, `fill="${newContent}"`)
