/* eslint-disable no-useless-escape */

import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { LiveEvent } from "api/api-models"
import { UserProfileId } from "api/branded-types"
import { mapToSortFilterDc } from "../api/api-utils"
import { liveEventSelectorClient } from "../api/clients/live-event-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 25

export type UseLiveEventsSearchModel = {
    searchKey: string
    creatorIds: UserProfileId[]
    profileId?: UserProfileId
    enabled?: boolean
}

const listLiveEventsSearch = async ({
    pageParam,
    filter,
    queryClient,
}: {
    pageParam: number
    filter: string
    queryClient: QueryClient
}) => {
    const events = await liveEventSelectorClient.listLiveEvents({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    events.data.map(data =>
        queryClient.setQueryData<{ data: LiveEvent; error: undefined }>(
            QueryKeys.liveEvent(data.id),
            { data, error: undefined },
        ),
    )

    return events
}

export const useLiveEventsSearch = (model: UseLiveEventsSearchModel) => {
    const queryClient = useQueryClient()

    const queryKey = QueryKeys.searchLiveEvents(model)
    const titleFilter = `title: *"${model.searchKey}"*:ci`
    const descriptionFilter = `description: *"${model.searchKey}"*:ci`
    const stateFilter = `state: "Running"`
    const sortFilter = mapToSortFilterDc("lastChangedAt: desc")

    const removeMyProfileFilter =
        !model.profileId || model.searchKey.length > 0
            ? ""
            : `, creatorId: !"${model.profileId}"`
    //TODO: this is not 100% precise, but would work out most of the time
    //TODO: otherwise we need specific API endpoint for this
    const profilesFilter =
        model.searchKey.length === 0
            ? ""
            : `, {creatorId: in ${JSON.stringify(model.creatorIds)}}`

    const filter = `{$match:{$or: [{${titleFilter}}, {${descriptionFilter}}${profilesFilter}]${removeMyProfileFilter}, ${stateFilter}}, ${sortFilter}}`

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) =>
            listLiveEventsSearch({ pageParam, filter, queryClient }),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: model.enabled,
    })
}
