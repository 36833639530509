import { QueryClient, queryOptions, useQuery } from "@tanstack/react-query"
import { UserProfile } from "api/api-models"
import { UserProfileId } from "api/branded-types"
import { userProfileSelectorClient } from "api/clients/user-profile-api-client"
import { QueryKeys } from "api/query-keys"

type FollowingRequestModel = {
    queryClient: QueryClient
    myProfileId?: UserProfileId
    profileId?: UserProfileId
}

export const isFollowingByProfileId = async (model: FollowingRequestModel) => {
    if (!model.profileId) throw new Error("UserProfileId should be defined")
    if (!model.myProfileId)
        throw new Error("My UserProfileId should be defined")

    const filter = `{$match:{id: in ["${model.myProfileId}"]}}`
    const profilesRes =
        await userProfileSelectorClient.listFollowersOfUserProfileId(
            { filter },
            { params: { id: model.profileId } },
        )

    profilesRes.data.map(data =>
        model.queryClient.setQueryData<{ data: UserProfile; error: undefined }>(
            QueryKeys.listProfile(data.id),
            {
                data,
                error: undefined,
            },
        ),
    )

    return profilesRes.totalCount > 0
}

const isFollowingByProfileIdQueryOptions = (model: FollowingRequestModel) =>
    queryOptions({
        queryKey: QueryKeys.profileFollow(model.myProfileId, model.profileId),
        queryFn: () => isFollowingByProfileId(model),
        enabled: !!model.myProfileId && !!model.profileId,
    })

export const useIsFollowingByProfileId = (model: FollowingRequestModel) => {
    return useQuery(isFollowingByProfileIdQueryOptions(model))
}
