import { QueryClient, queryOptions } from "@tanstack/react-query"
import { UserMessage } from "api/api-models"
import { UserProfileId } from "api/branded-types"
import { userMessageSelectorClient } from "api/clients/message-api-client"
import { QueryKeys } from "api/query-keys"

const PAGINATION_AMOUNT = 50

type Params = {
    recipientId?: UserProfileId
    senderId?: UserProfileId
}

export const listLastMessagesQueryOptions = (
    params: Params & { queryClient: QueryClient },
) =>
    queryOptions({
        queryKey: QueryKeys.listLastMessages(
            params.recipientId,
            params.senderId,
        ),
        queryFn: () =>
            listLastMessages({
                ...params,
                queryClient: params.queryClient,
            }),
        enabled: !!params.recipientId && !!params.senderId,
    })

const listLastMessages = async ({
    recipientId,
    senderId,
    queryClient,
}: { queryClient: QueryClient } & Params) => {
    if (!recipientId) throw new Error("Recipient Id is required")
    if (!senderId) throw new Error("Sender Id is required")

    const filter = `{$match: {$or: [
              {recipient.contentType: "UserProfile", recipient.contentId: "${recipientId}", senderId: "${senderId}"},
              {recipient.contentType: "UserProfile", recipient.contentId: "${senderId}", senderId: "${recipientId}"},
            ]},$sort: {createdAt: desc}
            }`

    const messages = await userMessageSelectorClient.listMessages({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: -1,
        },
    })

    messages.data.map(message =>
        queryClient.setQueryData<UserMessage>(
            QueryKeys.userMessage(message.id),
            message,
        ),
    )

    return messages
}
