import { useInfiniteQuery } from "@tanstack/react-query"

import { liveEventSelectorClient } from "../api/clients/live-event-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 20

export const useLiveNowEvents = () => {
    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn: listLiveNowEvents,
        queryKey: QueryKeys.feedLiveNowEvents(),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
    })
}

const listLiveNowEvents = async ({ pageParam }: { pageParam: number }) => {
    const liveEventsRes = await liveEventSelectorClient.listLiveEvents({
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
        filter: `{$sort: {title: asc}, $match: {state: "Running"}}`,
    })
    return liveEventsRes
}
