import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { calc } from "@vanilla-extract/css-utils"
import { CSSProperties, FC } from "react"
import {
    NAVIGATION_MARGIN_TOP,
    OPTION_BUTTON_MARGIN_RIGHT,
} from "theme/global.css"

export type Props = {
    style?: CSSProperties
    icon?: IconProp
    iconColor?: string
    fallbackRoute?: string
    onClicked?: (event: any) => void
}

export const OptionButton: FC<Props> = ({
    style,
    icon = faEllipsisVertical,
    iconColor = "white",
    onClicked,
}) => (
    <div
        style={{
            position: "fixed",
            zIndex: 1000,
            top: calc.add("env(safe-area-inset-top, 0)", NAVIGATION_MARGIN_TOP),
            right: calc.add(
                "env(safe-area-inset-left, 0)",
                OPTION_BUTTON_MARGIN_RIGHT,
            ),
            width: 28,
            height: 28,
            justifyContent: "center",
            display: "flex",
            ...style,
        }}
        onClick={onClicked}
    >
        <FontAwesomeIcon
            icon={icon}
            style={{
                color: `var(--nav-icon-color, ${iconColor})`,
                position: "fixed",
                top: "calc(16px + env(safe-area-inset-top, 0))",
                width: 28,
                height: 28,
            }}
        />
    </div>
)
