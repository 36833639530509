import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FC } from "react"
import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./new-feature-coming-soon.css"

type TextModel = {
    title: string
    description: string
}

export type NewFeatureComingSoonModalModel = {
    text: TextModel
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const NewFeatureComingSoonModal: FC<
    NewFeatureComingSoonModalModel
> = props => (
    <Drawer noBodyStyles open={props.open} onOpenChange={props.onOpenChange}>
        <DrawerContent className={styles.modal}>
            <DrawerTitle />
            <DrawerDescription />
            <DrawerClose className={styles.closeIconContainer}>
                <FontAwesomeIcon className={styles.closeIcon} icon={faXmark} />
            </DrawerClose>

            <div className={styles.content}>
                <p
                    className={styles.text}
                    style={{
                        marginTop: 16,
                        fontSize: vars.font.size.xxxl,
                        fontWeight: 600,
                    }}
                >
                    {props.text.title}
                </p>
                <p
                    className={styles.text}
                    style={{
                        marginTop: 12,
                        fontSize: vars.font.size.regular,
                    }}
                >
                    {props.text.description}
                </p>
            </div>
        </DrawerContent>
    </Drawer>
)
