import { faCrown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQueryClient, useSuspenseInfiniteQuery } from "@tanstack/react-query"
import { UserProfileId } from "api/branded-types"
import { Badge } from "common/badge"
import { listFirstUsersInfiniteQueryOptions } from "hooks/use-list-first-users"
import { FC } from "react"
import { vars } from "theme/variables.css"

import {
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
} from "common/popover"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useTranslation } from "react-i18next"
import * as styles from "./first-adopters-badge.css"

type FirstAdopterBadgeModel = { profileId?: UserProfileId }

export const FirstAdopterBadge: FC<FirstAdopterBadgeModel> = ({
    profileId,
}) => {
    const { t } = useTranslation("common")
    const queryClient = useQueryClient()

    const userProfileQuery = useListUserProfileById(profileId)
    const profile = userProfileQuery.data?.data

    const firstUsersQuery = useSuspenseInfiniteQuery(
        listFirstUsersInfiniteQueryOptions(queryClient),
    )

    const firstUserIds = firstUsersQuery.data.pages.flatMap(page =>
        page.data.map(item => item.id),
    )
    //TODO: consider moving this computation into cache
    const isFirstAdopter =
        userProfileQuery.isSuccess &&
        profile &&
        firstUserIds.includes(profile.userId)

    return (
        <>
            {isFirstAdopter && (
                <Popover>
                    <PopoverTrigger asChild>
                        <Badge
                            variant="dark"
                            className={styles.firstAdoptersBadge}
                        >
                            <FontAwesomeIcon
                                className={styles.firstAdopterIcon}
                                icon={faCrown}
                            />
                            <p
                                style={{
                                    fontSize: vars.font.size.xs,
                                    fontWeight: 400,
                                }}
                            >
                                {t("badges.firstAdopters")}
                            </p>
                        </Badge>
                    </PopoverTrigger>
                    <PopoverContent
                        side="top"
                        className={styles.popoverContent}
                    >
                        <PopoverArrow />
                        <p
                            style={{
                                fontSize: vars.font.size.xs,
                                fontWeight: 700,
                            }}
                        >
                            First 1K Member – One of our earliest supporters!
                        </p>
                    </PopoverContent>
                </Popover>
            )}
            {userProfileQuery.isLoading && (
                <Badge
                    variant="loading"
                    className={styles.firstAdoptersBadge}
                />
            )}
        </>
    )
}
