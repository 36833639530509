import { queryOptions, useQuery } from "@tanstack/react-query"
import { VideoId } from "../../../api/branded-types"
import { videoSelectorClient } from "../../../api/clients/video-api-client"
import { QueryKeys } from "../../../api/query-keys"

export const getVideoById = async (id?: VideoId) => {
    if (!id) throw new Error("VideoId should be defined")
    const videoRes = await videoSelectorClient.getVideoById({ params: { id } })
    return videoRes.data
}

export const videoByIdQueryOptions = (id?: VideoId) =>
    queryOptions({
        queryFn: () => getVideoById(id),
        queryKey: QueryKeys.payableVideo(id),
        enabled: !!id,
    })

export const useVideoById = (id?: VideoId) => {
    return useQuery(videoByIdQueryOptions(id))
}
