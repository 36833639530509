import { FC } from "react"

import { faShieldHeart } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "../../common/dialog"
import * as styles from "./cookie-consent-initial-modal.css"

type CookieConsentInitialModalModel = {
    open: boolean
    onOpenChange: (v: boolean) => void
    onNavigateToPrivacyPolicy: () => void
    onAcceptAllClicked: () => void
    onOptionsClicked: () => void
}

export const CookieConsentInitialModal: FC<
    CookieConsentInitialModalModel
> = model => {
    const { t } = useTranslation(["modal"])

    return (
        <Dialog open={model.open} onOpenChange={model.onOpenChange}>
            <DialogContent className={styles.content}>
                <FontAwesomeIcon className={styles.icon} icon={faShieldHeart} />
                <div className={styles.textContainer}>
                    <DialogTitle
                        style={{
                            fontSize: vars.font.size.l,
                            margin: 0,
                            fontWeight: 600,
                        }}
                    >
                        {t("cookie.title")}
                    </DialogTitle>
                    <DialogDescription
                        style={{
                            margin: 0,
                            fontSize: vars.font.size.regular,
                        }}
                    >
                        {t("cookie.description")}{" "}
                        <Link
                            to="/registration/privacy-policy"
                            className={styles.link}
                            style={{ fontSize: vars.font.size.regular }}
                            onClick={model.onNavigateToPrivacyPolicy}
                        >
                            {" "}
                            {t("cookie.privacySettings")}
                        </Link>
                    </DialogDescription>
                </div>
                <div className={styles.buttonsContainer}>
                    <Button
                        data-test="accept-all"
                        onClick={model.onAcceptAllClicked}
                    >
                        {t("cookie.acceptAll")}
                    </Button>

                    <Button variant="control" onClick={model.onOptionsClicked}>
                        {t("cookie.options")}
                    </Button>

                    <Button
                        variant="ghost"
                        onClick={() => model.onOpenChange(false)}
                    >
                        {t("cookie.continueWithoutAccepting")}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
