import classNames from "classnames"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useMemo, useState } from "react"
import { match } from "ts-pattern"

import {
    getMergedProfileIdPages,
    mergeSortedInfiniteData,
} from "../../../../api/api-utils"
import { UserProfileId } from "../../../../api/branded-types"
import { useRecommendedPostits } from "../../../../hooks/use-recommended-postits"
import { useRecommendedVideos } from "../../../../hooks/use-recommended-videos"
import { fill } from "../../../../utils/array"
import { PostLoadingTile } from "../../../search/videos/post-loading-tile"
import {
    PostAreaTile,
    PostAreaTileModel,
    isPostit,
    isVideo,
} from "../post/post-area-tile"
import { VideoAreaTile, VideoAreaTileModel } from "./video-area-tile"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { InfiniteScroll } from "common/infinite-scroll"
import { assetsQueryOptions } from "hooks/use-asset"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useUserFollowings } from "hooks/use-user-followings"
import { vars } from "theme/variables.css"
import * as styles from "./recommended-post-area-view.css"

export type RecommendedVideosAreaViewModel = {
    loc: VideoAreaTileModel["loc"] & PostAreaTileModel["loc"]
    className?: string
    title?: string
    placeholder?: string
    profileId?: UserProfileId
}

export const RecommendedPostAreaView: FC<RecommendedVideosAreaViewModel> = ({
    loc,
    className = "",
    title,
    placeholder,
    profileId,
}) => {
    const followingsQuery = useUserFollowings(profileId)

    const userFollowingsProfileIds =
        followingsQuery.data?.pages.flatMap(page =>
            page.data.map(item => item.id),
        ) ?? []

    const feedRecommendedVideosQuery = useRecommendedVideos(
        userFollowingsProfileIds,
        profileId,
    )
    const feedRecommendedPostsQuery = useRecommendedPostits(
        userFollowingsProfileIds,
        profileId,
    )

    const feedRecommendedVideosData = useMemo(
        () =>
            feedRecommendedVideosQuery.data?.pages.flatMap(page => page.data) ??
            [],
        [feedRecommendedVideosQuery.data?.pages],
    )
    const feedRecommendedPostsData = useMemo(
        () =>
            feedRecommendedPostsQuery.data?.pages.flatMap(page => page.data) ??
            [],
        [feedRecommendedPostsQuery.data?.pages],
    )

    const queryClient = useQueryClient()

    const postResourceAssetRefsPages = pipe(
        feedRecommendedPostsQuery.data?.pages ?? [],

        A.map(page =>
            pipe(
                page.data,
                A.filter(post => post.type === "Image"),
                A.map(post => post.imageRef),
            ),
        ),
    )

    const assetQueries = useQueries({
        queries: postResourceAssetRefsPages.map((page, idx) =>
            assetsQueryOptions({
                pageNumber: idx,
                queryClient,
                resources: page,
            }),
        ),
    })

    const oneOfAssetQueriesLoading = assetQueries.some(query => query.isLoading)

    const profileIdsPages = getMergedProfileIdPages(
        feedRecommendedVideosQuery.data?.pages ?? [],
        feedRecommendedPostsQuery.data?.pages ?? [],
    )

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const totalItemsFetched =
        feedRecommendedVideosData.length + feedRecommendedPostsData.length
    const initialDesiredAmount = totalItemsFetched > 0 ? totalItemsFetched : 50

    const [desiredAmount, setDesiredAmount] = useState(initialDesiredAmount)

    const data = useMemo(
        () =>
            mergeSortedInfiniteData({
                firstItems: feedRecommendedVideosData,
                secondItems: feedRecommendedPostsData,
                firstHasNextPage: feedRecommendedVideosQuery.hasNextPage,
                secondHasNextPage: feedRecommendedPostsQuery.hasNextPage,
                desiredAmount,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            desiredAmount,
            feedRecommendedVideosData,
            feedRecommendedPostsData,
            feedRecommendedVideosQuery.hasNextPage,
            feedRecommendedVideosQuery.fetchNextPage,
            feedRecommendedPostsQuery.hasNextPage,
            feedRecommendedPostsQuery.fetchNextPage,
        ],
    )

    const videosState = match(feedRecommendedVideosQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const postsState = match(feedRecommendedPostsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const contentState =
        videosState === "Loaded" && postsState === "Loaded"
            ? "Loaded"
            : videosState === "Failed" || postsState === "Failed"
              ? "Failed"
              : videosState === "Loading" || postsState === "Loading"
                ? "Loading"
                : "Unloaded"

    const showLoadingState =
        (contentState === "Loading" ||
            oneOfProfileQueriesLoading ||
            oneOfAssetQueriesLoading) &&
        !feedRecommendedVideosQuery.isPlaceholderData &&
        !feedRecommendedPostsQuery.isPlaceholderData

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.header}>
                <h1
                    style={{
                        fontSize: vars.font.size.l,
                        color: "white",
                    }}
                >
                    {title}
                </h1>
            </div>
            <InfiniteScroll
                disabled={
                    !feedRecommendedPostsQuery.hasNextPage &&
                    !feedRecommendedVideosQuery.hasNextPage
                }
                state={contentState}
                threshold="100px"
                onLoadRequested={() => {
                    setDesiredAmount(prev => prev + 50)
                    if (feedRecommendedVideosQuery.hasNextPage) {
                        feedRecommendedVideosQuery.fetchNextPage()
                    }

                    if (feedRecommendedPostsQuery.hasNextPage) {
                        feedRecommendedPostsQuery.fetchNextPage()
                    }
                }}
            >
                <div className={styles.content}>
                    {
                        //TODO: this is not correct, data should be paginated. But currently there is
                        // no way to properly merge infinite query data in a manageable way :(
                        profileQueries.some(query => !query.isLoading) &&
                            assetQueries.some(query => !query.isLoading) &&
                            pipe(
                                data,
                                A.map(post =>
                                    pipe(
                                        post,
                                        O.fromPredicate(isVideo),
                                        O.fold(
                                            () =>
                                                pipe(
                                                    post,
                                                    O.fromPredicate(isPostit),
                                                    O.fold(
                                                        () => null,
                                                        postit => (
                                                            <PostAreaTile
                                                                key={postit.id}
                                                                loc={loc}
                                                                postId={
                                                                    postit.id
                                                                }
                                                            />
                                                        ),
                                                    ),
                                                ),
                                            video => (
                                                <VideoAreaTile
                                                    key={video.id}
                                                    loc={loc}
                                                    videoId={video.id}
                                                />
                                            ),
                                        ),
                                    ),
                                ),
                            )
                    }

                    {showLoadingState &&
                        fill(2, idx => <PostLoadingTile key={idx} />)}
                </div>
                {contentState === "Loaded" && data.length === 0 && (
                    <PlaceholderView placeholder={placeholder} />
                )}
            </InfiniteScroll>
        </div>
    )
}

const PlaceholderView: FC<{ placeholder?: string }> = ({ placeholder }) => (
    <div className={styles.infoBox}>
        <p
            style={{
                fontSize: vars.font.size.m,
                color: "white",
            }}
        >
            {placeholder}
        </p>
    </div>
)
