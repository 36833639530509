import classNames from "classnames"
import { FC } from "react"

import { Avatar, createStateBorderStyle } from "../../components/avatars/avatar"
import { AvatarLoading } from "../../components/avatars/avatar-loading"
import { useMyUserProfiles } from "../../hooks/use-my-user-profiles"

import { identity } from "fp-ts/function"
import { useTranslation } from "react-i18next"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { SkeletonStyleProps } from "react-loading-skeleton"
import * as styles from "./my-profile-avatar.css"

export type MyProfileAvatarModel = {
    className?: string
    slot?: string | undefined
    avatarSize?: number
    skeletonStyle?: SkeletonStyleProps
    avatarStyle?: React.CSSProperties
    onUserClicked?: () => void
    isAuthorizedAccount: boolean
}

export const MyProfileAvatar: FC<MyProfileAvatarModel> = ({
    className = "",
    slot,
    skeletonStyle,
    avatarSize,
    avatarStyle,
    onUserClicked,
    isAuthorizedAccount,
}) => {
    const { t } = useTranslation(["myProfile"])
    const myProfilesQuery = useMyUserProfiles(identity, isAuthorizedAccount)
    const showRegularAvatar =
        myProfilesQuery.isSuccess && myProfilesQuery.data.profiles.length > 0

    return (
        <div
            slot={slot}
            className={classNames(styles.wrapper, className)}
            onClick={onUserClicked}
        >
            {myProfilesQuery.isLoading ? (
                <AvatarLoading
                    className={styles.avatar}
                    style={{
                        ...skeletonStyle,
                    }}
                    size={40}
                />
            ) : myProfilesQuery.isError ? (
                <LazyLoadImage
                    width={avatarSize}
                    height={avatarSize}
                    className={styles.avatar}
                    src="/images/default_avatar.jpg"
                    style={{
                        ...createStateBorderStyle("None"),
                        ...avatarStyle,
                    }}
                />
            ) : (
                <>
                    {showRegularAvatar ? (
                        <Avatar
                            className={styles.avatar}
                            src={myProfilesQuery.data?.profiles.at(0)?.imageUrl}
                            size={avatarSize}
                            style={avatarStyle}
                            styleState={
                                myProfilesQuery.data?.profiles.at(0)?.isLive
                                    ? t("avatar.live")
                                    : t("avatar.none")
                            }
                        />
                    ) : (
                        <LazyLoadImage
                            width={avatarSize}
                            height={avatarSize}
                            className={styles.avatar}
                            src="/images/default_avatar.jpg"
                            style={{
                                ...createStateBorderStyle("None"),
                                ...avatarStyle,
                            }}
                        />
                    )}
                </>
            )}
        </div>
    )
}
