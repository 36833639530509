import { FC, useState } from "react"

import { Except } from "type-fest"

import { FoldableText, FoldableTextModel } from "./foldable-text"

export type FoldableStatefulTextModel = Except<
    FoldableTextModel,
    "onOpenChange"
>

export const FoldableStatefulText: FC<FoldableStatefulTextModel> = model => {
    const [open, setOpen] = useState(model.open)

    return <FoldableText {...model} open={open} onOpenChange={setOpen} />
}
