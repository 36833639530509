import { createFileRoute } from "@tanstack/react-router"
import { notValidRouteBeforeLoad } from "utils/router"

export const Route = createFileRoute("/registration/")({
    component: RouteComponent,
    beforeLoad: notValidRouteBeforeLoad,
})

function RouteComponent() {
    return <></>
}
