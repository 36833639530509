import { makeApi } from "@zodios/core"
import z from "zod"

import {
    AccountType,
    ApiError,
    Pagination,
    ProfileChallenge,
    ProfileChallenges,
    ProfileReward,
    Tier,
    UserProfile,
    UserProfileStatistic,
    UserSubscriptionMonetization,
} from "../api-models"
import { ProfileChallengeId, UserId, UserProfileId } from "../branded-types"

export const userProfileSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/profiles",
        alias: "listProfiles",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserProfile),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/user/me/profiles",
        alias: "getMyProfiles",
        response: z.object({
            data: z.array(UserProfile),
            paging: Pagination,
            totalCount: z.number(),
            accountType: z.optional(AccountType).default("Anonymous"),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/user/:id/profiles",
        alias: "listProfilesByUserId",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserId,
            },
        ],
        response: z.object({
            data: z.array(UserProfile),
            accountType: z.optional(AccountType).default("Anonymous"),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/profile/:id",
        alias: "getProfileById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
        ],
        response: z.object({
            data: UserProfile,
            accountType: z.optional(AccountType).default("Anonymous"),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/profile/:id/rewards",
        alias: "listProfileRewards",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(ProfileReward),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/profile/:id/statistic",
        alias: "getUserProfileStatisticById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
        ],
        response: z.object({
            data: UserProfileStatistic,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/profile/:id/followers",
        alias: "listFollowersOfUserProfileId",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserProfile),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/profile/:id/following",
        alias: "listProfilesWhichUserProfileIdFollows",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserProfile),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/profile/:id/subscribers",
        alias: "listSubscriberOfUserProfileId",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserProfile),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/profile/:id/subscriptions",
        alias: "listSubscriptionsOfProfileId",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: z.object({
            data: z.array(UserProfile),
            paging: Pagination,
            totalCount: z.number(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/profile/:id/challenges/overview",
        alias: "getProfileChallengesOverview",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserProfileId,
            },
        ],
        response: z.object({
            data: ProfileChallenges,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/challenge/:id",
        alias: "getProfileChallengeById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: ProfileChallengeId,
            },
        ],
        response: z.object({
            data: ProfileChallenge,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/tiers",
        alias: "getTiers",
        response: z.object({
            data: z.array(Tier),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        alias: "getSubscriptionMonetization",
        path: "/v1/profile/:profileId/monetization",
        parameters: [
            {
                name: "profileId",
                type: "Path",
                schema: UserProfileId,
            },
        ],
        response: z.object({
            data: UserSubscriptionMonetization,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
