import * as SwitchPrimitives from "@radix-ui/react-switch"
import classNames from "classnames"

import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"
import * as styles from "./switch.css"

export const Switch = forwardRef<
    ElementRef<typeof SwitchPrimitives.Root>,
    ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={classNames(styles.switchRoot, className)}
        {...props}
        ref={ref}
    >
        <SwitchPrimitives.Thumb className={classNames(styles.switchThumb)} />
    </SwitchPrimitives.Root>
))
