import classNames from "classnames"
import { FC } from "react"

import { AvatarModel } from "../../../../components/avatars/avatar"

import { Link } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import { postTimeInfo } from "utils/time"
import * as styles from "./user-details.css"

export type UserDetailsBasicModel = {
    className?: string
    avatarSize?: AvatarModel["size"]
    contentLastChangedAt?: string
    profileId?: UserProfileId
}

export const UserDetailsBasic: FC<UserDetailsBasicModel> = ({
    className = "",
    avatarSize,
    contentLastChangedAt,
    profileId,
}) => {
    const { t, i18n } = useTranslation("common")

    const myProfileIdQuery = useMyUserProfileId()
    const profileQuery = useListUserProfileById(profileId)
    const profile = profileQuery.data?.data

    return (
        <div className={classNames(styles.wrapper, className)}>
            {profileQuery.isSuccess && profile && (
                <Link
                    to={
                        myProfileIdQuery.data === profile.id
                            ? "/app/my-profile"
                            : "/app/user-profile/$id"
                    }
                    params={{ id: profile.id }}
                >
                    <div className={styles.content}>
                        <AvatarWithBadge
                            profileId={profile.id}
                            className={styles.avatar}
                            src={profile.imageUrl}
                            size={avatarSize}
                            styleState={profile.isLive ? "isLive" : "None"}
                        />

                        <div>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    color: "white",
                                }}
                            >
                                {profile.displayName}
                            </p>

                            {contentLastChangedAt && (
                                <p
                                    style={{
                                        marginTop: "2px",
                                        fontSize: vars.font.size.xs,
                                        color: "white",
                                        opacity: 0.75,
                                    }}
                                >
                                    {postTimeInfo({
                                        date: contentLastChangedAt,
                                        i18nLang: i18n.language,
                                        t,
                                    })}
                                </p>
                            )}
                        </div>
                    </div>
                </Link>
            )}
        </div>
    )
}
