import { differenceInDays, parseISO } from "date-fns"
import { TFunction } from "i18next"
import { localeMap } from "./localization"

export const hmDateFormat = (date: Date) =>
    new Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
    }).format(date)

export const mdDateFormat = (date: Date) =>
    new Intl.DateTimeFormat(navigator.language, {
        month: "long",
        day: "numeric",
    }).format(date)

export const isOneDayOld = (date: Date | string | number): boolean =>
    differenceInDays(
        new Date(),
        typeof date === "string" ? parseISO(date) : new Date(date),
    ) === 1

export const isAWeekOld = (date: Date | string | number): boolean =>
    differenceInDays(
        new Date(),
        typeof date === "string" ? parseISO(date) : new Date(date),
    ) === 7

export const isALastWeekOld = (date: Date | string | number): boolean => {
    const diff = differenceInDays(
        new Date(),
        typeof date === "string" ? parseISO(date) : new Date(date),
    )

    return diff < 7 && diff > 1
}

export const isOlderThanAWeek = (date: Date | string | number): boolean =>
    differenceInDays(
        new Date(),
        typeof date === "string" ? parseISO(date) : new Date(date),
    ) > 7

export const delay = (fn: () => unknown, wait?: number) =>
    setTimeout(() => {
        fn()
    }, wait ?? 3000)

type MessageTimeInfoProps = {
    date: string
    i18nLang: string
    t: TFunction<"common">
}

export const messageTimeInfo = ({
    date,
    i18nLang,
    t,
}: MessageTimeInfoProps) => {
    const d = new Date(date)
    const now = new Date()
    const today = new Date(now)
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)

    const lastWeek = new Date(today)
    lastWeek.setDate(today.getDate() - 7)

    const twoWeeksAgo = new Date(today)
    twoWeeksAgo.setDate(today.getDate() - 14)

    const timeDiffMs = now.getTime() - d.getTime()
    const timeDiffMinutes = Math.floor(timeDiffMs / (1000 * 60))
    const timeDiffHours = Math.floor(timeDiffMinutes / 60)

    const locale = localeMap[i18nLang] || "en-US"

    // Formatters
    const timeFormatter = new Intl.DateTimeFormat(locale, {
        timeStyle: "short",
    })
    const dayFormatter = new Intl.DateTimeFormat(locale, { weekday: "short" })
    const fullFormatter = new Intl.DateTimeFormat(locale, {
        dateStyle: "short",
    })
    const relativeFormatter = new Intl.RelativeTimeFormat(locale, {
        numeric: "auto",
        style: "narrow",
    })

    if (timeDiffMinutes < 1) {
        return relativeFormatter.format(0, "second")
    }
    if (timeDiffMinutes < 60) {
        return relativeFormatter.format(-timeDiffMinutes, "minute")
    }
    if (timeDiffHours < 24) {
        return relativeFormatter.format(-timeDiffHours, "hour")
    }

    if (d >= today) {
        return `${timeFormatter.format(d)}`
    }
    if (d >= yesterday) {
        return `${t("time.yesterday")}, ${timeFormatter.format(d)}`
    }
    if (d >= lastWeek) {
        return `${dayFormatter.format(d)}, ${timeFormatter.format(d)}`
    }
    if (d >= twoWeeksAgo) {
        return `${t("time.last")} ${dayFormatter.format(d)}, ${timeFormatter.format(d)}`
    }
    return fullFormatter.format(d)
}

type PostTimeInfoProps = {
    date: string
    i18nLang: string
    detailed?: boolean
    t: TFunction<"common">
}

export const postTimeInfo = ({
    date,
    i18nLang,
    t,
    detailed = false,
}: PostTimeInfoProps) => {
    const d = new Date(date)
    const now = new Date()
    const today = new Date(now)
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)

    const startOfWeek = new Date(today)
    startOfWeek.setDate(today.getDate() - today.getDay() + 1)

    const startOfYear = new Date(today.getFullYear(), 0, 1)

    const timeDiffMs = now.getTime() - d.getTime()
    const timeDiffSeconds = Math.floor(timeDiffMs / 1000)
    const timeDiffMinutes = Math.floor(timeDiffSeconds / 60)
    const timeDiffHours = Math.floor(timeDiffMinutes / 60)

    const locale = localeMap[i18nLang] || "en-US"

    // Formatters
    const shortDateFormatter = new Intl.DateTimeFormat(locale, {
        day: "2-digit",
        month: "short",
    })
    const shortDateDetailedFormatter = new Intl.DateTimeFormat(locale, {
        day: "2-digit",
        month: "long",
    })
    const fullDateYearFormatter = new Intl.DateTimeFormat(locale, {
        day: "2-digit",
        month: "short",
        year: "numeric",
    })
    const fullDateFormatter = new Intl.DateTimeFormat(locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
    })
    const dayFormatter = new Intl.DateTimeFormat(locale, { weekday: "long" })

    const relativeFormatter = new Intl.RelativeTimeFormat(locale, {
        numeric: "auto",
        style: "narrow",
    })

    // Time-based conditions
    if (timeDiffSeconds < 60) {
        return relativeFormatter.format(0, "second")
    }
    if (timeDiffMinutes < 60) {
        return relativeFormatter.format(-timeDiffMinutes, "minute")
    }
    if (timeDiffHours < 24) {
        return relativeFormatter.format(-timeDiffHours, "hour")
    }

    // Date-based conditions
    if (d >= today) {
        return t("today")
    }
    if (d >= yesterday) {
        return t("yesterday")
    }
    if (d >= startOfWeek) {
        return dayFormatter.format(d)
    }
    if (d >= startOfYear) {
        return detailed
            ? shortDateDetailedFormatter.format(d)
            : shortDateFormatter.format(d)
    }
    return detailed
        ? fullDateFormatter.format(d)
        : fullDateYearFormatter.format(d)
}
