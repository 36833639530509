import { createFileRoute } from "@tanstack/react-router"
import { MyUserProfilePageLoading } from "features/user-profile/my-user-profile-page"

import { z } from "zod"

export const MyUserProfilePageTab = z.union([
    z.literal("All"),
    z.literal("Purchased"),
])

export const MyProfilePageSearchSchema = z.object({
    tab: MyUserProfilePageTab.catch("All"),
})
export type MyProfilePageSearchSchema = z.infer<
    typeof MyProfilePageSearchSchema
>

export const Route = createFileRoute(
    "/_authenticated/app/_tab-bar-layout/my-profile/",
)({
    loader: () => {},
    pendingComponent: MyUserProfilePageLoading,
    validateSearch: search => MyProfilePageSearchSchema.parse(search),
})
