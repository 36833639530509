import {
    faEye,
    faCircleStar as solidStar,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { pipe } from "fp-ts/function"
import parse from "html-react-parser"
import { CSSProperties, FC } from "react"
import { Postit, Video } from "../../../../api/api-models"
import { PostitId } from "../../../../api/branded-types"
import { truncateText } from "../../../../utils/text"

import { faBan } from "@fortawesome/pro-regular-svg-icons"
import { Link } from "@tanstack/react-router"
import { Badge } from "common/badge"
import { Image } from "common/image"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { LinkItUrl } from "features/linkify"
import { useListPostitById } from "features/postit/hooks/use-list-postit-by-id"
import { PostLoadingTile } from "features/search/videos/post-loading-tile"
import { useAsset } from "hooks/use-asset"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { LocalStorage } from "local-storage"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import { isImageAsset } from "utils/asset"
import { postTimeInfo } from "utils/time"
import * as styles from "./post-area-tile.css"

export type PostAreaTileModel = {
    loc: {
        title: string
    }
    className?: string
    style?: CSSProperties | undefined
    postId: PostitId
}

export const PostAreaTile: FC<PostAreaTileModel> = ({
    className = "",
    loc,
    postId,
}) => {
    const { t: tCommon, i18n } = useTranslation("common")
    const { t } = useTranslation("postitViewer")

    const { toast } = useInfoToast()

    const postitQuery = useListPostitById(postId)
    const postit = postitQuery.data?.data

    const profileQuery = useListUserProfileById(postit?.creatorId)
    const profile = profileQuery.data?.data

    const shouldBeSecured = postit?.monetization.type === "SubscriptionOnly"

    const assetRef = postit?.type === "Image" ? postit.imageRef : undefined

    const assetQuery = useAsset({ resource: assetRef })

    const openModal = useAppStore(store => store.openModal)
    const aspectRatio =
        assetRef && assetQuery.isSuccess && isImageAsset(assetQuery.data)
            ? assetQuery.data.resolution.width /
              assetQuery.data.resolution.height
            : undefined
    const desiredImageWidth = window.innerWidth - 40
    const desiredImageHeight = aspectRatio
        ? desiredImageWidth / aspectRatio
        : undefined

    const myProfileIdQuery = useMyUserProfileId()

    const isInReview = postit?.status === "InReview"
    const isBlocked =
        postit?.status === "Rejected" ||
        (postit?.status === "None" && postit.isBlocked)

    const hasStatusBadge = isInReview || isBlocked
    const hasBadges = shouldBeSecured || hasStatusBadge

    const showLoading = postitQuery.isLoading

    const metadata = {
        status: assetQuery.status,
        data: assetQuery.data?.type === "Image" ? assetQuery.data : undefined,
        refresh: () => assetQuery.refetch(),
    }

    return postitQuery.isSuccess && postit ? (
        <div className={classNames(styles.wrapper, className)}>
            <Link
                to="/app/postit/viewer/$id"
                params={{ id: postit.id }}
                onClick={e => {
                    if (LocalStorage.getAccountTypeOrDefault() === "Guest") {
                        e.preventDefault()
                        openModal({
                            type: "LoginOrRegister",
                            profileId: postit?.creatorId,
                        })
                    }
                }}
            >
                <div
                    className={classNames(
                        styles.badgesContainer,
                        hasBadges && styles.badgesMargin,
                        postit.type === "Image" &&
                            styles.badgesContainerAbsolute,
                    )}
                >
                    {shouldBeSecured && (
                        <div className={classNames(styles.payableMark)}>
                            <FontAwesomeIcon
                                style={{
                                    width: 16,
                                    height: 16,
                                }}
                                icon={solidStar}
                                color="white"
                            />
                            <p
                                className={styles.message}
                                style={{
                                    fontSize: vars.font.size.regular,
                                    fontWeight: 600,
                                }}
                            >
                                {loc.title}
                            </p>
                        </div>
                    )}
                    {hasStatusBadge && (
                        <Badge
                            onClick={e => {
                                e.preventDefault()
                                toast({
                                    toastContent: isBlocked ? (
                                        <InfoToastContent
                                            icon={faBan}
                                            iconColor="medium"
                                            iconClassName={
                                                styles.toastBlockedIcon
                                            }
                                            text={t(
                                                "moderation.blocked.toast.text",
                                            )}
                                        />
                                    ) : (
                                        <InfoToastContent
                                            icon={faEye}
                                            iconColor="medium"
                                            iconClassName={
                                                styles.toastReviewIcon
                                            }
                                            text={t(
                                                "moderation.review.toast.text",
                                            )}
                                        />
                                    ),
                                })
                            }}
                            variant={
                                isBlocked ? "dangerOnSecondary" : "secondary"
                            }
                        >
                            {isBlocked
                                ? t("moderation.badge.rejected")
                                : t("moderation.badge.review")}
                        </Badge>
                    )}
                </div>

                {postit.type === "Image" && (
                    <div
                        className={classNames(
                            styles.imageWrapper,
                            assetQuery.isLoading && styles.imageWrapperLoading,
                        )}
                    >
                        {!assetQuery.isLoading && (
                            <>
                                {shouldBeSecured && (
                                    <div className={styles.secureOverlay} />
                                )}
                                <Image
                                    metadata={metadata}
                                    alt={postit.title}
                                    className={
                                        shouldBeSecured
                                            ? styles.progressiveImageSecured
                                            : ""
                                    }
                                    src={assetQuery.data?.source}
                                    width={desiredImageWidth}
                                    height={desiredImageHeight}
                                />
                            </>
                        )}
                        {assetQuery.isLoading && (
                            <Skeleton
                                width={desiredImageWidth}
                                height={desiredImageWidth / 1.2}
                                borderRadius={vars.measurement.radius.md}
                                inline
                            />
                        )}
                    </div>
                )}

                <div className={styles.content}>
                    <div className={classNames(styles.textContent)}>
                        <p
                            className={styles.title}
                            style={{
                                fontSize: vars.font.size.m,
                                fontWeight: 600,
                            }}
                        >
                            {postit.title}
                        </p>

                        {postit.message ? (
                            <p
                                className={styles.message}
                                style={{ fontSize: vars.font.size.regular }}
                            >
                                {
                                    //TODO: move this out probably, since this is nested link
                                    //? complexity - you do not know the size of description
                                }
                                <LinkItUrl
                                    onLinkClicked={e => e.stopPropagation()}
                                    className={styles.link}
                                >
                                    {pipe(
                                        postit.message,
                                        truncateText(200),
                                        parse,
                                    )}
                                </LinkItUrl>
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <div className={styles.divider} />
            </Link>
            {profileQuery.isSuccess && profile && (
                //TODO: add loading state probably
                <Link
                    to={
                        myProfileIdQuery.data === profile.id
                            ? "/app/my-profile"
                            : "/app/user-profile/$id"
                    }
                    params={{ id: profile.id }}
                >
                    <div className={styles.footer}>
                        <AvatarWithBadge
                            profileId={postit.creatorId}
                            className={styles.avatar}
                            src={profile.imageUrl}
                            size={36}
                            styleState={profile.isLive ? "isLive" : "None"}
                        />

                        <div>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    color: "white",
                                }}
                            >
                                {profile.displayName ?? profile.profileName}
                            </p>
                            <p
                                style={{
                                    marginTop: "2px",
                                    fontSize: vars.font.size.xs,
                                    color: "white",
                                    opacity: 0.5,
                                }}
                            >
                                {postTimeInfo({
                                    date: postit.createdAt,
                                    i18nLang: i18n.language,
                                    t: tCommon,
                                })}
                            </p>
                        </div>
                    </div>
                </Link>
            )}
        </div>
    ) : showLoading ? (
        <PostLoadingTile />
    ) : null
}

export const isPostit = (post: Video | Postit): post is Postit =>
    Postit.safeParse(post).success

export const isVideo = (post: Video | Postit): post is Video =>
    Video.safeParse(post).success
