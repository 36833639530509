import { Button } from "common/button"
import { DialogDescription, DialogTitle } from "common/dialog"
import {
    multiStepModalVariants,
    MultiStepModalWrapper,
} from "common/multi-step-modal"
import { CheckItem } from "components/controls/check-item"
import { AnimatePresence, motion, MotionConfig } from "framer-motion"
import { FC, useMemo } from "react"

import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { DrawerClose } from "common/drawer"
import { useMeasure } from "hooks/use-measure"

import { useMutation } from "@tanstack/react-query"
import { CreateContentReport } from "api/api-models"
import { PostitId, VideoId } from "api/branded-types"
import { postitCreatorClient } from "api/clients/post-api-client"
import { videoCreatorClient } from "api/clients/video-api-client"
import { Textarea } from "common/textarea"
import { useTranslation } from "react-i18next"
import * as styles from "./report-content-modal.css"
import { useReportStore } from "./report-store"

type ReportContentModalModel = {
    open: boolean
    onClose: () => void
    onReset: () => void
}

type ReportPostitMutationParams = CreateContentReport & { id: PostitId }
type ReportVideoMutationParams = CreateContentReport & { id: VideoId }

export const ReportContentModal: FC<ReportContentModalModel> = ({
    open,
    onClose,
    onReset,
}) => {
    const currentStep = useReportStore(store => store.reportContentStep)
    const setCurrentStep = useReportStore(store => store.setReportContentStep)
    const customDetails = useReportStore(store => store.reportContentMessage)
    const reportContent = useReportStore(store => store.reportContent)
    const reportContentOwner = useReportStore(store => store.reportContentOwner)
    const issue = useReportStore(store => store.selectedIssue)

    const { mutate: reportPostit } = useMutation({
        mutationFn: (params: ReportPostitMutationParams) =>
            postitCreatorClient.reportPostitById(
                { customDetails: params.customDetails, issue: params.issue },
                { params: { id: params.id } },
            ),
    })

    const { mutate: reportVideo } = useMutation({
        mutationFn: (params: ReportVideoMutationParams) =>
            videoCreatorClient.reportVideoById(
                { customDetails: params.customDetails, issue: params.issue },
                { params: { id: params.id } },
            ),
    })

    const [ref, bounds] = useMeasure()

    const content = useMemo(() => {
        switch (currentStep) {
            case "initial": {
                return <InitialScreen />
            }
            case "detail": {
                return <DetailScreen />
            }
            case "thanks": {
                return <ThanksScreen />
            }
            default: {
                return <></>
            }
        }
    }, [currentStep])

    return (
        <MultiStepModalWrapper open={open} onClose={onClose} onReset={onReset}>
            <DrawerClose asChild className={styles.closeIconContainer}>
                <FontAwesomeIcon
                    className={styles.closeIcon}
                    icon={faXmark}
                    onClick={onClose}
                />
            </DrawerClose>
            <MotionConfig
                transition={{
                    bounce: 0,
                    duration: 0.27,
                    ease: [0.26, 0.08, 0.25, 1],
                }}
            >
                <AnimatePresence
                    key={currentStep}
                    mode="popLayout"
                    initial={false}
                >
                    <motion.div
                        key={`step-content-${currentStep}`}
                        transition={{
                            duration: 0.27,
                            ease: [0.26, 0.08, 0.25, 1],
                        }}
                        style={{ paddingBottom: bounds.height }}
                        className={styles.content}
                        variants={multiStepModalVariants}
                        initial="initial"
                        animate="active"
                        exit="exit"
                    >
                        {content}
                    </motion.div>
                    <motion.div key={`step-footer-${currentStep}`}>
                        <div className={styles.footer} ref={ref}>
                            <DrawerClose
                                asChild
                                onClick={e => {
                                    //? if not last step - do not close the modal
                                    if (currentStep !== "thanks")
                                        e.preventDefault()
                                }}
                            >
                                <Button
                                    disabled={
                                        currentStep === "initial" && !issue
                                    }
                                    onClick={() => {
                                        if (currentStep === "initial") {
                                            setCurrentStep("detail")
                                        }
                                        if (currentStep === "detail") {
                                            if (
                                                !reportContentOwner ||
                                                !reportContent ||
                                                !issue
                                            )
                                                return
                                            if (
                                                reportContent.contentType ===
                                                "Postit"
                                            ) {
                                                reportPostit({
                                                    customDetails,
                                                    issue,
                                                    id: reportContent.contentId,
                                                })
                                            } else if (
                                                reportContent.contentType ===
                                                "Video"
                                            ) {
                                                reportVideo({
                                                    customDetails,
                                                    issue,
                                                    id: reportContent.contentId,
                                                })
                                            }
                                            setCurrentStep("thanks")
                                        }
                                    }}
                                >
                                    {currentStep === "initial" && "Continue"}
                                    {currentStep === "detail" && "Submit"}
                                    {currentStep === "thanks" && "Close"}
                                </Button>
                            </DrawerClose>
                            {currentStep === "detail" && (
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        setCurrentStep("initial")
                                    }}
                                    asChild
                                >
                                    <motion.button
                                        key="back-button"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        Back
                                    </motion.button>
                                </Button>
                            )}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </MotionConfig>
        </MultiStepModalWrapper>
    )
}

const InitialScreen = () => {
    const { t } = useTranslation(["modal"])
    const selectedIssue = useReportStore(store => store.selectedIssue)
    const setReportContentIssue = useReportStore(
        store => store.setReportContentIssue,
    )

    return (
        <>
            <DialogTitle className={styles.title}>Content report</DialogTitle>
            <DialogDescription className={styles.description}>
                Please select a reason for your report. Your report will be
                treated anonymously and confidentially. We take all reports
                seriously and take legal action against violations.
            </DialogDescription>

            <div className={styles.checkItems}>
                {[
                    {
                        id: "Spam" as const,
                        title: "Spam",
                        description: t("report.init.screen.spam"),
                    },
                    {
                        id: "Harassment" as const,
                        title: "Harassment",
                        description: t("report.init.screen.harassment"),
                    },
                    {
                        id: "MissInformation" as const,
                        title: "False information",
                        description: t("report.init.screen.miss"),
                    },
                    {
                        id: "Offensive" as const,
                        title: "Offensive content",
                        description: t("report.init.screen.offensive"),
                    },
                    {
                        id: "Other" as const,
                        title: "Other",
                        description: t("report.init.screen.other"),
                    },
                ].map(({ title, description, id }) => (
                    <CheckItem
                        key={id}
                        id={id}
                        title={title}
                        message={description}
                        checked={id === selectedIssue}
                        onChange={value =>
                            setReportContentIssue(value ? id : undefined)
                        }
                    />
                ))}
            </div>
        </>
    )
}

const DetailScreen: FC = () => {
    const { t } = useTranslation(["modal"])
    const reportMessage = useReportStore(store => store.reportContentMessage)
    const setReportMessage = useReportStore(
        store => store.setReportContentMessage,
    )
    return (
        <>
            <DialogTitle className={styles.title}>
                {t("report.init.screen.detail.title")}
            </DialogTitle>
            <DialogDescription className={styles.description}>
                {t("report.init.screen.detail.description")}
            </DialogDescription>
            <Textarea
                value={reportMessage}
                onChange={e => setReportMessage(e.target.value ?? "")}
                className={styles.textarea}
            />
        </>
    )
}

export const ThanksScreen: FC = () => {
    const { t } = useTranslation(["modal"])

    return (
        <>
            <DialogTitle className={styles.title}>
                {t("report.init.screen.thank.title")}
            </DialogTitle>
            <DialogDescription
                className={classNames(
                    styles.description,
                    styles.thanksDescription,
                )}
            >
                {t("report.init.screen.thank.description")}
            </DialogDescription>
        </>
    )
}
