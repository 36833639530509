import { UserProfileId } from "api/branded-types"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import { useAppStore } from "store"

type MouseEvent =
    | React.MouseEvent<HTMLDivElement>
    | React.MouseEvent<HTMLAnchorElement>

export const useOnlyRegisteredUser = () => {
    const openModal = useAppStore(store => store.openModal)
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const ensureUserPreventDefaultCallback =
        (profileId?: UserProfileId) =>
        (callback: (event: MouseEvent) => void) =>
        (event: MouseEvent) => {
            if (accountType === "Guest") {
                event.preventDefault()
                openModal({
                    type: "LoginOrRegister",
                    profileId,
                })
                return
            }

            if (accountType === "Trial") {
                event.preventDefault()
                openModal({ type: "ContinueRegistrationByEmail" })
                return
            }

            return callback(event)
        }

    const ensureUserPreventDefault =
        (profileId?: UserProfileId) => (event: MouseEvent) => {
            if (accountType === "Guest") {
                event.preventDefault()
                openModal({
                    type: "LoginOrRegister",
                    profileId,
                })
            }

            if (accountType === "Trial") {
                event.preventDefault()
                openModal({ type: "ContinueRegistrationByEmail" })
            }
        }

    const ensureUserCallback =
        (profileId?: UserProfileId) => (callback: () => void) => () => {
            if (accountType === "Guest") {
                openModal({
                    type: "LoginOrRegister",
                    profileId,
                })
                return
            }

            if (accountType === "Trial") {
                openModal({ type: "ContinueRegistrationByEmail" })
                return
            }

            return callback()
        }

    const ensureUser = (profileId?: UserProfileId) => () => {
        if (accountType === "Guest")
            openModal({
                type: "LoginOrRegister",
                profileId,
            })

        if (accountType === "Trial")
            openModal({ type: "ContinueRegistrationByEmail" })
    }

    return {
        ensureUserPreventDefaultCallback,
        ensureUserCallback,
        ensureUserPreventDefault,
        ensureUser,
        accountType,
    }
}
