import { faMessageExclamation } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { List, ListItem } from "common/list"
import { vars } from "theme/variables.css"
import * as styles from "./post-consumer-actions-modal.css"

export type PostConsumerActionsModalTextModel = {
    report: string
}

type PostConsumerActionsModalModel = {
    text: PostConsumerActionsModalTextModel
    open: boolean
    onReportClicked: () => void
    onOpenChange: (v: boolean) => void
}
export const PostConsumerActionsModal: FC<
    PostConsumerActionsModalModel
> = model => (
    <Drawer open={model.open} onOpenChange={model.onOpenChange}>
        <DrawerContent className={styles.actionModalContent}>
            <DrawerTitle />
            <DrawerDescription />
            <List className={styles.actionList}>
                <ListItem
                    className={styles.action}
                    onClick={model.onReportClicked}
                >
                    <FontAwesomeIcon
                        icon={faMessageExclamation}
                        className={styles.icon}
                    />
                    <span
                        style={{
                            fontSize: vars.font.size.m,
                        }}
                    >
                        {model.text.report}
                    </span>
                </ListItem>
            </List>
        </DrawerContent>
    </Drawer>
)
