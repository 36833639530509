import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Video } from "api/api-models"
import { UserProfileId } from "../../../api/branded-types"
import { videoSelectorClient } from "../../../api/clients/video-api-client"
import { QueryKeys } from "../../../api/query-keys"

const PAGINATION_AMOUNT = 25

export const useProfileVideoContent = (profileId?: UserProfileId) => {
    const filter = `{$match: {mimeType: !"" and !"unknown/unknown", creatorId: in ["${profileId}"]}, $sort:{createdAt: desc}}`
    const queryClient = useQueryClient()
    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            getProfileVideoContent({ pageParam, queryClient, filter }),
        queryKey: QueryKeys.profileVideoContent(profileId),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!profileId,
    })
}

export const getProfileVideoContent = async ({
    pageParam,
    filter,
    queryClient,
}: {
    pageParam: number
    filter: string
    queryClient: QueryClient
}) => {
    const videos = await videoSelectorClient.listVideos({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    videos.data.forEach(data =>
        queryClient.setQueryData<{ data: Video; error: undefined }>(
            QueryKeys.video(data.id),
            { data, error: undefined },
        ),
    )

    return videos
}
