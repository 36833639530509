import {
    QueryClient,
    queryOptions,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Asset, ResourceRef, ResourceUrl } from "api/api-models"
import { mapToMatchFilterDc } from "api/api-utils"
import { Url } from "api/branded-types"
import { assetSelectorClient } from "api/clients/asset-api-client"
import { getAssetById, getAssetData } from "utils/asset"
import { QueryKeys } from "../api/query-keys"

type UseAssetsProps = {
    pageNumber: number
    resources: ResourceRef[]
}
//TODO: probably not great utilizing cache in this way, try to rewrite in other way
export const getAssetsQueryFn = async ({
    resourceRefs,
    queryClient,
}: {
    resourceRefs: ResourceRef[]
    queryClient: QueryClient
}) => {
    const assetIds = resourceRefs.map(ref => ref.resourceId)

    const filter = `{${mapToMatchFilterDc("id", assetIds)}}`
    const assetResponse = await assetSelectorClient.listAssets({ filter })

    assetResponse.data.forEach(asset =>
        queryClient.setQueryData<Asset>(QueryKeys.asset(asset.id), asset),
    )

    const assets = resourceRefs
        .map(id => {
            const cachedData = queryClient.getQueryData<Asset>(
                QueryKeys.asset(id),
            )
            return (
                cachedData ||
                assetResponse.data.find(
                    item => item.id.resourceId === id.resourceId,
                )
            )
        })
        .filter(Boolean) as Asset[]

    return assets
}

export const assetsQueryOptions = (
    props: UseAssetsProps & { queryClient: QueryClient },
) =>
    queryOptions({
        queryKey: QueryKeys.assets(props.pageNumber, props.resources),
        queryFn: () =>
            getAssetsQueryFn({
                resourceRefs: props.resources,
                queryClient: props.queryClient,
            }),
        enabled: props.resources.length > 0,
    })

export const useAssets = (props: UseAssetsProps) => {
    const queryClient = useQueryClient()
    return useQuery(
        assetsQueryOptions({
            resources: props.resources,
            pageNumber: props.pageNumber,
            queryClient,
        }),
    )
}

export const getAssetQueryFn = async (assetUrl?: ResourceUrl) => {
    if (!assetUrl) throw new Error("assetUrl must be defined")
    const { data: asset } = await getAssetById(assetUrl)
    return asset
}

type UseAssetProps = {
    resource?: ResourceRef
    enabled?: boolean
}

export const assetQueryOptions = (props: UseAssetProps) => {
    const assetUrl = props.resource
        ? (`${props.resource.resourceHost}${props.resource.resourceId}` as const)
        : undefined
    return queryOptions({
        queryFn: () => getAssetQueryFn(assetUrl),
        queryKey: QueryKeys.asset(props.resource),
        enabled: !!props.resource && (props.enabled ?? true),
    })
}

export const useAsset = (props: UseAssetProps) => {
    return useQuery(assetQueryOptions(props))
}

export const useAssetData = (baseUrl?: Url, src?: Url) => {
    return useQuery({
        queryKey: QueryKeys.assetData(src),
        queryFn: async () => getAssetData(src),
        staleTime: 5 * 60 * 1000,
        gcTime: 30 * 60 * 1000,
        retry: 2,
        refetchOnWindowFocus: false,
        enabled: !!baseUrl && !!src,
    })
}
