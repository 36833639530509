import {
    IconDefinition,
    faImage,
    faPlay,
    faSignalStream,
    faText,
    faWaveformLines,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"
import { FC } from "react"
import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./create-content-overview-modal.css"

export type CreateContentOverviewModalModel = {
    text: {
        title: string
        labels: {
            goLive: string
            video: string
            text: string
            image: string
            audio: string
        }
    }
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const CreateContentOverviewModal: FC<
    CreateContentOverviewModalModel
> = ({ text, open, onOpenChange }) => {
    const { accountType, ensureUserPreventDefault } = useOnlyRegisteredUser()
    const disableNav = accountType === "Trial" || accountType === "Guest"

    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent className={styles.modal}>
                <DrawerTitle>{text.title}</DrawerTitle>
                <DrawerDescription />

                <div
                    className={styles.wrapper}
                    onClick={ensureUserPreventDefault()}
                >
                    <Link disabled={disableNav} to="/app/live-event/creator">
                        <ActionTile
                            title={text.labels.goLive}
                            dataTestKey="action-go-live"
                            icon={faSignalStream}
                            onClicked={() => {
                                onOpenChange(false)
                            }}
                        />
                    </Link>

                    <Link disabled={disableNav} to="/app/video-upload">
                        <ActionTile
                            title={text.labels.video}
                            dataTestKey="action-post-video"
                            icon={faPlay}
                            onClicked={() => {
                                onOpenChange(false)
                            }}
                        />
                    </Link>

                    <Link disabled={disableNav} to="/app/text-upload">
                        <ActionTile
                            title={text.labels.text}
                            dataTestKey="action-post-text"
                            icon={faText}
                            onClicked={() => {
                                onOpenChange(false)
                            }}
                        />
                    </Link>

                    <Link disabled={disableNav} to="/app/image-upload">
                        <ActionTile
                            title={text.labels.image}
                            dataTestKey="action-post-image"
                            icon={faImage}
                            onClicked={() => {
                                onOpenChange(false)
                            }}
                        />
                    </Link>
                    <Link disabled to=".">
                        <ActionTile
                            title={text.labels.audio}
                            dataTestKey="action-post-audio"
                            icon={faWaveformLines}
                            onClicked={() => {
                                onOpenChange(false)
                            }}
                        />
                    </Link>
                </div>
            </DrawerContent>
        </Drawer>
    )
}

type ActionTileModel = {
    title: string
    icon: IconDefinition
    dataTestKey?: string
    onClicked?: () => void
}

const ActionTile: FC<ActionTileModel> = ({
    title,
    icon,
    dataTestKey,
    onClicked,
}) => (
    <div
        className={styles.actionWrapper}
        data-test={dataTestKey}
        onClick={onClicked}
    >
        <div className={styles.actionTileImage}>
            <FontAwesomeIcon
                className={styles.icon}
                icon={icon}
                color="black"
            />
        </div>
        <div className={styles.actionTileTitle}>
            <p style={{ fontSize: vars.font.size.m, margin: 0 }}>{title}</p>
        </div>
    </div>
)
