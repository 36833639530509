import { useQuery } from "@tanstack/react-query"
import { PaymentId } from "api/branded-types"
import { paymentSelectorClient } from "api/clients/payment-api-client"
import { QueryKeys } from "api/query-keys"

const getPaymentById = async (id?: PaymentId) => {
    if (!id) throw new Error("Payment id is not defined")
    const { data } = await paymentSelectorClient.getPaymentById({
        params: { id },
    })
    return data
}

export const usePaymentById = (id?: PaymentId) => {
    return useQuery({
        queryKey: QueryKeys.payment(id),
        queryFn: () => getPaymentById(id),
        enabled: !!id,
    })
}
