import { create } from "zustand"

type State = {
    modalOpen: boolean
}

type Actions = {
    onModalOpenChange: (v: State["modalOpen"]) => void
    reset: () => void
}

const initialState: State = {
    modalOpen: false,
}

export const useManageSubscriptionStore = create<State & Actions>(set => ({
    ...initialState,

    onModalOpenChange: v => set({ modalOpen: v }),

    reset: () => set(initialState),
}))
