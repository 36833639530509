import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { Content } from "common/content"
import { Page } from "common/page"
import { useTranslation } from "react-i18next"
import * as styles from "./root-boundary.css"

export const NotFoundPage = () => {
    const { t } = useTranslation("notFound")
    return (
        <Page>
            <Content className={styles.content}>
                <div>
                    <h2>{t("title")}</h2>
                </div>

                <Button full variant="primary" asChild>
                    <Link to="/app/feed">{t("actions.goBack")}</Link>
                </Button>
            </Content>
        </Page>
    )
}
