import {
    faArrowRotateRight,
    faImageSlash,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { QueryObserverResult } from "@tanstack/react-query"
import { ImageAsset } from "api/api-models"
import { Url } from "api/branded-types"
import classNames from "classnames"
import { ComponentPropsWithoutRef, FC, useEffect, useState } from "react"
import { Blurhash } from "react-blurhash"
import { useTranslation } from "react-i18next"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { vars } from "theme/variables.css"
import { useAssetData } from "../hooks/use-asset"
import * as styles from "./image.css"

/**
 * @param src - The url for asset data (blob)
 */
export const Image: FC<
    ComponentPropsWithoutRef<"img"> & {
        src?: Url
        metadata?: {
            status: QueryObserverResult["status"]
            data?: ImageAsset
            refresh?: () => void
        }
        color?: string
    }
> = ({ src, color, className, metadata, ...props }) => {
    const { t } = useTranslation("common")

    const imageUrl = props.width
        ? `${src}?width=${props.width}`
        : props.height
          ? `${src}?height=${props.height}`
          : src

    const imageType = metadata?.data?.imageType || "Jpg"

    const assetDataQuery = useAssetData(src, imageUrl as Url)
    const [imageSrc, setImageSrc] = useState<string>("")
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (!imageType) return
        let objectUrl: string | null = null

        if (assetDataQuery.isSuccess && assetDataQuery.data) {
            const blob = assetDataQuery.data // URL returned by useAssetData

            if (imageType === "Jpg" || imageType === "Png") {
                const objectUrl = URL.createObjectURL(blob)
                setImageSrc(objectUrl)
            }
        } else {
            //todo: add fallback url
            setImageSrc("")
        }

        return () => {
            if (objectUrl) URL.revokeObjectURL(objectUrl)
        }
    }, [
        assetDataQuery.isSuccess,
        assetDataQuery.data,
        imageType,
        props.width,
        props.height,
    ])

    return (
        <div
            className={styles.container}
            style={{ width: props.width, height: props.height }}
        >
            <LazyLoadImage
                src={imageSrc}
                onLoad={() => setIsLoaded(true)}
                className={classNames(
                    isLoaded ? styles.imageVisible : styles.imageInvisible,
                    className,
                )}
                {...props}
            />
            {metadata?.data?.previewHash && (
                <Blurhash
                    className={classNames(
                        styles.blur,
                        isLoaded && styles.invisible,
                    )}
                    hash={metadata.data.previewHash}
                    width={props.width}
                    height={props.height}
                />
            )}
            {metadata?.status === "error" && (
                <div
                    className={styles.errorFallback}
                    style={{ aspectRatio: 1.2 }}
                >
                    <FontAwesomeIcon
                        className={styles.fallbackIcon}
                        icon={faImageSlash}
                    />
                    {metadata.refresh && (
                        <div
                            onClick={e => {
                                e.preventDefault()
                                metadata.refresh && metadata.refresh()
                                assetDataQuery.refetch()
                            }}
                            className={styles.refreshFallbackSection}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRotateRight}
                                className={styles.refreshIcon}
                            />
                            <p
                                style={{
                                    fontWeight: 600,
                                    fontSize: vars.font.size.xs,
                                }}
                            >
                                {t("image.error.reload")}
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
