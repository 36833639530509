import { useState } from "react"

export const useToggle = (initialState: boolean = false) => {
    const [open, setOpen] = useState(initialState)

    const toggleOpen = () => setOpen(true)
    const toggleClose = () => setOpen(false)
    const toggle = () => setOpen(prev => !prev)

    return {
        setOpen,
        open: open,
        toggleOpen,
        toggleClose,
        toggle,
    }
}
