import classNames from "classnames"
import { Drawer as DrawerPrimitive } from "vaul"

import {
    ComponentProps,
    ComponentPropsWithoutRef,
    ElementRef,
    forwardRef,
    HTMLAttributes,
} from "react"
import * as styles from "./drawer.css"

export const Drawer = ({
    shouldScaleBackground = true,
    ...props
}: ComponentProps<typeof DrawerPrimitive.Root>) => (
    <DrawerPrimitive.Root
        repositionInputs={false}
        shouldScaleBackground={shouldScaleBackground}
        {...props}
    />
)

export const DrawerTrigger = DrawerPrimitive.Trigger
export const DrawerPortal = DrawerPrimitive.Portal
export const DrawerClose = DrawerPrimitive.Close

export const DrawerOverlay = forwardRef<
    ElementRef<typeof DrawerPrimitive.Overlay>,
    ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Overlay
        ref={ref}
        className={classNames(styles.overlay, className)}
        {...props}
    />
))

export const DrawerContent = forwardRef<
    ElementRef<typeof DrawerPrimitive.Content>,
    ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> & {
        handle?: boolean
    }
>(({ className, children, handle = true, ...props }, ref) => (
    <DrawerPortal>
        <DrawerOverlay />
        <DrawerPrimitive.Content
            ref={ref}
            className={classNames(styles.content, className)}
            {...props}
        >
            {handle && <div className={styles.handle} />}
            {children}
        </DrawerPrimitive.Content>
    </DrawerPortal>
))

export const DrawerHeader = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <div className={classNames(styles.header, className)} {...props} />
)

export const DrawerFooter = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <div className={classNames(styles.footer, className)} {...props} />
)

export const DrawerTitle = forwardRef<
    ElementRef<typeof DrawerPrimitive.Title>,
    ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Title
        ref={ref}
        className={classNames(styles.title, className)}
        {...props}
    />
))

export const DrawerDescription = forwardRef<
    ElementRef<typeof DrawerPrimitive.Description>,
    ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DrawerPrimitive.Description
        ref={ref}
        className={classNames(styles.description, className)}
        {...props}
    />
))
