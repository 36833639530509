/* eslint-disable no-useless-escape */

import {
    GetNextPageParamFunction,
    InfiniteData,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Postit } from "api/api-models"
import { mapToSortFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { postitSelectorClient } from "../api/clients/post-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 25

export type UseImagePostSearchModel = {
    searchKey: string
    profileId?: UserProfileId
    creatorIds: UserProfileId[]
    enabled?: boolean
}

const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
const typeFilter = `type: "Image"`

export const searchImagePostsDefaultFilter = `{$match:{${typeFilter}}, ${sortFilter}}`

export const getSearchImagePosts = async ({
    pageParam,
    filter,
    queryClient,
}: {
    pageParam: unknown
    filter: string
    queryClient: QueryClient
}) => {
    const posts = await postitSelectorClient.listPostits({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam as number,
        },
    })

    posts.data.forEach(data =>
        queryClient.setQueryData<{ data: Postit; error: undefined }>(
            QueryKeys.postit(data.id),
            { data, error: undefined },
        ),
    )

    return posts
}

type Data = Awaited<ReturnType<typeof getSearchImagePosts>>

export type SearchImagePostsData = Data
export type InfiniteSearchImagePostsData = InfiniteData<Data>

export const useImagePostsSearch = (model: UseImagePostSearchModel) => {
    const queryKey = QueryKeys.searchImagePosts(model)
    const queryClient = useQueryClient()

    const titleFilter = `title: *"${model.searchKey}"*:ci`
    const messageFilter = `message: *"${model.searchKey}"*:ci`
    const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
    const typeFilter = `type: "Image"`
    const removeMyProfileFilter =
        !model.profileId || model.searchKey.length > 0
            ? ""
            : `, creatorId: !"${model.profileId}"`
    //TODO: this is not 100% precise, but would work out most of the time
    //TODO: otherwise we need specific API endpoint for this
    const profilesFilter =
        model.searchKey.length === 0
            ? ""
            : `, {creatorId: in ${JSON.stringify(model.creatorIds)}}`

    const filter = `{$match:{$or: [{${titleFilter}}, {${messageFilter}}${profilesFilter}]${removeMyProfileFilter}, ${typeFilter}}, ${sortFilter}}`

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) =>
            getSearchImagePosts({ pageParam, filter, queryClient }),
        getNextPageParam: getSearchImagePostsNextPageParam,
        enabled: model.enabled,
    })
}

export const getSearchImagePostsNextPageParam: GetNextPageParamFunction<
    number,
    Data
> = lastPage => {
    if (lastPage.data.length !== PAGINATION_AMOUNT) {
        return undefined
    }

    return lastPage.paging.type === "Index"
        ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
        : -1
}
