import {
    keepPreviousData,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query"
import { StrictMode } from "react"
import ReactDOM from "react-dom/client"

import "css/reset.css"
import { IntlProvider } from "react-intl"
import "react-loading-skeleton/dist/skeleton.css"
import "swiper/css"
import "swiper/css/pagination"
import "theme/global.css"
import "theme/variables.css"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

import { createRouter, RouterProvider } from "@tanstack/react-router"
import * as styles from "./index.css"

// Import the generated route tree
import * as Sentry from "@sentry/react"
import { oidcConfig } from "api/api-utils"
import { QueryKeys, StaticQueryKeys } from "api/query-keys"
import { typography } from "app.css"
import { AxiosResponse } from "axios"
import classNames from "classnames"
import { Button } from "common/button"
import debug from "debug"
import { DEBUG } from "envs"
import i18n from "features/locales/localization"
import {
    PaymentMessagingStoreMessage,
    usePaymentMessagingStore,
} from "features/payment/store/payment-messaging-store"
import { useNewVersionCheck } from "hooks/use-new-version-check"
import { I18nextProvider, useTranslation } from "react-i18next"
import { AuthProvider, useAuth } from "react-oidc-context"
import { stepTheme } from "theme/step.css"
import { routeTree } from "./routeTree.gen"

if (DEBUG) debug.enable("*")

export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

Sentry.init({
    dsn: "https://4580ea8a4aec03de12af34c2f7a09b12@o4508297423945728.ingest.de.sentry.io/4508297885646928",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            placeholderData: keepPreviousData,
            staleTime: 5 * MINUTE,
            gcTime: 5 * MINUTE,
            retry: (failureCount, res) => {
                const axiosResponse = res as unknown as AxiosResponse
                if (axiosResponse.status === 404) return false
                return failureCount < 3
            },
        },
    },
})

const paymentMessagingStore = usePaymentMessagingStore.getState()

//TODO: move elsewhere
window.addEventListener("message", (event: MessageEvent) => {
    //! SECURITY: Check the origin of the message.
    if (event.origin !== window.location.origin) {
        console.warn("Received message from unauthorized origin:", event.origin)
        return
    }

    if (!event.data) return
    const parsedEvent = PaymentMessagingStoreMessage.safeParse(event.data)
    if (parsedEvent.error) return

    if (parsedEvent.data.type === "VIDEO_PAYMENT_STATUS") {
        //TODO: move this possibly into notifications hub
        queryClient.invalidateQueries({
            queryKey: QueryKeys.payments(),
            refetchType: "all",
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.myPayments(),
            refetchType: "all",
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.video(parsedEvent.data.videoId),
            refetchType: "all",
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.payableVideo(parsedEvent.data.videoId),
            refetchType: "all",
        })
        paymentMessagingStore.setVideoPaymentStatus(parsedEvent.data.status)
    } else if (parsedEvent.data.type === "POSTIT_PAYMENT_STATUS") {
        //TODO: move this possibly into notifications hub
        queryClient.invalidateQueries({
            queryKey: QueryKeys.payments(),
            refetchType: "all",
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.myPayments(),
            refetchType: "all",
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.postit(parsedEvent.data.postitId),
            refetchType: "all",
        })
        queryClient.invalidateQueries({
            queryKey: QueryKeys.payablePostit(parsedEvent.data.postitId),
            refetchType: "all",
        })
        paymentMessagingStore.setPostitPaymentStatus(parsedEvent.data.status)
    } else if (
        parsedEvent.data.type === "PAYMENT_PROVIDER_CONNECTIVITY_STATUS"
    ) {
        paymentMessagingStore.setProviderConnectivityStatus(
            parsedEvent.data.status,
        )
    } else if (parsedEvent.data.type === "USER_SUBSCRIPTION_STATUS") {
        queryClient.invalidateQueries({
            queryKey: QueryKeys.hasProfileSubscription(
                parsedEvent.data.profileId,
            ),
        })
        queryClient.invalidateQueries({
            queryKey: [StaticQueryKeys.PAYABLE_VIDEO],
            type: "all",
        })
        queryClient.invalidateQueries({
            queryKey: [StaticQueryKeys.PAYABLE_POSTIT],
            type: "all",
        })
    } else if (
        parsedEvent.data.type === "USER_SUBSCRIPTION_FROM_VIDEO_STATUS"
    ) {
        queryClient.invalidateQueries({
            queryKey: QueryKeys.hasProfileSubscription(
                parsedEvent.data.profileId,
            ),
        })
        queryClient.invalidateQueries({
            queryKey: [StaticQueryKeys.PAYABLE_VIDEO],
            type: "all",
        })
    } else if (
        parsedEvent.data.type === "USER_SUBSCRIPTION_FROM_POSTIT_STATUS"
    ) {
        queryClient.invalidateQueries({
            queryKey: QueryKeys.hasProfileSubscription(
                parsedEvent.data.profileId,
            ),
        })
        queryClient.invalidateQueries({
            queryKey: [StaticQueryKeys.PAYABLE_POSTIT],
            type: "all",
        })
    }
})

const router = createRouter({
    routeTree,
    context: {
        auth: undefined!,
        queryClient,
    },
    //TODO: consider using viewport, or do a viewport only for specific routes. needs discussion
    defaultPreload: "intent",
    defaultPreloadStaleTime: 0,
    defaultPendingMs: 0,
    defaultPendingMinMs: 0,
    notFoundMode: "root",
    scrollRestoration: true,
    getScrollRestorationKey: ({ pathname }) => pathname,
})

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router
    }
}

const rootElement = document.getElementById("root")!
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement)

    root.render(
        <StrictMode>
            <I18nextProvider i18n={i18n}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider {...oidcConfig}>
                        <InnerApp />
                    </AuthProvider>
                </QueryClientProvider>
            </I18nextProvider>
        </StrictMode>,
    )
}

function InnerApp() {
    const auth = useAuth()
    const { i18n: i18nextInstance } = useTranslation()
    const currentLocale = i18nextInstance.language || "en"

    const { newVersion, setNewVersion } = useNewVersionCheck()
    const { t } = useTranslation(["common"])

    return (
        <IntlProvider locale={currentLocale}>
            <div className={classNames(stepTheme, typography)}>
                {newVersion && (
                    <div className={styles.notificationContainer}>
                        <h3 style={{ color: "#262142" }}>
                            {t("newAppVersion.available")}
                        </h3>

                        <Button
                            full
                            variant="light"
                            onClick={async () => {
                                await clearCaches()
                                await router.invalidate()

                                const url = new URL(window.location.href)
                                url.searchParams.set("t", Date.now().toString())

                                window.location.replace(url)
                            }}
                        >
                            {t("newAppVersion.action")}
                        </Button>

                        <Button
                            full
                            variant="ghost"
                            onClick={() => setNewVersion(false)}
                        >
                            {t("newAppVersion.skip")}
                        </Button>
                    </div>
                )}
                <RouterProvider router={router} context={{ auth }} />
            </div>
        </IntlProvider>
    )
}

const clearCaches = async () => {
    if ("caches" in window) {
        const cacheNames = await caches.keys()
        await Promise.all(cacheNames.map(name => caches.delete(name)))
    }

    if ("serviceWorker" in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations()
        await Promise.all(registrations.map(reg => reg.unregister()))
    }
}
