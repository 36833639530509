import { TitleMessageModal } from "features/user-profile/modal/title-message-modal"
import { FC } from "react"
import {
    ProceedWithPaymentModal,
    ProceedWithPaymentModalModel,
} from "../proceed-with-payment-modal"
import {
    ProceedWithSubscriptionModal,
    ProceedWithSubscriptionModalModel,
} from "../proceed-with-subscription-modal"
import { SuccessMessageModal } from "./success-message-modal"
import { WalletPaymentDetailsModal } from "./wallet-payment-details-modal"

export type PaymentModalProviderProps = {
    loc: {
        success: {
            title: string
            message: string
            confirm: string
        }
        failure: {
            title: string
            message: string
            confirm: string
        }
        proceedPayment: ProceedWithPaymentModalModel["loc"]
        proceedSubscription: ProceedWithSubscriptionModalModel["loc"]
    }
}

export const PaymentModalProvider: FC<PaymentModalProviderProps> = ({
    loc,
}) => {
    return (
        <>
            <TitleMessageModal
                title={loc.failure.title}
                message={loc.failure.message}
                action={loc.failure.confirm}
            />

            <SuccessMessageModal
                title={loc.success.title}
                message={loc.success.message}
                action={loc.success.confirm}
            />
            <WalletPaymentDetailsModal />
            <ProceedWithPaymentModal loc={loc.proceedPayment} />
            <ProceedWithSubscriptionModal loc={loc.proceedSubscription} />
        </>
    )
}
