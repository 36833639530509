import { Link, NavigateOptions } from "@tanstack/react-router"
import { Button } from "common/button"
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "common/dialog"
import { FC } from "react"

import { vars } from "theme/variables.css"
import * as styles from "./content-blocked-modal.css"

export type ContentBlockedModalModel = {
    open: boolean
    onOpenChange: (v: boolean) => void
    actionNavigateOptions: NavigateOptions
    reasons: string[]
    loc: {
        title: string
        descriptionFirst: string
        descriptionSecond: string
        descriptionThird: string
        reasons: string
        action: string
        policyAction: string
    }
}

export const ContentBlockedModal: FC<ContentBlockedModalModel> = ({
    loc,
    actionNavigateOptions,
    reasons,
    open,
    onOpenChange,
}) => {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={styles.content}>
                <DialogHeader className={styles.header}>
                    <DialogTitle className={styles.title}>
                        {loc.title}
                    </DialogTitle>
                    <DialogDescription className={styles.description}>
                        {loc.descriptionFirst}{" "}
                        <Link
                            className={styles.link}
                            to="/registration/terms-and-condition"
                        >
                            {loc.descriptionSecond}
                        </Link>{" "}
                        {loc.descriptionThird}
                    </DialogDescription>
                </DialogHeader>
                {reasons.length > 0 && (
                    <div className={styles.reasonsContainer}>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                fontWeight: 700,
                                color: vars.color.medium.hex,
                            }}
                        >
                            {loc.reasons}
                        </p>
                        <ul className={styles.reasonList}>
                            {reasons.map((reason, idx) => (
                                <li
                                    className={styles.reasonListItem}
                                    key={idx + reason}
                                >
                                    {reason}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <DialogFooter className={styles.footer}>
                    <DialogClose asChild>
                        <Button asChild>
                            <Link {...actionNavigateOptions}>{loc.action}</Link>
                        </Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button
                            className={styles.ghostButton}
                            variant="ghost"
                            asChild
                        >
                            <Link to="/registration/terms-and-condition">
                                {loc.policyAction}
                            </Link>
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
