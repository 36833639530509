import { createFileRoute } from "@tanstack/react-router"
import { notValidRouteBeforeLoad } from "utils/router"

export const Route = createFileRoute("/_authenticated/app/live-event/summary/")(
    {
        component: RouteComponent,
        beforeLoad: notValidRouteBeforeLoad,
    },
)

function RouteComponent() {
    return <></>
}
