import { makeApi } from "@zodios/core"
import { z } from "zod"
import { ApiError, CreateUserReport, UserReport } from "../api-models"

export const reportCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/report",
        description: "Create user report",
        alias: "reportUser",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: CreateUserReport,
            },
        ],
        response: z.object({
            data: UserReport,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
