import classNames from "classnames"
import { FC } from "react"

import { Button } from "common/button"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { usePaymentModalStore } from "features/payment/store/payment-modal-store"
import * as styles from "./title-message-modal.css"

export type TitleMessageModalModel = {
    className?: string
    presentingElement?: HTMLElement | undefined

    title: string
    message: string
    action: string
}
export const TitleMessageModal: FC<TitleMessageModalModel> = ({
    className,
    title,
    message,
    action,
}) => {
    const open = usePaymentModalStore(store => store.failedPaymentModalOpen)
    const onOpenChange = usePaymentModalStore(
        store => store.setFailedPaymentModalOpen,
    )
    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent className={classNames(styles.modal, className)}>
                <div className={styles.content}>
                    <div className={styles.wrapper}>
                        <div style={{ margin: "auto" }}>
                            <DrawerTitle className={styles.title}>
                                {title}
                            </DrawerTitle>
                        </div>

                        <div style={{ margin: "auto" }}>
                            <DrawerDescription className={styles.description}>
                                {message}
                            </DrawerDescription>
                        </div>
                    </div>

                    <DrawerClose asChild>
                        <Button full className={styles.closeButton}>
                            {action}
                        </Button>
                    </DrawerClose>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
