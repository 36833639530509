import { faEye } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { Link } from "@tanstack/react-router"
import { LiveEventId } from "api/branded-types"
import { Badge } from "common/badge"
import { useListLiveEvent } from "hooks/use-list-live-event"
import { useLiveEventStatistics } from "hooks/use-live-event-statistics"
import { vars } from "theme/variables.css"
import { UserDetailsBasic } from "../user/user-details-basic"
import * as styles from "./live-now-area-tile.css"

export type LiveNowAreaTileModel = {
    text: { liveState: string }
    liveEventId: LiveEventId
}

export const LiveNowAreaTile: FC<LiveNowAreaTileModel> = ({
    text,
    liveEventId,
}) => {
    const liveEventQuery = useListLiveEvent(liveEventId)
    const liveEvent = liveEventQuery.data?.data

    const liveStatisticQuery = useLiveEventStatistics(liveEventId, 5000)
    return (
        <div className={styles.relative}>
            <Link to="/app/live-event/viewer/$id" params={{ id: liveEventId }}>
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <Badge variant="live" className={styles.liveBadge}>
                            {text.liveState}
                        </Badge>

                        {liveStatisticQuery.isSuccess && (
                            <Badge className={styles.statBadge}>
                                <FontAwesomeIcon
                                    className={styles.viewerCountIcon}
                                    icon={faEye}
                                    color="white"
                                />
                                {liveStatisticQuery.data.viewCount.current}
                            </Badge>
                        )}
                    </div>
                    <div className={styles.content}>
                        {liveEventQuery.isSuccess && liveEvent && (
                            <p
                                style={{
                                    fontSize: vars.font.size.m,
                                    color: "white",
                                }}
                            >
                                {liveEvent.title}
                            </p>
                        )}
                    </div>
                </div>
            </Link>
            <div className={styles.footer}>
                <UserDetailsBasic
                    contentLastChangedAt={liveEvent?.lastChangedAt}
                    profileId={liveEvent?.creatorId}
                    avatarSize={36}
                />
            </div>
        </div>
    )
}
