/* eslint-disable functional/no-throw-statements */
/* eslint-disable no-useless-escape */

import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query"

import {
    Pagination,
    UserProfile,
    VotedResourceRef,
} from "../../../api/api-models"
import { votingSelectorClient } from "../../../api/clients/voting-api-client"
import { QueryKeys } from "../../../api/query-keys"

const PAGINATION_AMOUNT = 20

export type InfiniteVideoVotesData = InfiniteData<{
    paging: Pagination
    total: number
    profiles: UserProfile[]
}>

export const useResourceVotes = (resource?: VotedResourceRef) => {
    const queryKey = QueryKeys.resourceVotes(resource?.contentId)

    const queryFn = async ({ pageParam }: { pageParam: number }) => {
        if (!resource?.contentId) throw new Error("VoteContentId is required")

        const votesRes =
            await votingSelectorClient.listVotesOfTypeByVotedResource(
                {
                    paging: {
                        type: "Index",
                        direction: "After",
                        limit: PAGINATION_AMOUNT,
                        index: pageParam,
                    },
                },
                {
                    params: {
                        id: resource.contentId,
                        ref: resource.contentType,
                        type: "Like",
                    },
                },
            )
        return votesRes
    }

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn,
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!resource,
    })
}
