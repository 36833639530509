import * as ToastPrimitives from "@radix-ui/react-toast"
import classNames from "classnames"

import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"
import { Color, vars } from "theme/variables.css"
import * as styles from "./toast.css"

export const ToastProvider = ToastPrimitives.Provider

export const NotificationToastViewport = forwardRef<
    ElementRef<typeof ToastPrimitives.Viewport>,
    ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Viewport
        ref={ref}
        className={classNames(styles.notificationToastViewport, className)}
        {...props}
    />
))

export const InfoToastViewport = forwardRef<
    ElementRef<typeof ToastPrimitives.Viewport>,
    ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Viewport
        ref={ref}
        className={classNames(styles.infoToastViewport, className)}
        {...props}
    />
))

export const NotificationToast = forwardRef<
    ElementRef<typeof ToastPrimitives.Root>,
    ComponentPropsWithoutRef<typeof ToastPrimitives.Root>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Root
        ref={ref}
        className={classNames(styles.notificationToastStyles, className)}
        {...props}
    />
))

export type NotificationToastProps = ComponentPropsWithoutRef<
    typeof NotificationToast
>

export const InfoToast = forwardRef<
    ElementRef<typeof ToastPrimitives.Root>,
    ComponentPropsWithoutRef<typeof ToastPrimitives.Root>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Root
        ref={ref}
        className={classNames(styles.infoToastStyles, className)}
        {...props}
    />
))

export type InfoToastProps = ComponentPropsWithoutRef<typeof InfoToast>

export type InfoToastContentProps = JSX.IntrinsicElements["div"] & {
    icon: IconDefinition
    iconColor: Color
    iconClassName?: string
    textClassName?: string
    text?: string
}

export const InfoToastContent = forwardRef<
    ElementRef<"div">,
    InfoToastContentProps
>(
    (
        {
            className,
            icon,
            iconColor,
            iconClassName,
            textClassName,
            text,
            ...props
        },
        ref,
    ) => (
        <div
            ref={ref}
            className={classNames(styles.infoToastContent, className)}
            {...props}
        >
            <FontAwesomeIcon
                icon={icon}
                className={classNames(styles.icon, iconClassName)}
                style={{ color: vars.color[iconColor].hex }}
            />
            <p className={classNames(styles.infoToastText, textClassName)}>
                {text}
            </p>
        </div>
    ),
)
