import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo, useState } from "react"
import * as styles from "./tab-bar.css"

import {
    faHouseBlank as houseLight,
    faMessages as messageLight,
    faSearch as searchLight,
} from "@fortawesome/pro-light-svg-icons"

import {
    faPlus,
    faHouseBlank as house,
    faMessages as message,
    faSearch as search,
} from "@fortawesome/pro-regular-svg-icons"
import { Link, Outlet } from "@tanstack/react-router"
import classNames from "classnames"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"
import { useTranslation } from "react-i18next"
import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import { getIsAuthorizedAccount } from "../../api/api-utils"
import { useMyMessageGroups } from "../../features/messaging/hooks/use-my-message-groups"
import { useUnreadNotifications } from "../../features/notifications/hooks/use-unread-notifications"
import { MyProfileAvatar } from "../../features/user-profile/my-profile-avatar"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { CreateContentMenu } from "../buttons/create-content-menu"
import { NewFeatureComingSoonModal } from "../modals/new-feature-coming-soon-modal"

export const TabBar: FC = () => {
    const openModal = useAppStore(store => store.openModal)
    const closeModal = useAppStore(store => store.closeModal)
    const isModalVisible = useAppStore(store => store.isModalVisible)
    const scrollFeedToTop = useAppStore(state => state.scrollFeedToTop)
    const scrollSearchToTop = useAppStore(state => state.scrollSearchToTop)
    const { accountType, ensureUserPreventDefault } = useOnlyRegisteredUser()

    const { t } = useTranslation(["common"])

    const [newFeatureModalOpen, setNewFeatureModalOpen] = useState(false)

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const newNotificationsQuery = useUnreadNotifications(profileId)

    const myMessagesQuery = useMyMessageGroups({
        me: profileId,
    })

    const newMessageIds = pipe(
        myMessagesQuery.data?.pages ?? [],
        A.chain(page => page.data),
        A.chain(x => x.newMessageIds),
    )

    const isHaveNewNotifications =
        (newNotificationsQuery.data?.totalCount ?? 0) > 0

    return (
        <>
            <CreateContentMenu
                text={{
                    title: "Create content",
                    labels: {
                        goLive: "go live",
                        video: "video",
                        audio: "audio",
                        image: "image",
                        text: "text",
                    },
                }}
                open={isModalVisible.CreateContentMenu.visible}
                onOpenChange={open =>
                    open
                        ? openModal({ type: "CreateContentMenu" })
                        : closeModal("CreateContentMenu")
                }
            />
            <NewFeatureComingSoonModal
                open={newFeatureModalOpen}
                onOpenChange={setNewFeatureModalOpen}
                text={{
                    title: t("newFeatureComingSoon.title"),
                    description: t("newFeatureComingSoon.description"),
                }}
            />
            <Outlet />

            <div className={styles.container}>
                <Link className={styles.tabButton} to="/app/feed">
                    {({ isActive }) => (
                        <div
                            className={styles.iconWrapper}
                            onClick={() => {
                                if (isActive) scrollFeedToTop()
                            }}
                        >
                            <FontAwesomeIcon
                                color="white"
                                style={{ height: "26px" }}
                                icon={isActive ? house : houseLight}
                            />
                            {isHaveNewNotifications && (
                                <div className={styles.notificationsBadge} />
                            )}
                        </div>
                    )}
                </Link>

                <Link className={styles.tabButton} to="/app/search">
                    {({ isActive }) => (
                        <FontAwesomeIcon
                            onClick={() => {
                                if (isActive) scrollSearchToTop()
                            }}
                            color="white"
                            style={{ height: "26px" }}
                            icon={isActive ? search : searchLight}
                        />
                    )}
                </Link>

                <div
                    onClick={() => openModal({ type: "CreateContentMenu" })}
                    data-test="action-create-content"
                    style={{
                        background: "#FF944B",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 40,
                        height: 40,
                    }}
                >
                    <FontAwesomeIcon
                        color={vars.color.background.hex}
                        style={{
                            width: 24,
                            height: 24,
                        }}
                        icon={faPlus}
                    />
                </div>

                <Link
                    className={styles.tabButton}
                    to="/app/messages"
                    onClick={ensureUserPreventDefault()}
                >
                    {({ isActive }) =>
                        isActive ? (
                            <FontAwesomeIcon
                                color="white"
                                style={{ height: "26px" }}
                                icon={message}
                            />
                        ) : (
                            <div className={styles.iconWrapper}>
                                <FontAwesomeIcon
                                    color="white"
                                    style={{
                                        height: "26px",
                                        color: vars.color.tertiary.hex,
                                    }}
                                    icon={messageLight}
                                />
                                {newMessageIds.length > 0 && (
                                    <span
                                        className={styles.messageCountInfo}
                                        style={{ fontSize: vars.font.size.xs }}
                                    >
                                        {newMessageIds.length}
                                    </span>
                                )}
                            </div>
                        )
                    }
                </Link>

                <Link
                    className={styles.tabButton}
                    to="/app/my-profile"
                    search={{ tab: "All" }}
                    onClick={ensureUserPreventDefault()}
                >
                    {({ isActive }) => (
                        <MyProfileAvatar
                            className={classNames(
                                styles.borderRounded,
                                isActive
                                    ? styles.activeBorder
                                    : styles.inactiveBorder,
                            )}
                            isAuthorizedAccount={isAuthorizedAccount}
                            avatarSize={30}
                            avatarStyle={{ border: "none" }}
                        />
                    )}
                </Link>
            </div>
        </>
    )
}
