import classNames from "classnames"
import { animate, motion, useMotionValue, useTransform } from "framer-motion"
import { FC, useEffect } from "react"

import { UserProfileId } from "../../../api/branded-types"
import { useProfileStatistics } from "../hooks/use-profile-statistic"

import { Link } from "@tanstack/react-router"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { isDefined } from "utils/object"
import { useProfilePostContent } from "../hooks/use-profile-post-content"
import { useProfileVideoContent } from "../hooks/use-profile-video-content"
import * as styles from "./profile-statistics.css"

type ProfileStatisticsTextModel = {
    posts: string
    following: string
    followers: string
}

export type ProfileStatisticsModel = {
    id?: UserProfileId
    text: ProfileStatisticsTextModel
}

export const ProfileStatistics: FC<ProfileStatisticsModel> = ({ id, text }) => {
    const followerCount = useMotionValue(0)
    const roundedFollowerCount = useTransform(followerCount, Math.round)
    const profileStatistics = useProfileStatistics(id)

    const profileVideoContentQuery = useProfileVideoContent(id)
    const profilePostContentQuery = useProfilePostContent(id)

    const videoTotal = profileVideoContentQuery.data?.pages.at(0)?.totalCount
    const postTotal = profilePostContentQuery.data?.pages.at(0)?.totalCount

    const postsCount =
        isDefined(videoTotal) && isDefined(postTotal)
            ? videoTotal + postTotal
            : 0

    useEffect(
        () => {
            if (!profileStatistics.isSuccess) return
            const animation = animate(
                followerCount,
                profileStatistics.data.count.followers,
                { duration: 0.25, ease: "easeIn", delay: 0.25 },
            )
            return animation.stop
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profileStatistics],
    )

    return (
        <>
            {profileStatistics.isSuccess && (
                <div className={styles.userStats}>
                    <div className={styles.column}>
                        <p
                            className={styles.statsText}
                            style={{ fontSize: vars.font.size.xs }}
                        >
                            {text.posts}
                        </p>
                        <p
                            className={classNames(
                                styles.statsText,
                                styles.statsTextValues,
                            )}
                            style={{ fontSize: vars.font.size.m }}
                        >
                            {postsCount}
                        </p>
                    </div>

                    <Link
                        to={
                            profileStatistics.data.count.followers > 0
                                ? "/app/followers/overview/$id"
                                : "."
                        }
                        params={{ id }}
                        className={styles.column}
                    >
                        <p
                            className={styles.statsText}
                            style={{ fontSize: vars.font.size.xs }}
                        >
                            {text.followers}
                        </p>
                        <motion.p
                            key="follower-count"
                            className={classNames(
                                styles.statsText,
                                styles.statsTextValues,
                            )}
                            style={{ fontSize: vars.font.size.m }}
                        >
                            {roundedFollowerCount}
                        </motion.p>
                    </Link>

                    <Link
                        to={
                            profileStatistics.data.count.following > 0
                                ? "/app/followings/overview/$id"
                                : "."
                        }
                        params={{ id }}
                        className={styles.column}
                    >
                        <p
                            className={styles.statsText}
                            style={{ fontSize: vars.font.size.xs }}
                        >
                            {text.following}
                        </p>
                        <p
                            className={classNames(
                                styles.statsText,
                                styles.statsTextValues,
                            )}
                            style={{ fontSize: vars.font.size.m }}
                        >
                            {profileStatistics.data.count.following}
                        </p>
                    </Link>
                </div>
            )}
            {profileStatistics.isLoading && <ProfileStatisticsLoading />}
        </>
    )
}

export const ProfileStatisticsLoading: FC = () => (
    <div className={styles.userStatsLoading}>
        <Skeleton width="100%" height={54} borderRadius={12} />
    </div>
)
