import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import { vars } from "theme/variables.css"

import { Property } from "csstype"
import { FC } from "react"
import { Route } from "routes/__root"

type Props = {
    icon: IconDefinition
    closeIcon: IconDefinition
    title: string
    description: string
    routeTo: Route
    color: Property.BackgroundColor
    onClose?: () => void
}

export const FeedTeaser: FC<Props> = ({
    closeIcon,
    description,
    icon,
    onClose,
    routeTo,
    color,
    title,
}) => (
    <div
        style={{
            display: "flex",
            padding: "16px 20px",
            backgroundColor: color,
            borderRadius: 12,
            minWidth: "320px",
            gap: 18,
            color: vars.color.medium.hex,
            fontWeight: 600,
            scrollSnapAlign: "start",
        }}
    >
        <FontAwesomeIcon icon={icon} style={{ marginTop: 2, height: 32 }} />

        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                textAlign: "left",
                alignItems: "flex-start",
                gap: 4,
            }}
        >
            <p
                style={{
                    fontSize: vars.font.size.m,
                    lineHeight: vars.lineHeight.title,
                    fontWeight: 600,
                }}
            >
                {title}
            </p>
            <Link
                style={{
                    fontSize: vars.font.size.regular,
                    lineHeight: vars.lineHeight.content,
                    cursor: "pointer",
                    fontWeight: 400,
                }}
                to={routeTo}
            >
                {description}
            </Link>
        </div>

        {onClose && (
            <FontAwesomeIcon
                icon={closeIcon}
                style={{ marginTop: 2, cursor: "pointer" }}
                onClick={onClose}
            />
        )}
    </div>
)
