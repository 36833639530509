import { faLocationPin } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { AvatarLoading } from "../../../../components/avatars/avatar-loading"

import { Link } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import { Button } from "common/button"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./user-search-result-tile.css"

export type UserSearchResultTileTextModel = {
    action: string
}

export type UserSearchResultTileModel = {
    profileId: UserProfileId
    text: UserSearchResultTileTextModel
}

export const UserSearchResultTile: FC<UserSearchResultTileModel> = model => {
    const myProfileIdQuery = useMyUserProfileId()
    const profileQuery = useListUserProfileById(model.profileId)
    const profile = profileQuery.data?.data

    if (!profileQuery.isSuccess || !profile) return
    return (
        <div className={styles.tile}>
            <div className={styles.info}>
                <Link
                    to={
                        myProfileIdQuery.data === model.profileId
                            ? "/app/my-profile"
                            : "/app/user-profile/$id"
                    }
                    params={{ id: model.profileId }}
                >
                    <AvatarWithBadge
                        profileId={model.profileId}
                        size={72}
                        src={profile.imageUrl}
                    />
                </Link>

                <div className={styles.description}>
                    <p
                        className={styles.text}
                        style={{
                            fontSize: vars.font.size.m,
                            color: "white",
                        }}
                    >
                        {profile.displayName ?? profile.profileName}
                    </p>
                    <p
                        className={styles.text}
                        style={{
                            fontSize: vars.font.size.regular,
                            color: vars.color.tertiary.hex,
                        }}
                    >
                        <FontAwesomeIcon icon={faLocationPin} />{" "}
                        {profile.location ?? "not specified"}
                    </p>
                </div>
            </div>

            <Button className={styles.actionButton} variant="primary" asChild>
                <Link
                    to={
                        myProfileIdQuery.data === model.profileId
                            ? "/app/my-profile"
                            : "/app/user-profile/$id"
                    }
                    params={{ id: model.profileId }}
                >
                    {model.text.action}
                </Link>
            </Button>
        </div>
    )
}

export const UserSearchResultLoadingTile: FC = () => (
    <div className={styles.tile}>
        <div className={styles.info}>
            <AvatarLoading baseColor="#3C375C" size={72} />

            <div className={styles.description}>
                <Skeleton width={75} height={18} borderRadius={24} />
                <Skeleton width={50} height={12} borderRadius={24} />
            </div>

            <div className={styles.loadingButton} />
        </div>
    </div>
)
