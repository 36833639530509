import {
    createFileRoute,
    Navigate,
    Outlet,
    redirect,
} from "@tanstack/react-router"
import { Loading } from "common/loading"
import { listFirstUsersInfiniteQueryOptions } from "hooks/use-list-first-users"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import { ProtectedProviders } from "providers/protected-provider-outlet"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { hasAuthParams, useAuth } from "react-oidc-context"
import { parseJwt } from "utils/auth"
import { privacyPolicyUrl, termsAndConditionsUrl } from "utils/constant"
import { z } from "zod"

export const AuthenticatedRouteSearchParams = z.object({
    share_token: z.string().optional(),
    x_redirect: z.string().optional(),
})

export type AuthenticatedRouteSearchParams = z.infer<
    typeof AuthenticatedRouteSearchParams
>

export const Route = createFileRoute("/_authenticated")({
    component: RouteComponent,
    validateSearch: AuthenticatedRouteSearchParams.parse,
    beforeLoad: async ({ context, search }) => {
        const shareToken = search.share_token
        const accessToken = LocalStorage.getAccessToken()

        if (!accessToken && !shareToken) throw redirect({ to: "/intro" })
        else if (!accessToken && shareToken) {
            await context.auth.signinRedirect({
                extraQueryParams: {
                    auth_policy: "shareSignIn",
                    share_token: shareToken,
                    x_redirect: search.x_redirect ?? "",
                    privacyPolicyUrl: privacyPolicyUrl,
                    termsAndConditionsUrl: termsAndConditionsUrl,
                },
            })
        } else if (accessToken) {
            //! FIXME find a way to clarify if refresh/access token is valid by not using users datetime
            // this is a workaround to know if user can still have valid auth
            // when he starts using the application (if we should let him through)
            const nowInSeconds = Math.floor(new Date().getTime() / 1000)
            const accessExpiresAt = LocalStorage.getAccessTokenExpiresAt()
            const accessTokenInvalid =
                !accessExpiresAt || accessExpiresAt < nowInSeconds
            if (accessTokenInvalid) {
                const refreshToken = LocalStorage.getRefreshToken()
                const parsedRefresh = parseJwt(refreshToken)

                const refreshTokenInvalid =
                    !refreshToken ||
                    !parsedRefresh ||
                    parsedRefresh.exp < nowInSeconds

                if (refreshTokenInvalid && shareToken) {
                    await context.auth.signinRedirect({
                        extraQueryParams: {
                            auth_policy: "shareSignIn",
                            share_token: shareToken,
                            x_redirect: search.x_redirect ?? "",
                            privacyPolicyUrl: privacyPolicyUrl,
                            termsAndConditionsUrl: termsAndConditionsUrl,
                        },
                    })
                }
                if (refreshTokenInvalid) {
                    throw redirect({ to: "/intro", replace: true })
                }
            }
        }
    },
    loader: ({ context }) =>
        context.queryClient.ensureInfiniteQueryData({
            ...listFirstUsersInfiniteQueryOptions(
                context.queryClient,
                context.auth.isAuthenticated,
            ),
            pages: 5,
        }),
})

function RouteComponent() {
    const auth = useAuth()
    const meQuery = useMe(auth.isAuthenticated)
    const { t: tCommon } = useTranslation("common")

    useEffect(() => {
        if (hasAuthParams()) return
        if (auth.isAuthenticated) return
        if (auth.activeNavigator) return
        if (auth.isLoading) return

        const silentSigninOrRedirect = () =>
            auth.signinSilent().catch(() => {
                const loginHint = meQuery.isSuccess
                    ? meQuery.data.user.username
                    : (LocalStorage.getUsernameOrUndefined() ?? "")

                auth.signinRedirect({
                    extraQueryParams: {
                        login_hint: loginHint,
                        privacyPolicyUrl,
                        termsAndConditionsUrl,
                    },
                })
            })

        silentSigninOrRedirect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        auth.isAuthenticated,
        auth.activeNavigator,
        auth.isLoading,
        auth.signinRedirect,
        meQuery.data?.user.username,
        meQuery.isSuccess,
    ])

    if (auth.activeNavigator)
        switch (auth.activeNavigator) {
            case "signinSilent":
                return <Loading open message="Signing you in..." />
            case "signoutRedirect":
                return <Loading open message="Signing you out..." />
        }

    if (auth.isLoading) return <Loading open message={tCommon("loading")} />
    if (auth.error) return <Navigate to="/intro" replace />

    if (auth.isAuthenticated)
        return (
            <>
                <ProtectedProviders />
                <Outlet />
            </>
        )

    return <></>
}
