import { createFileRoute } from '@tanstack/react-router'
import { TabBar } from 'components/tabs/tab-bar'

export const Route = createFileRoute('/_authenticated/app/_tab-bar-layout')({
  component: RouteComponent,
})

function RouteComponent() {
  return <TabBar />
}
