import {
    GetNextPageParamFunction,
    InfiniteData,
    infiniteQueryOptions,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"
import { UserProfile } from "api/api-models"
import { UserProfileId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 50

export type UserFollowingsData = Awaited<
    ReturnType<typeof listProfileIdFollowings>
>
export type UserFollowingsInfiniteData = InfiniteData<UserFollowingsData>

type UserFollowingsInfiniteQueryOptionsParams = {
    queryClient: QueryClient
    profileId?: UserProfileId
}

export const userFollowingsInfiniteQueryOptions = (
    params: UserFollowingsInfiniteQueryOptionsParams,
) =>
    infiniteQueryOptions({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            listProfileIdFollowings({
                profileId: params.profileId,
                queryClient: params.queryClient,
                pageParam,
            }),
        queryKey: QueryKeys.profileFollowings(params.profileId),
        getNextPageParam: userFollowingsGetNextPageParam,
        enabled: !!params.profileId,
    })

export const useUserFollowings = (profileId?: UserProfileId) => {
    const queryClient = useQueryClient()
    return useInfiniteQuery(
        userFollowingsInfiniteQueryOptions({ queryClient, profileId }),
    )
}

export const userFollowingsGetNextPageParam: GetNextPageParamFunction<
    number,
    UserFollowingsData
> = lastPage => {
    //returning undefined switches `hasNextPage` to false
    if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

    return lastPage.paging.type === "Index"
        ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
        : -1
}

const listProfileIdFollowings = async ({
    profileId,
    queryClient,
    pageParam,
}: {
    profileId?: UserProfileId
    queryClient: QueryClient
    pageParam: number
}) => {
    if (!profileId) throw new Error("UserProfileId required!")

    const followingsRes =
        await userProfileSelectorClient.listProfilesWhichUserProfileIdFollows(
            {
                filter: "{$sort:{createdAt: desc}}",
                paging: {
                    type: "Index",
                    direction: "After",
                    index: pageParam,
                    limit: PAGINATION_AMOUNT,
                },
            },
            { params: { id: profileId } },
        )

    followingsRes.data.map(data =>
        queryClient.setQueryData<{ data: UserProfile; error: undefined }>(
            QueryKeys.listProfile(data.id),
            {
                data,
                error: undefined,
            },
        ),
    )
    return followingsRes
}
