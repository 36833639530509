/* eslint-disable no-useless-escape */

import { useInfiniteQuery } from "@tanstack/react-query"
import {
    getNotificationCenterItemsQueryFn,
    NOTIFICATION_CENTER_PAGINATION_AMOUNT,
} from "data-flow/notification"
import { mapToSortFilterDc } from "../../../api/api-utils"
import { UserProfileId } from "../../../api/branded-types"
import { QueryKeys } from "../../../api/query-keys"

export const useSubscriptionNotification = (profileId?: UserProfileId) => {
    const queryKey = QueryKeys.subscriptionNotifications(profileId)

    const sortFilter = mapToSortFilterDc("createdAt: desc")
    const tagFilter = `pushNotification.tag: in ["Subscribe"]`
    const profileFilter = `receiverId: "${profileId}"`

    const filter = `{$match: {${profileFilter}, ${tagFilter}}, ${sortFilter}}`

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) =>
            getNotificationCenterItemsQueryFn({
                filter,
                pageParam,
                profileId,
            }),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== NOTIFICATION_CENTER_PAGINATION_AMOUNT)
                return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) +
                      NOTIFICATION_CENTER_PAGINATION_AMOUNT
                : -1
        },
        enabled: !!profileId,
    })
}
