import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { fill } from "../../../utils/array"

import { LiveEvent } from "api/api-models"
import * as styles from "./live-event-search-result-list.css"
import {
    LiveEventSearchResultLoadingTile,
    LiveEventSearchResultTile,
} from "./live-event-search-result-tile"

export type LiveEventSearchResultListTextModel = {
    liveText: string
}

export type LiveEventSearchResultListModel = {
    text: LiveEventSearchResultListTextModel
    liveEvents: LiveEvent[]
    isLoading?: boolean
    loadingItemsAmount?: number
}

export const LiveEventSearchResultList: FC<LiveEventSearchResultListModel> = ({
    text,
    liveEvents,
    isLoading,
    loadingItemsAmount,
}) => (
    <div className={styles.list}>
        {pipe(
            liveEvents,
            A.map(event => (
                <LiveEventSearchResultTile
                    key={event.id}
                    liveEventId={event.id}
                    stateText={text.liveText}
                />
            )),
        )}
        {isLoading &&
            fill(loadingItemsAmount ?? 4, idx => (
                <LiveEventSearchResultLoadingTile key={idx} />
            ))}
    </div>
)

export const LiveEventSearchSectionLoading: FC = () => (
    <div className={styles.loadingSectionContainer}>
        <div className={styles.loadingSectionTitle} />
        <div className={styles.list}>
            <LiveEventSearchResultLoadingTile />
            <LiveEventSearchResultLoadingTile />
            <LiveEventSearchResultLoadingTile />
            <LiveEventSearchResultLoadingTile />
        </div>
    </div>
)
