import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useState } from "react"
import { useCookies } from "react-cookie"
import z from "zod"

import { useAuth } from "react-oidc-context"
import { CookieConsentInitialModal } from "./cookie-consent-initial-modal"
import { CookieConsentOptionsModal } from "./cookie-consent-options-modal"

export const CookiePreferences = z.object({
    essential: z.literal(true), //essential cookies are always enabled
    functional: z.boolean(),
    marketing: z.boolean(),
})

export type CookiePreferences = z.infer<typeof CookiePreferences>

export type CookiePreference = keyof CookiePreferences
export type UpdateCookiePreference<TPreference extends CookiePreference> = {
    key: TPreference
    value: CookiePreferences[TPreference]
}

export const CookieConsent: FC = () => {
    const auth = useAuth()

    const [cookies, setCookie] = useCookies(["cookiePreferences"])

    const cookiePreferences = pipe(
        cookies.cookiePreferences,
        O.fromNullable,
        O.map(CookiePreferences.safeParse),
    )

    const cookiePreferencesAreValid =
        O.isSome(cookiePreferences) && cookiePreferences.value.success

    const [initialModalOpen, setInitialModalOpen] = useState(
        !cookiePreferencesAreValid,
    )
    const [optionsModalOpen, setOptionsModalOpen] = useState(false)

    const [preferences, setPreferences] = useState<CookiePreferences>(
        cookiePreferencesAreValid
            ? {
                  essential: cookiePreferences.value.data.essential,
                  functional: cookiePreferences.value.data.functional,
                  marketing: cookiePreferences.value.data.marketing,
              }
            : {
                  essential: true,
                  functional: false,
                  marketing: false,
              },
    )

    const onAcceptAllClicked = () => {
        const newPreferences = {
            essential: true,
            functional: true,
            marketing: true,
        } satisfies CookiePreferences

        const expirationDate = getCookieExpirationDate()

        setPreferences(newPreferences)

        setCookie("cookiePreferences", JSON.stringify(newPreferences), {
            path: "/",
            expires: expirationDate,
            secure: true,
        })

        setInitialModalOpen(false)
        setOptionsModalOpen(false)
    }

    const onSaveClicked = () => {
        const expirationDate = getCookieExpirationDate()

        setCookie("cookiePreferences", JSON.stringify(preferences), {
            path: "/",
            expires: expirationDate,
            secure: true,
        })

        setOptionsModalOpen(false)
    }

    return (
        <>
            <CookieConsentInitialModal
                open={!auth.isLoading && initialModalOpen}
                onAcceptAllClicked={onAcceptAllClicked}
                onOpenChange={setInitialModalOpen}
                onNavigateToPrivacyPolicy={() => {
                    setInitialModalOpen(false)
                }}
                onOptionsClicked={() => {
                    setInitialModalOpen(false)
                    setOptionsModalOpen(true)
                }}
            />

            <CookieConsentOptionsModal
                open={optionsModalOpen}
                preferences={preferences}
                updatePreferences={updateValues =>
                    setPreferences(prev => ({
                        ...prev,
                        [updateValues.key]: updateValues.value,
                    }))
                }
                onAcceptAllClicked={onAcceptAllClicked}
                onDidDismiss={() => setOptionsModalOpen(false)}
                onNavigateToPrivacyPolicy={() => {
                    setOptionsModalOpen(false)
                    setInitialModalOpen(false)
                }}
                onSaveClicked={onSaveClicked}
            />
        </>
    )
}

export const getCookieExpirationDate = () => {
    const currentDate = new Date()
    const expirationDate = new Date(
        currentDate.setMonth(currentDate.getMonth() + 13),
    )
    return expirationDate
}
