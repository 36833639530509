import { createFileRoute } from "@tanstack/react-router"
import { PostitId } from "api/branded-types"
import { postitByIdQueryOptions } from "features/postit/hooks/use-postit-by-id"
import { PostitConsumerPageLoading } from "features/postit/pages/postit-consumer-page"

export const Route = createFileRoute("/_authenticated/app/postit/viewer/$id")({
    pendingComponent: PostitConsumerPageLoading,
    params: {
        parse: params => ({
            id: PostitId.parse(params.id),
        }),
    },
    loader: async opts => {
        await opts.context.queryClient.ensureQueryData(
            postitByIdQueryOptions(opts.params.id),
        )
        //? when there is an error - it will trigger loading state but would never navigate to page
        // if (data.type === "Image") {
        //     opts.context.queryClient.ensureQueryData(
        //         assetQueryOptions({ resource: data.imageRef }),
        //     )
        // }
    },
})
