import { useQuery } from "@tanstack/react-query"
import { LiveEventId } from "api/branded-types"
import { liveEventSelectorClient } from "api/clients/live-event-api-client"
import { QueryKeys } from "api/query-keys"

export const useListLiveEvent = (
    liveEventId?: LiveEventId,
    refetchInterval?: number,
) => {
    return useQuery({
        queryFn: () => listLiveEventById(liveEventId),
        queryKey: QueryKeys.listLiveEvent(liveEventId),
        enabled: !!liveEventId,
        refetchInterval,
    })
}

export const listLiveEventById = async (liveEventId?: LiveEventId) => {
    if (!liveEventId) throw new Error("LiveEventId is required")
    const res = await liveEventSelectorClient.listLiveEvents({
        filter: `{ $match: {id: "${liveEventId}"}}`,
        paging: { type: "Index", direction: "After", index: -1, limit: 1 },
    })
    const data = res.data.at(0)

    if (!data) {
        return {
            data,
            error: "LIVE_EVENT_NOT_FOUND",
        }
    }
    return {
        data,
        error: undefined,
    }
}
