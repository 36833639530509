import { useInfiniteQuery } from "@tanstack/react-query"

import { CommentedResourceRef } from "../../../api/api-models"
import { userCommentSelectorClient } from "../../../api/clients/comment-api-client"
import { QueryKeys } from "../../../api/query-keys"

const PAGINATION_AMOUNT = 20

export const useResourceComments = (resource?: CommentedResourceRef) => {
    const queryKey = QueryKeys.resourceComments(resource?.contentId)

    const queryFn = async ({ pageParam }: { pageParam: number }) => {
        if (!resource?.contentId)
            throw new Error("CommentContentId is required")

        const comments = await userCommentSelectorClient.listComments({
            filter: `{$match: {resource.contentType: "${resource.contentType}", resource.contentId: "${resource.contentId}"}}`,
            paging: {
                type: "Index",
                direction: "After",
                limit: PAGINATION_AMOUNT,
                index: pageParam,
            },
        })
        return comments
    }

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn,
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!resource,
    })
}
