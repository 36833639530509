import { faPen, faTrash } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC } from "react"

import { Link, useParams } from "@tanstack/react-router"
import { PostitId } from "api/branded-types"
import { Button } from "common/button"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { List, ListItem } from "common/list"
import { usePostitById } from "features/postit/hooks/use-postit-by-id"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import * as styles from "./post-consumer-actions-modal.css"

type OwnPostConsumerActionsModalModel = {
    type: "Video" | "Postit"
    open: boolean
    onEditClicked?: () => void
    onDeleteClicked: () => void
    dismiss: () => void
}
export const OwnPostConsumerActionsModal: FC<
    OwnPostConsumerActionsModalModel
> = model => {
    const { t: tCommon } = useTranslation("common")

    const params = useParams({
        from:
            model.type === "Video"
                ? "/_authenticated/app/video/viewer/$id"
                : "/_authenticated/app/postit/viewer/$id",
    })

    const postitQuery = usePostitById(
        model.type === "Postit" ? (params.id as PostitId) : undefined,
    )
    const postit = postitQuery.data

    return (
        <Drawer open={model.open} onClose={model.dismiss}>
            <DrawerContent className={styles.actionModalContent}>
                <DrawerTitle />
                <DrawerDescription />
                <List className={styles.actionList}>
                    {model.onEditClicked && (
                        <ListItem
                            className={styles.action}
                            onClick={model.onEditClicked}
                            asChild
                        >
                            <Link
                                className={styles.actionLink}
                                to={
                                    model.type === "Video"
                                        ? "/app/video-edit/$id"
                                        : model.type === "Postit"
                                          ? postit?.type === "Text"
                                              ? `/app/text-edit/$id`
                                              : `/app/image-edit/$id`
                                          : "."
                                }
                                params={{ id: params.id }}
                            >
                                <FontAwesomeIcon
                                    icon={faPen}
                                    className={styles.icon}
                                />
                                <span
                                    style={{
                                        fontSize: vars.font.size.m,
                                    }}
                                >
                                    {tCommon("actions.edit")}
                                </span>
                            </Link>
                        </ListItem>
                    )}
                    <ListItem
                        className={classNames(styles.danger, styles.action)}
                        onClick={model.onDeleteClicked}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            className={classNames(styles.icon, styles.danger)}
                        />
                        <span
                            style={{
                                fontSize: vars.font.size.m,
                            }}
                        >
                            {tCommon("actions.delete")}
                        </span>
                    </ListItem>
                </List>
                <Button variant="secondary" onClick={model.dismiss}>
                    {tCommon("actions.cancel")}
                </Button>
            </DrawerContent>
        </Drawer>
    )
}
