import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import {
    InfoToast,
    InfoToastViewport,
    NotificationToast,
    NotificationToastViewport,
    ToastProvider,
} from "./toast"
import { useInfoToast } from "./use-info-toast"
import { useNotificationToast } from "./use-notification-toast"

export const NotificationToaster: FC = () => {
    const { toasts } = useNotificationToast()

    return (
        <ToastProvider swipeDirection="up">
            {pipe(
                toasts,
                A.map(({ id, toastContent, ...props }) => (
                    <NotificationToast key={id} {...props}>
                        {toastContent}
                    </NotificationToast>
                )),
            )}

            <NotificationToastViewport />
        </ToastProvider>
    )
}

export const InfoToaster: FC = () => {
    const { toasts } = useInfoToast()

    return (
        <ToastProvider swipeDirection="right">
            {pipe(
                toasts,
                A.map(({ id, toastContent, ...props }) => (
                    <InfoToast key={id} {...props}>
                        {toastContent}
                    </InfoToast>
                )),
            )}

            <InfoToastViewport />
        </ToastProvider>
    )
}
