import { z } from "zod"

export const ParsedToken = z.object({
    exp: z.number().min(0),
})
export type ParsedToken = z.infer<typeof ParsedToken>

export function parseJwt(token: string | undefined) {
    if (!token) {
        return
    }
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace("-", "+").replace("_", "/")
    const jsonParsed = JSON.parse(window.atob(base64))
    const result = ParsedToken.safeParse(jsonParsed)
    if (result.success) return result.data
    else return
}
