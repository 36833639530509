import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { forwardRef, useImperativeHandle, useRef } from "react"
import { Button } from "./button"
import { ClearableInput, ClearableInputProps } from "./input"
import * as styles from "./searchbar.css"

export interface SearchBarProps extends ClearableInputProps {
    outerClassName?: string
    showCancelButton?: boolean
    cancelButtonText?: string
    onCancel?: () => void
}

export const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
    (
        {
            className,
            type,
            showCancelButton,
            cancelButtonText,
            onCancel,
            outerClassName,
            ...props
        },
        ref,
    ) => {
        const inputRef = useRef<HTMLInputElement>(null)

        useImperativeHandle(ref, () => inputRef.current!)

        const handleCancel = () => {
            if (inputRef.current) {
                inputRef.current.blur()
            }
        }
        return (
            <div className={classNames(styles.wrapper, outerClassName)}>
                <ClearableInput
                    ref={ref}
                    type={type}
                    className={classNames(styles.searchbar, className)}
                    {...props}
                >
                    <FontAwesomeIcon
                        icon={faSearch}
                        className={styles.searchIcon}
                    />
                </ClearableInput>

                {showCancelButton && props.value && (
                    <Button
                        className={styles.cancelButton}
                        onClick={() => {
                            handleCancel()
                            onCancel && onCancel()
                        }}
                    >
                        {cancelButtonText || "cancel"}
                    </Button>
                )}
            </div>
        )
    },
)
