import { pipe } from "fp-ts/function"
import {
    base64WithUrlEncode,
    removeQueryParamFromLocation,
    removeQueryParamFromStr,
    removeTrailSlash,
} from "utils/url"
import { ShareToken } from "../api/branded-types"

export const createShareUrl = (token: ShareToken) => {
    const url = pipe(
        window.location,
        removeQueryParamFromLocation("share_token"),
        removeQueryParamFromStr("x_redirect"),
        removeTrailSlash,
    )

    return `${url}?share_token=${token}&x_redirect=${base64WithUrlEncode(window.location.toString())}`
}

export const createShareUrlWithLocation =
    (location: string) => (token: ShareToken) => {
        const url = pipe(
            window.location.origin,
            removeQueryParamFromStr("share_token"),
            removeQueryParamFromStr("x_redirect"),
            removeTrailSlash,
        )

        return `${url}/${location}?share_token=${token}&x_redirect=${base64WithUrlEncode(window.location.origin + "/" + location)}`
    }
