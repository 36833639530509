import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { useUserProfilesSearch } from "../../hooks/use-user-profiles-search"
import {
    UserSearchResultSectionLoading,
    UserSearchResultTileList,
} from "./users/tile/user-search-result-tile-list"

import { InfiniteData } from "@tanstack/react-query"
import { getIsAuthorizedAccount } from "api/api-utils"
import { useMe } from "hooks/use-me"
import { vars } from "theme/variables.css"
import { Pagination, UserProfile } from "../../api/api-models"
import { Content } from "../../common/content"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { LocalStorage } from "../../local-storage"
import * as styles from "./search-sub-pages.css"

export type InfiniteProfilesData = InfiniteData<{
    data: UserProfile[]
    paging: Pagination
    totalCount: number
}>

export const SearchProfilesPage: FC = () => {
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { t } = useTranslation(["search"])

    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const userProfilesSearchQuery = useUserProfilesSearch({
        searchKey: "",
        profileId,
    })
    const userProfileState = match(userProfilesSearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const refreshSearchProfilesPage = async () => {
        await userProfilesSearchQuery.refetch()
    }

    return (
        <Page>
            <NavigationBackButton />
            <Content className={styles.pageContent}>
                <PullToRefresh onRefresh={refreshSearchProfilesPage}>
                    <div className={styles.header}>
                        <h1
                            style={{
                                marginLeft: 4,
                                fontSize: vars.font.size.l,
                                color: "white",
                            }}
                        >
                            {t("profiles.title")}
                        </h1>
                    </div>

                    <InfiniteScroll
                        disabled={!userProfilesSearchQuery.hasNextPage}
                        state={userProfileState}
                        threshold="100px"
                        onLoadRequested={userProfilesSearchQuery.fetchNextPage}
                    >
                        <UserSearchResultTileList
                            isLoading={
                                userProfilesSearchQuery.isFetchingNextPage ||
                                userProfilesSearchQuery.isLoading
                            }
                            profiles={pipe(
                                userProfilesSearchQuery?.data?.pages ?? [],
                                A.flatMap(page => page.data),
                            )}
                            text={{ action: t("tabs.profile.action") }}
                        />
                    </InfiniteScroll>
                </PullToRefresh>
            </Content>
        </Page>
    )
}

export const SearchProfilesPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content className={styles.pageContent} fullscreen>
            <UserSearchResultSectionLoading />
        </Content>
    </Page>
)

export default SearchProfilesPage
