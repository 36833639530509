import { queryOptions, useQuery } from "@tanstack/react-query"
import { UserProfileId } from "api/branded-types"
import { userProfileSelectorClient } from "api/clients/user-profile-api-client"
import { QueryKeys } from "api/query-keys"

export const getSubscriptionMonetization = async (
    profileId?: UserProfileId,
) => {
    if (!profileId) throw new Error("ProfileId should be defined")
    const { data } =
        await userProfileSelectorClient.getSubscriptionMonetization({
            params: { profileId },
        })
    return data
}

export const profileSubscriptionMonetizationQueryOptions = (
    profileId?: UserProfileId,
) =>
    queryOptions({
        queryFn: () => getSubscriptionMonetization(profileId),
        queryKey: QueryKeys.userProfileSubscription(profileId),
        enabled: !!profileId,
    })

export const useProfileSubscriptionMonetization = (profileId?: UserProfileId) =>
    useQuery(profileSubscriptionMonetizationQueryOptions(profileId))
