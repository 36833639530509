import { queryOptions } from "@tanstack/react-query"
import { PaymentId } from "api/branded-types"
import { paymentCreatorClient } from "api/clients/payment-api-client"
import { QueryKeys } from "api/query-keys"

export const getPaymentCheckoutLinkById = async (paymentId?: PaymentId) => {
    if (!paymentId) throw new Error("PaymentID is required")
    const paymentRes = await paymentCreatorClient.getPaymentCheckoutLinkById({
        params: { id: paymentId },
    })
    return paymentRes.data
}

type PaymentCheckoutLinkByIdQueryOptionsParams = {
    enabled?: boolean
    paymentId?: PaymentId
}

export const paymentCheckoutLinkByIdQueryOptions = ({
    enabled = true,
    paymentId,
}: PaymentCheckoutLinkByIdQueryOptionsParams) =>
    queryOptions({
        queryFn: () => getPaymentCheckoutLinkById(paymentId),
        enabled: enabled && !!paymentId,
        queryKey: QueryKeys.paymentCheckoutLink(paymentId),
    })
