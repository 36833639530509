import { QueryClient } from "@tanstack/react-query"
import { Payment } from "api/api-models"
import { UserProfileId } from "api/branded-types"
import { paymentSelectorClient } from "api/clients/payment-api-client"
import { QueryKeys } from "api/query-keys"
import { MY_PAYMENTS_PAGINATION_AMOUNT } from "hooks/use-my-payments"
import { PAYMENTS_PAGINATION_AMOUNT } from "hooks/use-payments"

export const listPayments = async ({
    pageParam,
    queryClient,
}: {
    pageParam: number
    queryClient: QueryClient
}) => {
    const payments = await paymentSelectorClient.listPayments({
        paging: {
            type: "Index",
            direction: "After",
            limit: PAYMENTS_PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    payments.data.map(payment =>
        queryClient.setQueryData<Payment>(
            QueryKeys.payment(payment.id),
            payment,
        ),
    )

    return payments
}

export const getMyPayments = async ({
    pageParam,
    queryClient,
    profileId,
}: {
    profileId?: UserProfileId
    pageParam: number
    queryClient: QueryClient
}) => {
    if (!profileId) throw new Error("UserProfileId should be defined!")

    const paymentsRes = await paymentSelectorClient.listPayments({
        filter: `{$match: {state: "Succeeded", contentOwnerId: !in ["${profileId}"]} ,$sort:{createdAt: desc}}`,
        paging: {
            type: "Index",
            direction: "After",
            limit: MY_PAYMENTS_PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    paymentsRes.data.forEach(payment =>
        queryClient.setQueryData<Payment>(
            QueryKeys.payment(payment.id),
            payment,
        ),
    )

    return paymentsRes
}
