import { useEffect, useState } from "react"

const LOCAL_STORAGE_KEY = "teaser-skipped-state"

const defaultState = {
    CollectPoints: false,
    SoftLaunch: false,
}

type TeaserSkippedState = typeof defaultState
type TeaserSkippedKeys = keyof TeaserSkippedState

export const useTeaserState = () => {
    const [skippedState, setSkippedState] = useState<TeaserSkippedState>(() => {
        const storedState = localStorage.getItem(LOCAL_STORAGE_KEY)
        return storedState
            ? (JSON.parse(storedState) as TeaserSkippedState)
            : defaultState
    })

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(skippedState))
    }, [skippedState])

    const skip = (type: TeaserSkippedKeys) => {
        setSkippedState(prevState => ({
            ...prevState,
            [type]: true,
        }))
    }

    const isSkipped = (type: TeaserSkippedKeys) => !!skippedState[type]

    return { skip, isSkipped }
}
