import { faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Slot } from "@radix-ui/react-slot"
import classNames from "classnames"
import React from "react"
import * as styles from "./list.css"

export type ListProps = JSX.IntrinsicElements["div"]

export const List = React.forwardRef<HTMLDivElement, ListProps>(
    ({ className, ...props }, ref) => (
        <div
            ref={ref}
            className={classNames(styles.list, className)}
            {...props}
        />
    ),
)

export type ListItemProps = JSX.IntrinsicElements["div"] & {
    detail?: boolean
    asChild?: boolean
}

export const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>(
    ({ className, children, detail, asChild, ...props }, ref) => {
        const Comp = asChild ? Slot : "div"
        return (
            <Comp
                ref={ref}
                className={classNames(styles.listItem, className)}
                {...props}
            >
                <div className={styles.listItemInner}>
                    {children}
                    {detail && (
                        <FontAwesomeIcon
                            icon={faAngleRight}
                            className={styles.detail}
                        />
                    )}
                </div>
            </Comp>
        )
    },
)
