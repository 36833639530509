import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "common/dialog"

import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { useModalState } from "hooks/use-modal-state"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { vars } from "theme/variables.css"
import * as styles from "./first-steps-welcome-modal.css"

type FirstStepsWelcomeModalModel = {
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const FirstStepsWelcomeModal: FC<
    FirstStepsWelcomeModalModel
> = model => {
    const { t } = useTranslation(["modal"])
    const { skipModal } = useModalState()

    return (
        <Dialog open={model.open} onOpenChange={model.onOpenChange}>
            <DialogContent className={styles.content}>
                <DialogHeader className={styles.header}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className={styles.closeIcon}
                        onClick={() => model.onOpenChange(false)}
                    />
                </DialogHeader>

                <div className={styles.mainContentWrapper}>
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xxxl,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            {t("first.steps.welcome.title")}
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {`${t("first.steps.welcome.description")} `}
                            <span style={{ fontWeight: 700, margin: 0 }}>
                                {t("first.steps.welcome.basicLevel")}
                            </span>
                        </DialogDescription>
                    </div>

                    <LazyLoadImage
                        width={window.innerWidth - 36 * 2}
                        height={(window.innerWidth - 36 * 2) * 0.44}
                        className={styles.image}
                        alt="rewards"
                        loading="eager"
                        src="/images/gfx_points_welcome.svg"
                        style={{ marginBottom: 40 }}
                    />

                    <div className={styles.textContent}>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                fontWeight: 400,
                                marginBottom: 26,
                            }}
                        >
                            {t("first.steps.welcome.content.text")}
                        </p>
                        <div style={{ margin: "12px 4px" }}>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    fontWeight: 700,
                                    margin: 0,
                                }}
                            >
                                {t("first.steps.welcome.tasks.start")}
                            </p>
                            <ul className={styles.tasksList}>
                                {[
                                    t(
                                        "first.steps.welcome.tasks.completeProfile",
                                    ),
                                    t(
                                        "first.steps.welcome.tasks.inviteFriends",
                                    ),
                                    t(
                                        "first.steps.welcome.tasks.postFirstImage",
                                    ),
                                    t("first.steps.welcome.tasks.more"),
                                ].map((task, index) => (
                                    <li key={index}>
                                        <p
                                            style={{
                                                fontSize:
                                                    vars.font.size.regular,
                                                fontWeight: 400,
                                                margin: 0,
                                            }}
                                        >
                                            {task}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    fontWeight: 400,
                                    margin: "12px 4px 32px",
                                }}
                            >
                                {t("first.steps.welcome.tasks.unlockLevels")}
                            </p>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    fontWeight: 700,
                                    margin: "0px 4px 12px",
                                }}
                            >
                                {t("first.steps.welcome.tasks.claimRewards")}
                            </p>
                        </div>
                    </div>
                </div>

                <DialogFooter
                    style={{
                        display: "flex",
                        flexDirection: "column",

                        width: "100%",

                        position: "fixed",
                        padding: "24px 36px 52px",
                        bottom: 0,
                    }}
                >
                    <Button
                        onClick={() => {
                            model.onOpenChange(false)
                            skipModal("FirstStepsWelcome")
                        }}
                        asChild
                    >
                        <Link
                            to="/app/settings/rewards"
                            search={{ currentTab: "get-started" }}
                        >
                            {t("first.steps.welcome.start")}
                        </Link>
                    </Button>

                    <Button
                        variant="ghost"
                        className={styles.donNotShowAgainButton}
                        onClick={() => {
                            model.onOpenChange(false)
                            skipModal("FirstStepsWelcome")
                        }}
                    >
                        {t("first.steps.welcome.close")}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
