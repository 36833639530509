import { faEye } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { CSSProperties, FC } from "react"

import { AvatarLoading } from "../../../components/avatars/avatar-loading"
import { isFoldNeeded, truncateText } from "../../../utils/text"

import { Link } from "@tanstack/react-router"
import { LiveEventId } from "api/branded-types"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import { useListLiveEvent } from "hooks/use-list-live-event"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useLiveEventStatistics } from "hooks/use-live-event-statistics"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./live-event-search-result-tile.css"

export type LiveEventSearchResultTileModel = {
    style?: CSSProperties | undefined
    className?: string
    stateText: string
    liveEventId: LiveEventId
    onClicked?: () => void
}

export const LiveEventSearchResultTile: FC<LiveEventSearchResultTileModel> = ({
    style,
    className = "",
    liveEventId,
    stateText,
    onClicked,
}) => {
    const liveEventQuery = useListLiveEvent(liveEventId)
    const liveEvent = liveEventQuery.data?.data

    const liveEventStatisticsQuery = useLiveEventStatistics(liveEventId, 5000)

    const profileQuery = useListUserProfileById(liveEvent?.creatorId)
    const profile = profileQuery.data?.data

    const myProfileIdQuery = useMyUserProfileId()

    return (
        <Link
            to="/app/live-event/viewer/$id"
            params={{
                id: liveEventId,
            }}
        >
            <div
                style={{
                    backgroundImage:
                        "url(/icons/custom/live-now-area-tile-bg.svg)",
                    ...style,
                }}
                className={classNames(styles.tile, className)}
                onClick={onClicked}
            >
                <div
                    className={styles.stats}
                    style={{ fontSize: vars.font.size.regular }}
                >
                    <div className={styles.playState}>{stateText}</div>

                    {liveEventStatisticsQuery.isSuccess && (
                        <div className={styles.viewerCount}>
                            <FontAwesomeIcon
                                className={styles.viewerCountIcon}
                                icon={faEye}
                                color="white"
                            />
                            <p style={{ fontSize: vars.font.size.s }}>
                                {
                                    liveEventStatisticsQuery.data.viewCount
                                        .current
                                }
                            </p>
                        </div>
                    )}
                </div>

                <div className={styles.overlay} />

                {liveEventQuery.isSuccess && liveEvent && (
                    <p
                        className={styles.title}
                        style={{ fontSize: vars.font.size.regular }}
                    >
                        {pipe(
                            liveEvent.title,
                            O.fromPredicate(isFoldNeeded(40)),
                            O.map(truncateText(40)),
                            O.map(text => `${text}...`),
                            O.getOrElse(() => liveEvent.title),
                        )}
                    </p>
                )}
                {profileQuery.isSuccess && profile && (
                    <Link
                        to={
                            myProfileIdQuery.data === profile.id
                                ? "/app/my-profile"
                                : "/app/user-profile/$id"
                        }
                        params={{ id: profile.id }}
                    >
                        <div className={styles.avatarWrapper}>
                            <AvatarWithBadge
                                profileId={profile.id}
                                className={styles.avatar}
                                size={36}
                                src={profile.imageUrl}
                                styleState={profile.isLive ? "isLive" : "None"}
                            />

                            <p
                                className={styles.userName}
                                style={{ fontSize: vars.font.size.xs }}
                            >
                                {profile.displayName ?? profile.profileName}
                            </p>
                        </div>
                    </Link>
                )}
            </div>
        </Link>
    )
}

export const LiveEventSearchResultLoadingTile: FC = () => (
    <div className={styles.tile}>
        <Skeleton
            width="100%"
            height="100%"
            containerClassName="flex-fit"
            borderRadius={18}
            style={{ paddingLeft: 2 }}
        />

        <div className={styles.avatarWrapper}>
            <AvatarLoading size={36} baseColor="#3C375C" />
            <Skeleton
                width="100%"
                containerClassName="flex-fit"
                height={18}
                borderRadius={24}
            />
        </div>
    </div>
)
