import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import {
    ChangeEvent,
    forwardRef,
    InputHTMLAttributes,
    useImperativeHandle,
    useRef,
} from "react"
import * as styles from "./input.css"

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    maxLength?: number
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, ...props }, ref) => {
        return (
            <input
                type={type}
                className={classNames(styles.input, className)}
                ref={ref}
                {...props}
            />
        )
    },
)

export type ClearableInputProps = InputHTMLAttributes<HTMLInputElement> & {
    clearInput?: boolean
    onClear?: () => void
}

export const ClearableInput = forwardRef<HTMLInputElement, ClearableInputProps>(
    ({ className, clearInput, onClear, type, children, ...props }, ref) => {
        const inputRef = useRef<HTMLInputElement>(null)

        useImperativeHandle(ref, () => inputRef.current!)

        const handleClear = () => {
            if (inputRef.current) {
                inputRef.current.value = ""

                props.onChange?.({
                    target: { value: "" },
                } as ChangeEvent<HTMLInputElement>)
            }
        }
        return (
            <div className={styles.clearableInputWrapper}>
                <input
                    type={type}
                    className={classNames(
                        styles.input,
                        styles.clearableInput,
                        className,
                    )}
                    ref={inputRef}
                    {...props}
                />
                {props.value && clearInput ? (
                    <FontAwesomeIcon
                        onClick={() => {
                            handleClear()
                            onClear && onClear()
                        }}
                        icon={faCircleXmark}
                        className={styles.clearIcon}
                    />
                ) : (
                    <></>
                )}
                {children}
            </div>
        )
    },
)
