import { FC } from "react"
import { useAuth } from "react-oidc-context"

import { Avatar } from "../components/avatars/avatar"
import { LocalStorage } from "../local-storage"

import { Button } from "common/button"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import Skeleton from "react-loading-skeleton"
import { useAppStore } from "store"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../common/drawer"
import { AvatarLoading } from "../components/avatars/avatar-loading"
import { privacyPolicyUrl, termsAndConditionsUrl } from "../utils/constant"
import * as styles from "./login-or-register-modal.css"

type LoginOrRegisterModalTextProps = {
    title: (name: string | undefined) => string
    message: string
    onSignUp: string
    onSignIn: string
}

export type LoginOrRegisterModalProps = {
    text: LoginOrRegisterModalTextProps
}

// Show this modal only for guest accounts
export const LoginOrRegisterModal: FC<LoginOrRegisterModalProps> = ({
    text,
}) => {
    const auth = useAuth()
    const isModalVisible = useAppStore(store => store.isModalVisible)
    const userProfileQuery = useListUserProfileById(
        isModalVisible.LoginOrRegister.profileId,
    )
    const closeModal = useAppStore(store => store.closeModal)

    const profile = userProfileQuery.data?.data

    return (
        <Drawer
            open={isModalVisible.LoginOrRegister.visible}
            onClose={() => closeModal("LoginOrRegister")}
        >
            <DrawerContent className={styles.modal}>
                <DrawerTitle />
                <DrawerDescription />
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <svg className={styles.logo}>
                            <use href="/app-logo.svg#app_logo" />
                        </svg>

                        {userProfileQuery.isSuccess && profile && (
                            <Avatar
                                className={styles.avatar}
                                src={profile.imageUrl}
                                styleState={profile.isLive ? "isLive" : "None"}
                            />
                        )}
                        {userProfileQuery.isSuccess && profile && (
                            <h1 style={{ margin: 0 }}>
                                <strong>
                                    {text.title(profile.displayName)}
                                </strong>
                            </h1>
                        )}
                        {userProfileQuery.isLoading && (
                            <AvatarLoading
                                baseColor="#D5D6D8"
                                className={"flex-fit"}
                            />
                        )}
                        {userProfileQuery.isLoading && (
                            <Skeleton
                                baseColor="#D5D6D8"
                                width={220}
                                height={35}
                                borderRadius={24}
                            />
                        )}
                        <p style={{ margin: 0 }}>{text.message}</p>
                    </div>

                    <div className={styles.buttonGroup}>
                        <Button
                            variant="medium"
                            full
                            onClick={() =>
                                auth.signinRedirect({
                                    extraQueryParams: {
                                        auth_policy: "trialSignUp",
                                        privacyPolicyUrl,
                                        termsAndConditionsUrl,
                                    },
                                })
                            }
                        >
                            {text.onSignUp}
                        </Button>

                        <Button
                            variant="ghost"
                            full
                            onClick={() =>
                                auth.signinRedirect({
                                    extraQueryParams: {
                                        login_hint:
                                            LocalStorage.getUsernameOrUndefined() ??
                                            "",
                                        auth_policy: "signIn",
                                        privacyPolicyUrl,
                                        termsAndConditionsUrl,
                                    },
                                })
                            }
                        >
                            {text.onSignIn}
                        </Button>
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
