import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { faCircleStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC, useState } from "react"

import { Url, UserProfileId } from "../../api/branded-types"
import { Avatar } from "../../components/avatars/avatar"

import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { useIsSubscriptionSupported } from "features/payment/hooks/use-is-subscription-supported"
import { useProfileSubscriptionMonetization } from "features/payment/hooks/use-profile-subscription-monetization"
import { useTranslation } from "react-i18next"
import { useIntl } from "react-intl"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { vars } from "theme/variables.css"
import { Checkbox } from "../../common/checkbox"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./content-pay-modal.css"

export type ContentPayModalText = {
    purchase: {
        amount: string
    }
    subscribe: {
        title: string
        action: string
    }
    terms: string
}

export type ContentPayModalModel = {
    text: ContentPayModalText
    user: {
        name: string
        imageUrl: Url
        isLive: boolean
    }
    profileId?: UserProfileId
    content: {
        title: string
        imageUrl: Url
        type: "Video" | "Image" | "Text"
    }
    open: boolean
    purchaseActionDisabled?: boolean

    onCanceled: () => void
    onPurchased: () => void
    onSubscribed: () => void
}

export const ContentPayModal: FC<ContentPayModalModel> = ({
    text,
    open,
    user,
    profileId,
    content,
    purchaseActionDisabled,
    onCanceled,
    onPurchased,
    onSubscribed,
}) => {
    const intl = useIntl()
    const profileSubscriptionMonetizationQuery =
        useProfileSubscriptionMonetization(profileId)
    const subscriptionSupportedQuery = useIsSubscriptionSupported(profileId)

    const [purchaseConsentChecked, setPurchaseConsentChecked] = useState(false)
    const { t } = useTranslation(["modal"])

    const canShowSubscription =
        profileSubscriptionMonetizationQuery.isSuccess &&
        profileSubscriptionMonetizationQuery.data.interval !== "None" &&
        subscriptionSupportedQuery.isSuccess &&
        subscriptionSupportedQuery.data.isAvailable

    return (
        <Drawer open={open} onClose={onCanceled}>
            <DrawerContent className={styles.modal}>
                <DrawerHeader>
                    <DrawerClose asChild>
                        <FontAwesomeIcon
                            icon={faXmark}
                            className={styles.closeIcon}
                        />
                    </DrawerClose>
                </DrawerHeader>
                <DrawerTitle />
                <DrawerDescription />
                <div className={styles.content}>
                    <div className={styles.contentWrapper}>
                        {content.type === "Video" && (
                            <div className={styles.videoImageContentWrapper}>
                                <FontAwesomeIcon
                                    className={styles.icon}
                                    icon={faCircleStar}
                                    color="white"
                                />
                                <LazyLoadImage
                                    width={64}
                                    height={90}
                                    style={{
                                        objectFit: "cover",
                                        borderRadius:
                                            vars.measurement.radius.md,
                                    }}
                                    alt="content-image"
                                    src={content.imageUrl}
                                    loading="eager"
                                />
                            </div>
                        )}
                        {content.type === "Image" && (
                            <div className={styles.imageContentWrapper}>
                                <FontAwesomeIcon
                                    className={styles.icon}
                                    icon={faCircleStar}
                                    color="white"
                                />
                                <LazyLoadImage
                                    width={85}
                                    height={85}
                                    style={{ objectFit: "cover" }}
                                    src={content.imageUrl}
                                    loading="eager"
                                />
                                <div className={styles.secureOverlay} />
                            </div>
                        )}
                        {content.type === "Text" && (
                            <div style={{ marginRight: 6 }}>
                                <FontAwesomeIcon
                                    className={classNames(
                                        styles.icon,
                                        styles.textIcon,
                                    )}
                                    icon={faCircleStar}
                                />
                            </div>
                        )}

                        <div className={styles.userLabelWrapper}>
                            <p
                                style={{
                                    fontSize: vars.font.size.l,
                                    color: vars.color.medium.hex,
                                    fontWeight: 500,
                                    margin: 0,
                                }}
                            >
                                {content.title}
                            </p>

                            <div className={styles.user}>
                                <Avatar
                                    className={styles.avatar}
                                    src={user.imageUrl}
                                    size={28}
                                    styleState={user.isLive ? "isLive" : "None"}
                                />

                                <p
                                    style={{
                                        fontSize: vars.font.size.regular,
                                        margin: 0,
                                        color: vars.color.medium.hex,
                                    }}
                                >
                                    {user.name}
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                padding: 2,
                                marginLeft: "auto",
                                alignItems: "center",
                                fontSize: vars.font.size.l,
                            }}
                        >
                            {text.purchase.amount}
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 16,
                        }}
                    >
                        <div className={styles.consentContainer}>
                            <Checkbox
                                checked={purchaseConsentChecked}
                                onCheckedChange={v =>
                                    setPurchaseConsentChecked(v === true)
                                }
                                id="purchase_consent"
                            />

                            <label
                                htmlFor="purchase_consent"
                                style={{
                                    fontSize: vars.font.size.regular,
                                    color: vars.color.tertiary.hex,
                                }}
                            >
                                {t("purchase.consent.label")}
                            </label>
                        </div>

                        <label
                            htmlFor="purchase_consent"
                            style={{
                                fontSize: vars.font.size.regular,
                                color: vars.color.tertiary.hex,
                                marginLeft: 34,
                            }}
                        >
                            {t("purchase.consent.cancellationPolicy")}{" "}
                            <Link
                                className={styles.link}
                                to="/registration/cancellation-policy"
                            >
                                {t("purchase.cancellationLink")}
                            </Link>{" "}
                            {t("purchase.contract.continued")}
                        </label>
                    </div>

                    <Button
                        variant="dark"
                        className={styles.action}
                        full
                        disabled={
                            purchaseActionDisabled || !purchaseConsentChecked
                        }
                        onClick={() => onPurchased()}
                    >
                        {text.purchase.amount}
                    </Button>

                    {canShowSubscription && (
                        <>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    color: vars.color.tertiary.hex,
                                    padding: "12px 10px 0 10px",
                                    margin: 0,
                                }}
                            >
                                {t("subscribe.info", {
                                    amount: intl.formatNumber(
                                        profileSubscriptionMonetizationQuery
                                            .data.price.amount,
                                        {
                                            style: "currency",
                                            currency: "EUR",
                                            currencyDisplay: "symbol",
                                        },
                                    ),
                                })}
                            </p>

                            <Button
                                full
                                variant="dark"
                                className={styles.action}
                                disabled={!purchaseConsentChecked}
                                onClick={() => onSubscribed()}
                            >
                                {t("subscribe.action")}
                            </Button>
                        </>
                    )}

                    <p
                        style={{
                            fontSize: vars.font.size.xxs,
                            color: vars.color.tertiary.hex,
                            padding: "12px 10px 0 10px",
                            margin: 0,
                        }}
                    >
                        {t("purchase.contract")}{" "}
                        <Link
                            className={styles.link}
                            to="/registration/terms-and-condition"
                        >
                            {t("purchase.termsLink")}
                        </Link>{" "}
                        {t("purchase.contract.continued")}
                    </p>

                    <p
                        style={{
                            fontSize: vars.font.size.xxs,
                            color: vars.color.tertiary.hex,
                            padding: "0 10px",
                            margin: 0,
                        }}
                    >
                        {t("privacy.info")}{" "}
                        <Link
                            className={styles.link}
                            to="/registration/privacy-policy"
                        >
                            {t("privacy.policyLink")}
                        </Link>
                        {t("privacy.info.continued")}
                    </p>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
