import { FC } from "react"

import { FollowState, FollowStateAdt } from "../../../data-flow/follower"

import { UserProfileId } from "api/branded-types"
import { Button } from "common/button"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./user-profile-page-follow-button.css"

export type UserProfilePageFollowButtonModel = {
    text: {
        follow: string
        unfollow: string
    }
    followingState: FollowState
    profileId: UserProfileId
    onFollowClicked: () => void
    onUnfollowClicked: () => void
}

export const UserProfilePageFollowButton: FC<
    UserProfilePageFollowButtonModel
> = ({
    text,
    profileId,
    followingState,
    onFollowClicked,
    onUnfollowClicked,
}) => {
    const { ensureUserPreventDefault } = useOnlyRegisteredUser()

    return (
        <div onClick={ensureUserPreventDefault(profileId)}>
            {FollowStateAdt.match({
                Following: () => (
                    <Button
                        full
                        variant="dark"
                        style={{ color: vars.color.background.hex }}
                        onClick={onUnfollowClicked}
                    >
                        <p
                            style={{ fontSize: vars.font.size.m }}
                            className={styles.isFollowingText}
                        >
                            {text.unfollow}
                        </p>
                    </Button>
                ),
                IgnoringFollow: () => (
                    <Button full variant="primary" onClick={onFollowClicked}>
                        <p
                            style={{ fontSize: vars.font.size.m }}
                            className={styles.isNotFollowingText}
                        >
                            {text.follow}
                        </p>
                    </Button>
                ),
                LoadingFollow: () => (
                    <Skeleton height={50} width="100%" borderRadius={16} />
                ),
                NotPossibleFollow: () => (
                    <Button full variant="primary">
                        <p
                            style={{ fontSize: vars.font.size.m }}
                            className={styles.isNotFollowingText}
                        >
                            {text.follow}
                        </p>
                    </Button>
                ),
            })(followingState)}
        </div>
    )
}
