import { DialogContent } from "common/dialog"
import { CSSProperties, FC, PropsWithChildren } from "react"

type Props = {
    style?: CSSProperties | undefined
}

export const ModalContent: FC<PropsWithChildren<Props>> = ({
    style,
    children,
}) => (
    <DialogContent
        style={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
            justifyContent: "start",
            textAlign: "center",

            paddingInline: 24,
            paddingBlock: 10,

            backgroundColor: "white",
            whiteSpace: "pre-wrap",
            borderRadius: "16px",
            color: "#262142",

            ...style,
        }}
    >
        {children}
    </DialogContent>
)
