import { FC, useEffect } from "react"

import { Button } from "common/button"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { faBan } from "@fortawesome/pro-regular-svg-icons"
import { faOctagonCheck } from "@fortawesome/pro-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { userProfileCreatorClient } from "api/clients/user-profile-api-client"
import { QueryKeys } from "api/query-keys"
import { Form } from "common/form"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"

import { useRouter } from "@tanstack/react-router"
import { Content } from "common/content"
import { Footer } from "common/footer"
import { Page } from "common/page"
import { Toolbar } from "common/toolbar"
import { NavigationBackButton } from "components/buttons/navigation-back-button"
import { useProfileSubscriptionMonetization } from "./hooks/use-profile-subscription-monetization"
import * as styles from "./manage-subscription-page.css"
import {
    EnabledControlField,
    PriceControlField,
} from "./modals/manage-subscription-modal"
import { manageSubscriptionValidationSchema } from "./validation/manage-subscription-schema"

export type ManageSubscriptionPageProps = {
    loc: {
        title: string
        description: string
        submit: string
        cancel: string
        form: {
            title: string
            description: string
            infos: string[]
            placeholder: string
            accountValidation: {
                title: string
                success: string
                failure: string
            }
            price: {
                set: string
                info: string
            }
        }
    }
}

export const ManageSubscriptionPage: FC<ManageSubscriptionPageProps> = ({
    loc,
}) => {
    const router = useRouter()
    const queryClient = useQueryClient()

    const { toast } = useInfoToast()

    const form = useForm<z.infer<typeof manageSubscriptionValidationSchema>>({
        resolver: zodResolver(manageSubscriptionValidationSchema),
        defaultValues: {
            enabled: false,
            price: {
                amount: 4.99,
                currency: "EUR",
            },
        },
    })

    const myProfileIdQuery = useMyUserProfileId()
    const monetizationSubscriptionQuery = useProfileSubscriptionMonetization(
        myProfileIdQuery.data,
    )

    const closePage = () => {
        router.history.back()
    }

    const { mutate: updateSubMonetization, isPending: isUpdateSubInProgress } =
        useMutation({
            mutationFn: userProfileCreatorClient.updateSubscriptionMonetization,
        })
    const {
        mutate: deleteSubscriptionMonetization,
        isPending: isDeleteSubscriptionInProgress,
    } = useMutation({
        mutationFn: userProfileCreatorClient.deleteSubscriptionMonetization,
    })

    const updateDisabled =
        monetizationSubscriptionQuery.isLoading ||
        isUpdateSubInProgress ||
        isDeleteSubscriptionInProgress

    function onSubmit(
        values: z.infer<typeof manageSubscriptionValidationSchema>,
    ) {
        const { enabled, price } = values
        if (enabled) {
            updateSubMonetization(
                { interval: "Months1", price },
                {
                    onSuccess: () =>
                        toast({
                            toastContent: (
                                <InfoToastContent
                                    icon={faOctagonCheck}
                                    iconColor="success"
                                    text={loc.form.accountValidation.success}
                                />
                            ),
                        }),
                    onSettled: () => {
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.userProfileSubscription(
                                myProfileIdQuery.data,
                            ),
                        })
                        closePage()
                    },
                },
            )
        } else {
            deleteSubscriptionMonetization(undefined, {
                onSuccess: () =>
                    toast({
                        toastContent: (
                            <InfoToastContent
                                icon={faBan}
                                iconColor="liveColor"
                                text={loc.form.accountValidation.failure}
                            />
                        ),
                    }),
                onSettled: () => {
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.userProfileSubscription(
                            myProfileIdQuery.data,
                        ),
                    })
                    closePage()
                },
            })
        }
    }

    useEffect(() => {
        const subscriptionDisabled =
            monetizationSubscriptionQuery.isSuccess &&
            monetizationSubscriptionQuery.data.interval === "None"

        if (subscriptionDisabled) {
            form.reset({
                enabled: false,
                price: {
                    amount: 4.99,
                    currency: "EUR",
                },
            })
        } else if (monetizationSubscriptionQuery.isSuccess) {
            form.reset({
                enabled: true,
                price: monetizationSubscriptionQuery.data.price,
            })
        }
    }, [
        monetizationSubscriptionQuery.data,
        monetizationSubscriptionQuery.isSuccess,
        form.reset,
    ])

    return (
        <Page className={styles.page}>
            <Toolbar />
            <NavigationBackButton iconColor="#262142" />
            <Content className={styles.content}>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className={styles.controls}>
                            <h1 className={styles.title}>{loc.title}</h1>
                            <p className={styles.description}></p>
                            <div
                                className={
                                    styles.manageSubscriptionSectionWrapper
                                }
                            >
                                <EnabledControlField
                                    disabled={updateDisabled}
                                    loc={loc}
                                    form={form}
                                />
                                <PriceControlField
                                    disabled={updateDisabled}
                                    loc={loc}
                                    form={form}
                                />
                            </div>
                        </div>

                        <Footer className={styles.footer}>
                            <Button type="submit" full>
                                {loc.submit}
                            </Button>

                            <Button
                                onClick={closePage}
                                type="button"
                                full
                                variant="secondary"
                            >
                                {loc.cancel}
                            </Button>
                        </Footer>
                    </form>
                </Form>
            </Content>
        </Page>
    )
}
