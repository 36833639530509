/* eslint-disable no-useless-escape */

import {
    GetNextPageParamFunction,
    InfiniteData,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Video } from "api/api-models"
import { mapToSortFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { videoSelectorClient } from "../api/clients/video-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 50

export type UseVideosSearchModel = {
    searchKey: string
    creatorIds: UserProfileId[]
    profileId?: UserProfileId
    enabled?: boolean
}

const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
const mimeType = 'mimeType: !"" and !"unknown/unknown"'

export const searchVideosDefaultFilter = `{$match:{${mimeType}}, ${sortFilter}}`

export const getSearchVideos = async ({
    pageParam,
    filter,
    queryClient,
}: {
    pageParam: unknown
    filter: string
    queryClient: QueryClient
}) => {
    const videos = await videoSelectorClient.listVideos({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam as number,
        },
    })

    videos.data.forEach(data =>
        queryClient.setQueryData<{ data: Video; error: undefined }>(
            QueryKeys.video(data.id),
            { data, error: undefined },
        ),
    )

    return videos
}

type Data = Awaited<ReturnType<typeof getSearchVideos>>

export type SearchVideosData = Data
export type InfiniteSearchVideosData = InfiniteData<Data>

export const useVideosSearch = (model: UseVideosSearchModel) => {
    const queryKey = QueryKeys.searchVideos(model)
    const queryClient = useQueryClient()

    const titleFilter = `title: *"${model.searchKey}"*:ci`
    const descriptionFilter = `description: *"${model.searchKey}"*:ci`
    const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
    const mimeType = 'mimeType: !"" and !"unknown/unknown"'
    const removeMyProfileFilter =
        !model.profileId || model.searchKey.length > 0
            ? ""
            : `, creatorId: !"${model.profileId}"`
    //TODO: this is not 100% precise, but would work out most of the time
    //TODO: otherwise we need specific API endpoint for this
    const profilesFilter =
        model.searchKey.length === 0
            ? ""
            : `, {creatorId: in ${JSON.stringify(model.creatorIds)}}`

    const filter = `{$match:{$or: [{${titleFilter}}, {${descriptionFilter}}${profilesFilter}]${removeMyProfileFilter}, ${mimeType}}, ${sortFilter}}`

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn: ({ pageParam }) =>
            getSearchVideos({ pageParam, filter, queryClient }),
        getNextPageParam: getSearchVideosNextPageParam,
        enabled: model.enabled,
    })
}

export const getSearchVideosNextPageParam: GetNextPageParamFunction<
    number,
    Data
> = lastPage => {
    if (lastPage.data.length !== PAGINATION_AMOUNT) {
        return undefined
    }

    return lastPage.paging.type === "Index"
        ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
        : -1
}
