import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "common/dialog"
import { FC, useMemo } from "react"

import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons"
import { getIsAuthorizedAccount } from "api/api-utils"
import * as O from "fp-ts/Option"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { useProfileChallengesOverview } from "hooks/use-profile-challenges-overview"
import { LocalStorage } from "local-storage"
import { ParticleAnimation } from "./particles-animation/particles-animation"
import { usePointSystemModalStore } from "./store/point-system-modal-store"

import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { pipe } from "fp-ts/function"
import { useMe } from "hooks/use-me"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import { isDefined } from "utils/object"
import * as styles from "./first-steps-complete-modal.css"

type FirstStepsCompleteModalModel = {
    open: boolean
    onOpenChange: (v: boolean) => void
}

const BONUS_POINTS = 50

export const FirstStepsCompleteModal: FC<
    FirstStepsCompleteModalModel
> = model => {
    const { t } = useTranslation(["modal"])
    const firstStepsModalState = usePointSystemModalStore(
        store => store.firstStepsModalState,
    )

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const profileIdQuery = useMyUserProfileId(isAuthorizedAccount)

    const profileChallengesOverviewQuery = useProfileChallengesOverview(
        profileIdQuery.data,
    )

    const firstStepsTasksTotalPoints = profileChallengesOverviewQuery.isSuccess
        ? profileChallengesOverviewQuery.data.firstSteps.reduce(
              (prev, current) => prev + current.rewardPoints,
              0,
          )
        : undefined

    return (
        <Dialog open={model.open} onOpenChange={model.onOpenChange}>
            {model.open && <ParticleAnimation />}
            <DialogContent className={styles.content}>
                <div>
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={faPartyHorn}
                    />
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xl,
                                margin: 0,
                                fontWeight: 500,
                            }}
                        >
                            {firstStepsModalState?.title}
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {firstStepsModalState?.description}
                        </DialogDescription>
                    </div>
                    <ul className={styles.earningsList}>
                        {isDefined(firstStepsTasksTotalPoints) && (
                            <li>
                                <h3 className={styles.earningItemTitle}>
                                    {t("first.steps.complete.earnings.title", {
                                        totalPoints: firstStepsTasksTotalPoints,
                                    })}
                                </h3>
                                <p className={styles.earningItemDescription}>
                                    {t(
                                        "first.steps.complete.earnings.description",
                                    )}
                                </p>
                            </li>
                        )}
                        <li>
                            <h3 className={styles.earningItemTitle}>
                                {t("first.steps.complete.reward.text", {
                                    bonusPoints: BONUS_POINTS,
                                })}
                            </h3>
                            <p className={styles.earningItemDescription}>
                                {t("first.steps.complete.earnings.description")}
                            </p>
                        </li>
                    </ul>
                    <div className={styles.rewardContainer}>
                        {isDefined(firstStepsTasksTotalPoints) && (
                            <p className={styles.rewardText}>
                                {t("first.steps.complete.reward.text", {
                                    bonusPoints:
                                        BONUS_POINTS +
                                        firstStepsTasksTotalPoints,
                                })}
                            </p>
                        )}
                    </div>
                    <div className={styles.hintContainer}>
                        <h3 className={styles.hintTitle}>
                            {t("first.steps.complete.hint.title")}
                        </h3>
                        <p className={styles.hintDescription}>
                            {t("first.steps.complete.hint.description")}
                        </p>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <Button
                            className={styles.button}
                            variant="medium"
                            onClick={() => {
                                model.onOpenChange(false)
                            }}
                            asChild
                        >
                            <Link
                                to="/app/settings/rewards"
                                search={{ currentTab: "get-started" }}
                            >
                                {t("first.steps.complete.continue")}
                            </Link>
                        </Button>
                        <Button
                            onClick={() => {
                                model.onOpenChange(false)
                            }}
                            variant="ghost"
                        >
                            {t("first.steps.complete.close")}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
