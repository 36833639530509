import { includes, some } from "lodash"
import { useEffect, useState } from "react"

export type DevicePlatform = "android" | "ios" | "macos" | "unknown"
export type DeviceBrowser =
    | "chrome"
    | "safari"
    | "firefox"
    | "edge"
    | "opera"
    | "unknown"

export const useDeviceInfo = () => {
    const [isStandalone, setIsStandalone] = useState(false)
    const [isStandaloneLoading, setIsStandaloneLoading] = useState(true)
    const [platform, setPlatform] = useState<DevicePlatform>("unknown")
    const [browser, setBrowser] = useState<DeviceBrowser>("unknown")
    const [isDesktop, setIsDesktop] = useState(false)

    const checkStandalone = () => {
        const inStandalone =
            // iOS
            !!(window.navigator as any).standalone ||
            // Android
            window.matchMedia("(display-mode: standalone)").matches ||
            // fallback
            (window.innerHeight === window.outerHeight &&
                window.innerWidth === window.outerWidth)

        setIsStandalone(inStandalone)
        setIsStandaloneLoading(false)
    }

    const detectPlatform = (): DevicePlatform => {
        if (navigator.userAgentData) {
            const plat = navigator.userAgentData.platform.toLowerCase()
            if (includes(plat, "android")) return "android"
            if (includes(plat, "ios")) return "ios"
            if (includes(plat, "macos")) return "macos"
            return "unknown"
        }
        const ua = navigator.userAgent.toLowerCase()
        if (includes(ua, "android")) return "android"
        if (includes(ua, "iphone") || includes(ua, "ipad")) return "ios"
        return "unknown"
    }

    const detectBrowser = (): DeviceBrowser => {
        if (navigator.userAgentData?.brands) {
            const brands = navigator.userAgentData.brands.map(b =>
                b.brand.toLowerCase(),
            )
            if (
                some(
                    brands,
                    b => includes(b, "google chrome") || includes(b, "chrome"),
                )
            )
                return "chrome"
            if (some(brands, b => includes(b, "safari"))) return "safari"
            if (some(brands, b => includes(b, "firefox"))) return "firefox"
            if (some(brands, b => includes(b, "edge"))) return "edge"
            if (some(brands, b => includes(b, "opera"))) return "opera"
            return "unknown"
        }

        const ua = navigator.userAgent.toLowerCase()
        if (includes(ua, "edg/") || includes(ua, "edge")) return "edge"
        if (includes(ua, "firefox")) return "firefox"
        if (includes(ua, "opr") || includes(ua, "opera")) return "opera"
        if (
            (includes(ua, "chrome") ||
                includes(ua, "crios") ||
                includes(ua, "chromium")) &&
            !includes(ua, "edg/") &&
            !includes(ua, "opr") &&
            !includes(ua, "samsungbrowser")
        )
            return "chrome"
        if (includes(ua, "safari") && !includes(ua, "chrome")) return "safari"

        return "unknown"
    }

    const isMobile = (): boolean =>
        /android|iphone|ipad/i.test(navigator.userAgent)

    const updateDesktopStatus = () => setIsDesktop(!isMobile())

    useEffect(() => {
        checkStandalone()
        updateDesktopStatus()
        setPlatform(detectPlatform())
        setBrowser(detectBrowser())
    }, [])

    return {
        isStandalone,
        isStandaloneLoading,
        platform,
        browser,
        isDesktop,
        isApple:
            platform === "ios" ||
            platform === "macos" ||
            platform === "unknown",
        isAndroid: platform === "android",
    }
}
