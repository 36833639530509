import { createFileRoute } from "@tanstack/react-router"
import { ManageSubscriptionPage } from "features/payment/manage-subscription-page"
import { useTranslation } from "react-i18next"

export const Route = createFileRoute(
    "/_authenticated/app/settings/monetization/manage-subscription/",
)({
    component: RouteComponent,
})

function RouteComponent() {
    const { t } = useTranslation("settingsMonetization")

    return (
        <ManageSubscriptionPage
            loc={{
                title: t("manageSubscriptionModal.title"),
                description: t("manageSubscriptionModal.description"),
                submit: t("manageSubscriptionModal.submit"),
                cancel: t("manageSubscriptionModal.cancel"),
                form: {
                    title: t("manageSubscriptionModal.form.title"),
                    placeholder: t("manageSubscriptionModal.form.placeholder"),
                    description: t("manageSubscriptionModal.form.description"),
                    infos: [
                        t("manageSubscriptionModal.form.infos.0"),
                        t("manageSubscriptionModal.form.infos.1"),
                        t("manageSubscriptionModal.form.infos.2"),
                    ],
                    accountValidation: {
                        title: t(
                            "manageSubscriptionModal.form.accountValidation.title",
                        ),
                        success: t(
                            "manageSubscriptionModal.form.accountValidation.success",
                        ),
                        failure: t(
                            "manageSubscriptionModal.form.accountValidation.failure",
                        ),
                    },
                    price: {
                        set: t("manageSubscriptionModal.form.price.set"),
                        info: t("manageSubscriptionModal.form.price.info"),
                    },
                },
            }}
        />
    )
}
