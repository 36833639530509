import { createFileRoute } from "@tanstack/react-router"
import { notValidRouteBeforeLoad } from "utils/router"

export const Route = createFileRoute("/_authenticated/app/followers/overview/")(
    {
        component: RouteComponent,
        beforeLoad: notValidRouteBeforeLoad,
    },
)

function RouteComponent() {
    return <></>
}
