import { QueryClient, queryOptions } from "@tanstack/react-query"
import { UserProfile } from "api/api-models"
import { mapToMatchFilterDc } from "api/api-utils"
import { UserProfileId } from "api/branded-types"
import { userProfileSelectorClient } from "api/clients/user-profile-api-client"
import { QueryKeys } from "api/query-keys"

export const listUserProfiles = async (
    ids: UserProfileId[],
    queryClient: QueryClient,
) => {
    const uniqueIds = Array.from(new Set(ids))

    const profilesRes = await userProfileSelectorClient.listProfiles({
        filter: `{${mapToMatchFilterDc("id", uniqueIds)}}`,
        paging: {
            type: "Index",
            direction: "After",
            index: -1,
            limit: uniqueIds.length,
        },
    })
    profilesRes.data.map(data =>
        queryClient.setQueryData<{ data: UserProfile; error: undefined }>(
            QueryKeys.listProfile(data.id),
            {
                data,
                error: undefined,
            },
        ),
    )
    return profilesRes.data
}

export const listUserProfilesQueryOptions = (
    pageNumber: number,
    ids: UserProfileId[],
    queryClient: QueryClient,
) =>
    queryOptions({
        queryKey: QueryKeys.listProfiles(pageNumber, ids),
        queryFn: () => listUserProfiles(ids, queryClient),
        enabled: ids.length > 0,
    })
