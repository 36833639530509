import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import classNames from "classnames"
import { Button } from "common/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogTitle,
} from "common/dialog"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import { fill } from "utils/array"
import * as styles from "./daily-login-modal.css"

type DailyLoginModalModel = {
    streak: number
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const DailyLoginModal: FC<DailyLoginModalModel> = model => {
    const { t } = useTranslation(["pointSystemInvite"])

    return (
        <Dialog open={model.open} onOpenChange={model.onOpenChange}>
            <DialogContent className={styles.content}>
                <FontAwesomeIcon
                    icon={faXmark}
                    className={styles.closeIcon}
                    onClick={() => model.onOpenChange(false)}
                />
                <div className={styles.mainContentWrapper}>
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xxxl,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            {translateTitle(model.streak)}
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {t("dailyLogin.congratulation.title")}
                        </DialogDescription>
                    </div>
                    <div className={styles.streakContainer}>
                        <Streak value={model.streak} />
                    </div>
                    <div>
                        <div className={styles.reward}>+10 Points</div>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                margin: "8px 0 0",
                            }}
                        >
                            {translateReward(model.streak)}
                        </p>
                    </div>
                </div>
                <DialogFooter className={styles.footer}>
                    <Button
                        variant="medium"
                        className={styles.button}
                        onClick={() => {
                            model.onOpenChange(false)
                        }}
                    >
                        {t("dailyLogin.congratulation.close")}
                    </Button>
                    <Button
                        asChild
                        variant="ghost"
                        className={styles.ghostButton}
                        onClick={() => model.onOpenChange(false)}
                    >
                        <Link
                            to="/app/settings/rewards"
                            search={{ currentTab: "get-started" }}
                        >
                            {t("dailyLogin.congratulation.rewards")}
                        </Link>
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

const translateTitle = (streak: number) =>
    streak !== 1 ? `${streak} days streak` : "1 day streak"

const numberWords: Record<number, string> = {
    1: "One",
    2: "Two",
    3: "Three",
    4: "Four",
    5: "Five",
    6: "Six",
    7: "Seven",
}

const translateReward = (streak: number) =>
    `${numberWords[streak]} ${streak !== 1 ? "days" : "day"} in a row - you have collected 10 points!`

type StreakItemModel = {
    value: number
    activated: boolean
}
//TODO: temporarily using css capabilities to render, but should be an svg to match with design
const StreakItem: FC<StreakItemModel> = model => (
    <div
        className={classNames(
            styles.streakItem,
            model.activated && styles.streakItemActivated,
        )}
    >
        {model.value}
    </div>
)

type StreakModel = {
    value: number
}

export const Streak: FC<StreakModel> = model => (
    <div className={styles.streakItemsContainer}>
        {fill(7, index => (
            <StreakItem
                key={index}
                value={index + 1}
                activated={index < model.value}
            />
        ))}
    </div>
)
