import { InfiniteScroll } from "common/infinite-scroll"
import { FC } from "react"
import { ResultNotFoundPlaceholderView } from "./result-not-found-placeholder-view"
import { UserSearchResultList } from "./users/list/user-search-result-list"

import { UserProfileId } from "api/branded-types"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { isEmpty } from "fp-ts/string"
import { useUserProfilesSearch } from "hooks/use-user-profiles-search"
import { match } from "ts-pattern"

type SearchPageProfileTabContentModel = {
    profileId?: UserProfileId
    searchKey: string
    debouncedSearchKey: string
    className?: string
}

export const SearchPageProfileTabContent: FC<
    SearchPageProfileTabContentModel
> = ({ searchKey, debouncedSearchKey, profileId, className }) => {
    const userProfilesSearchQuery = useUserProfilesSearch({
        searchKey: debouncedSearchKey,
        profileId,
    })

    const isSearchKeyEmpty = isEmpty(debouncedSearchKey)

    const hasAvailableUserProfiles =
        (userProfilesSearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !userProfilesSearchQuery.isPending &&
        userProfilesSearchQuery.isSuccess

    const isNoResultUser =
        !isSearchKeyEmpty &&
        !hasAvailableUserProfiles &&
        !userProfilesSearchQuery.isPending

    const userProfileState = match(userProfilesSearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    return (
        <InfiniteScroll
            disabled={!userProfilesSearchQuery.hasNextPage}
            state={userProfileState}
            threshold="100px"
            onLoadRequested={userProfilesSearchQuery.fetchNextPage}
        >
            <div className={className}>
                <UserSearchResultList
                    isLoading={
                        userProfilesSearchQuery.isFetchingNextPage ||
                        userProfilesSearchQuery.isLoading
                    }
                    profiles={pipe(
                        userProfilesSearchQuery?.data?.pages ?? [],
                        A.flatMap(page => page.data),
                    )}
                />
            </div>

            {isNoResultUser && (
                <ResultNotFoundPlaceholderView searchKey={searchKey} />
            )}
        </InfiniteScroll>
    )
}
