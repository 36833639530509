import { QueryClient, queryOptions } from "@tanstack/react-query"
import { Postit } from "api/api-models"
import { mapToMatchFilterDc } from "api/api-utils"
import { PostitId } from "api/branded-types"
import { postitSelectorClient } from "api/clients/post-api-client"
import { QueryKeys } from "api/query-keys"

export const listPosts = async (ids: PostitId[], queryClient: QueryClient) => {
    const uniqueIds = Array.from(new Set(ids))

    const postsRes = await postitSelectorClient.listPostits({
        filter: `{${mapToMatchFilterDc("id", uniqueIds)}}`,
        paging: {
            type: "Index",
            direction: "After",
            index: -1,
            limit: uniqueIds.length,
        },
    })
    postsRes.data.forEach(data =>
        queryClient.setQueryData<{ data: Postit; error: undefined }>(
            QueryKeys.postit(data.id),
            { data, error: undefined },
        ),
    )
    return postsRes.data
}

export const listPostsQueryOptions = (
    pageNumber: number,
    ids: PostitId[],
    queryClient: QueryClient,
) =>
    queryOptions({
        queryKey: QueryKeys.listPosts(pageNumber, ids),
        queryFn: () => listPosts(ids, queryClient),
        enabled: ids.length > 0,
    })
