import { faCirclePlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InfiniteData, useQueries, useQueryClient } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useState } from "react"
import { match } from "ts-pattern"

import { AccountType, Pagination, Postit, Video } from "../../../api/api-models"
import {
    getMergedProfileIdPages,
    mergeSortedInfiniteData,
} from "../../../api/api-utils"
import { UserProfileId } from "../../../api/branded-types"
import { fill } from "../../../utils/array"
import {
    PostAreaTile,
    isPostit,
    isVideo,
} from "../../feed/areas/post/post-area-tile"
import {
    VideoAreaTile,
    VideoAreaTileModel,
} from "../../feed/areas/videos/video-area-tile"
import { PostLoadingTile } from "../../search/videos/post-loading-tile"
import { useProfilePostContent } from "../hooks/use-profile-post-content"
import { useProfileVideoContent } from "../hooks/use-profile-video-content"

import { Button } from "common/button"
import { assetsQueryOptions } from "hooks/use-asset"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import { InfiniteScroll } from "../../../common/infinite-scroll"
import * as styles from "../my-user-profile-page.css"

export type MyUserProfilePageTextModel = {
    title: string
    subtitle: string
    action: string
}

export type InfiniteVideosData = InfiniteData<{
    data: Video[]
    paging: Pagination
}>

export type InfinitePostsData = InfiniteData<{
    data: Postit[]
    paging: Pagination
}>

type Props = {
    loc: VideoAreaTileModel["loc"]
    profileId?: UserProfileId
    accountType: AccountType
    text: MyUserProfilePageTextModel
}

export const MyUserProfilePostContent: FC<Props> = model => {
    const openModal = useAppStore(store => store.openModal)

    const myProfileVideoContentQuery = useProfileVideoContent(model.profileId)
    const myProfilePostContentQuery = useProfilePostContent(model.profileId)

    const myProfileVideoItems =
        myProfileVideoContentQuery.data?.pages.flatMap(
            page => page.data ?? [],
        ) ?? []
    const myProfilePostItems =
        myProfilePostContentQuery.data?.pages.flatMap(
            page => page.data ?? [],
        ) ?? []

    const startingValue =
        myProfileVideoItems.length + myProfilePostItems.length > 100
            ? myProfileVideoItems.length + myProfilePostItems.length
            : 100

    const [desiredAmount, setDesiredAmount] = useState(startingValue)

    const data = mergeSortedInfiniteData({
        firstItems: myProfileVideoItems,
        secondItems: myProfilePostItems,
        firstHasNextPage: myProfileVideoContentQuery.hasNextPage,
        secondHasNextPage: myProfilePostContentQuery.hasNextPage,
        desiredAmount,
    })

    const queryClient = useQueryClient()

    const postResourceAssetRefsPages = pipe(
        myProfilePostContentQuery.data?.pages ?? [],

        A.map(page =>
            pipe(
                page.data,
                A.filter(post => post.type === "Image"),
                A.map(post => post.imageRef),
            ),
        ),
    )

    const assetQueries = useQueries({
        queries: postResourceAssetRefsPages.map((page, idx) =>
            assetsQueryOptions({
                pageNumber: idx,
                queryClient,
                resources: page,
            }),
        ),
    })

    const oneOfAssetQueriesLoading = assetQueries.some(query => query.isLoading)

    const profileIdsPages = getMergedProfileIdPages(
        myProfileVideoContentQuery.data?.pages ?? [],
        myProfilePostContentQuery.data?.pages ?? [],
    )

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const videosState = match(myProfileVideoContentQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const postsState = match(myProfilePostContentQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const contentState =
        videosState === "Loaded" && postsState === "Loaded"
            ? "Loaded"
            : videosState === "Failed" || postsState === "Failed"
              ? "Failed"
              : videosState === "Loading" || postsState === "Loading"
                ? "Loading"
                : "Unloaded"

    const showLoadingState =
        contentState === "Loading" ||
        oneOfAssetQueriesLoading ||
        oneOfProfileQueriesLoading

    return pipe(
        contentState,
        O.fromPredicate(state => state !== "Unloaded"),
        O.fold(
            () => <></>,
            () =>
                pipe(
                    contentState,
                    O.fromPredicate(state => state === "Loaded"),
                    O.chain(O.fromPredicate(() => data.length === 0)),
                    O.fold(
                        () => (
                            <div className={styles.postContent}>
                                <InfiniteScroll
                                    disabled={
                                        !myProfileVideoContentQuery.hasNextPage &&
                                        !myProfilePostContentQuery.hasNextPage
                                    }
                                    state={contentState}
                                    threshold="100px"
                                    onLoadRequested={() => {
                                        setDesiredAmount(prev => prev + 50)
                                        if (
                                            myProfileVideoContentQuery.hasNextPage
                                        ) {
                                            myProfileVideoContentQuery.fetchNextPage()
                                        }

                                        if (
                                            myProfilePostContentQuery.hasNextPage
                                        ) {
                                            myProfilePostContentQuery.fetchNextPage()
                                        }
                                    }}
                                >
                                    {
                                        //TODO: this is not correct, data should be paginated. But currently there is
                                        // no way to properly merge infinite query data in a manageable way :(
                                        profileQueries.some(
                                            query => !query.isLoading,
                                        ) &&
                                            assetQueries.some(
                                                query => !query.isLoading,
                                            ) &&
                                            pipe(
                                                data,
                                                A.map(post =>
                                                    pipe(
                                                        post,
                                                        O.fromPredicate(
                                                            isVideo,
                                                        ),
                                                        O.fold(
                                                            () =>
                                                                pipe(
                                                                    post,
                                                                    O.fromPredicate(
                                                                        isPostit,
                                                                    ),
                                                                    O.fold(
                                                                        () =>
                                                                            null,
                                                                        post => (
                                                                            <PostAreaTile
                                                                                key={
                                                                                    post.id
                                                                                }
                                                                                loc={{
                                                                                    title: "Exclusive Post",
                                                                                }}
                                                                                postId={
                                                                                    post.id
                                                                                }
                                                                            />
                                                                        ),
                                                                    ),
                                                                ),
                                                            video => (
                                                                <VideoAreaTile
                                                                    key={
                                                                        video.id
                                                                    }
                                                                    loc={
                                                                        model.loc
                                                                    }
                                                                    videoId={
                                                                        video.id
                                                                    }
                                                                />
                                                            ),
                                                        ),
                                                    ),
                                                ),
                                            )
                                    }
                                    {showLoadingState &&
                                        fill(3, idx => (
                                            <PostLoadingTile key={idx} />
                                        ))}
                                </InfiniteScroll>
                            </div>
                        ),
                        () => (
                            <div className={styles.infoBox}>
                                <div className={styles.infoBoxText}>
                                    <p
                                        className={styles.infoBoxTitle}
                                        style={{ fontSize: vars.font.size.m }}
                                    >
                                        {model.text.title}
                                    </p>

                                    <p
                                        className={styles.infoBoxSubtitle}
                                        style={{
                                            fontSize: vars.font.size.regular,
                                        }}
                                    >
                                        {model.text.subtitle}
                                    </p>
                                </div>

                                <Button
                                    variant="primary"
                                    className={styles.createPostButton}
                                    onClick={() =>
                                        openModal({ type: "CreateContentMenu" })
                                    }
                                >
                                    <FontAwesomeIcon
                                        className={styles.createPostIcon}
                                        icon={faCirclePlus}
                                        color="black"
                                    />
                                    {model.text.action}
                                </Button>
                            </div>
                        ),
                    ),
                ),
        ),
    )
}
