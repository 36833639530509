import { getIsAuthorizedAccount } from "api/api-utils"
import { UserProfileId } from "api/branded-types"
import { useNotificationMessagesHub } from "features/notifications/hooks/use-notifications-hub"
import { useProfileStatistics } from "features/user-profile/hooks/use-profile-statistic"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useMe } from "hooks/use-me"
import { useModalState } from "hooks/use-modal-state"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { useProfileChallengesOverview } from "hooks/use-profile-challenges-overview"
import { LocalStorage } from "local-storage"
import { FC, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAppStore } from "store"
import { ChallengeCompleteModal } from "./challenge-complete-modal"
import { CongratulationBronzeModal } from "./congratulation/congratulation-bronze-modal"
import { CongratulationGoldModal } from "./congratulation/congratulation-gold-modal"
import { CongratulationPlatinumModal } from "./congratulation/congratulation-platinum-modal"
import { CongratulationSilverModal } from "./congratulation/congratulation-silver-modal"
import { CreateContentCompleteModal } from "./create-content-complete-modal"
import { FirstStepsCompleteModal } from "./first-steps-complete-modal"
import { FirstStepsWelcomeModal } from "./first-steps-welcome-modal"
import { DailyLoginModal } from "./repeated-tasks/daily-login-modal"
import { useModalQueueStore as useModalQueueSystemStore } from "./store/modal-queue-system-store"
import { usePointSystemModalStore } from "./store/point-system-modal-store"

export const PointSystemModalProvider: FC = () => {
    const meQuery = useMe()
    const openModal = useAppStore(store => store.openModal)
    const closeModal = useAppStore(store => store.closeModal)
    const isModalVisible = useAppStore(store => store.isModalVisible)
    const { isModalSkipped } = useModalState()
    const { handleCloseModal } = useModalQueueSystemStore()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => "Guest" as const),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const myProfileIdQuery = useMyUserProfileId(isAuthorizedAccount)

    const profileChallengesOverviewQuery = useProfileChallengesOverview(
        myProfileIdQuery.data,
    )

    const createContentCompleteModalOpen = usePointSystemModalStore(
        store => store.createContentCompleteModalOpen,
    )
    const setCreateContentCompleteModalOpen = usePointSystemModalStore(
        store => store.setCreateContentCompleteModalOpen,
    )

    const challengeCompleteModalOpen = usePointSystemModalStore(
        store => store.challengeCompleteModalOpen,
    )
    const setChallengeCompleteModalOpen = usePointSystemModalStore(
        store => store.setChallengeCompleteModalOpen,
    )

    const firstStepsModalOpen = usePointSystemModalStore(
        store => store.firstStepsModalOpen,
    )
    const setFirstStepsModalOpen = usePointSystemModalStore(
        store => store.setFirstStepsModalOpen,
    )

    const dailyStreakModalOpen = usePointSystemModalStore(
        store => store.dailyStreakModalOpen,
    )
    const setDailyStreakModalOpen = usePointSystemModalStore(
        store => store.setDailyStreakModalOpen,
    )

    const setLevelUpModalOpen = usePointSystemModalStore(
        store => store.setLevelUpModalOpen,
    )

    const firstStepsNotCompleted = useMemo(
        () =>
            profileChallengesOverviewQuery.isSuccess &&
            !profileChallengesOverviewQuery.data.firstSteps.every(
                task => task.progress.required === task.progress.done,
            ),
        [profileChallengesOverviewQuery.status],
    )

    useEffect(() => {
        if (!firstStepsNotCompleted) return
        if (isModalSkipped("FirstStepsWelcome")) return

        openModal({ type: "FirstWelcome" })
    }, [firstStepsNotCompleted, openModal])

    useNotificationMessagesHub()

    return (
        <>
            <CreateContentCompleteModal
                open={createContentCompleteModalOpen}
                onOpenChange={open => {
                    setCreateContentCompleteModalOpen(open)
                    if (!open) handleCloseModal()
                }}
            />

            <ChallengeCompleteModal
                open={challengeCompleteModalOpen}
                onOpenChange={open => {
                    setChallengeCompleteModalOpen(open)
                    if (!open) handleCloseModal()
                }}
            />

            <FirstStepsCompleteModal
                open={firstStepsModalOpen}
                onOpenChange={open => {
                    setFirstStepsModalOpen(open)
                    if (!open) handleCloseModal()
                }}
            />
            <FirstStepsWelcomeModal
                open={isModalVisible.FirstWelcome.visible}
                onOpenChange={() => closeModal("FirstWelcome")}
            />
            <DailyLoginModal
                open={dailyStreakModalOpen}
                onOpenChange={open => {
                    setDailyStreakModalOpen(open)
                    if (!open) handleCloseModal()
                }}
                streak={4}
            />
            <CongratulationModalProvider
                profileId={myProfileIdQuery.data}
                onOpenChange={open => {
                    setLevelUpModalOpen(open)
                    if (!open) handleCloseModal()
                }}
            />
        </>
    )
}

type CongratulationModalProviderModel = {
    profileId?: UserProfileId
    onOpenChange: (v: boolean) => void
}

const CongratulationModalProvider: FC<
    CongratulationModalProviderModel
> = model => {
    const { t } = useTranslation(["modal"])

    const levelUpModalOpen = usePointSystemModalStore(
        store => store.levelUpModalOpen,
    )
    const profileStatisticsQuery = useProfileStatistics(model.profileId)

    const isBronzeOpen =
        levelUpModalOpen &&
        profileStatisticsQuery.isSuccess &&
        profileStatisticsQuery.data.currentTier.type === "Bronze"
    const isSilverOpen =
        levelUpModalOpen &&
        profileStatisticsQuery.isSuccess &&
        profileStatisticsQuery.data.currentTier.type === "Silver"
    const isGoldOpen =
        levelUpModalOpen &&
        profileStatisticsQuery.isSuccess &&
        profileStatisticsQuery.data.currentTier.type === "Gold"
    const isPlatinumOpen =
        levelUpModalOpen &&
        profileStatisticsQuery.isSuccess &&
        profileStatisticsQuery.data.currentTier.type === "Platinum"

    return (
        <>
            <CongratulationBronzeModal
                open={isBronzeOpen}
                onOpenChange={model.onOpenChange}
                loc={{
                    title: t("congratulation.title"),
                    congratulations: t("congratulation.congratulations"),
                    descriptionA: t("congratulation.description.start"),
                    descriptionB: "Bronze level.",
                    points: t("congratulation.points"),
                    message: t("congratulation.bronze.description"),
                    benefits: {
                        title: t("congratulation.bronze.benefits.title"),
                        descriptions: [
                            t("congratulation.bronze.benefits.description1"),
                            t("congratulation.bronze.benefits.description2"),
                            t("congratulation.bronze.benefits.description3"),
                            t("congratulation.bronze.benefits.description4"),
                        ],
                    },
                    close: t("congratulation.close"),
                    confirm: t("congratulation.confirm"),
                }}
            />

            <CongratulationSilverModal
                open={isSilverOpen}
                onOpenChange={model.onOpenChange}
                loc={{
                    title: t("congratulation.title"),
                    congratulations: t("congratulation.congratulations"),
                    descriptionA: t("congratulation.description.start"),
                    descriptionB: "Silver level.",
                    points: t("congratulation.points"),
                    message: t("congratulation.silver.description"),
                    benefits: {
                        title: t("congratulation.silver.benefits.title"),
                        descriptions: [
                            t("congratulation.silver.benefits.description1"),
                            t("congratulation.silver.benefits.description2"),
                            t("congratulation.silver.benefits.description3"),
                            t("congratulation.silver.benefits.description4"),
                        ],
                    },
                    close: t("congratulation.close"),
                    confirm: t("congratulation.confirm"),
                }}
            />

            <CongratulationGoldModal
                open={isGoldOpen}
                onOpenChange={model.onOpenChange}
                loc={{
                    title: t("congratulation.title"),
                    congratulations: t("congratulation.congratulations"),
                    descriptionA: t("congratulation.description.start"),
                    descriptionB: "Gold level.",
                    points: t("congratulation.points"),
                    message: t("congratulation.gold.description"),
                    benefits: {
                        title: t("congratulation.gold.benefits.title"),
                        descriptions: [
                            t("congratulation.gold.benefits.description1"),
                            t("congratulation.gold.benefits.description2"),
                            t("congratulation.gold.benefits.description3"),
                            t("congratulation.gold.benefits.description4"),
                        ],
                    },
                    close: t("congratulation.close"),
                    confirm: t("congratulation.confirm"),
                }}
            />

            <CongratulationPlatinumModal
                open={isPlatinumOpen}
                onOpenChange={model.onOpenChange}
                loc={{
                    title: t("congratulation.title"),
                    congratulations: t("congratulation.congratulations"),
                    descriptionA: t("congratulation.description.start"),
                    descriptionB: "Platinum level.",
                    points: t("congratulation.points"),
                    message: t("congratulation.platinum.description"),
                    benefits: {
                        title: t("congratulation.platinum.benefits.title"),
                        descriptions: [
                            t("congratulation.platinum.benefits.description1"),
                            t("congratulation.platinum.benefits.description2"),
                            t("congratulation.platinum.benefits.description3"),
                            t("congratulation.platinum.benefits.description4"),
                        ],
                    },
                    close: t("congratulation.close"),
                    confirm: t("congratulation.confirm"),
                }}
            />
        </>
    )
}
