import { FC } from "react"

import { useLiveNowEvents } from "../../../../hooks/use-live-now-events"
import { LiveNowAreaPlaceholder } from "./live-now-area-placeholder"
import { LiveNowAreaTile } from "./live-now-area-tile"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./live-now-area-view.css"

export type LiveNowAreaViewModel = {
    text: {
        titleA: string
        titleB: string
        placeholder: string
        action: string
        userDetails: {
            liveState: string
        }
        account: {
            title: string
            message: string
            cancelTitle: string
            createAccountTitle: string
        }
    }
    onGoLiveClicked?: () => void
}

export const LiveNowAreaView: FC<LiveNowAreaViewModel> = ({
    text,
    onGoLiveClicked,
}) => {
    const liveNowEventsQuery = useLiveNowEvents()

    const queryClient = useQueryClient()

    const profileIdsPages =
        liveNowEventsQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const hasAvailableLiveEvents =
        (liveNowEventsQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !liveNowEventsQuery.isPending &&
        liveNowEventsQuery.isSuccess

    const showLoadingState =
        oneOfProfileQueriesLoading ||
        (liveNowEventsQuery.isPending && !liveNowEventsQuery.isPlaceholderData)

    return (
        <>
            {showLoadingState && <LiveNowAreaViewLoading />}

            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h1
                        style={{
                            fontSize: vars.font.size.l,
                            color: "white",
                        }}
                    >
                        <span
                            style={{
                                marginLeft: 4,
                                color: vars.color.liveColor.hex,
                                fontSize: vars.font.size.l,
                            }}
                        >
                            {text.titleA}
                        </span>
                        {` ${text.titleB}`}
                    </h1>
                </div>

                <div className={styles.content}>
                    {hasAvailableLiveEvents &&
                        liveNowEventsQuery.data.pages.map(
                            (page, idx) =>
                                !profileQueries.at(idx)?.isLoading &&
                                page.data.map(event => (
                                    <LiveNowAreaTile
                                        key={event.id}
                                        text={text.userDetails}
                                        liveEventId={event.id}
                                    />
                                )),
                        )}

                    {!hasAvailableLiveEvents &&
                        !liveNowEventsQuery.isPending && (
                            <LiveNowAreaPlaceholder
                                description={text.placeholder}
                                action={text.action}
                                onClicked={onGoLiveClicked}
                            />
                        )}
                </div>
            </div>
        </>
    )
}

export const LiveNowAreaViewLoading = () => (
    <div className={styles.placeholder}>
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div
                    style={{
                        width: 150,
                        height: 26,
                        borderRadius: 24,
                        backgroundColor: vars.color.dark.hex,
                    }}
                />
            </div>

            <div className={styles.content}>
                <div className={styles.loadingPlaceholderContainer}>
                    <Skeleton height={26} width={100} borderRadius={10} />
                </div>
            </div>
        </div>
    </div>
)
