import { makeApi } from "@zodios/core"
import z from "zod"

import {
    ApiError,
    BuyVideoByIdRequestBody,
    CreateContentReport,
    CreateVideo,
    CreateVideoResponse,
    Report,
    UpdateVideo,
    UpdateVideoResponse,
} from "../api-models"
import { Url, VideoId } from "../branded-types"

export const videoCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/video",
        alias: "createVideoUpload",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: CreateVideo,
            },
        ],
        response: CreateVideoResponse,
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/video/:id",
        alias: "updateVideoUploadById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: VideoId,
            },
            {
                name: "body",
                type: "Body",
                schema: UpdateVideo,
            },
        ],
        response: UpdateVideoResponse,
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/video/:id",
        alias: "deleteVideoById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: VideoId,
            },
        ],
        response: z.string(),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/video/:id/buy",
        alias: "buyVideoById",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: BuyVideoByIdRequestBody,
            },
        ],
        response: z.object({
            data: Url,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/video/:id/report",
        alias: "reportVideoById",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: CreateContentReport,
            },
        ],
        response: z.object({
            data: Report,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
