import { PaymentId } from "api/branded-types"
import { usePaymentById } from "hooks/use-payment-by-id"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { PostAreaTile } from "../post/post-area-tile"
import { VideoAreaTile } from "../videos/video-area-tile"

type PaymentAreaTileModel = {
    paymentId: PaymentId
}

export const PaymentAreaTile: FC<PaymentAreaTileModel> = ({ paymentId }) => {
    const paymentQuery = usePaymentById(paymentId)
    const { t } = useTranslation(["myProfile"])

    if (!paymentQuery.isSuccess) {
        return null
    }

    const { contentRef } = paymentQuery.data
    const { contentType, contentId } = contentRef

    const loc = {
        payed: t("post.payed"),
        title: t("post.exclusive"),
    }

    if (contentType === "Video") {
        return <VideoAreaTile videoId={contentId} loc={loc} />
    }

    if (contentType === "Postit") {
        return <PostAreaTile postId={contentId} loc={{ title: loc.title }} />
    }

    return null
}
