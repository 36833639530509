import { UserProfileId } from "api/branded-types"
import { AppState } from "./state"

import { Except } from "type-fest"
import { StateCreator } from "zustand"

export type ModalTypes = { visible: boolean } & (
    | { type: "ContinueRegistrationByEmail" }
    | { type: "LoginOrRegister"; profileId?: UserProfileId }
    | { type: "CreateContentMenu" }
    | { type: "FirstStepsWelcome" }
    | { type: "FindOutMore" }
    | { type: "StandaloneWelcome" }
    | { type: "FirstWelcome" }
    | { type: "EnableNotifications" }
)

export type ModalType = ModalTypes["type"]

export type AppActions = {
    openModal: (modal: Except<ModalTypes, "visible">) => void
    closeModal: (type: ModalType) => void

    setScrollSearchToTop: (fn: () => void) => void
    scrollSearchToTop: () => void

    setScrollFeedToTop: (fn: () => void) => void
    scrollFeedToTop: () => void

    setSelectedProfileImage: (file: File) => void
    removeSelectedProfileImage: () => void
}

export const createAppStore: StateCreator<AppState & AppActions> = set => ({
    isModalVisible: {
        ContinueRegistrationByEmail: { visible: false },
        LoginOrRegister: { visible: false },
        CreateContentMenu: { visible: false },
        FirstStepsWelcome: { visible: false },
        FindOutMore: { visible: false },
        StandaloneWelcome: { visible: true },
        FirstWelcome: { visible: false },
        EnableNotifications: { visible: false },
    },

    openModal: (modal: Except<ModalTypes, "visible">) =>
        set(state => ({
            isModalVisible: {
                ...state.isModalVisible,
                [modal.type]: {
                    ...state.isModalVisible[modal.type],
                    ...modal,
                    visible: true,
                },
            },
        })),
    closeModal: (type: ModalTypes["type"]) =>
        set(state => ({
            isModalVisible: {
                ...state.isModalVisible,
                [type]: {
                    ...state.isModalVisible[type],
                    visible: false,
                },
            },
        })),

    scrollFeedToTop: () => {},
    setScrollFeedToTop: fun => set({ scrollFeedToTop: fun }),

    scrollSearchToTop: () => {},
    setScrollSearchToTop: fun => set({ scrollSearchToTop: fun }),

    setSelectedProfileImage: file => set({ selectedProfileImage: file }),
    removeSelectedProfileImage: () => set({ selectedProfileImage: undefined }),
})
