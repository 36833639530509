import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "common/dialog"
import { FC } from "react"

import { faEye } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { vars } from "theme/variables.css"
import * as styles from "./content-review-modal.css"

export type ContentReviewModalModel = {
    open: boolean
    onOpenChange: (v: boolean) => void
    reasons: string[]
    loc: {
        title: string
        descriptionFirst: string
        descriptionSecond: string
        disclaimerFirst: string
        disclaimerSecond: string
        reasons: string
        action: string
        policyAction: string
    }
}

export const ContentReviewModal: FC<ContentReviewModalModel> = ({
    loc,
    reasons,
    open,
    onOpenChange,
}) => {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={styles.content}>
                <FontAwesomeIcon icon={faEye} className={styles.icon} />
                <DialogHeader className={styles.header}>
                    <DialogTitle className={styles.title}>
                        {loc.title}
                    </DialogTitle>
                    <DialogDescription className={styles.description}>
                        {loc.descriptionFirst}{" "}
                        <Link
                            className={styles.link}
                            to="/registration/terms-and-condition"
                        >
                            {loc.descriptionSecond}
                        </Link>
                        {"."}
                    </DialogDescription>
                </DialogHeader>
                <div>
                    {reasons.length > 0 && (
                        <div className={styles.reasonsContainer}>
                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    fontWeight: 700,
                                    color: vars.color.medium.hex,
                                }}
                            >
                                {loc.reasons}
                            </p>
                            <ul className={styles.reasonList}>
                                {reasons.map((reason, idx) => (
                                    <li
                                        className={styles.reasonListItem}
                                        key={idx + reason}
                                    >
                                        {reason}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <p
                        style={{
                            fontSize: vars.font.size.xs,
                            color: vars.color.tertiary.hex,
                            marginInline: 8,
                        }}
                    >
                        <span
                            style={{
                                fontSize: vars.font.size.xs,
                                fontWeight: 700,
                            }}
                        >
                            {loc.disclaimerFirst}
                        </span>{" "}
                        {loc.disclaimerSecond}
                    </p>
                </div>
                <DialogFooter className={styles.footer}>
                    <DialogClose asChild>
                        <Button>{loc.action}</Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button
                            className={styles.ghostButton}
                            variant="ghost"
                            asChild
                        >
                            <Link to="/registration/terms-and-condition">
                                {loc.policyAction}
                            </Link>
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
