import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"
import { match } from "ts-pattern"

import { VotedResourceRef } from "../../api/api-models"
import {
    UserListItem,
    UserListLoadingItem,
} from "../../features/feed/areas/user/user-list-item"
import { useResourceVotes } from "../../features/video/hooks/use-resource-votes"
import { fill } from "../../utils/array"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQueries, useQueryClient } from "@tanstack/react-query"
import { UserProfileId } from "api/branded-types"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { InfiniteScroll } from "../../common/infinite-scroll"
import * as styles from "./voter-overview-modal.css"

export type VoterOverviewPageModel = {
    title: string
    open: boolean
    voteContentRef: VotedResourceRef
    onOpenChange: (v: boolean) => void
    onUserClicked: () => void
}
//TODO: find a way to impl. refresh functionality in `Drawer` without triggering close (or a workaround)
//TODO: find a way to impl. condensed header in the `Drawer`
export const VoterOverviewModal: FC<VoterOverviewPageModel> = ({
    title,
    open,
    voteContentRef,

    onOpenChange,
    onUserClicked,
}) => {
    const queryClient = useQueryClient()

    const resourceVotesQuery = useResourceVotes(voteContentRef)

    const profileIdsPages =
        resourceVotesQuery.data?.pages.map(page =>
            page.data
                .filter(item => item.voter.type === "UserProfile")
                .map(item => item.voter.id as UserProfileId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const votesState = match(resourceVotesQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const showLoadingState =
        votesState === "Loading" || oneOfProfileQueriesLoading

    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent className={styles.modal}>
                <DrawerClose asChild>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className={styles.closeIcon}
                    />
                </DrawerClose>
                <div className={styles.content}>
                    <div>
                        <DrawerTitle className={styles.title}>
                            {title}
                        </DrawerTitle>
                        <DrawerDescription />
                    </div>
                    <InfiniteScroll
                        disabled={!resourceVotesQuery.hasNextPage}
                        state={votesState}
                        onLoadRequested={resourceVotesQuery.fetchNextPage}
                        threshold="100px"
                    >
                        <div className={styles.contentBox}>
                            {resourceVotesQuery.data?.pages.map(
                                (page, idx) =>
                                    !profileQueries.at(idx)?.isLoading &&
                                    page.data.map(vote =>
                                        vote.voter.type === "UserProfile" ? (
                                            <UserListItem
                                                className={styles.userListItem}
                                                key={vote.id}
                                                profileId={vote.voter.id}
                                                avatarSize={40}
                                                onClicked={onUserClicked}
                                            />
                                        ) : null,
                                    ),
                            )}
                            {showLoadingState &&
                                fill(5, i => (
                                    <UserListLoadingItem
                                        key={i}
                                        className={styles.userListItem}
                                    />
                                ))}
                        </div>
                    </InfiniteScroll>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
