import classNames from "classnames"
import { FC } from "react"

import { SeeMoreTextViewModal } from "../../../components/texts/see-more-text-view-modal"

import { faEye } from "@fortawesome/pro-light-svg-icons"
import { faBan } from "@fortawesome/pro-regular-svg-icons"
import { useSuspenseQuery } from "@tanstack/react-query"
import { useParams } from "@tanstack/react-router"
import { Badge } from "common/badge"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { videoByIdQueryOptions } from "../hooks/use-video-by-id"
import * as styles from "./video-details.css"

export type VideoDetailsModel = {
    className?: string
    textClassName?: string
    text: {
        seeMore: string
    }
}

export const VideoDetails: FC<VideoDetailsModel> = ({
    className = "",
    textClassName = "",
    text,
}) => {
    const params = useParams({ from: "/_authenticated/app/video/viewer/$id" })
    const videoQuery = useSuspenseQuery(videoByIdQueryOptions(params.id))
    const video = videoQuery.data

    const { toast } = useInfoToast()
    const { t } = useTranslation("videoViewer")

    const isInReview = videoQuery.data?.status === "InReview"
    const isBlocked =
        videoQuery.data?.status === "Rejected" ||
        (videoQuery.data?.status === "None" && videoQuery.data.isBlocked)

    const hasStatusBadge = isInReview || isBlocked

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.textWrapper}>
                <div className={styles.badgesContainer}>
                    {hasStatusBadge && (
                        <Badge
                            onClick={e => {
                                e.preventDefault()
                                toast({
                                    toastContent: isBlocked ? (
                                        <InfoToastContent
                                            icon={faBan}
                                            iconColor="medium"
                                            iconClassName={
                                                styles.toastBlockedIcon
                                            }
                                            text={t(
                                                "moderation.blocked.toast.text",
                                            )}
                                        />
                                    ) : (
                                        <InfoToastContent
                                            icon={faEye}
                                            iconColor="medium"
                                            iconClassName={
                                                styles.toastReviewIcon
                                            }
                                            text={t(
                                                "moderation.review.toast.text",
                                            )}
                                        />
                                    ),
                                })
                            }}
                            variant={
                                isBlocked ? "dangerOnSecondary" : "secondary"
                            }
                        >
                            {isBlocked
                                ? t("moderation.badge.rejected")
                                : t("moderation.badge.review")}
                        </Badge>
                    )}
                </div>
                <div className={styles.videoDescription}>
                    <SeeMoreTextViewModal
                        className={textClassName}
                        loc={{
                            title: video.title,
                            description: video.description ?? "",
                            seeMore: text.seeMore,
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

type VideoDetailsLoadingModel = {
    className?: string
}

export const VideoDetailsLoading: FC<VideoDetailsLoadingModel> = model => (
    <div className={classNames(styles.videoDescription, model.className)}>
        <Skeleton
            width={(window.innerWidth - 40) * 0.65}
            height={30}
            borderRadius={24}
        />
        <Skeleton
            width={(window.innerWidth - 40) * 0.35}
            height={18}
            borderRadius={24}
        />
    </div>
)
