import classNames from "classnames"
import { FC, ReactElement } from "react"
import ReactPlayer from "react-player"

import { PlayStateAdt } from "../../../components/controls/play-state-control"
import { VideoDetails, VideoDetailsModel } from "../details/video-details"
import {
    VideoConsumerControl,
    VideoConsumerControlModel,
} from "./video-consumer-control"

import { useSuspenseQuery } from "@tanstack/react-query"
import { useParams } from "@tanstack/react-router"
import { UserDetailsModel } from "../../feed/areas/user/user-details"
import { videoByIdQueryOptions } from "../hooks/use-video-by-id"
import * as styles from "./video-consumer-with-video-details-control.css"

const Details: FC<VideoDetailsModel & { className: string }> = model => (
    <VideoDetails {...model} />
)

export type VideoConsumerControlWithPlayerModel = VideoConsumerControlModel &
    VideoDetailsModel & {
        muted?: boolean
        fallback?: ReactElement
        onError: (error: unknown) => void
        onPurchaseClicked: UserDetailsModel["onPurchaseClicked"]
    }

/**
 * React player offers url examples to test the functionality.
 * https://hls-js.netlify.app/demo
 */
export const VideoConsumerControlWithPlayer: FC<
    VideoConsumerControlWithPlayerModel
> = ({
    className = "",
    muted = false,
    text,
    playState,
    fallback = <></>,
    onChanged,
    onError,
    onPurchaseClicked,
}) => {
    const params = useParams({ from: "/_authenticated/app/video/viewer/$id" })
    const videoQuery = useSuspenseQuery(videoByIdQueryOptions(params.id))
    const video = videoQuery.data
    return (
        <div className={classNames(styles.sizing, styles.wrapper, className)}>
            <VideoConsumerControl
                className={classNames(styles.wrapper, className)}
                playState={playState}
                onChanged={value =>
                    videoQuery.data.monetization.type === "SubscriptionOnly" &&
                    videoQuery.data.paymentState.type === "Required"
                        ? onPurchaseClicked()
                        : onChanged(value)
                }
            >
                <Details
                    text={text}
                    className={classNames(
                        PlayStateAdt.is.Playing(playState)
                            ? styles.fadeOutStyle
                            : "",
                        styles.details,
                    )}
                />

                <ReactPlayer
                    width="100%"
                    height="100%"
                    style={{
                        ["opacity"]: PlayStateAdt.is.Playing(playState) ? 1 : 0,
                    }}
                    playing={PlayStateAdt.is.Playing(playState)}
                    muted={muted}
                    fallback={fallback}
                    playIcon={<></>}
                    url={video.url}
                    config={{
                        file: {
                            attributes: {
                                style: {
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                },
                            },
                        },
                    }}
                    onEnded={() => onChanged(PlayStateAdt.as.Ended({}))}
                    onError={onError}
                />
            </VideoConsumerControl>
        </div>
    )
}
