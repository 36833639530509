import { InfiniteScroll } from "common/infinite-scroll"
import { FC } from "react"
import { ResultNotFoundPlaceholderView } from "./result-not-found-placeholder-view"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { isEmpty } from "fp-ts/string"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useLiveEventsSearch } from "hooks/use-live-event-search"
import { match } from "ts-pattern"
import { fill } from "utils/array"
import {
    LiveEventSearchResultLoadingTile,
    LiveEventSearchResultTile,
} from "./live-events/live-event-search-result-tile"

import { UserProfileId } from "api/branded-types"
import * as styles from "./search-sub-pages.css"

type SearchPageLiveEventTabContentModel = {
    searchKey: string
    profileId?: UserProfileId
    creatorIds: UserProfileId[]
    debouncedSearchKey: string
    className?: string
}

export const SearchPageLiveEventTabContent: FC<
    SearchPageLiveEventTabContentModel
> = ({ searchKey, debouncedSearchKey, profileId, creatorIds, className }) => {
    const liveEventQuerySearchQuery = useLiveEventsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
    })

    const queryClient = useQueryClient()

    const profileIdsPages =
        liveEventQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const isSearchKeyEmpty = isEmpty(debouncedSearchKey)

    const hasAvailableLiveEvents =
        (liveEventQuerySearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !liveEventQuerySearchQuery.isPending &&
        liveEventQuerySearchQuery.isSuccess

    const isNoResultWithSearchKey =
        !isSearchKeyEmpty &&
        !hasAvailableLiveEvents &&
        !liveEventQuerySearchQuery.isPending

    const isNoResultInitial =
        !hasAvailableLiveEvents && liveEventQuerySearchQuery.isSuccess

    const isNoResultLiveStream = isNoResultInitial || isNoResultWithSearchKey

    const liveEventState = match(liveEventQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const showLoadingState =
        liveEventQuerySearchQuery.isFetchingNextPage ||
        liveEventQuerySearchQuery.isLoading ||
        oneOfProfileQueriesLoading

    return (
        <InfiniteScroll
            disabled={!liveEventQuerySearchQuery.hasNextPage}
            state={liveEventState}
            threshold="100px"
            onLoadRequested={liveEventQuerySearchQuery.fetchNextPage}
        >
            <div className={className}>
                <div className={styles.list}>
                    {liveEventQuerySearchQuery.data?.pages.map(
                        (page, idx) =>
                            !profileQueries.at(idx)?.isLoading &&
                            page.data.map(event => (
                                <LiveEventSearchResultTile
                                    key={event.id}
                                    liveEventId={event.id}
                                    stateText={"Live"}
                                />
                            )),
                    )}
                    {showLoadingState &&
                        fill(4, idx => (
                            <LiveEventSearchResultLoadingTile key={idx} />
                        ))}
                </div>
            </div>
            {isNoResultLiveStream && (
                // TODO: add view for when there are no events at all
                // not only when they are not found by key
                <ResultNotFoundPlaceholderView searchKey={searchKey} />
            )}
        </InfiniteScroll>
    )
}
