import { Currency } from "api/api-models"
import { z } from "zod"

export const manageSubscriptionValidationSchema = z.union([
    z.object({
        enabled: z.literal(true),
        price: z.object({
            amount: z.coerce
                .number()
                .min(0.99, { message: "Price must be at least 0.99" }),
            currency: Currency,
        }),
        message: z.string().optional(),
    }),
    z.object({
        enabled: z.literal(false),
        price: z
            .object({
                amount: z.coerce
                    .number()
                    .min(0.99, { message: "Price must be at least 0.99" }),
                currency: Currency,
            })
            .optional(),
        message: z.string().optional(),
    }),
])
