import { useQuery } from "@tanstack/react-query"
import { UserProfileId } from "api/branded-types"
import { userProfileSelectorClient } from "api/clients/user-profile-api-client"
import { QueryKeys } from "api/query-keys"

export const getProfileChallengesOverviewQueryFn = async ({
    profileId,
}: {
    profileId?: UserProfileId
}) => {
    if (!profileId) throw new Error("UserProfileId should be defined")

    const response =
        await userProfileSelectorClient.getProfileChallengesOverview({
            params: { id: profileId },
        })

    return response.data
}

export const useProfileChallengesOverview = (profileId?: UserProfileId) => {
    return useQuery({
        queryFn: () => getProfileChallengesOverviewQueryFn({ profileId }),
        queryKey: QueryKeys.profileChallengesOverview(profileId),
        enabled: !!profileId,
    })
}
