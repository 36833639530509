import { ContentModerationModalProvider } from "features/content-moderation/content-moderation-modal-provider"
import { EnableNotificationsProvider } from "features/notifications/enable-notifications-provider"
import { OnboardingProvider } from "features/onboarding/onboarding-provider"
import { PaymentModalProvider } from "features/payment/modals/payment-modal-provider"
import { PointSystemModalProvider } from "features/point-system/point-system-modal-provider"
import { ReportProvider } from "features/report/report-provider"
import { ContinueRegistrationByEmailModal } from "modals/continue-registration-by-email-modal"
import { LoginOrRegisterModal } from "modals/login-or-register-modal"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "react-oidc-context"

export const ProtectedProviders: FC = () => {
    const { t } = useTranslation(["modal"])
    const { isAuthenticated } = useAuth()
    const { t: tCommon } = useTranslation(["common"])

    if (!isAuthenticated) return <></>

    return (
        <>
            <LoginOrRegisterModal
                text={{
                    title: name => tCommon("signin.modal.title", { name }),
                    message: tCommon("signin.modal.message"),
                    onSignIn: tCommon("signin.modal.onSignIn"),
                    onSignUp: tCommon("signin.modal.onSignUp"),
                }}
            />
            <ContinueRegistrationByEmailModal />
            <PointSystemModalProvider />
            <ReportProvider />
            <EnableNotificationsProvider
                loc={{
                    cancel: t("enableNotifications.cancel"),
                    submit: t("enableNotifications.submit"),
                    title: t("enableNotifications.title"),
                    description: t("enableNotifications.description"),
                }}
            />
            <PaymentModalProvider
                loc={{
                    failure: {
                        title: t("paymentModal.failure.title"),
                        message: t("paymentModal.failure.message"),
                        confirm: t("paymentModal.failure.confirm"),
                    },
                    success: {
                        title: t("paymentModal.success.title"),
                        message: t("paymentModal.success.message"),
                        confirm: t("paymentModal.success.confirm"),
                    },
                    proceedPayment: {
                        title: t("paymentModal.proceedPayment.title"),
                        descriptionA: t(
                            "paymentModal.proceedPayment.descriptionA",
                        ),
                        descriptionB: t(
                            "paymentModal.proceedPayment.descriptionB",
                        ),
                        descriptionC: t(
                            "paymentModal.proceedPayment.descriptionC",
                        ),
                        action: t("paymentModal.proceedPayment.action"),
                    },
                    proceedSubscription: {
                        title: t("paymentModal.proceedSubscription.title"),
                        descriptionA: t(
                            "paymentModal.proceedSubscription.descriptionA",
                        ),
                        descriptionB: t(
                            "paymentModal.proceedSubscription.descriptionB",
                        ),
                        descriptionC: t(
                            "paymentModal.proceedSubscription.descriptionC",
                        ),
                        action: t("paymentModal.proceedSubscription.action"),
                    },
                }}
            />
            <OnboardingProvider
                loc={{
                    title: t("onboarding.title"),
                    description: t("onboarding.description"),
                    info: t("onboarding.info"),
                    readMore: t("onboarding.readMore"),
                }}
            />
            <ContentModerationModalProvider />
        </>
    )
}
