import { faPaperPlaneTop } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import {
    FC,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { match } from "ts-pattern"
import { UserProfileId } from "../../api/branded-types"
import { Avatar } from "../../components/avatars/avatar"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { useMessageMutation } from "./hooks/use-message-mutation"
import { useMessages } from "./hooks/use-messages"
import * as styles from "./messages-to-user-profile-page.css"
import { UserMessageItem } from "./user-message-item"

import { Link, useParams } from "@tanstack/react-router"
import { getIsAuthorizedAccount } from "api/api-utils"
import classNames from "classnames"
import { Textarea } from "common/textarea"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { UserProfile } from "../../api/api-models"
import { Content } from "../../common/content"
import { Footer } from "../../common/footer"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { Toolbar } from "../../common/toolbar"
import { useSeenMessageMutation } from "./hooks/use-seen-message-mutation"
import { MyMessageItem } from "./my-message-item"

export const MessageToUserProfilePage: FC = () => {
    const params = useParams({
        from: "/_authenticated/app/message/profile/$id",
    })

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const recipientProfileQuery = useListUserProfileById(params.id)
    const recipientProfile = recipientProfileQuery.data?.data

    const { data: myProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const messagesQuery = useMessages({
        senderId: myProfileId,
        recipientId: recipientProfile?.id,
        refetchInterval: 3000,
    })
    const messagesWithUserData = useMemo(
        () =>
            pipe(
                messagesQuery.data?.pages ?? [],
                A.flatMap(page => page.data),
                A.reverse,
            ),
        [messagesQuery.data?.pages],
    )

    const { mutate: postSeenMessages } = useSeenMessageMutation(myProfileId)

    const [lastMessageId, setLastMessageId] = useState("")
    const [autoScroll, setAutoScroll] = useState(true)
    const [scrollHeight, setScrollHeight] = useState(0)

    const scrollingContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const observer = new MutationObserver(() => {
            if (!scrollingContainerRef.current) return
            setScrollHeight(scrollingContainerRef.current.scrollHeight)
        })

        if (scrollingContainerRef.current) {
            observer.observe(scrollingContainerRef.current, {
                childList: true,
                subtree: true,
            })
        }

        return () => observer.disconnect()
    }, [])

    useLayoutEffect(() => {
        if (!scrollingContainerRef.current) return
        const previousScrollSmallerThanCurrent =
            scrollHeight < scrollingContainerRef.current.scrollHeight
        if (autoScroll) {
            scrollingContainerRef.current.scrollTop =
                scrollingContainerRef.current.scrollHeight
        } else if (previousScrollSmallerThanCurrent) {
            scrollingContainerRef.current.scrollTop =
                scrollingContainerRef.current.scrollHeight - scrollHeight
        }
    }, [messagesQuery.data, autoScroll, scrollHeight])

    const handleScroll = () => {
        if (!scrollingContainerRef.current) return

        const { scrollTop, scrollHeight, clientHeight } =
            scrollingContainerRef.current

        //make the content scroll automatically only if user is near to the bottom
        const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 50
        if (isNearBottom) {
            setAutoScroll(true)
        } else if (autoScroll) {
            setAutoScroll(false)
        }
    }

    const handleScrollToBottom = () => {
        setAutoScroll(true)
        if (!scrollingContainerRef.current) return

        scrollingContainerRef.current.scrollTop =
            scrollingContainerRef.current.scrollHeight
    }

    useEffect(() => {
        if (messagesQuery.isLoading) return
        if (!messagesQuery.data) return
        if (!messagesQuery.isSuccess) return

        const firstMessage = messagesWithUserData.at(0)
        let noUpdatesExists = false

        if (!firstMessage) {
            noUpdatesExists = true
        } else {
            setLastMessageId(firstMessage.id)

            const isOld = firstMessage.id === lastMessageId
            noUpdatesExists = isOld
        }

        if (noUpdatesExists) return

        postSeenMessages({
            messageIds: pipe(
                messagesWithUserData,
                A.map(m => m.id),
            ),
        })
    }, [
        messagesQuery.isLoading,
        messagesQuery.data,
        messagesQuery.isSuccess,
        lastMessageId,
        messagesWithUserData,
        postSeenMessages,
    ])

    const messageState = match(messagesQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    return (
        <Page className={styles.page}>
            <NavigationBackButton />
            <Toolbar>
                {recipientProfile && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            marginTop: "-20px",
                            alignItems: "center",
                        }}
                    >
                        <Link
                            to={
                                myProfileId === params.id
                                    ? "/app/my-profile"
                                    : "/app/user-profile/$id"
                            }
                            params={{ id: params.id }}
                        >
                            <Avatar
                                src={recipientProfile.imageUrl}
                                styleState={
                                    recipientProfile.isLive ? "isLive" : "None"
                                }
                                size={40}
                            />
                        </Link>

                        <p
                            style={{
                                marginTop: "6px",
                                fontSize: vars.font.size.regular,
                                color: "white",
                                fontWeight: 700,
                                textAlign: "center",
                            }}
                        >
                            {recipientProfile.displayName}
                        </p>
                    </div>
                )}
            </Toolbar>
            <Content ref={scrollingContainerRef} onScroll={handleScroll}>
                <InfiniteScroll
                    disabled={!messagesQuery.hasNextPage}
                    state={messageState}
                    onLoadRequested={messagesQuery.fetchNextPage}
                    threshold="100px"
                    direction="Before"
                >
                    <div className={styles.wrapper}>
                        {messagesQuery.data?.pages.map(page =>
                            page.data.map(message =>
                                message.senderId === myProfileId ? (
                                    <MyMessageItem
                                        key={message.id}
                                        showTime={true}
                                        messageId={message.id}
                                    />
                                ) : (
                                    <UserMessageItem
                                        key={message.id}
                                        showTime={true}
                                        messageId={message.id}
                                    />
                                ),
                            ),
                        )}
                    </div>
                </InfiniteScroll>
            </Content>

            <MessageToUserProfilePageToolbar
                messageState={messageState}
                myProfileId={myProfileId}
                recipientProfile={recipientProfile}
                onMessageSent={handleScrollToBottom}
            />
        </Page>
    )
}

type MessageToUserProfilePageToolbarModel = {
    messageState: "Loading" | "Loaded" | "Failed" | "Unloaded"
    recipientProfile?: UserProfile
    myProfileId?: UserProfileId
    onMessageSent: () => void
}

const MessageToUserProfilePageToolbar: FC<
    MessageToUserProfilePageToolbarModel
> = ({ messageState, myProfileId, recipientProfile, onMessageSent }) => {
    const toolbarRef = useRef<HTMLDivElement>(null)

    const [message, setMessage] = useState("")
    const { mutate: postMessage, isPending: isPostPending } =
        useMessageMutation()

    // prevent scrolling inside the toolbar because of iOS keyboard offset issue
    useEffect(() => {
        const toolbar = toolbarRef.current

        if (toolbar) {
            const handleTouchMove = (e: TouchEvent) => {
                e.preventDefault()
            }
            toolbar.addEventListener("touchmove", handleTouchMove, {
                passive: false,
            })

            return () => {
                toolbar.removeEventListener("touchmove", handleTouchMove)
            }
        }

        return
    }, [])

    useEffect(() => {
        const onResize = () => {
            const viewport = window.visualViewport
            if (viewport && toolbarRef.current) {
                const keyboardHeight = window.innerHeight - viewport.height

                toolbarRef.current.style.bottom =
                    keyboardHeight > 0 ? `${keyboardHeight}px` : "0px"
            }
        }

        window.visualViewport?.addEventListener("resize", onResize)
        return () =>
            window.visualViewport?.removeEventListener("resize", onResize)
    }, [])

    return (
        <div ref={toolbarRef} className={classNames(styles.bottomToolbar)}>
            <div className={styles.inputWrapper}>
                <Textarea
                    className={styles.input}
                    disabled={isPostPending}
                    placeholder="Your message"
                    rows={1}
                    value={message}
                    onChange={flow(
                        e => e.target.value,
                        value => value.length < 100000 && setMessage(value),
                    )}
                />

                <>
                    {!isPostPending &&
                        message.length > 0 &&
                        pipe(
                            messageState,
                            O.fromPredicate(state => state === "Loaded"),
                            O.chain(O.fromPredicate(() => message.length > 0)),
                            O.fold(
                                () => (
                                    <FontAwesomeIcon
                                        className={styles.inputIcon}
                                        icon={faPaperPlaneTop}
                                        color={vars.color.tertiary.hex}
                                    />
                                ),
                                () => (
                                    <FontAwesomeIcon
                                        className={styles.inputIcon}
                                        icon={faPaperPlaneTop}
                                        onClick={() => {
                                            if (!myProfileId) return
                                            if (!recipientProfile) return

                                            postMessage(
                                                {
                                                    senderId: myProfileId,
                                                    message,
                                                    recipient: {
                                                        contentType:
                                                            "UserProfile",
                                                        contentId:
                                                            recipientProfile?.id,
                                                    },
                                                },
                                                {
                                                    onSettled: () => {
                                                        setTimeout(
                                                            onMessageSent,
                                                            250,
                                                        )
                                                        setMessage("")
                                                    },
                                                },
                                            )
                                        }}
                                    />
                                ),
                            ),
                        )}
                </>
            </div>
        </div>
    )
}

export const MessageToUserProfilePageLoading: FC = () => (
    <Page className={styles.pageLoading}>
        <NavigationBackButton />

        <Toolbar className={styles.newToolbar}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignSelf: "center",
                    marginTop: 8,
                }}
            >
                <Skeleton width={48} height={48} circle />
                <Skeleton
                    style={{
                        marginTop: "6px",
                    }}
                    height={14}
                    width={60}
                    borderRadius={24}
                />
            </div>
        </Toolbar>
        <Footer className={styles.loadingFooter}>
            <Skeleton width="100%" height={50} borderRadius={8} />
        </Footer>
    </Page>
)

export default MessageToUserProfilePage
