import classNames from "classnames"
import { CSSProperties, forwardRef } from "react"

import * as styles from "./toolbar.css"

type Props = {
    wrapperStyles?: CSSProperties
}

export const Toolbar = forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div"> & Props
>(({ className, wrapperStyles, ...props }, ref) => (
    <div
        ref={ref}
        {...props}
        className={classNames(styles.toolbar, className)}
        {...(wrapperStyles ? { style: wrapperStyles } : {})}
    />
))
