import { Slot } from "@radix-ui/react-slot"
import classNames from "classnames"

import { ButtonHTMLAttributes, forwardRef } from "react"
import * as styles from "./button.css"

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: keyof typeof styles.variantStyles
    size?: keyof typeof styles.sizeStyles
    full?: boolean
    asChild?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = "default",
            size = "default",
            full = false,
            asChild = false,
            className,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : "button"
        return (
            <Comp
                className={classNames(
                    styles.baseButton,
                    styles.variantStyles[variant],
                    styles.sizeStyles[size],
                    full ? styles.full : "",
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    },
)
