/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as IntroImport } from './routes/intro'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as Import } from './routes/*'
import { Route as IndexImport } from './routes/index'
import { Route as RegistrationIndexImport } from './routes/registration/index'
import { Route as RegistrationTermsAndConditionImport } from './routes/registration/terms-and-condition'
import { Route as RegistrationPrivacyPolicyImport } from './routes/registration/privacy-policy'
import { Route as RegistrationCookieConsentImport } from './routes/registration/cookie-consent'
import { Route as RegistrationConfirmationImport } from './routes/registration/confirmation'
import { Route as RegistrationCancellationPolicyImport } from './routes/registration/cancellation-policy'
import { Route as AuthenticatedAppIndexImport } from './routes/_authenticated/app/index'
import { Route as AuthenticatedAppTabBarLayoutImport } from './routes/_authenticated/app/_tab-bar-layout'
import { Route as AuthenticatedAppVideoIndexImport } from './routes/_authenticated/app/video/index'
import { Route as AuthenticatedAppVideoUploadIndexImport } from './routes/_authenticated/app/video-upload/index'
import { Route as AuthenticatedAppVideoEditIndexImport } from './routes/_authenticated/app/video-edit/index'
import { Route as AuthenticatedAppTextUploadIndexImport } from './routes/_authenticated/app/text-upload/index'
import { Route as AuthenticatedAppTextEditIndexImport } from './routes/_authenticated/app/text-edit/index'
import { Route as AuthenticatedAppSettingsIndexImport } from './routes/_authenticated/app/settings/index'
import { Route as AuthenticatedAppRewardsExplainedIndexImport } from './routes/_authenticated/app/rewards-explained/index'
import { Route as AuthenticatedAppPostitIndexImport } from './routes/_authenticated/app/postit/index'
import { Route as AuthenticatedAppPointSystemIndexImport } from './routes/_authenticated/app/point-system/index'
import { Route as AuthenticatedAppNotificationsIndexImport } from './routes/_authenticated/app/notifications/index'
import { Route as AuthenticatedAppMessageIndexImport } from './routes/_authenticated/app/message/index'
import { Route as AuthenticatedAppLiveEventIndexImport } from './routes/_authenticated/app/live-event/index'
import { Route as AuthenticatedAppImageUploadIndexImport } from './routes/_authenticated/app/image-upload/index'
import { Route as AuthenticatedAppImageEditIndexImport } from './routes/_authenticated/app/image-edit/index'
import { Route as AuthenticatedAppFollowingsIndexImport } from './routes/_authenticated/app/followings/index'
import { Route as AuthenticatedAppFollowersIndexImport } from './routes/_authenticated/app/followers/index'
import { Route as AuthenticatedAppVideoEditIdImport } from './routes/_authenticated/app/video-edit/$id'
import { Route as AuthenticatedAppTextEditIdImport } from './routes/_authenticated/app/text-edit/$id'
import { Route as AuthenticatedAppSearchVideosImport } from './routes/_authenticated/app/search/videos'
import { Route as AuthenticatedAppSearchTextPostsImport } from './routes/_authenticated/app/search/text-posts'
import { Route as AuthenticatedAppSearchProfilesImport } from './routes/_authenticated/app/search/profiles'
import { Route as AuthenticatedAppSearchLiveEventsImport } from './routes/_authenticated/app/search/live-events'
import { Route as AuthenticatedAppSearchImagePostsImport } from './routes/_authenticated/app/search/image-posts'
import { Route as AuthenticatedAppPointSystemInviteImport } from './routes/_authenticated/app/point-system/invite'
import { Route as AuthenticatedAppPlaygroundPlayMessagingImport } from './routes/_authenticated/app/playground/play-messaging'
import { Route as AuthenticatedAppImageEditIdImport } from './routes/_authenticated/app/image-edit/$id'
import { Route as AuthenticatedAppVideoViewerIndexImport } from './routes/_authenticated/app/video/viewer/index'
import { Route as AuthenticatedAppSettingsWalletIndexImport } from './routes/_authenticated/app/settings/wallet/index'
import { Route as AuthenticatedAppSettingsRewardsIndexImport } from './routes/_authenticated/app/settings/rewards/index'
import { Route as AuthenticatedAppSettingsNotificationsIndexImport } from './routes/_authenticated/app/settings/notifications/index'
import { Route as AuthenticatedAppSettingsMonetizationIndexImport } from './routes/_authenticated/app/settings/monetization/index'
import { Route as AuthenticatedAppPostitViewerIndexImport } from './routes/_authenticated/app/postit/viewer/index'
import { Route as AuthenticatedAppMyProfileImageIndexImport } from './routes/_authenticated/app/my-profile/image/index'
import { Route as AuthenticatedAppMyProfileEditIndexImport } from './routes/_authenticated/app/my-profile/edit/index'
import { Route as AuthenticatedAppMessageProfileIndexImport } from './routes/_authenticated/app/message/profile/index'
import { Route as AuthenticatedAppLiveEventViewerIndexImport } from './routes/_authenticated/app/live-event/viewer/index'
import { Route as AuthenticatedAppLiveEventSummaryIndexImport } from './routes/_authenticated/app/live-event/summary/index'
import { Route as AuthenticatedAppLiveEventCreatorIndexImport } from './routes/_authenticated/app/live-event/creator/index'
import { Route as AuthenticatedAppFollowingsOverviewIndexImport } from './routes/_authenticated/app/followings/overview/index'
import { Route as AuthenticatedAppFollowersOverviewIndexImport } from './routes/_authenticated/app/followers/overview/index'
import { Route as AuthenticatedAppTabBarLayoutSearchIndexImport } from './routes/_authenticated/app/_tab-bar-layout/search/index'
import { Route as AuthenticatedAppTabBarLayoutMyProfileIndexImport } from './routes/_authenticated/app/_tab-bar-layout/my-profile/index'
import { Route as AuthenticatedAppTabBarLayoutMessagesIndexImport } from './routes/_authenticated/app/_tab-bar-layout/messages/index'
import { Route as AuthenticatedAppTabBarLayoutFeedIndexImport } from './routes/_authenticated/app/_tab-bar-layout/feed/index'
import { Route as AuthenticatedAppVideoViewerIdImport } from './routes/_authenticated/app/video/viewer/$id'
import { Route as AuthenticatedAppPostitViewerIdImport } from './routes/_authenticated/app/postit/viewer/$id'
import { Route as AuthenticatedAppMessageProfileIdImport } from './routes/_authenticated/app/message/profile/$id'
import { Route as AuthenticatedAppLiveEventViewerIdImport } from './routes/_authenticated/app/live-event/viewer/$id'
import { Route as AuthenticatedAppLiveEventSummaryIdImport } from './routes/_authenticated/app/live-event/summary/$id'
import { Route as AuthenticatedAppFollowingsOverviewIdImport } from './routes/_authenticated/app/followings/overview/$id'
import { Route as AuthenticatedAppFollowersOverviewIdImport } from './routes/_authenticated/app/followers/overview/$id'
import { Route as AuthenticatedAppTabBarLayoutUserProfileIdImport } from './routes/_authenticated/app/_tab-bar-layout/user-profile/$id'
import { Route as AuthenticatedAppSettingsRewardsChallengeDetailsIndexImport } from './routes/_authenticated/app/settings/rewards/challenge-details/index'
import { Route as AuthenticatedAppSettingsRewardsBenefitsIndexImport } from './routes/_authenticated/app/settings/rewards/benefits/index'
import { Route as AuthenticatedAppSettingsMonetizationManageSubscriptionIndexImport } from './routes/_authenticated/app/settings/monetization/manage-subscription/index'
import { Route as AuthenticatedAppSettingsRewardsChallengeDetailsIdImport } from './routes/_authenticated/app/settings/rewards/challenge-details/$id'
import { Route as AuthenticatedAppSettingsRewardsBenefitsOverviewImport } from './routes/_authenticated/app/settings/rewards/benefits/overview'

// Create Virtual Routes

const AuthenticatedAppImport = createFileRoute('/_authenticated/app')()
const AuthenticatedAppPlaygroundPlayLanguageLazyImport = createFileRoute(
  '/_authenticated/app/playground/play-language',
)()

// Create/Update Routes

const IntroRoute = IntroImport.update({
  id: '/intro',
  path: '/intro',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/intro.lazy').then((d) => d.Route))

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const Route = Import.update({
  id: '/*',
  path: '/*',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedAppRoute = AuthenticatedAppImport.update({
  id: '/app',
  path: '/app',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const RegistrationIndexRoute = RegistrationIndexImport.update({
  id: '/registration/',
  path: '/registration/',
  getParentRoute: () => rootRoute,
} as any)

const RegistrationTermsAndConditionRoute =
  RegistrationTermsAndConditionImport.update({
    id: '/registration/terms-and-condition',
    path: '/registration/terms-and-condition',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/registration/terms-and-condition.lazy').then(
      (d) => d.Route,
    ),
  )

const RegistrationPrivacyPolicyRoute = RegistrationPrivacyPolicyImport.update({
  id: '/registration/privacy-policy',
  path: '/registration/privacy-policy',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/registration/privacy-policy.lazy').then((d) => d.Route),
)

const RegistrationCookieConsentRoute = RegistrationCookieConsentImport.update({
  id: '/registration/cookie-consent',
  path: '/registration/cookie-consent',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/registration/cookie-consent.lazy').then((d) => d.Route),
)

const RegistrationConfirmationRoute = RegistrationConfirmationImport.update({
  id: '/registration/confirmation',
  path: '/registration/confirmation',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/registration/confirmation.lazy').then((d) => d.Route),
)

const RegistrationCancellationPolicyRoute =
  RegistrationCancellationPolicyImport.update({
    id: '/registration/cancellation-policy',
    path: '/registration/cancellation-policy',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/registration/cancellation-policy.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppIndexRoute = AuthenticatedAppIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedAppRoute,
} as any)

const AuthenticatedAppTabBarLayoutRoute =
  AuthenticatedAppTabBarLayoutImport.update({
    id: '/_tab-bar-layout',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppVideoIndexRoute = AuthenticatedAppVideoIndexImport.update(
  {
    id: '/video/',
    path: '/video/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any,
)

const AuthenticatedAppVideoUploadIndexRoute =
  AuthenticatedAppVideoUploadIndexImport.update({
    id: '/video-upload/',
    path: '/video-upload/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/video-upload/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppVideoEditIndexRoute =
  AuthenticatedAppVideoEditIndexImport.update({
    id: '/video-edit/',
    path: '/video-edit/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppTextUploadIndexRoute =
  AuthenticatedAppTextUploadIndexImport.update({
    id: '/text-upload/',
    path: '/text-upload/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/text-upload/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppTextEditIndexRoute =
  AuthenticatedAppTextEditIndexImport.update({
    id: '/text-edit/',
    path: '/text-edit/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppSettingsIndexRoute =
  AuthenticatedAppSettingsIndexImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppRewardsExplainedIndexRoute =
  AuthenticatedAppRewardsExplainedIndexImport.update({
    id: '/rewards-explained/',
    path: '/rewards-explained/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/rewards-explained/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppPostitIndexRoute =
  AuthenticatedAppPostitIndexImport.update({
    id: '/postit/',
    path: '/postit/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppPointSystemIndexRoute =
  AuthenticatedAppPointSystemIndexImport.update({
    id: '/point-system/',
    path: '/point-system/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppNotificationsIndexRoute =
  AuthenticatedAppNotificationsIndexImport.update({
    id: '/notifications/',
    path: '/notifications/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/notifications/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppMessageIndexRoute =
  AuthenticatedAppMessageIndexImport.update({
    id: '/message/',
    path: '/message/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppLiveEventIndexRoute =
  AuthenticatedAppLiveEventIndexImport.update({
    id: '/live-event/',
    path: '/live-event/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppImageUploadIndexRoute =
  AuthenticatedAppImageUploadIndexImport.update({
    id: '/image-upload/',
    path: '/image-upload/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/image-upload/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppImageEditIndexRoute =
  AuthenticatedAppImageEditIndexImport.update({
    id: '/image-edit/',
    path: '/image-edit/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppFollowingsIndexRoute =
  AuthenticatedAppFollowingsIndexImport.update({
    id: '/followings/',
    path: '/followings/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppFollowersIndexRoute =
  AuthenticatedAppFollowersIndexImport.update({
    id: '/followers/',
    path: '/followers/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppPlaygroundPlayLanguageLazyRoute =
  AuthenticatedAppPlaygroundPlayLanguageLazyImport.update({
    id: '/playground/play-language',
    path: '/playground/play-language',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/playground/play-language.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppVideoEditIdRoute =
  AuthenticatedAppVideoEditIdImport.update({
    id: '/video-edit/$id',
    path: '/video-edit/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/video-edit/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppTextEditIdRoute = AuthenticatedAppTextEditIdImport.update(
  {
    id: '/text-edit/$id',
    path: '/text-edit/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any,
).lazy(() =>
  import('./routes/_authenticated/app/text-edit/$id.lazy').then((d) => d.Route),
)

const AuthenticatedAppSearchVideosRoute =
  AuthenticatedAppSearchVideosImport.update({
    id: '/search/videos',
    path: '/search/videos',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/search/videos.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppSearchTextPostsRoute =
  AuthenticatedAppSearchTextPostsImport.update({
    id: '/search/text-posts',
    path: '/search/text-posts',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/search/text-posts.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppSearchProfilesRoute =
  AuthenticatedAppSearchProfilesImport.update({
    id: '/search/profiles',
    path: '/search/profiles',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/search/profiles.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppSearchLiveEventsRoute =
  AuthenticatedAppSearchLiveEventsImport.update({
    id: '/search/live-events',
    path: '/search/live-events',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/search/live-events.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppSearchImagePostsRoute =
  AuthenticatedAppSearchImagePostsImport.update({
    id: '/search/image-posts',
    path: '/search/image-posts',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/search/image-posts.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppPointSystemInviteRoute =
  AuthenticatedAppPointSystemInviteImport.update({
    id: '/point-system/invite',
    path: '/point-system/invite',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/point-system/invite.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppPlaygroundPlayMessagingRoute =
  AuthenticatedAppPlaygroundPlayMessagingImport.update({
    id: '/playground/play-messaging',
    path: '/playground/play-messaging',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/playground/play-messaging.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppImageEditIdRoute =
  AuthenticatedAppImageEditIdImport.update({
    id: '/image-edit/$id',
    path: '/image-edit/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/image-edit/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppVideoViewerIndexRoute =
  AuthenticatedAppVideoViewerIndexImport.update({
    id: '/video/viewer/',
    path: '/video/viewer/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppSettingsWalletIndexRoute =
  AuthenticatedAppSettingsWalletIndexImport.update({
    id: '/settings/wallet/',
    path: '/settings/wallet/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/settings/wallet/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppSettingsRewardsIndexRoute =
  AuthenticatedAppSettingsRewardsIndexImport.update({
    id: '/settings/rewards/',
    path: '/settings/rewards/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/settings/rewards/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppSettingsNotificationsIndexRoute =
  AuthenticatedAppSettingsNotificationsIndexImport.update({
    id: '/settings/notifications/',
    path: '/settings/notifications/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/app/settings/notifications/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedAppSettingsMonetizationIndexRoute =
  AuthenticatedAppSettingsMonetizationIndexImport.update({
    id: '/settings/monetization/',
    path: '/settings/monetization/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/settings/monetization/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppPostitViewerIndexRoute =
  AuthenticatedAppPostitViewerIndexImport.update({
    id: '/postit/viewer/',
    path: '/postit/viewer/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppMyProfileImageIndexRoute =
  AuthenticatedAppMyProfileImageIndexImport.update({
    id: '/my-profile/image/',
    path: '/my-profile/image/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/my-profile/image/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppMyProfileEditIndexRoute =
  AuthenticatedAppMyProfileEditIndexImport.update({
    id: '/my-profile/edit/',
    path: '/my-profile/edit/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/my-profile/edit/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppMessageProfileIndexRoute =
  AuthenticatedAppMessageProfileIndexImport.update({
    id: '/message/profile/',
    path: '/message/profile/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppLiveEventViewerIndexRoute =
  AuthenticatedAppLiveEventViewerIndexImport.update({
    id: '/live-event/viewer/',
    path: '/live-event/viewer/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppLiveEventSummaryIndexRoute =
  AuthenticatedAppLiveEventSummaryIndexImport.update({
    id: '/live-event/summary/',
    path: '/live-event/summary/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppLiveEventCreatorIndexRoute =
  AuthenticatedAppLiveEventCreatorIndexImport.update({
    id: '/live-event/creator/',
    path: '/live-event/creator/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/live-event/creator/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppFollowingsOverviewIndexRoute =
  AuthenticatedAppFollowingsOverviewIndexImport.update({
    id: '/followings/overview/',
    path: '/followings/overview/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppFollowersOverviewIndexRoute =
  AuthenticatedAppFollowersOverviewIndexImport.update({
    id: '/followers/overview/',
    path: '/followers/overview/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppTabBarLayoutSearchIndexRoute =
  AuthenticatedAppTabBarLayoutSearchIndexImport.update({
    id: '/search/',
    path: '/search/',
    getParentRoute: () => AuthenticatedAppTabBarLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/app/_tab-bar-layout/search/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedAppTabBarLayoutMyProfileIndexRoute =
  AuthenticatedAppTabBarLayoutMyProfileIndexImport.update({
    id: '/my-profile/',
    path: '/my-profile/',
    getParentRoute: () => AuthenticatedAppTabBarLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/app/_tab-bar-layout/my-profile/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedAppTabBarLayoutMessagesIndexRoute =
  AuthenticatedAppTabBarLayoutMessagesIndexImport.update({
    id: '/messages/',
    path: '/messages/',
    getParentRoute: () => AuthenticatedAppTabBarLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/app/_tab-bar-layout/messages/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedAppTabBarLayoutFeedIndexRoute =
  AuthenticatedAppTabBarLayoutFeedIndexImport.update({
    id: '/feed/',
    path: '/feed/',
    getParentRoute: () => AuthenticatedAppTabBarLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/_tab-bar-layout/feed/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppVideoViewerIdRoute =
  AuthenticatedAppVideoViewerIdImport.update({
    id: '/video/viewer/$id',
    path: '/video/viewer/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/video/viewer/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppPostitViewerIdRoute =
  AuthenticatedAppPostitViewerIdImport.update({
    id: '/postit/viewer/$id',
    path: '/postit/viewer/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/postit/viewer/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppMessageProfileIdRoute =
  AuthenticatedAppMessageProfileIdImport.update({
    id: '/message/profile/$id',
    path: '/message/profile/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/message/profile/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppLiveEventViewerIdRoute =
  AuthenticatedAppLiveEventViewerIdImport.update({
    id: '/live-event/viewer/$id',
    path: '/live-event/viewer/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/live-event/viewer/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppLiveEventSummaryIdRoute =
  AuthenticatedAppLiveEventSummaryIdImport.update({
    id: '/live-event/summary/$id',
    path: '/live-event/summary/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/live-event/summary/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppFollowingsOverviewIdRoute =
  AuthenticatedAppFollowingsOverviewIdImport.update({
    id: '/followings/overview/$id',
    path: '/followings/overview/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/followings/overview/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppFollowersOverviewIdRoute =
  AuthenticatedAppFollowersOverviewIdImport.update({
    id: '/followers/overview/$id',
    path: '/followers/overview/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/app/followers/overview/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedAppTabBarLayoutUserProfileIdRoute =
  AuthenticatedAppTabBarLayoutUserProfileIdImport.update({
    id: '/user-profile/$id',
    path: '/user-profile/$id',
    getParentRoute: () => AuthenticatedAppTabBarLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/app/_tab-bar-layout/user-profile/$id.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedAppSettingsRewardsChallengeDetailsIndexRoute =
  AuthenticatedAppSettingsRewardsChallengeDetailsIndexImport.update({
    id: '/settings/rewards/challenge-details/',
    path: '/settings/rewards/challenge-details/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppSettingsRewardsBenefitsIndexRoute =
  AuthenticatedAppSettingsRewardsBenefitsIndexImport.update({
    id: '/settings/rewards/benefits/',
    path: '/settings/rewards/benefits/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppSettingsMonetizationManageSubscriptionIndexRoute =
  AuthenticatedAppSettingsMonetizationManageSubscriptionIndexImport.update({
    id: '/settings/monetization/manage-subscription/',
    path: '/settings/monetization/manage-subscription/',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any)

const AuthenticatedAppSettingsRewardsChallengeDetailsIdRoute =
  AuthenticatedAppSettingsRewardsChallengeDetailsIdImport.update({
    id: '/settings/rewards/challenge-details/$id',
    path: '/settings/rewards/challenge-details/$id',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/app/settings/rewards/challenge-details/$id.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedAppSettingsRewardsBenefitsOverviewRoute =
  AuthenticatedAppSettingsRewardsBenefitsOverviewImport.update({
    id: '/settings/rewards/benefits/overview',
    path: '/settings/rewards/benefits/overview',
    getParentRoute: () => AuthenticatedAppRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/app/settings/rewards/benefits/overview.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/*': {
      id: '/*'
      path: '/*'
      fullPath: '/*'
      preLoaderRoute: typeof Import
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/intro': {
      id: '/intro'
      path: '/intro'
      fullPath: '/intro'
      preLoaderRoute: typeof IntroImport
      parentRoute: typeof rootRoute
    }
    '/registration/cancellation-policy': {
      id: '/registration/cancellation-policy'
      path: '/registration/cancellation-policy'
      fullPath: '/registration/cancellation-policy'
      preLoaderRoute: typeof RegistrationCancellationPolicyImport
      parentRoute: typeof rootRoute
    }
    '/registration/confirmation': {
      id: '/registration/confirmation'
      path: '/registration/confirmation'
      fullPath: '/registration/confirmation'
      preLoaderRoute: typeof RegistrationConfirmationImport
      parentRoute: typeof rootRoute
    }
    '/registration/cookie-consent': {
      id: '/registration/cookie-consent'
      path: '/registration/cookie-consent'
      fullPath: '/registration/cookie-consent'
      preLoaderRoute: typeof RegistrationCookieConsentImport
      parentRoute: typeof rootRoute
    }
    '/registration/privacy-policy': {
      id: '/registration/privacy-policy'
      path: '/registration/privacy-policy'
      fullPath: '/registration/privacy-policy'
      preLoaderRoute: typeof RegistrationPrivacyPolicyImport
      parentRoute: typeof rootRoute
    }
    '/registration/terms-and-condition': {
      id: '/registration/terms-and-condition'
      path: '/registration/terms-and-condition'
      fullPath: '/registration/terms-and-condition'
      preLoaderRoute: typeof RegistrationTermsAndConditionImport
      parentRoute: typeof rootRoute
    }
    '/registration/': {
      id: '/registration/'
      path: '/registration'
      fullPath: '/registration'
      preLoaderRoute: typeof RegistrationIndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/app': {
      id: '/_authenticated/app'
      path: '/app'
      fullPath: '/app'
      preLoaderRoute: typeof AuthenticatedAppImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/app/_tab-bar-layout': {
      id: '/_authenticated/app/_tab-bar-layout'
      path: '/app'
      fullPath: '/app'
      preLoaderRoute: typeof AuthenticatedAppTabBarLayoutImport
      parentRoute: typeof AuthenticatedAppRoute
    }
    '/_authenticated/app/': {
      id: '/_authenticated/app/'
      path: '/'
      fullPath: '/app/'
      preLoaderRoute: typeof AuthenticatedAppIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/image-edit/$id': {
      id: '/_authenticated/app/image-edit/$id'
      path: '/image-edit/$id'
      fullPath: '/app/image-edit/$id'
      preLoaderRoute: typeof AuthenticatedAppImageEditIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/playground/play-messaging': {
      id: '/_authenticated/app/playground/play-messaging'
      path: '/playground/play-messaging'
      fullPath: '/app/playground/play-messaging'
      preLoaderRoute: typeof AuthenticatedAppPlaygroundPlayMessagingImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/point-system/invite': {
      id: '/_authenticated/app/point-system/invite'
      path: '/point-system/invite'
      fullPath: '/app/point-system/invite'
      preLoaderRoute: typeof AuthenticatedAppPointSystemInviteImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/search/image-posts': {
      id: '/_authenticated/app/search/image-posts'
      path: '/search/image-posts'
      fullPath: '/app/search/image-posts'
      preLoaderRoute: typeof AuthenticatedAppSearchImagePostsImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/search/live-events': {
      id: '/_authenticated/app/search/live-events'
      path: '/search/live-events'
      fullPath: '/app/search/live-events'
      preLoaderRoute: typeof AuthenticatedAppSearchLiveEventsImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/search/profiles': {
      id: '/_authenticated/app/search/profiles'
      path: '/search/profiles'
      fullPath: '/app/search/profiles'
      preLoaderRoute: typeof AuthenticatedAppSearchProfilesImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/search/text-posts': {
      id: '/_authenticated/app/search/text-posts'
      path: '/search/text-posts'
      fullPath: '/app/search/text-posts'
      preLoaderRoute: typeof AuthenticatedAppSearchTextPostsImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/search/videos': {
      id: '/_authenticated/app/search/videos'
      path: '/search/videos'
      fullPath: '/app/search/videos'
      preLoaderRoute: typeof AuthenticatedAppSearchVideosImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/text-edit/$id': {
      id: '/_authenticated/app/text-edit/$id'
      path: '/text-edit/$id'
      fullPath: '/app/text-edit/$id'
      preLoaderRoute: typeof AuthenticatedAppTextEditIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/video-edit/$id': {
      id: '/_authenticated/app/video-edit/$id'
      path: '/video-edit/$id'
      fullPath: '/app/video-edit/$id'
      preLoaderRoute: typeof AuthenticatedAppVideoEditIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/playground/play-language': {
      id: '/_authenticated/app/playground/play-language'
      path: '/playground/play-language'
      fullPath: '/app/playground/play-language'
      preLoaderRoute: typeof AuthenticatedAppPlaygroundPlayLanguageLazyImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/followers/': {
      id: '/_authenticated/app/followers/'
      path: '/followers'
      fullPath: '/app/followers'
      preLoaderRoute: typeof AuthenticatedAppFollowersIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/followings/': {
      id: '/_authenticated/app/followings/'
      path: '/followings'
      fullPath: '/app/followings'
      preLoaderRoute: typeof AuthenticatedAppFollowingsIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/image-edit/': {
      id: '/_authenticated/app/image-edit/'
      path: '/image-edit'
      fullPath: '/app/image-edit'
      preLoaderRoute: typeof AuthenticatedAppImageEditIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/image-upload/': {
      id: '/_authenticated/app/image-upload/'
      path: '/image-upload'
      fullPath: '/app/image-upload'
      preLoaderRoute: typeof AuthenticatedAppImageUploadIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/live-event/': {
      id: '/_authenticated/app/live-event/'
      path: '/live-event'
      fullPath: '/app/live-event'
      preLoaderRoute: typeof AuthenticatedAppLiveEventIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/message/': {
      id: '/_authenticated/app/message/'
      path: '/message'
      fullPath: '/app/message'
      preLoaderRoute: typeof AuthenticatedAppMessageIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/notifications/': {
      id: '/_authenticated/app/notifications/'
      path: '/notifications'
      fullPath: '/app/notifications'
      preLoaderRoute: typeof AuthenticatedAppNotificationsIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/point-system/': {
      id: '/_authenticated/app/point-system/'
      path: '/point-system'
      fullPath: '/app/point-system'
      preLoaderRoute: typeof AuthenticatedAppPointSystemIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/postit/': {
      id: '/_authenticated/app/postit/'
      path: '/postit'
      fullPath: '/app/postit'
      preLoaderRoute: typeof AuthenticatedAppPostitIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/rewards-explained/': {
      id: '/_authenticated/app/rewards-explained/'
      path: '/rewards-explained'
      fullPath: '/app/rewards-explained'
      preLoaderRoute: typeof AuthenticatedAppRewardsExplainedIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/': {
      id: '/_authenticated/app/settings/'
      path: '/settings'
      fullPath: '/app/settings'
      preLoaderRoute: typeof AuthenticatedAppSettingsIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/text-edit/': {
      id: '/_authenticated/app/text-edit/'
      path: '/text-edit'
      fullPath: '/app/text-edit'
      preLoaderRoute: typeof AuthenticatedAppTextEditIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/text-upload/': {
      id: '/_authenticated/app/text-upload/'
      path: '/text-upload'
      fullPath: '/app/text-upload'
      preLoaderRoute: typeof AuthenticatedAppTextUploadIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/video-edit/': {
      id: '/_authenticated/app/video-edit/'
      path: '/video-edit'
      fullPath: '/app/video-edit'
      preLoaderRoute: typeof AuthenticatedAppVideoEditIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/video-upload/': {
      id: '/_authenticated/app/video-upload/'
      path: '/video-upload'
      fullPath: '/app/video-upload'
      preLoaderRoute: typeof AuthenticatedAppVideoUploadIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/video/': {
      id: '/_authenticated/app/video/'
      path: '/video'
      fullPath: '/app/video'
      preLoaderRoute: typeof AuthenticatedAppVideoIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/_tab-bar-layout/user-profile/$id': {
      id: '/_authenticated/app/_tab-bar-layout/user-profile/$id'
      path: '/user-profile/$id'
      fullPath: '/app/user-profile/$id'
      preLoaderRoute: typeof AuthenticatedAppTabBarLayoutUserProfileIdImport
      parentRoute: typeof AuthenticatedAppTabBarLayoutImport
    }
    '/_authenticated/app/followers/overview/$id': {
      id: '/_authenticated/app/followers/overview/$id'
      path: '/followers/overview/$id'
      fullPath: '/app/followers/overview/$id'
      preLoaderRoute: typeof AuthenticatedAppFollowersOverviewIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/followings/overview/$id': {
      id: '/_authenticated/app/followings/overview/$id'
      path: '/followings/overview/$id'
      fullPath: '/app/followings/overview/$id'
      preLoaderRoute: typeof AuthenticatedAppFollowingsOverviewIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/live-event/summary/$id': {
      id: '/_authenticated/app/live-event/summary/$id'
      path: '/live-event/summary/$id'
      fullPath: '/app/live-event/summary/$id'
      preLoaderRoute: typeof AuthenticatedAppLiveEventSummaryIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/live-event/viewer/$id': {
      id: '/_authenticated/app/live-event/viewer/$id'
      path: '/live-event/viewer/$id'
      fullPath: '/app/live-event/viewer/$id'
      preLoaderRoute: typeof AuthenticatedAppLiveEventViewerIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/message/profile/$id': {
      id: '/_authenticated/app/message/profile/$id'
      path: '/message/profile/$id'
      fullPath: '/app/message/profile/$id'
      preLoaderRoute: typeof AuthenticatedAppMessageProfileIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/postit/viewer/$id': {
      id: '/_authenticated/app/postit/viewer/$id'
      path: '/postit/viewer/$id'
      fullPath: '/app/postit/viewer/$id'
      preLoaderRoute: typeof AuthenticatedAppPostitViewerIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/video/viewer/$id': {
      id: '/_authenticated/app/video/viewer/$id'
      path: '/video/viewer/$id'
      fullPath: '/app/video/viewer/$id'
      preLoaderRoute: typeof AuthenticatedAppVideoViewerIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/_tab-bar-layout/feed/': {
      id: '/_authenticated/app/_tab-bar-layout/feed/'
      path: '/feed'
      fullPath: '/app/feed'
      preLoaderRoute: typeof AuthenticatedAppTabBarLayoutFeedIndexImport
      parentRoute: typeof AuthenticatedAppTabBarLayoutImport
    }
    '/_authenticated/app/_tab-bar-layout/messages/': {
      id: '/_authenticated/app/_tab-bar-layout/messages/'
      path: '/messages'
      fullPath: '/app/messages'
      preLoaderRoute: typeof AuthenticatedAppTabBarLayoutMessagesIndexImport
      parentRoute: typeof AuthenticatedAppTabBarLayoutImport
    }
    '/_authenticated/app/_tab-bar-layout/my-profile/': {
      id: '/_authenticated/app/_tab-bar-layout/my-profile/'
      path: '/my-profile'
      fullPath: '/app/my-profile'
      preLoaderRoute: typeof AuthenticatedAppTabBarLayoutMyProfileIndexImport
      parentRoute: typeof AuthenticatedAppTabBarLayoutImport
    }
    '/_authenticated/app/_tab-bar-layout/search/': {
      id: '/_authenticated/app/_tab-bar-layout/search/'
      path: '/search'
      fullPath: '/app/search'
      preLoaderRoute: typeof AuthenticatedAppTabBarLayoutSearchIndexImport
      parentRoute: typeof AuthenticatedAppTabBarLayoutImport
    }
    '/_authenticated/app/followers/overview/': {
      id: '/_authenticated/app/followers/overview/'
      path: '/followers/overview'
      fullPath: '/app/followers/overview'
      preLoaderRoute: typeof AuthenticatedAppFollowersOverviewIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/followings/overview/': {
      id: '/_authenticated/app/followings/overview/'
      path: '/followings/overview'
      fullPath: '/app/followings/overview'
      preLoaderRoute: typeof AuthenticatedAppFollowingsOverviewIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/live-event/creator/': {
      id: '/_authenticated/app/live-event/creator/'
      path: '/live-event/creator'
      fullPath: '/app/live-event/creator'
      preLoaderRoute: typeof AuthenticatedAppLiveEventCreatorIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/live-event/summary/': {
      id: '/_authenticated/app/live-event/summary/'
      path: '/live-event/summary'
      fullPath: '/app/live-event/summary'
      preLoaderRoute: typeof AuthenticatedAppLiveEventSummaryIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/live-event/viewer/': {
      id: '/_authenticated/app/live-event/viewer/'
      path: '/live-event/viewer'
      fullPath: '/app/live-event/viewer'
      preLoaderRoute: typeof AuthenticatedAppLiveEventViewerIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/message/profile/': {
      id: '/_authenticated/app/message/profile/'
      path: '/message/profile'
      fullPath: '/app/message/profile'
      preLoaderRoute: typeof AuthenticatedAppMessageProfileIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/my-profile/edit/': {
      id: '/_authenticated/app/my-profile/edit/'
      path: '/my-profile/edit'
      fullPath: '/app/my-profile/edit'
      preLoaderRoute: typeof AuthenticatedAppMyProfileEditIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/my-profile/image/': {
      id: '/_authenticated/app/my-profile/image/'
      path: '/my-profile/image'
      fullPath: '/app/my-profile/image'
      preLoaderRoute: typeof AuthenticatedAppMyProfileImageIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/postit/viewer/': {
      id: '/_authenticated/app/postit/viewer/'
      path: '/postit/viewer'
      fullPath: '/app/postit/viewer'
      preLoaderRoute: typeof AuthenticatedAppPostitViewerIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/monetization/': {
      id: '/_authenticated/app/settings/monetization/'
      path: '/settings/monetization'
      fullPath: '/app/settings/monetization'
      preLoaderRoute: typeof AuthenticatedAppSettingsMonetizationIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/notifications/': {
      id: '/_authenticated/app/settings/notifications/'
      path: '/settings/notifications'
      fullPath: '/app/settings/notifications'
      preLoaderRoute: typeof AuthenticatedAppSettingsNotificationsIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/rewards/': {
      id: '/_authenticated/app/settings/rewards/'
      path: '/settings/rewards'
      fullPath: '/app/settings/rewards'
      preLoaderRoute: typeof AuthenticatedAppSettingsRewardsIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/wallet/': {
      id: '/_authenticated/app/settings/wallet/'
      path: '/settings/wallet'
      fullPath: '/app/settings/wallet'
      preLoaderRoute: typeof AuthenticatedAppSettingsWalletIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/video/viewer/': {
      id: '/_authenticated/app/video/viewer/'
      path: '/video/viewer'
      fullPath: '/app/video/viewer'
      preLoaderRoute: typeof AuthenticatedAppVideoViewerIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/rewards/benefits/overview': {
      id: '/_authenticated/app/settings/rewards/benefits/overview'
      path: '/settings/rewards/benefits/overview'
      fullPath: '/app/settings/rewards/benefits/overview'
      preLoaderRoute: typeof AuthenticatedAppSettingsRewardsBenefitsOverviewImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/rewards/challenge-details/$id': {
      id: '/_authenticated/app/settings/rewards/challenge-details/$id'
      path: '/settings/rewards/challenge-details/$id'
      fullPath: '/app/settings/rewards/challenge-details/$id'
      preLoaderRoute: typeof AuthenticatedAppSettingsRewardsChallengeDetailsIdImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/monetization/manage-subscription/': {
      id: '/_authenticated/app/settings/monetization/manage-subscription/'
      path: '/settings/monetization/manage-subscription'
      fullPath: '/app/settings/monetization/manage-subscription'
      preLoaderRoute: typeof AuthenticatedAppSettingsMonetizationManageSubscriptionIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/rewards/benefits/': {
      id: '/_authenticated/app/settings/rewards/benefits/'
      path: '/settings/rewards/benefits'
      fullPath: '/app/settings/rewards/benefits'
      preLoaderRoute: typeof AuthenticatedAppSettingsRewardsBenefitsIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
    '/_authenticated/app/settings/rewards/challenge-details/': {
      id: '/_authenticated/app/settings/rewards/challenge-details/'
      path: '/settings/rewards/challenge-details'
      fullPath: '/app/settings/rewards/challenge-details'
      preLoaderRoute: typeof AuthenticatedAppSettingsRewardsChallengeDetailsIndexImport
      parentRoute: typeof AuthenticatedAppImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedAppTabBarLayoutRouteChildren {
  AuthenticatedAppTabBarLayoutUserProfileIdRoute: typeof AuthenticatedAppTabBarLayoutUserProfileIdRoute
  AuthenticatedAppTabBarLayoutFeedIndexRoute: typeof AuthenticatedAppTabBarLayoutFeedIndexRoute
  AuthenticatedAppTabBarLayoutMessagesIndexRoute: typeof AuthenticatedAppTabBarLayoutMessagesIndexRoute
  AuthenticatedAppTabBarLayoutMyProfileIndexRoute: typeof AuthenticatedAppTabBarLayoutMyProfileIndexRoute
  AuthenticatedAppTabBarLayoutSearchIndexRoute: typeof AuthenticatedAppTabBarLayoutSearchIndexRoute
}

const AuthenticatedAppTabBarLayoutRouteChildren: AuthenticatedAppTabBarLayoutRouteChildren =
  {
    AuthenticatedAppTabBarLayoutUserProfileIdRoute:
      AuthenticatedAppTabBarLayoutUserProfileIdRoute,
    AuthenticatedAppTabBarLayoutFeedIndexRoute:
      AuthenticatedAppTabBarLayoutFeedIndexRoute,
    AuthenticatedAppTabBarLayoutMessagesIndexRoute:
      AuthenticatedAppTabBarLayoutMessagesIndexRoute,
    AuthenticatedAppTabBarLayoutMyProfileIndexRoute:
      AuthenticatedAppTabBarLayoutMyProfileIndexRoute,
    AuthenticatedAppTabBarLayoutSearchIndexRoute:
      AuthenticatedAppTabBarLayoutSearchIndexRoute,
  }

const AuthenticatedAppTabBarLayoutRouteWithChildren =
  AuthenticatedAppTabBarLayoutRoute._addFileChildren(
    AuthenticatedAppTabBarLayoutRouteChildren,
  )

interface AuthenticatedAppRouteChildren {
  AuthenticatedAppTabBarLayoutRoute: typeof AuthenticatedAppTabBarLayoutRouteWithChildren
  AuthenticatedAppIndexRoute: typeof AuthenticatedAppIndexRoute
  AuthenticatedAppImageEditIdRoute: typeof AuthenticatedAppImageEditIdRoute
  AuthenticatedAppPlaygroundPlayMessagingRoute: typeof AuthenticatedAppPlaygroundPlayMessagingRoute
  AuthenticatedAppPointSystemInviteRoute: typeof AuthenticatedAppPointSystemInviteRoute
  AuthenticatedAppSearchImagePostsRoute: typeof AuthenticatedAppSearchImagePostsRoute
  AuthenticatedAppSearchLiveEventsRoute: typeof AuthenticatedAppSearchLiveEventsRoute
  AuthenticatedAppSearchProfilesRoute: typeof AuthenticatedAppSearchProfilesRoute
  AuthenticatedAppSearchTextPostsRoute: typeof AuthenticatedAppSearchTextPostsRoute
  AuthenticatedAppSearchVideosRoute: typeof AuthenticatedAppSearchVideosRoute
  AuthenticatedAppTextEditIdRoute: typeof AuthenticatedAppTextEditIdRoute
  AuthenticatedAppVideoEditIdRoute: typeof AuthenticatedAppVideoEditIdRoute
  AuthenticatedAppPlaygroundPlayLanguageLazyRoute: typeof AuthenticatedAppPlaygroundPlayLanguageLazyRoute
  AuthenticatedAppFollowersIndexRoute: typeof AuthenticatedAppFollowersIndexRoute
  AuthenticatedAppFollowingsIndexRoute: typeof AuthenticatedAppFollowingsIndexRoute
  AuthenticatedAppImageEditIndexRoute: typeof AuthenticatedAppImageEditIndexRoute
  AuthenticatedAppImageUploadIndexRoute: typeof AuthenticatedAppImageUploadIndexRoute
  AuthenticatedAppLiveEventIndexRoute: typeof AuthenticatedAppLiveEventIndexRoute
  AuthenticatedAppMessageIndexRoute: typeof AuthenticatedAppMessageIndexRoute
  AuthenticatedAppNotificationsIndexRoute: typeof AuthenticatedAppNotificationsIndexRoute
  AuthenticatedAppPointSystemIndexRoute: typeof AuthenticatedAppPointSystemIndexRoute
  AuthenticatedAppPostitIndexRoute: typeof AuthenticatedAppPostitIndexRoute
  AuthenticatedAppRewardsExplainedIndexRoute: typeof AuthenticatedAppRewardsExplainedIndexRoute
  AuthenticatedAppSettingsIndexRoute: typeof AuthenticatedAppSettingsIndexRoute
  AuthenticatedAppTextEditIndexRoute: typeof AuthenticatedAppTextEditIndexRoute
  AuthenticatedAppTextUploadIndexRoute: typeof AuthenticatedAppTextUploadIndexRoute
  AuthenticatedAppVideoEditIndexRoute: typeof AuthenticatedAppVideoEditIndexRoute
  AuthenticatedAppVideoUploadIndexRoute: typeof AuthenticatedAppVideoUploadIndexRoute
  AuthenticatedAppVideoIndexRoute: typeof AuthenticatedAppVideoIndexRoute
  AuthenticatedAppFollowersOverviewIdRoute: typeof AuthenticatedAppFollowersOverviewIdRoute
  AuthenticatedAppFollowingsOverviewIdRoute: typeof AuthenticatedAppFollowingsOverviewIdRoute
  AuthenticatedAppLiveEventSummaryIdRoute: typeof AuthenticatedAppLiveEventSummaryIdRoute
  AuthenticatedAppLiveEventViewerIdRoute: typeof AuthenticatedAppLiveEventViewerIdRoute
  AuthenticatedAppMessageProfileIdRoute: typeof AuthenticatedAppMessageProfileIdRoute
  AuthenticatedAppPostitViewerIdRoute: typeof AuthenticatedAppPostitViewerIdRoute
  AuthenticatedAppVideoViewerIdRoute: typeof AuthenticatedAppVideoViewerIdRoute
  AuthenticatedAppFollowersOverviewIndexRoute: typeof AuthenticatedAppFollowersOverviewIndexRoute
  AuthenticatedAppFollowingsOverviewIndexRoute: typeof AuthenticatedAppFollowingsOverviewIndexRoute
  AuthenticatedAppLiveEventCreatorIndexRoute: typeof AuthenticatedAppLiveEventCreatorIndexRoute
  AuthenticatedAppLiveEventSummaryIndexRoute: typeof AuthenticatedAppLiveEventSummaryIndexRoute
  AuthenticatedAppLiveEventViewerIndexRoute: typeof AuthenticatedAppLiveEventViewerIndexRoute
  AuthenticatedAppMessageProfileIndexRoute: typeof AuthenticatedAppMessageProfileIndexRoute
  AuthenticatedAppMyProfileEditIndexRoute: typeof AuthenticatedAppMyProfileEditIndexRoute
  AuthenticatedAppMyProfileImageIndexRoute: typeof AuthenticatedAppMyProfileImageIndexRoute
  AuthenticatedAppPostitViewerIndexRoute: typeof AuthenticatedAppPostitViewerIndexRoute
  AuthenticatedAppSettingsMonetizationIndexRoute: typeof AuthenticatedAppSettingsMonetizationIndexRoute
  AuthenticatedAppSettingsNotificationsIndexRoute: typeof AuthenticatedAppSettingsNotificationsIndexRoute
  AuthenticatedAppSettingsRewardsIndexRoute: typeof AuthenticatedAppSettingsRewardsIndexRoute
  AuthenticatedAppSettingsWalletIndexRoute: typeof AuthenticatedAppSettingsWalletIndexRoute
  AuthenticatedAppVideoViewerIndexRoute: typeof AuthenticatedAppVideoViewerIndexRoute
  AuthenticatedAppSettingsRewardsBenefitsOverviewRoute: typeof AuthenticatedAppSettingsRewardsBenefitsOverviewRoute
  AuthenticatedAppSettingsRewardsChallengeDetailsIdRoute: typeof AuthenticatedAppSettingsRewardsChallengeDetailsIdRoute
  AuthenticatedAppSettingsMonetizationManageSubscriptionIndexRoute: typeof AuthenticatedAppSettingsMonetizationManageSubscriptionIndexRoute
  AuthenticatedAppSettingsRewardsBenefitsIndexRoute: typeof AuthenticatedAppSettingsRewardsBenefitsIndexRoute
  AuthenticatedAppSettingsRewardsChallengeDetailsIndexRoute: typeof AuthenticatedAppSettingsRewardsChallengeDetailsIndexRoute
}

const AuthenticatedAppRouteChildren: AuthenticatedAppRouteChildren = {
  AuthenticatedAppTabBarLayoutRoute:
    AuthenticatedAppTabBarLayoutRouteWithChildren,
  AuthenticatedAppIndexRoute: AuthenticatedAppIndexRoute,
  AuthenticatedAppImageEditIdRoute: AuthenticatedAppImageEditIdRoute,
  AuthenticatedAppPlaygroundPlayMessagingRoute:
    AuthenticatedAppPlaygroundPlayMessagingRoute,
  AuthenticatedAppPointSystemInviteRoute:
    AuthenticatedAppPointSystemInviteRoute,
  AuthenticatedAppSearchImagePostsRoute: AuthenticatedAppSearchImagePostsRoute,
  AuthenticatedAppSearchLiveEventsRoute: AuthenticatedAppSearchLiveEventsRoute,
  AuthenticatedAppSearchProfilesRoute: AuthenticatedAppSearchProfilesRoute,
  AuthenticatedAppSearchTextPostsRoute: AuthenticatedAppSearchTextPostsRoute,
  AuthenticatedAppSearchVideosRoute: AuthenticatedAppSearchVideosRoute,
  AuthenticatedAppTextEditIdRoute: AuthenticatedAppTextEditIdRoute,
  AuthenticatedAppVideoEditIdRoute: AuthenticatedAppVideoEditIdRoute,
  AuthenticatedAppPlaygroundPlayLanguageLazyRoute:
    AuthenticatedAppPlaygroundPlayLanguageLazyRoute,
  AuthenticatedAppFollowersIndexRoute: AuthenticatedAppFollowersIndexRoute,
  AuthenticatedAppFollowingsIndexRoute: AuthenticatedAppFollowingsIndexRoute,
  AuthenticatedAppImageEditIndexRoute: AuthenticatedAppImageEditIndexRoute,
  AuthenticatedAppImageUploadIndexRoute: AuthenticatedAppImageUploadIndexRoute,
  AuthenticatedAppLiveEventIndexRoute: AuthenticatedAppLiveEventIndexRoute,
  AuthenticatedAppMessageIndexRoute: AuthenticatedAppMessageIndexRoute,
  AuthenticatedAppNotificationsIndexRoute:
    AuthenticatedAppNotificationsIndexRoute,
  AuthenticatedAppPointSystemIndexRoute: AuthenticatedAppPointSystemIndexRoute,
  AuthenticatedAppPostitIndexRoute: AuthenticatedAppPostitIndexRoute,
  AuthenticatedAppRewardsExplainedIndexRoute:
    AuthenticatedAppRewardsExplainedIndexRoute,
  AuthenticatedAppSettingsIndexRoute: AuthenticatedAppSettingsIndexRoute,
  AuthenticatedAppTextEditIndexRoute: AuthenticatedAppTextEditIndexRoute,
  AuthenticatedAppTextUploadIndexRoute: AuthenticatedAppTextUploadIndexRoute,
  AuthenticatedAppVideoEditIndexRoute: AuthenticatedAppVideoEditIndexRoute,
  AuthenticatedAppVideoUploadIndexRoute: AuthenticatedAppVideoUploadIndexRoute,
  AuthenticatedAppVideoIndexRoute: AuthenticatedAppVideoIndexRoute,
  AuthenticatedAppFollowersOverviewIdRoute:
    AuthenticatedAppFollowersOverviewIdRoute,
  AuthenticatedAppFollowingsOverviewIdRoute:
    AuthenticatedAppFollowingsOverviewIdRoute,
  AuthenticatedAppLiveEventSummaryIdRoute:
    AuthenticatedAppLiveEventSummaryIdRoute,
  AuthenticatedAppLiveEventViewerIdRoute:
    AuthenticatedAppLiveEventViewerIdRoute,
  AuthenticatedAppMessageProfileIdRoute: AuthenticatedAppMessageProfileIdRoute,
  AuthenticatedAppPostitViewerIdRoute: AuthenticatedAppPostitViewerIdRoute,
  AuthenticatedAppVideoViewerIdRoute: AuthenticatedAppVideoViewerIdRoute,
  AuthenticatedAppFollowersOverviewIndexRoute:
    AuthenticatedAppFollowersOverviewIndexRoute,
  AuthenticatedAppFollowingsOverviewIndexRoute:
    AuthenticatedAppFollowingsOverviewIndexRoute,
  AuthenticatedAppLiveEventCreatorIndexRoute:
    AuthenticatedAppLiveEventCreatorIndexRoute,
  AuthenticatedAppLiveEventSummaryIndexRoute:
    AuthenticatedAppLiveEventSummaryIndexRoute,
  AuthenticatedAppLiveEventViewerIndexRoute:
    AuthenticatedAppLiveEventViewerIndexRoute,
  AuthenticatedAppMessageProfileIndexRoute:
    AuthenticatedAppMessageProfileIndexRoute,
  AuthenticatedAppMyProfileEditIndexRoute:
    AuthenticatedAppMyProfileEditIndexRoute,
  AuthenticatedAppMyProfileImageIndexRoute:
    AuthenticatedAppMyProfileImageIndexRoute,
  AuthenticatedAppPostitViewerIndexRoute:
    AuthenticatedAppPostitViewerIndexRoute,
  AuthenticatedAppSettingsMonetizationIndexRoute:
    AuthenticatedAppSettingsMonetizationIndexRoute,
  AuthenticatedAppSettingsNotificationsIndexRoute:
    AuthenticatedAppSettingsNotificationsIndexRoute,
  AuthenticatedAppSettingsRewardsIndexRoute:
    AuthenticatedAppSettingsRewardsIndexRoute,
  AuthenticatedAppSettingsWalletIndexRoute:
    AuthenticatedAppSettingsWalletIndexRoute,
  AuthenticatedAppVideoViewerIndexRoute: AuthenticatedAppVideoViewerIndexRoute,
  AuthenticatedAppSettingsRewardsBenefitsOverviewRoute:
    AuthenticatedAppSettingsRewardsBenefitsOverviewRoute,
  AuthenticatedAppSettingsRewardsChallengeDetailsIdRoute:
    AuthenticatedAppSettingsRewardsChallengeDetailsIdRoute,
  AuthenticatedAppSettingsMonetizationManageSubscriptionIndexRoute:
    AuthenticatedAppSettingsMonetizationManageSubscriptionIndexRoute,
  AuthenticatedAppSettingsRewardsBenefitsIndexRoute:
    AuthenticatedAppSettingsRewardsBenefitsIndexRoute,
  AuthenticatedAppSettingsRewardsChallengeDetailsIndexRoute:
    AuthenticatedAppSettingsRewardsChallengeDetailsIndexRoute,
}

const AuthenticatedAppRouteWithChildren =
  AuthenticatedAppRoute._addFileChildren(AuthenticatedAppRouteChildren)

interface AuthenticatedRouteChildren {
  AuthenticatedAppRoute: typeof AuthenticatedAppRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedAppRoute: AuthenticatedAppRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/*': typeof Route
  '': typeof AuthenticatedRouteWithChildren
  '/intro': typeof IntroRoute
  '/registration/cancellation-policy': typeof RegistrationCancellationPolicyRoute
  '/registration/confirmation': typeof RegistrationConfirmationRoute
  '/registration/cookie-consent': typeof RegistrationCookieConsentRoute
  '/registration/privacy-policy': typeof RegistrationPrivacyPolicyRoute
  '/registration/terms-and-condition': typeof RegistrationTermsAndConditionRoute
  '/registration': typeof RegistrationIndexRoute
  '/app': typeof AuthenticatedAppTabBarLayoutRouteWithChildren
  '/app/': typeof AuthenticatedAppIndexRoute
  '/app/image-edit/$id': typeof AuthenticatedAppImageEditIdRoute
  '/app/playground/play-messaging': typeof AuthenticatedAppPlaygroundPlayMessagingRoute
  '/app/point-system/invite': typeof AuthenticatedAppPointSystemInviteRoute
  '/app/search/image-posts': typeof AuthenticatedAppSearchImagePostsRoute
  '/app/search/live-events': typeof AuthenticatedAppSearchLiveEventsRoute
  '/app/search/profiles': typeof AuthenticatedAppSearchProfilesRoute
  '/app/search/text-posts': typeof AuthenticatedAppSearchTextPostsRoute
  '/app/search/videos': typeof AuthenticatedAppSearchVideosRoute
  '/app/text-edit/$id': typeof AuthenticatedAppTextEditIdRoute
  '/app/video-edit/$id': typeof AuthenticatedAppVideoEditIdRoute
  '/app/playground/play-language': typeof AuthenticatedAppPlaygroundPlayLanguageLazyRoute
  '/app/followers': typeof AuthenticatedAppFollowersIndexRoute
  '/app/followings': typeof AuthenticatedAppFollowingsIndexRoute
  '/app/image-edit': typeof AuthenticatedAppImageEditIndexRoute
  '/app/image-upload': typeof AuthenticatedAppImageUploadIndexRoute
  '/app/live-event': typeof AuthenticatedAppLiveEventIndexRoute
  '/app/message': typeof AuthenticatedAppMessageIndexRoute
  '/app/notifications': typeof AuthenticatedAppNotificationsIndexRoute
  '/app/point-system': typeof AuthenticatedAppPointSystemIndexRoute
  '/app/postit': typeof AuthenticatedAppPostitIndexRoute
  '/app/rewards-explained': typeof AuthenticatedAppRewardsExplainedIndexRoute
  '/app/settings': typeof AuthenticatedAppSettingsIndexRoute
  '/app/text-edit': typeof AuthenticatedAppTextEditIndexRoute
  '/app/text-upload': typeof AuthenticatedAppTextUploadIndexRoute
  '/app/video-edit': typeof AuthenticatedAppVideoEditIndexRoute
  '/app/video-upload': typeof AuthenticatedAppVideoUploadIndexRoute
  '/app/video': typeof AuthenticatedAppVideoIndexRoute
  '/app/user-profile/$id': typeof AuthenticatedAppTabBarLayoutUserProfileIdRoute
  '/app/followers/overview/$id': typeof AuthenticatedAppFollowersOverviewIdRoute
  '/app/followings/overview/$id': typeof AuthenticatedAppFollowingsOverviewIdRoute
  '/app/live-event/summary/$id': typeof AuthenticatedAppLiveEventSummaryIdRoute
  '/app/live-event/viewer/$id': typeof AuthenticatedAppLiveEventViewerIdRoute
  '/app/message/profile/$id': typeof AuthenticatedAppMessageProfileIdRoute
  '/app/postit/viewer/$id': typeof AuthenticatedAppPostitViewerIdRoute
  '/app/video/viewer/$id': typeof AuthenticatedAppVideoViewerIdRoute
  '/app/feed': typeof AuthenticatedAppTabBarLayoutFeedIndexRoute
  '/app/messages': typeof AuthenticatedAppTabBarLayoutMessagesIndexRoute
  '/app/my-profile': typeof AuthenticatedAppTabBarLayoutMyProfileIndexRoute
  '/app/search': typeof AuthenticatedAppTabBarLayoutSearchIndexRoute
  '/app/followers/overview': typeof AuthenticatedAppFollowersOverviewIndexRoute
  '/app/followings/overview': typeof AuthenticatedAppFollowingsOverviewIndexRoute
  '/app/live-event/creator': typeof AuthenticatedAppLiveEventCreatorIndexRoute
  '/app/live-event/summary': typeof AuthenticatedAppLiveEventSummaryIndexRoute
  '/app/live-event/viewer': typeof AuthenticatedAppLiveEventViewerIndexRoute
  '/app/message/profile': typeof AuthenticatedAppMessageProfileIndexRoute
  '/app/my-profile/edit': typeof AuthenticatedAppMyProfileEditIndexRoute
  '/app/my-profile/image': typeof AuthenticatedAppMyProfileImageIndexRoute
  '/app/postit/viewer': typeof AuthenticatedAppPostitViewerIndexRoute
  '/app/settings/monetization': typeof AuthenticatedAppSettingsMonetizationIndexRoute
  '/app/settings/notifications': typeof AuthenticatedAppSettingsNotificationsIndexRoute
  '/app/settings/rewards': typeof AuthenticatedAppSettingsRewardsIndexRoute
  '/app/settings/wallet': typeof AuthenticatedAppSettingsWalletIndexRoute
  '/app/video/viewer': typeof AuthenticatedAppVideoViewerIndexRoute
  '/app/settings/rewards/benefits/overview': typeof AuthenticatedAppSettingsRewardsBenefitsOverviewRoute
  '/app/settings/rewards/challenge-details/$id': typeof AuthenticatedAppSettingsRewardsChallengeDetailsIdRoute
  '/app/settings/monetization/manage-subscription': typeof AuthenticatedAppSettingsMonetizationManageSubscriptionIndexRoute
  '/app/settings/rewards/benefits': typeof AuthenticatedAppSettingsRewardsBenefitsIndexRoute
  '/app/settings/rewards/challenge-details': typeof AuthenticatedAppSettingsRewardsChallengeDetailsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/*': typeof Route
  '': typeof AuthenticatedRouteWithChildren
  '/intro': typeof IntroRoute
  '/registration/cancellation-policy': typeof RegistrationCancellationPolicyRoute
  '/registration/confirmation': typeof RegistrationConfirmationRoute
  '/registration/cookie-consent': typeof RegistrationCookieConsentRoute
  '/registration/privacy-policy': typeof RegistrationPrivacyPolicyRoute
  '/registration/terms-and-condition': typeof RegistrationTermsAndConditionRoute
  '/registration': typeof RegistrationIndexRoute
  '/app': typeof AuthenticatedAppIndexRoute
  '/app/image-edit/$id': typeof AuthenticatedAppImageEditIdRoute
  '/app/playground/play-messaging': typeof AuthenticatedAppPlaygroundPlayMessagingRoute
  '/app/point-system/invite': typeof AuthenticatedAppPointSystemInviteRoute
  '/app/search/image-posts': typeof AuthenticatedAppSearchImagePostsRoute
  '/app/search/live-events': typeof AuthenticatedAppSearchLiveEventsRoute
  '/app/search/profiles': typeof AuthenticatedAppSearchProfilesRoute
  '/app/search/text-posts': typeof AuthenticatedAppSearchTextPostsRoute
  '/app/search/videos': typeof AuthenticatedAppSearchVideosRoute
  '/app/text-edit/$id': typeof AuthenticatedAppTextEditIdRoute
  '/app/video-edit/$id': typeof AuthenticatedAppVideoEditIdRoute
  '/app/playground/play-language': typeof AuthenticatedAppPlaygroundPlayLanguageLazyRoute
  '/app/followers': typeof AuthenticatedAppFollowersIndexRoute
  '/app/followings': typeof AuthenticatedAppFollowingsIndexRoute
  '/app/image-edit': typeof AuthenticatedAppImageEditIndexRoute
  '/app/image-upload': typeof AuthenticatedAppImageUploadIndexRoute
  '/app/live-event': typeof AuthenticatedAppLiveEventIndexRoute
  '/app/message': typeof AuthenticatedAppMessageIndexRoute
  '/app/notifications': typeof AuthenticatedAppNotificationsIndexRoute
  '/app/point-system': typeof AuthenticatedAppPointSystemIndexRoute
  '/app/postit': typeof AuthenticatedAppPostitIndexRoute
  '/app/rewards-explained': typeof AuthenticatedAppRewardsExplainedIndexRoute
  '/app/settings': typeof AuthenticatedAppSettingsIndexRoute
  '/app/text-edit': typeof AuthenticatedAppTextEditIndexRoute
  '/app/text-upload': typeof AuthenticatedAppTextUploadIndexRoute
  '/app/video-edit': typeof AuthenticatedAppVideoEditIndexRoute
  '/app/video-upload': typeof AuthenticatedAppVideoUploadIndexRoute
  '/app/video': typeof AuthenticatedAppVideoIndexRoute
  '/app/user-profile/$id': typeof AuthenticatedAppTabBarLayoutUserProfileIdRoute
  '/app/followers/overview/$id': typeof AuthenticatedAppFollowersOverviewIdRoute
  '/app/followings/overview/$id': typeof AuthenticatedAppFollowingsOverviewIdRoute
  '/app/live-event/summary/$id': typeof AuthenticatedAppLiveEventSummaryIdRoute
  '/app/live-event/viewer/$id': typeof AuthenticatedAppLiveEventViewerIdRoute
  '/app/message/profile/$id': typeof AuthenticatedAppMessageProfileIdRoute
  '/app/postit/viewer/$id': typeof AuthenticatedAppPostitViewerIdRoute
  '/app/video/viewer/$id': typeof AuthenticatedAppVideoViewerIdRoute
  '/app/feed': typeof AuthenticatedAppTabBarLayoutFeedIndexRoute
  '/app/messages': typeof AuthenticatedAppTabBarLayoutMessagesIndexRoute
  '/app/my-profile': typeof AuthenticatedAppTabBarLayoutMyProfileIndexRoute
  '/app/search': typeof AuthenticatedAppTabBarLayoutSearchIndexRoute
  '/app/followers/overview': typeof AuthenticatedAppFollowersOverviewIndexRoute
  '/app/followings/overview': typeof AuthenticatedAppFollowingsOverviewIndexRoute
  '/app/live-event/creator': typeof AuthenticatedAppLiveEventCreatorIndexRoute
  '/app/live-event/summary': typeof AuthenticatedAppLiveEventSummaryIndexRoute
  '/app/live-event/viewer': typeof AuthenticatedAppLiveEventViewerIndexRoute
  '/app/message/profile': typeof AuthenticatedAppMessageProfileIndexRoute
  '/app/my-profile/edit': typeof AuthenticatedAppMyProfileEditIndexRoute
  '/app/my-profile/image': typeof AuthenticatedAppMyProfileImageIndexRoute
  '/app/postit/viewer': typeof AuthenticatedAppPostitViewerIndexRoute
  '/app/settings/monetization': typeof AuthenticatedAppSettingsMonetizationIndexRoute
  '/app/settings/notifications': typeof AuthenticatedAppSettingsNotificationsIndexRoute
  '/app/settings/rewards': typeof AuthenticatedAppSettingsRewardsIndexRoute
  '/app/settings/wallet': typeof AuthenticatedAppSettingsWalletIndexRoute
  '/app/video/viewer': typeof AuthenticatedAppVideoViewerIndexRoute
  '/app/settings/rewards/benefits/overview': typeof AuthenticatedAppSettingsRewardsBenefitsOverviewRoute
  '/app/settings/rewards/challenge-details/$id': typeof AuthenticatedAppSettingsRewardsChallengeDetailsIdRoute
  '/app/settings/monetization/manage-subscription': typeof AuthenticatedAppSettingsMonetizationManageSubscriptionIndexRoute
  '/app/settings/rewards/benefits': typeof AuthenticatedAppSettingsRewardsBenefitsIndexRoute
  '/app/settings/rewards/challenge-details': typeof AuthenticatedAppSettingsRewardsChallengeDetailsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/*': typeof Route
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/intro': typeof IntroRoute
  '/registration/cancellation-policy': typeof RegistrationCancellationPolicyRoute
  '/registration/confirmation': typeof RegistrationConfirmationRoute
  '/registration/cookie-consent': typeof RegistrationCookieConsentRoute
  '/registration/privacy-policy': typeof RegistrationPrivacyPolicyRoute
  '/registration/terms-and-condition': typeof RegistrationTermsAndConditionRoute
  '/registration/': typeof RegistrationIndexRoute
  '/_authenticated/app': typeof AuthenticatedAppRouteWithChildren
  '/_authenticated/app/_tab-bar-layout': typeof AuthenticatedAppTabBarLayoutRouteWithChildren
  '/_authenticated/app/': typeof AuthenticatedAppIndexRoute
  '/_authenticated/app/image-edit/$id': typeof AuthenticatedAppImageEditIdRoute
  '/_authenticated/app/playground/play-messaging': typeof AuthenticatedAppPlaygroundPlayMessagingRoute
  '/_authenticated/app/point-system/invite': typeof AuthenticatedAppPointSystemInviteRoute
  '/_authenticated/app/search/image-posts': typeof AuthenticatedAppSearchImagePostsRoute
  '/_authenticated/app/search/live-events': typeof AuthenticatedAppSearchLiveEventsRoute
  '/_authenticated/app/search/profiles': typeof AuthenticatedAppSearchProfilesRoute
  '/_authenticated/app/search/text-posts': typeof AuthenticatedAppSearchTextPostsRoute
  '/_authenticated/app/search/videos': typeof AuthenticatedAppSearchVideosRoute
  '/_authenticated/app/text-edit/$id': typeof AuthenticatedAppTextEditIdRoute
  '/_authenticated/app/video-edit/$id': typeof AuthenticatedAppVideoEditIdRoute
  '/_authenticated/app/playground/play-language': typeof AuthenticatedAppPlaygroundPlayLanguageLazyRoute
  '/_authenticated/app/followers/': typeof AuthenticatedAppFollowersIndexRoute
  '/_authenticated/app/followings/': typeof AuthenticatedAppFollowingsIndexRoute
  '/_authenticated/app/image-edit/': typeof AuthenticatedAppImageEditIndexRoute
  '/_authenticated/app/image-upload/': typeof AuthenticatedAppImageUploadIndexRoute
  '/_authenticated/app/live-event/': typeof AuthenticatedAppLiveEventIndexRoute
  '/_authenticated/app/message/': typeof AuthenticatedAppMessageIndexRoute
  '/_authenticated/app/notifications/': typeof AuthenticatedAppNotificationsIndexRoute
  '/_authenticated/app/point-system/': typeof AuthenticatedAppPointSystemIndexRoute
  '/_authenticated/app/postit/': typeof AuthenticatedAppPostitIndexRoute
  '/_authenticated/app/rewards-explained/': typeof AuthenticatedAppRewardsExplainedIndexRoute
  '/_authenticated/app/settings/': typeof AuthenticatedAppSettingsIndexRoute
  '/_authenticated/app/text-edit/': typeof AuthenticatedAppTextEditIndexRoute
  '/_authenticated/app/text-upload/': typeof AuthenticatedAppTextUploadIndexRoute
  '/_authenticated/app/video-edit/': typeof AuthenticatedAppVideoEditIndexRoute
  '/_authenticated/app/video-upload/': typeof AuthenticatedAppVideoUploadIndexRoute
  '/_authenticated/app/video/': typeof AuthenticatedAppVideoIndexRoute
  '/_authenticated/app/_tab-bar-layout/user-profile/$id': typeof AuthenticatedAppTabBarLayoutUserProfileIdRoute
  '/_authenticated/app/followers/overview/$id': typeof AuthenticatedAppFollowersOverviewIdRoute
  '/_authenticated/app/followings/overview/$id': typeof AuthenticatedAppFollowingsOverviewIdRoute
  '/_authenticated/app/live-event/summary/$id': typeof AuthenticatedAppLiveEventSummaryIdRoute
  '/_authenticated/app/live-event/viewer/$id': typeof AuthenticatedAppLiveEventViewerIdRoute
  '/_authenticated/app/message/profile/$id': typeof AuthenticatedAppMessageProfileIdRoute
  '/_authenticated/app/postit/viewer/$id': typeof AuthenticatedAppPostitViewerIdRoute
  '/_authenticated/app/video/viewer/$id': typeof AuthenticatedAppVideoViewerIdRoute
  '/_authenticated/app/_tab-bar-layout/feed/': typeof AuthenticatedAppTabBarLayoutFeedIndexRoute
  '/_authenticated/app/_tab-bar-layout/messages/': typeof AuthenticatedAppTabBarLayoutMessagesIndexRoute
  '/_authenticated/app/_tab-bar-layout/my-profile/': typeof AuthenticatedAppTabBarLayoutMyProfileIndexRoute
  '/_authenticated/app/_tab-bar-layout/search/': typeof AuthenticatedAppTabBarLayoutSearchIndexRoute
  '/_authenticated/app/followers/overview/': typeof AuthenticatedAppFollowersOverviewIndexRoute
  '/_authenticated/app/followings/overview/': typeof AuthenticatedAppFollowingsOverviewIndexRoute
  '/_authenticated/app/live-event/creator/': typeof AuthenticatedAppLiveEventCreatorIndexRoute
  '/_authenticated/app/live-event/summary/': typeof AuthenticatedAppLiveEventSummaryIndexRoute
  '/_authenticated/app/live-event/viewer/': typeof AuthenticatedAppLiveEventViewerIndexRoute
  '/_authenticated/app/message/profile/': typeof AuthenticatedAppMessageProfileIndexRoute
  '/_authenticated/app/my-profile/edit/': typeof AuthenticatedAppMyProfileEditIndexRoute
  '/_authenticated/app/my-profile/image/': typeof AuthenticatedAppMyProfileImageIndexRoute
  '/_authenticated/app/postit/viewer/': typeof AuthenticatedAppPostitViewerIndexRoute
  '/_authenticated/app/settings/monetization/': typeof AuthenticatedAppSettingsMonetizationIndexRoute
  '/_authenticated/app/settings/notifications/': typeof AuthenticatedAppSettingsNotificationsIndexRoute
  '/_authenticated/app/settings/rewards/': typeof AuthenticatedAppSettingsRewardsIndexRoute
  '/_authenticated/app/settings/wallet/': typeof AuthenticatedAppSettingsWalletIndexRoute
  '/_authenticated/app/video/viewer/': typeof AuthenticatedAppVideoViewerIndexRoute
  '/_authenticated/app/settings/rewards/benefits/overview': typeof AuthenticatedAppSettingsRewardsBenefitsOverviewRoute
  '/_authenticated/app/settings/rewards/challenge-details/$id': typeof AuthenticatedAppSettingsRewardsChallengeDetailsIdRoute
  '/_authenticated/app/settings/monetization/manage-subscription/': typeof AuthenticatedAppSettingsMonetizationManageSubscriptionIndexRoute
  '/_authenticated/app/settings/rewards/benefits/': typeof AuthenticatedAppSettingsRewardsBenefitsIndexRoute
  '/_authenticated/app/settings/rewards/challenge-details/': typeof AuthenticatedAppSettingsRewardsChallengeDetailsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/*'
    | ''
    | '/intro'
    | '/registration/cancellation-policy'
    | '/registration/confirmation'
    | '/registration/cookie-consent'
    | '/registration/privacy-policy'
    | '/registration/terms-and-condition'
    | '/registration'
    | '/app'
    | '/app/'
    | '/app/image-edit/$id'
    | '/app/playground/play-messaging'
    | '/app/point-system/invite'
    | '/app/search/image-posts'
    | '/app/search/live-events'
    | '/app/search/profiles'
    | '/app/search/text-posts'
    | '/app/search/videos'
    | '/app/text-edit/$id'
    | '/app/video-edit/$id'
    | '/app/playground/play-language'
    | '/app/followers'
    | '/app/followings'
    | '/app/image-edit'
    | '/app/image-upload'
    | '/app/live-event'
    | '/app/message'
    | '/app/notifications'
    | '/app/point-system'
    | '/app/postit'
    | '/app/rewards-explained'
    | '/app/settings'
    | '/app/text-edit'
    | '/app/text-upload'
    | '/app/video-edit'
    | '/app/video-upload'
    | '/app/video'
    | '/app/user-profile/$id'
    | '/app/followers/overview/$id'
    | '/app/followings/overview/$id'
    | '/app/live-event/summary/$id'
    | '/app/live-event/viewer/$id'
    | '/app/message/profile/$id'
    | '/app/postit/viewer/$id'
    | '/app/video/viewer/$id'
    | '/app/feed'
    | '/app/messages'
    | '/app/my-profile'
    | '/app/search'
    | '/app/followers/overview'
    | '/app/followings/overview'
    | '/app/live-event/creator'
    | '/app/live-event/summary'
    | '/app/live-event/viewer'
    | '/app/message/profile'
    | '/app/my-profile/edit'
    | '/app/my-profile/image'
    | '/app/postit/viewer'
    | '/app/settings/monetization'
    | '/app/settings/notifications'
    | '/app/settings/rewards'
    | '/app/settings/wallet'
    | '/app/video/viewer'
    | '/app/settings/rewards/benefits/overview'
    | '/app/settings/rewards/challenge-details/$id'
    | '/app/settings/monetization/manage-subscription'
    | '/app/settings/rewards/benefits'
    | '/app/settings/rewards/challenge-details'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/*'
    | ''
    | '/intro'
    | '/registration/cancellation-policy'
    | '/registration/confirmation'
    | '/registration/cookie-consent'
    | '/registration/privacy-policy'
    | '/registration/terms-and-condition'
    | '/registration'
    | '/app'
    | '/app/image-edit/$id'
    | '/app/playground/play-messaging'
    | '/app/point-system/invite'
    | '/app/search/image-posts'
    | '/app/search/live-events'
    | '/app/search/profiles'
    | '/app/search/text-posts'
    | '/app/search/videos'
    | '/app/text-edit/$id'
    | '/app/video-edit/$id'
    | '/app/playground/play-language'
    | '/app/followers'
    | '/app/followings'
    | '/app/image-edit'
    | '/app/image-upload'
    | '/app/live-event'
    | '/app/message'
    | '/app/notifications'
    | '/app/point-system'
    | '/app/postit'
    | '/app/rewards-explained'
    | '/app/settings'
    | '/app/text-edit'
    | '/app/text-upload'
    | '/app/video-edit'
    | '/app/video-upload'
    | '/app/video'
    | '/app/user-profile/$id'
    | '/app/followers/overview/$id'
    | '/app/followings/overview/$id'
    | '/app/live-event/summary/$id'
    | '/app/live-event/viewer/$id'
    | '/app/message/profile/$id'
    | '/app/postit/viewer/$id'
    | '/app/video/viewer/$id'
    | '/app/feed'
    | '/app/messages'
    | '/app/my-profile'
    | '/app/search'
    | '/app/followers/overview'
    | '/app/followings/overview'
    | '/app/live-event/creator'
    | '/app/live-event/summary'
    | '/app/live-event/viewer'
    | '/app/message/profile'
    | '/app/my-profile/edit'
    | '/app/my-profile/image'
    | '/app/postit/viewer'
    | '/app/settings/monetization'
    | '/app/settings/notifications'
    | '/app/settings/rewards'
    | '/app/settings/wallet'
    | '/app/video/viewer'
    | '/app/settings/rewards/benefits/overview'
    | '/app/settings/rewards/challenge-details/$id'
    | '/app/settings/monetization/manage-subscription'
    | '/app/settings/rewards/benefits'
    | '/app/settings/rewards/challenge-details'
  id:
    | '__root__'
    | '/'
    | '/*'
    | '/_authenticated'
    | '/intro'
    | '/registration/cancellation-policy'
    | '/registration/confirmation'
    | '/registration/cookie-consent'
    | '/registration/privacy-policy'
    | '/registration/terms-and-condition'
    | '/registration/'
    | '/_authenticated/app'
    | '/_authenticated/app/_tab-bar-layout'
    | '/_authenticated/app/'
    | '/_authenticated/app/image-edit/$id'
    | '/_authenticated/app/playground/play-messaging'
    | '/_authenticated/app/point-system/invite'
    | '/_authenticated/app/search/image-posts'
    | '/_authenticated/app/search/live-events'
    | '/_authenticated/app/search/profiles'
    | '/_authenticated/app/search/text-posts'
    | '/_authenticated/app/search/videos'
    | '/_authenticated/app/text-edit/$id'
    | '/_authenticated/app/video-edit/$id'
    | '/_authenticated/app/playground/play-language'
    | '/_authenticated/app/followers/'
    | '/_authenticated/app/followings/'
    | '/_authenticated/app/image-edit/'
    | '/_authenticated/app/image-upload/'
    | '/_authenticated/app/live-event/'
    | '/_authenticated/app/message/'
    | '/_authenticated/app/notifications/'
    | '/_authenticated/app/point-system/'
    | '/_authenticated/app/postit/'
    | '/_authenticated/app/rewards-explained/'
    | '/_authenticated/app/settings/'
    | '/_authenticated/app/text-edit/'
    | '/_authenticated/app/text-upload/'
    | '/_authenticated/app/video-edit/'
    | '/_authenticated/app/video-upload/'
    | '/_authenticated/app/video/'
    | '/_authenticated/app/_tab-bar-layout/user-profile/$id'
    | '/_authenticated/app/followers/overview/$id'
    | '/_authenticated/app/followings/overview/$id'
    | '/_authenticated/app/live-event/summary/$id'
    | '/_authenticated/app/live-event/viewer/$id'
    | '/_authenticated/app/message/profile/$id'
    | '/_authenticated/app/postit/viewer/$id'
    | '/_authenticated/app/video/viewer/$id'
    | '/_authenticated/app/_tab-bar-layout/feed/'
    | '/_authenticated/app/_tab-bar-layout/messages/'
    | '/_authenticated/app/_tab-bar-layout/my-profile/'
    | '/_authenticated/app/_tab-bar-layout/search/'
    | '/_authenticated/app/followers/overview/'
    | '/_authenticated/app/followings/overview/'
    | '/_authenticated/app/live-event/creator/'
    | '/_authenticated/app/live-event/summary/'
    | '/_authenticated/app/live-event/viewer/'
    | '/_authenticated/app/message/profile/'
    | '/_authenticated/app/my-profile/edit/'
    | '/_authenticated/app/my-profile/image/'
    | '/_authenticated/app/postit/viewer/'
    | '/_authenticated/app/settings/monetization/'
    | '/_authenticated/app/settings/notifications/'
    | '/_authenticated/app/settings/rewards/'
    | '/_authenticated/app/settings/wallet/'
    | '/_authenticated/app/video/viewer/'
    | '/_authenticated/app/settings/rewards/benefits/overview'
    | '/_authenticated/app/settings/rewards/challenge-details/$id'
    | '/_authenticated/app/settings/monetization/manage-subscription/'
    | '/_authenticated/app/settings/rewards/benefits/'
    | '/_authenticated/app/settings/rewards/challenge-details/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  Route: typeof Route
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  IntroRoute: typeof IntroRoute
  RegistrationCancellationPolicyRoute: typeof RegistrationCancellationPolicyRoute
  RegistrationConfirmationRoute: typeof RegistrationConfirmationRoute
  RegistrationCookieConsentRoute: typeof RegistrationCookieConsentRoute
  RegistrationPrivacyPolicyRoute: typeof RegistrationPrivacyPolicyRoute
  RegistrationTermsAndConditionRoute: typeof RegistrationTermsAndConditionRoute
  RegistrationIndexRoute: typeof RegistrationIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  Route: Route,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  IntroRoute: IntroRoute,
  RegistrationCancellationPolicyRoute: RegistrationCancellationPolicyRoute,
  RegistrationConfirmationRoute: RegistrationConfirmationRoute,
  RegistrationCookieConsentRoute: RegistrationCookieConsentRoute,
  RegistrationPrivacyPolicyRoute: RegistrationPrivacyPolicyRoute,
  RegistrationTermsAndConditionRoute: RegistrationTermsAndConditionRoute,
  RegistrationIndexRoute: RegistrationIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/*",
        "/_authenticated",
        "/intro",
        "/registration/cancellation-policy",
        "/registration/confirmation",
        "/registration/cookie-consent",
        "/registration/privacy-policy",
        "/registration/terms-and-condition",
        "/registration/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/*": {
      "filePath": "*.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/app"
      ]
    },
    "/intro": {
      "filePath": "intro.tsx"
    },
    "/registration/cancellation-policy": {
      "filePath": "registration/cancellation-policy.tsx"
    },
    "/registration/confirmation": {
      "filePath": "registration/confirmation.tsx"
    },
    "/registration/cookie-consent": {
      "filePath": "registration/cookie-consent.tsx"
    },
    "/registration/privacy-policy": {
      "filePath": "registration/privacy-policy.tsx"
    },
    "/registration/terms-and-condition": {
      "filePath": "registration/terms-and-condition.tsx"
    },
    "/registration/": {
      "filePath": "registration/index.tsx"
    },
    "/_authenticated/app": {
      "filePath": "_authenticated/app",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/app/_tab-bar-layout",
        "/_authenticated/app/",
        "/_authenticated/app/image-edit/$id",
        "/_authenticated/app/playground/play-messaging",
        "/_authenticated/app/point-system/invite",
        "/_authenticated/app/search/image-posts",
        "/_authenticated/app/search/live-events",
        "/_authenticated/app/search/profiles",
        "/_authenticated/app/search/text-posts",
        "/_authenticated/app/search/videos",
        "/_authenticated/app/text-edit/$id",
        "/_authenticated/app/video-edit/$id",
        "/_authenticated/app/playground/play-language",
        "/_authenticated/app/followers/",
        "/_authenticated/app/followings/",
        "/_authenticated/app/image-edit/",
        "/_authenticated/app/image-upload/",
        "/_authenticated/app/live-event/",
        "/_authenticated/app/message/",
        "/_authenticated/app/notifications/",
        "/_authenticated/app/point-system/",
        "/_authenticated/app/postit/",
        "/_authenticated/app/rewards-explained/",
        "/_authenticated/app/settings/",
        "/_authenticated/app/text-edit/",
        "/_authenticated/app/text-upload/",
        "/_authenticated/app/video-edit/",
        "/_authenticated/app/video-upload/",
        "/_authenticated/app/video/",
        "/_authenticated/app/followers/overview/$id",
        "/_authenticated/app/followings/overview/$id",
        "/_authenticated/app/live-event/summary/$id",
        "/_authenticated/app/live-event/viewer/$id",
        "/_authenticated/app/message/profile/$id",
        "/_authenticated/app/postit/viewer/$id",
        "/_authenticated/app/video/viewer/$id",
        "/_authenticated/app/followers/overview/",
        "/_authenticated/app/followings/overview/",
        "/_authenticated/app/live-event/creator/",
        "/_authenticated/app/live-event/summary/",
        "/_authenticated/app/live-event/viewer/",
        "/_authenticated/app/message/profile/",
        "/_authenticated/app/my-profile/edit/",
        "/_authenticated/app/my-profile/image/",
        "/_authenticated/app/postit/viewer/",
        "/_authenticated/app/settings/monetization/",
        "/_authenticated/app/settings/notifications/",
        "/_authenticated/app/settings/rewards/",
        "/_authenticated/app/settings/wallet/",
        "/_authenticated/app/video/viewer/",
        "/_authenticated/app/settings/rewards/benefits/overview",
        "/_authenticated/app/settings/rewards/challenge-details/$id",
        "/_authenticated/app/settings/monetization/manage-subscription/",
        "/_authenticated/app/settings/rewards/benefits/",
        "/_authenticated/app/settings/rewards/challenge-details/"
      ]
    },
    "/_authenticated/app/_tab-bar-layout": {
      "filePath": "_authenticated/app/_tab-bar-layout.tsx",
      "parent": "/_authenticated/app",
      "children": [
        "/_authenticated/app/_tab-bar-layout/user-profile/$id",
        "/_authenticated/app/_tab-bar-layout/feed/",
        "/_authenticated/app/_tab-bar-layout/messages/",
        "/_authenticated/app/_tab-bar-layout/my-profile/",
        "/_authenticated/app/_tab-bar-layout/search/"
      ]
    },
    "/_authenticated/app/": {
      "filePath": "_authenticated/app/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/image-edit/$id": {
      "filePath": "_authenticated/app/image-edit/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/playground/play-messaging": {
      "filePath": "_authenticated/app/playground/play-messaging.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/point-system/invite": {
      "filePath": "_authenticated/app/point-system/invite.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/search/image-posts": {
      "filePath": "_authenticated/app/search/image-posts.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/search/live-events": {
      "filePath": "_authenticated/app/search/live-events.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/search/profiles": {
      "filePath": "_authenticated/app/search/profiles.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/search/text-posts": {
      "filePath": "_authenticated/app/search/text-posts.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/search/videos": {
      "filePath": "_authenticated/app/search/videos.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/text-edit/$id": {
      "filePath": "_authenticated/app/text-edit/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/video-edit/$id": {
      "filePath": "_authenticated/app/video-edit/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/playground/play-language": {
      "filePath": "_authenticated/app/playground/play-language.lazy.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/followers/": {
      "filePath": "_authenticated/app/followers/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/followings/": {
      "filePath": "_authenticated/app/followings/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/image-edit/": {
      "filePath": "_authenticated/app/image-edit/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/image-upload/": {
      "filePath": "_authenticated/app/image-upload/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/live-event/": {
      "filePath": "_authenticated/app/live-event/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/message/": {
      "filePath": "_authenticated/app/message/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/notifications/": {
      "filePath": "_authenticated/app/notifications/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/point-system/": {
      "filePath": "_authenticated/app/point-system/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/postit/": {
      "filePath": "_authenticated/app/postit/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/rewards-explained/": {
      "filePath": "_authenticated/app/rewards-explained/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/": {
      "filePath": "_authenticated/app/settings/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/text-edit/": {
      "filePath": "_authenticated/app/text-edit/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/text-upload/": {
      "filePath": "_authenticated/app/text-upload/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/video-edit/": {
      "filePath": "_authenticated/app/video-edit/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/video-upload/": {
      "filePath": "_authenticated/app/video-upload/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/video/": {
      "filePath": "_authenticated/app/video/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/_tab-bar-layout/user-profile/$id": {
      "filePath": "_authenticated/app/_tab-bar-layout/user-profile/$id.tsx",
      "parent": "/_authenticated/app/_tab-bar-layout"
    },
    "/_authenticated/app/followers/overview/$id": {
      "filePath": "_authenticated/app/followers/overview/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/followings/overview/$id": {
      "filePath": "_authenticated/app/followings/overview/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/live-event/summary/$id": {
      "filePath": "_authenticated/app/live-event/summary/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/live-event/viewer/$id": {
      "filePath": "_authenticated/app/live-event/viewer/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/message/profile/$id": {
      "filePath": "_authenticated/app/message/profile/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/postit/viewer/$id": {
      "filePath": "_authenticated/app/postit/viewer/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/video/viewer/$id": {
      "filePath": "_authenticated/app/video/viewer/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/_tab-bar-layout/feed/": {
      "filePath": "_authenticated/app/_tab-bar-layout/feed/index.tsx",
      "parent": "/_authenticated/app/_tab-bar-layout"
    },
    "/_authenticated/app/_tab-bar-layout/messages/": {
      "filePath": "_authenticated/app/_tab-bar-layout/messages/index.tsx",
      "parent": "/_authenticated/app/_tab-bar-layout"
    },
    "/_authenticated/app/_tab-bar-layout/my-profile/": {
      "filePath": "_authenticated/app/_tab-bar-layout/my-profile/index.tsx",
      "parent": "/_authenticated/app/_tab-bar-layout"
    },
    "/_authenticated/app/_tab-bar-layout/search/": {
      "filePath": "_authenticated/app/_tab-bar-layout/search/index.tsx",
      "parent": "/_authenticated/app/_tab-bar-layout"
    },
    "/_authenticated/app/followers/overview/": {
      "filePath": "_authenticated/app/followers/overview/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/followings/overview/": {
      "filePath": "_authenticated/app/followings/overview/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/live-event/creator/": {
      "filePath": "_authenticated/app/live-event/creator/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/live-event/summary/": {
      "filePath": "_authenticated/app/live-event/summary/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/live-event/viewer/": {
      "filePath": "_authenticated/app/live-event/viewer/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/message/profile/": {
      "filePath": "_authenticated/app/message/profile/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/my-profile/edit/": {
      "filePath": "_authenticated/app/my-profile/edit/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/my-profile/image/": {
      "filePath": "_authenticated/app/my-profile/image/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/postit/viewer/": {
      "filePath": "_authenticated/app/postit/viewer/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/monetization/": {
      "filePath": "_authenticated/app/settings/monetization/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/notifications/": {
      "filePath": "_authenticated/app/settings/notifications/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/rewards/": {
      "filePath": "_authenticated/app/settings/rewards/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/wallet/": {
      "filePath": "_authenticated/app/settings/wallet/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/video/viewer/": {
      "filePath": "_authenticated/app/video/viewer/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/rewards/benefits/overview": {
      "filePath": "_authenticated/app/settings/rewards/benefits/overview.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/rewards/challenge-details/$id": {
      "filePath": "_authenticated/app/settings/rewards/challenge-details/$id.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/monetization/manage-subscription/": {
      "filePath": "_authenticated/app/settings/monetization/manage-subscription/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/rewards/benefits/": {
      "filePath": "_authenticated/app/settings/rewards/benefits/index.tsx",
      "parent": "/_authenticated/app"
    },
    "/_authenticated/app/settings/rewards/challenge-details/": {
      "filePath": "_authenticated/app/settings/rewards/challenge-details/index.tsx",
      "parent": "/_authenticated/app"
    }
  }
}
ROUTE_MANIFEST_END */
