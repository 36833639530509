import { InfiniteScroll } from "common/infinite-scroll"
import { FC } from "react"
import { ResultNotFoundPlaceholderView } from "./result-not-found-placeholder-view"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { UserProfileId } from "api/branded-types"
import { PostAreaTile } from "features/feed/areas/post/post-area-tile"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { isEmpty } from "fp-ts/string"
import { assetsQueryOptions } from "hooks/use-asset"
import { useImagePostsSearch } from "hooks/use-image-post-search"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"
import { fill } from "utils/array"
import { PostLoadingTile } from "./videos/post-loading-tile"

type SearchPageImageTabContentModel = {
    profileId?: UserProfileId
    creatorIds: UserProfileId[]
    searchKey: string
    debouncedSearchKey: string
    className?: string
    userDependandQueriesEnabled: boolean
}

export const SearchPageImageTabContent: FC<SearchPageImageTabContentModel> = ({
    searchKey,
    debouncedSearchKey,
    profileId,
    creatorIds,
    className,
    userDependandQueriesEnabled,
}) => {
    const { t } = useTranslation(["search"])

    const imagePostsQuerySearchQuery = useImagePostsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })

    const queryClient = useQueryClient()

    const profileIdsPages =
        imagePostsQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const postResourceAssetRefsPages = pipe(
        imagePostsQuerySearchQuery.data?.pages ?? [],

        A.map(page =>
            pipe(
                page.data,
                A.filter(post => post.type === "Image"),
                A.map(post => post.imageRef),
            ),
        ),
    )

    const assetQueries = useQueries({
        queries: postResourceAssetRefsPages.map((page, idx) =>
            assetsQueryOptions({
                pageNumber: idx,
                queryClient,
                resources: page,
            }),
        ),
    })

    const oneOfAssetQueriesLoading = assetQueries.some(query => query.isLoading)

    const isSearchKeyEmpty = isEmpty(debouncedSearchKey)

    const hasAvailableImagePosts =
        (imagePostsQuerySearchQuery.data?.pages.at(0)?.data.length ?? 0) > 0 &&
        !imagePostsQuerySearchQuery.isPending &&
        imagePostsQuerySearchQuery.isSuccess

    const isNoResultImagePost = !isSearchKeyEmpty && !hasAvailableImagePosts

    const imagePostsState = match(imagePostsQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const showLoadingState =
        imagePostsQuerySearchQuery.isFetchingNextPage ||
        imagePostsQuerySearchQuery.isLoading ||
        oneOfAssetQueriesLoading ||
        oneOfProfileQueriesLoading

    return (
        <InfiniteScroll
            disabled={!imagePostsQuerySearchQuery.hasNextPage}
            state={imagePostsState}
            threshold="100px"
            onLoadRequested={imagePostsQuerySearchQuery.fetchNextPage}
        >
            <div className={className}>
                {imagePostsQuerySearchQuery?.data?.pages.map(
                    (page, idx) =>
                        !profileQueries.at(idx)?.isLoading &&
                        !assetQueries.at(idx)?.isLoading &&
                        page.data.map(postit => (
                            <PostAreaTile
                                loc={{
                                    title: t("section.image.exclusive"),
                                }}
                                key={postit.id}
                                postId={postit.id}
                            />
                        )),
                )}
                {showLoadingState &&
                    fill(2, idx => <PostLoadingTile key={idx} />)}
            </div>
            {isNoResultImagePost && (
                <ResultNotFoundPlaceholderView searchKey={searchKey} />
            )}
        </InfiniteScroll>
    )
}
