import { useQuery } from "@tanstack/react-query"

import { UserId, UserProfileId } from "api/branded-types"
import { paymentCreatorClient } from "api/clients/payment-api-client"
import { QueryKeys } from "api/query-keys"

export const useIsPaymentActivated = (
    userId?: UserId,
    profileId?: UserProfileId,
) => {
    return useQuery({
        queryFn: () => getPaymentAvailable(userId, profileId),
        queryKey: QueryKeys.paymentAvailable(userId, profileId),
        enabled: !!userId && !!profileId,
    })
}

const getPaymentAvailable = async (
    userId?: UserId,
    profileId?: UserProfileId,
) => {
    if (!userId) throw new Error("UserId should be defined")
    if (!profileId) throw new Error("ProfileId should be defined")
    const res = await paymentCreatorClient.paymentAvailable({
        params: { userId, profileId },
    })

    return {
        isAvailable: res.data,
    }
}
