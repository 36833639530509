import { createFileRoute } from "@tanstack/react-router"
import { FallbackPending } from "features/routing/fallback-pending"

import { z } from "zod"

const RewardsPageTab = z.union([
    z.literal("get-started"),
    z.literal("activities"),
    z.literal("ranking"),
    z.literal("badge"),
    z.literal("challenges"),
])
export type RewardTab = z.infer<typeof RewardsPageTab>

export const RewardsPageSearchSchema = z.object({
    currentTab: RewardsPageTab.catch("get-started"),
})
export type RewardsPageSearchSchema = z.infer<typeof RewardsPageSearchSchema>

export const Route = createFileRoute("/_authenticated/app/settings/rewards/")({
    validateSearch: search => RewardsPageSearchSchema.parse(search),
    loader: () => {},
    pendingComponent: FallbackPending,
})
