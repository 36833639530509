import { makeApi } from "@zodios/core"
import z from "zod"

import {
    ApiError,
    BuyPostitByIdRequestBody,
    CreateContentReport,
    CreatePostit,
    CreatePostitResponse,
    Report,
    UpdatePostit,
    UpdatePostitResponse,
} from "../api-models"
import { PostitId, Url } from "../branded-types"

export const postitCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/postit",
        alias: "createPostit",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: CreatePostit,
            },
        ],
        response: CreatePostitResponse,
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/postit/:id",
        alias: "updatePostitById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: PostitId,
            },
            {
                name: "body",
                type: "Body",
                schema: UpdatePostit,
            },
        ],
        response: UpdatePostitResponse,
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/postit/:id",
        alias: "deletePostitById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: PostitId,
            },
        ],
        response: z.string({}).optional(),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "put",
        path: "/v1/postit/:id/image",
        alias: "uploadPostitImage",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: PostitId,
            },
        ],
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/postit/:id/buy",
        alias: "buyPostitById",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: BuyPostitByIdRequestBody,
            },
        ],
        response: z.object({
            data: Url,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "post",
        path: "/v1/postit/:id/report",
        alias: "reportPostitById",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: CreateContentReport,
            },
        ],
        response: z.object({
            data: Report,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
