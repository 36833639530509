import { DialogTitle } from "@radix-ui/react-dialog"
import { Button } from "common/button"
import { useDeviceInfo } from "features/user-profile/hooks/use-device-info"
import { FC } from "react"
import { HowItWorks } from "./how-it-works"
import { Intro } from "./intro"
import { Modal } from "./modal"
import { ModalContent } from "./modal-content"
import { ModalFooter } from "./modal-footer"

type Props = {
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const StandaloneWelcomeModal: FC<Props> = model => {
    const { browser } = useDeviceInfo()

    return (
        <Modal open={model.open} onOpenChange={model.onOpenChange}>
            <ModalContent
                style={{
                    maxWidth: 400,
                    justifyContent: "space-between",
                    height: "100dvh",
                    background: "none",
                    boxShadow: "none",
                }}
            >
                <div style={{ flex: "1 1 auto" }}>
                    {browser !== "safari" && (
                        <img
                            style={{
                                position: "absolute",
                                height: 75,
                                right: 0,
                                transform: "translateX(-25%)",
                                zIndex: 1000,
                                alignSelf: "end",
                            }}
                            src="/tutorial-arrow-up.svg"
                        />
                    )}
                </div>

                <div
                    style={{
                        backgroundColor: "white",
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        textAlign: "center",
                        padding: "6px 24px",
                        gap: "12px",
                        borderRadius: "16px",
                        overflowY: "auto",
                        flex: "0 0 auto",
                    }}
                >
                    <DialogTitle />

                    <img
                        src="/dashboard-seemee-icon.svg"
                        style={{ width: 75, height: 75 }}
                    />

                    <Intro />
                    <HowItWorks />

                    <ModalFooter>
                        <Button
                            style={{ zIndex: 1000 }}
                            variant="ghost"
                            onClick={() => model.onOpenChange(false)}
                        >
                            Skip
                        </Button>
                    </ModalFooter>
                </div>

                <div style={{ flex: "1 1 auto" }}>
                    {browser === "safari" && (
                        <img
                            src="/tutorial-arrow-down.svg"
                            style={{
                                height: 90,
                                bottom: 0,
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                        />
                    )}
                </div>
            </ModalContent>
        </Modal>
    )
}
