import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "react-oidc-context"

import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../common/drawer"
import { LocalStorage } from "../local-storage"
import { privacyPolicyUrl, termsAndConditionsUrl } from "../utils/constant"

import { Button } from "common/button"
import { useMe } from "hooks/use-me"
import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import * as styles from "./continue-registration-by-email-modal.css"

// Show this modal only for trial accounts
export const ContinueRegistrationByEmailModal: FC = () => {
    const closeModal = useAppStore(store => store.closeModal)
    const isModalVisible = useAppStore(store => store.isModalVisible)

    const { t } = useTranslation(["registration"])
    const auth = useAuth()
    const meQuery = useMe(auth.isAuthenticated)
    const userId = auth.user?.profile.sub

    const onCreateAccount = useCallback(
        () =>
            auth.signinRedirect({
                extraQueryParams:
                    auth.user && userId
                        ? {
                              auth_policy: "emailVerification",
                              userId,
                              login_hint: meQuery.isSuccess
                                  ? meQuery.data.user.username
                                  : (LocalStorage.getUsernameOrUndefined() ??
                                    ""),
                              privacyPolicyUrl,
                              termsAndConditionsUrl,
                          }
                        : {
                              auth_policy: "trialSignUp",
                              login_hint: meQuery.isSuccess
                                  ? meQuery.data.user.username
                                  : (LocalStorage.getUsernameOrUndefined() ??
                                    ""),
                              privacyPolicyUrl,
                              termsAndConditionsUrl,
                          },
            }),
        [auth, meQuery, userId],
    )

    return (
        <Drawer
            open={
                isModalVisible.ContinueRegistrationByEmail.visible &&
                LocalStorage.getAccountTypeOrDefault() === "Trial"
            }
            onClose={() => closeModal("ContinueRegistrationByEmail")}
        >
            <DrawerContent className={styles.modal}>
                <DrawerTitle />
                <DrawerDescription />
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <svg className={styles.logo}>
                            <use href="/app-logo.svg#app_logo" />
                        </svg>
                        <h1
                            style={{ margin: 0, fontSize: vars.font.size.xxxl }}
                        >
                            <strong>{t("verifyAccount.title")}</strong>
                        </h1>
                        <p
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {t("verifyAccount.message")}
                        </p>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button full onClick={onCreateAccount}>
                            {t("verifyAccount.action")}
                        </Button>
                        <DrawerClose asChild>
                            <Button variant="ghost" full>
                                {t("verifyAccount.cancel")}
                            </Button>
                        </DrawerClose>
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
