import { createFileRoute } from "@tanstack/react-router"
import { FeedOverviewPageLoading } from "features/feed/feed-overview-page"
import { meQueryOptions } from "hooks/use-me"
import {
    myUserProfilesQueryOptions,
    ProfilesResponse,
} from "hooks/use-my-user-profiles"
import { recommendedPostitsInfiniteQueryOptions } from "hooks/use-recommended-postits"
import { recommendedVideosInfiniteQueryOptions } from "hooks/use-recommended-videos"
import { topUsersInfiniteQueryOptions } from "hooks/use-top-users"
import {
    UserFollowingsInfiniteData,
    userFollowingsInfiniteQueryOptions,
} from "hooks/use-user-followings"

export const Route = createFileRoute(
    "/_authenticated/app/_tab-bar-layout/feed/",
)({
    loader: async ({ context }) => {
        const meRes = await context.queryClient.ensureQueryData(
            meQueryOptions({ queryClient: context.queryClient }),
        )
        const isAuthorizedAccount = meRes.accountType === "User"

        let myProfilesRes: ProfilesResponse | null = null
        try {
            myProfilesRes = await context.queryClient.ensureQueryData(
                myUserProfilesQueryOptions({ enabled: isAuthorizedAccount }),
            )
        } catch (err) {
            console.error(err)
        }

        const profileId = myProfilesRes?.profiles?.at(0)?.id

        context.queryClient.ensureInfiniteQueryData(
            topUsersInfiniteQueryOptions({
                queryClient: context.queryClient,
                userId: meRes.user.id,
            }),
        )

        let followingUserProfilesRes: UserFollowingsInfiniteData = {
            pages: [
                {
                    data: [],
                    paging: { type: "Index", direction: "After", limit: 50 },
                    totalCount: 0,
                },
            ],
            pageParams: [],
        }

        try {
            if (profileId) {
                followingUserProfilesRes =
                    await context.queryClient.ensureInfiniteQueryData(
                        userFollowingsInfiniteQueryOptions({
                            queryClient: context.queryClient,
                            profileId,
                        }),
                    )
            }
        } catch (err) {
            console.error(err)
        }

        const followingUserProfileIds = followingUserProfilesRes?.pages.flatMap(
            page => page.data.map(item => item.id),
        )

        Promise.allSettled([
            context.queryClient.ensureInfiniteQueryData(
                recommendedPostitsInfiniteQueryOptions({
                    queryClient: context.queryClient,
                    profileId,
                    followingUserProfileIds,
                }),
            ),
            context.queryClient.ensureInfiniteQueryData(
                recommendedVideosInfiniteQueryOptions({
                    queryClient: context.queryClient,
                    profileId,
                    followingUserProfileIds,
                }),
            ),
        ])
    },
    pendingComponent: FeedOverviewPageLoading,
})
