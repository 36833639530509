import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { FC, memo } from "react"

import { UserId } from "../../../../api/branded-types"
import { useTopUsers } from "../../../../hooks/use-top-users"
import { TopUserAreaLoadingTile, TopUserAreaTile } from "./top-user-area-tile"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./top-user-area-view.css"

export type TopUserAreaViewModel = {
    title: string
    userId?: UserId
}

const NonMemoizedTopUserAreaView: FC<TopUserAreaViewModel> = ({
    title,
    userId,
}) => {
    const topUsersQuery = useTopUsers(userId)
    const data = topUsersQuery.data?.pages.flatMap(page => page.data) ?? []

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <h1
                    style={{
                        fontSize: vars.font.size.l,
                        color: "white",
                    }}
                >
                    {title}
                </h1>
            </div>

            <div className={styles.content}>
                {
                    topUsersQuery.isSuccess &&
                        pipe(
                            data,
                            A.map(current => (
                                <TopUserAreaTile
                                    key={current.id}
                                    profileId={current.id}
                                />
                            )),
                        )
                    //TODO: add proper error state
                }

                {topUsersQuery.isPending &&
                    !topUsersQuery.isPlaceholderData && (
                        <>
                            <TopUserAreaLoadingTile />
                            <TopUserAreaLoadingTile />
                            <TopUserAreaLoadingTile />
                            <TopUserAreaLoadingTile />
                        </>
                    )}
            </div>
        </div>
    )
}

export const TopUserAreaViewLoading = () => (
    <div className={styles.wrapper}>
        <div className={styles.header}>
            <p style={{ marginLeft: 4 }}>
                <Skeleton width={150} height={26} borderRadius={24} />
            </p>
        </div>
        <div className={styles.content} style={{ overflow: "hidden" }}>
            <TopUserAreaLoadingTile />
            <TopUserAreaLoadingTile />
            <TopUserAreaLoadingTile />
            <TopUserAreaLoadingTile />
        </div>
    </div>
)

const arePropsEqual = (
    prev: TopUserAreaViewModel,
    next: TopUserAreaViewModel,
) => prev.title === next.title && prev.userId === next.userId

export const TopUserAreaView = memo(NonMemoizedTopUserAreaView, arePropsEqual)
