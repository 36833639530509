import { FC, ReactNode } from "react"
import { useInAppToastNotifications } from "../hooks/use-in-app-toast-notifications"

type NotificationProviderModel = { children: ReactNode }

export const NotificationProvider: FC<NotificationProviderModel> = ({
    children,
}) => {
    useInAppToastNotifications()

    return children
}
