import { ErrorComponentProps, Link, useLocation } from "@tanstack/react-router"
import { Button } from "common/button"
import { Content } from "common/content"
import { Page } from "common/page"
import { FC } from "react"
import * as styles from "./root-boundary.css"

export const RootBoundary: FC<ErrorComponentProps> = ({ error }) => {
    const location = useLocation()
    console.error("root boundary error", error)

    const handleRefresh = () => refreshPage(location.pathname)

    const isUpdatable =
        error instanceof Error &&
        (error.message.includes(
            "Failed to fetch dynamically imported module",
        ) ||
            error.message.includes("Importing a module script failed"))

    return isUpdatable ? (
        <UpdateInformation onRefresh={handleRefresh} />
    ) : (
        <ErrorPage />
    )
}

const UpdateInformation: FC<{ onRefresh: () => void }> = ({ onRefresh }) => (
    <Page>
        <Content className={styles.content}>
            <h2>New Version of the App is available!</h2>

            <p style={{ marginTop: 16 }}>
                To access it, please refresh the page.
            </p>

            <Button full variant="primary" onClick={onRefresh}>
                Refresh
            </Button>
        </Content>
    </Page>
)

const ErrorPage: FC = () => (
    <Page>
        <Content className={styles.content}>
            <h2>Something went wrong!</h2>

            <Button full variant="primary" asChild>
                <Link to="/app/feed">Go back to feed</Link>
            </Button>
        </Content>
    </Page>
)

const refreshPage = (path?: string) => {
    if (path) window.location.href = path
    else window.location.reload()
}
