import { FC, useMemo } from "react"
import { match } from "ts-pattern"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { useLiveEventsSearch } from "../../hooks/use-live-event-search"
import { LiveEventSearchSectionLoading } from "./live-events/live-event-search-result-list"
import { ResultNotFoundPlaceholder } from "./placeholder/result-not-found-placeholder"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { getIsAuthorizedAccount } from "api/api-utils"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useMe } from "hooks/use-me"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { LocalStorage } from "local-storage"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import { fill } from "utils/array"
import { Content } from "../../common/content"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import {
    LiveEventSearchResultLoadingTile,
    LiveEventSearchResultTile,
} from "./live-events/live-event-search-result-tile"
import * as styles from "./search-sub-pages.css"

export const SearchLiveEventsPage: FC = () => {
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const { t } = useTranslation(["searchImagePosts"])
    const liveEventQuerySearchQuery = useLiveEventsSearch({
        searchKey: "",
        profileId,
        creatorIds: [],
    })

    const liveEventState = match(liveEventQuerySearchQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const liveEventQuerySearchData =
        liveEventQuerySearchQuery.data?.pages.flatMap(page => page.data) ?? []

    const queryClient = useQueryClient()

    const profileIdsPages =
        liveEventQuerySearchQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const showLoadingState =
        liveEventQuerySearchQuery.isFetchingNextPage ||
        liveEventQuerySearchQuery.isLoading ||
        oneOfProfileQueriesLoading

    const refreshSearchLiveEventsPage = async () => {
        await liveEventQuerySearchQuery.refetch()
    }

    return (
        <Page>
            <NavigationBackButton />

            <Content className={styles.pageContent}>
                <PullToRefresh onRefresh={refreshSearchLiveEventsPage}>
                    <div className={styles.header}>
                        <p
                            style={{
                                marginLeft: 4,
                                fontSize: vars.font.size.xxxl,
                                fontWeight: 700,
                                color: "white",
                            }}
                        >
                            {t("live.events.title")}
                        </p>
                    </div>
                    <InfiniteScroll
                        disabled={!liveEventQuerySearchQuery.hasNextPage}
                        state={liveEventState}
                        threshold="100px"
                        onLoadRequested={
                            liveEventQuerySearchQuery.fetchNextPage
                        }
                    >
                        <div className={styles.list}>
                            {liveEventQuerySearchQuery.data?.pages.map(
                                (page, idx) =>
                                    !profileQueries.at(idx)?.isLoading &&
                                    page.data.map(event => (
                                        <LiveEventSearchResultTile
                                            key={event.id}
                                            liveEventId={event.id}
                                            stateText={"Live"}
                                        />
                                    )),
                            )}
                            {showLoadingState &&
                                fill(4, idx => (
                                    <LiveEventSearchResultLoadingTile
                                        key={idx}
                                    />
                                ))}
                        </div>
                    </InfiniteScroll>

                    {liveEventQuerySearchQuery.isSuccess &&
                    liveEventQuerySearchData.length === 0 ? (
                        <ResultNotFoundPlaceholder
                            title={t("live.events.fallback.title")}
                            description={t("live.events.fallback.description")}
                        />
                    ) : (
                        <></>
                    )}
                </PullToRefresh>
            </Content>
        </Page>
    )
}

export const SearchLiveEventsPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content className={styles.pageContent}>
            <LiveEventSearchSectionLoading />
        </Content>
    </Page>
)

export default SearchLiveEventsPage
