import { faTrash } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Button } from "common/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "common/dialog"
import * as styles from "./delete-post-dialog.css"

type DeletePostDialogTextModel = {
    title: string
    description: string
    cancel: string
    submit: string
}

export type DeletePostDialogModel = {
    text: DeletePostDialogTextModel
    open: boolean
    onSubmit: () => void
    onOpenChange: (open: boolean) => void
}
export const DeletePostDialog = (model: DeletePostDialogModel) => (
    <Dialog open={model.open} onOpenChange={model.onOpenChange}>
        <DialogContent className={styles.content}>
            <FontAwesomeIcon
                icon={faTrash}
                className={styles.icon}
                color="black"
            />
            <div className={styles.textContainer}>
                <DialogTitle className={styles.title}>
                    {model.text.title}
                </DialogTitle>
                <DialogDescription className={styles.description}>
                    {model.text.description}
                </DialogDescription>
            </div>
            <div className={styles.buttonWrapper}>
                <Button
                    variant="control"
                    className={styles.button}
                    onClick={() => model.onOpenChange(false)}
                >
                    {model.text.cancel}
                </Button>
                <Button
                    variant="live"
                    className={styles.button}
                    onClick={model.onSubmit}
                >
                    {model.text.submit}
                </Button>
            </div>
        </DialogContent>
    </Dialog>
)
