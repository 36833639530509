import { faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC } from "react"

import { UserProfileId } from "../../../../api/branded-types"
import { AvatarLoading } from "../../../../components/avatars/avatar-loading"

import { Link } from "@tanstack/react-router"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./user-search-result-list-item.css"

export type UserSearchResultListItemModel = {
    profileId: UserProfileId
    className?: string
    locationNotAvailable: string
    avatarSize?: number
}
export const UserSearchResultListItem: FC<UserSearchResultListItemModel> = ({
    profileId,
    className = "",
    avatarSize,
    locationNotAvailable,
}) => {
    const myProfileIdQuery = useMyUserProfileId()
    const profileQuery = useListUserProfileById(profileId)
    const profile = profileQuery.data?.data

    if (!profileQuery.isSuccess || !profile) return
    return (
        <Link
            to={
                myProfileIdQuery.data === profile.id
                    ? "/app/my-profile"
                    : "/app/user-profile/$id"
            }
            params={{ id: profile.id }}
            className={styles.linkWrapper}
        >
            <div className={classNames(styles.wrapper, className)}>
                <AvatarWithBadge
                    profileId={profileId}
                    src={profile.imageUrl}
                    size={avatarSize}
                    styleState={profile.isLive ? "isLive" : "None"}
                />

                <div className={styles.maxWidth}>
                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                            color: "var(--text-color, white)",
                            textAlign: "left",
                        }}
                    >
                        {profile.displayName && profile.displayName.length > 0
                            ? profile.displayName
                            : profile.profileName}
                    </p>

                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                            color: vars.color.tertiary.hex,
                            textAlign: "left",
                        }}
                    >
                        {profile.location && profile.location.length > 0
                            ? profile.location
                            : locationNotAvailable}
                    </p>
                </div>

                <FontAwesomeIcon
                    className={styles.navButton}
                    icon={faAngleRight}
                    color="var(--nav-icon-color, white)"
                />
            </div>
        </Link>
    )
}

export type UserResultListLoadingItemModel = {
    className?: string
    avatarSize?: number
}

export const UserResultListLoadingItem: FC<UserResultListLoadingItemModel> = ({
    className,
    avatarSize,
}) => (
    <div className={classNames(styles.wrapper, className)}>
        <AvatarLoading baseColor="#3C375C" size={avatarSize} />

        <div className={styles.maxWidth}>
            <Skeleton width={224} height={15} borderRadius={24} />
            <Skeleton width={164} height={15} borderRadius={24} />
        </div>
    </div>
)
