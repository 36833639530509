import { useMutation, useQueryClient } from "@tanstack/react-query"

import {
    DatabaseNotificationId,
    UserProfileId,
} from "../../../api/branded-types"
import { notificationCreatorClient } from "../../../api/clients/notification-api-client"
import {
    QueryKeys,
    QueryKeyValue,
    StaticQueryKeys,
} from "../../../api/query-keys"
import {
    InfiniteNotificationsData,
    UnreadNotificationsData,
} from "../notifications-page"

type UseReadNotificationMutationParams = {
    notificationIdSet: Set<DatabaseNotificationId>
    selectedPageQueryKey?: QueryKeyValue
    profileId?: UserProfileId
}

export const useReadMultipleNotificationsMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({
            notificationIdSet,
        }: UseReadNotificationMutationParams) =>
            notificationCreatorClient.readNotifications({
                notificationIds: Array.from(notificationIdSet),
            }),
        onMutate: ({ profileId, notificationIdSet, selectedPageQueryKey }) => {
            const unreadNotificationsQueryKey =
                QueryKeys.unreadNotifications(profileId)

            queryClient.cancelQueries({
                queryKey: unreadNotificationsQueryKey,
            })
            if (selectedPageQueryKey) {
                queryClient.cancelQueries({
                    queryKey: selectedPageQueryKey,
                })
            }

            const previousUnreadNotifications =
                queryClient.getQueryData<UnreadNotificationsData>(
                    unreadNotificationsQueryKey,
                )

            let previousSelectedPageNotificationCenterItems:
                | InfiniteNotificationsData
                | undefined = undefined

            if (selectedPageQueryKey) {
                previousSelectedPageNotificationCenterItems =
                    queryClient.getQueryData<InfiniteNotificationsData>(
                        selectedPageQueryKey,
                    )
            }

            if (previousUnreadNotifications) {
                queryClient.setQueryData<UnreadNotificationsData>(
                    unreadNotificationsQueryKey,
                    {
                        ...previousUnreadNotifications,
                        totalCount:
                            previousUnreadNotifications.totalCount -
                            notificationIdSet.size,
                    },
                )
            }

            if (
                previousSelectedPageNotificationCenterItems &&
                selectedPageQueryKey
            ) {
                queryClient.setQueryData<InfiniteNotificationsData>(
                    selectedPageQueryKey,
                    {
                        ...previousSelectedPageNotificationCenterItems,
                        pages: previousSelectedPageNotificationCenterItems.pages.map(
                            page => ({
                                ...page,
                                data: page.data.map(notification =>
                                    notificationIdSet.has(notification.id)
                                        ? {
                                              ...notification,
                                              notificationType:
                                                  "UserNotification",
                                          }
                                        : notification,
                                ),
                            }),
                        ),
                    },
                )
            }
            return {
                previousSelectedPageNotificationCenterItems,
                previousUnreadNotifications,
            }
        },

        onError: (err, { profileId, selectedPageQueryKey }, context) => {
            console.error(err)
            if (
                context?.previousSelectedPageNotificationCenterItems &&
                selectedPageQueryKey
            ) {
                queryClient.setQueryData(
                    selectedPageQueryKey,
                    context.previousSelectedPageNotificationCenterItems,
                )
            }
            if (context?.previousUnreadNotifications) {
                queryClient.setQueryData(
                    QueryKeys.unreadNotifications(profileId),
                    context.previousUnreadNotifications,
                )
            }
        },

        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [
                    StaticQueryKeys.NOTIFICATIONS,
                    StaticQueryKeys.NOTIFICATION_CENTER,
                ],
            })
        },
    })
}
