import { createFileRoute } from "@tanstack/react-router"
import { FallbackPending } from "features/routing/fallback-pending"
import { z } from "zod"

export const MonetizationPageSearchSchema = z.object({
    monetizationSetupSuccess: z.literal(true).optional(),
    registrationFailed: z.literal(true).optional(),
    loginFailed: z.literal(true).optional(),
})
export type MonetizationPageSearchSchema = z.infer<
    typeof MonetizationPageSearchSchema
>

export const Route = createFileRoute(
    "/_authenticated/app/settings/monetization/",
)({
    validateSearch: search => MonetizationPageSearchSchema.parse(search),
    loader: () => {},
    pendingComponent: FallbackPending,
})
