import { FC } from "react"

import { UserMessageId } from "api/branded-types"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import { messageTimeInfo } from "utils/time"
import { FoldableStatefulText } from "../../components/texts/foldable-stateful-text"
import { useMessageById } from "./hooks/use-message-by-id"
import * as styles from "./my-message-item.css"

type Props = {
    showTime: boolean
    messageId: UserMessageId
}

export const MyMessageItem: FC<Props> = ({ showTime, messageId }) => {
    const { t, i18n } = useTranslation("common")
    const messageQuery = useMessageById(messageId)
    if (!messageQuery.isSuccess) return null

    const message = messageQuery.data

    return (
        <div className={styles.message}>
            {showTime && (
                <p
                    style={{
                        textAlign: "right",
                        margin: "0 16px",
                        color: vars.color.tertiary.hex,
                        fontSize: vars.font.size.xs,
                    }}
                >
                    {messageTimeInfo({
                        date: message.createdAt,
                        i18nLang: i18n.language,
                        t,
                    })}
                </p>
            )}

            <FoldableStatefulText
                className={styles.text}
                open={false}
                maxLength={750}
                text={message.text.trim()}
                fontSize={vars.font.size.regular}
            />
        </div>
    )
}
