import { Link } from "@tanstack/react-router"
import { DatabaseNotification } from "api/api-models"
import { AxiosError } from "axios"
import classNames from "classnames"
import { Button } from "common/button"
import { Image } from "common/image"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import {
    getNotificationDetailsDataIds,
    PostCommentedNotificationDetails,
    PostLikedNotificationDetails,
    PostNotificationDetails,
    VideoCommentedNotificationDetails,
    VideoLikedNotificationDetails,
    VideoNotificationDetails,
} from "data-flow/notification"
import { useListPostitById } from "features/postit/hooks/use-list-postit-by-id"
import { useListVideoById } from "features/video/hooks/use-list-video-by-id"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { useAsset } from "hooks/use-asset"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { FC, forwardRef } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { AvatarLoading } from "../../components/avatars/avatar-loading"
import { formatRelativeTimeShortToParts } from "../locales/formats"
import { getNavigateUrlByActionDocument } from "./notification"
import * as styles from "./notification-center-item.css"

export type NotificationCenterItemModel = {
    className?: string
    notification: DatabaseNotification
}

const NotificationCenterItemInner = (
    model: NotificationCenterItemModel,
    ref: React.Ref<HTMLDivElement>,
) => {
    const myProfileIdQuery = useMyUserProfileId()

    const profileQuery = useListUserProfileById(model.notification.senderId)

    const profileDoesNotExist =
        profileQuery.isError &&
        profileQuery.error instanceof AxiosError &&
        profileQuery.error.code === "404"

    const profileData = profileQuery.data?.data
    const navigateUrl = getNavigateUrlByActionDocument(
        model.notification.pushNotification.clickAction,
    )

    const details = getNotificationDetailsDataIds(
        model.notification.pushNotification.clickAction,
    )

    const isVideoDetailsContent =
        details.type === "VideoCommentedNotificationDetails" ||
        details.type === "VideoLikedNotificationDetails" ||
        details.type === "VideoNotificationDetails"
    const isPostDetailsContent =
        details.type === "PostCommentedNotificationDetails" ||
        details.type === "PostLikedNotificationDetails" ||
        details.type === "PostNotificationDetails"
    const isUserDetailsContent =
        details.type === "UserProfileNotificationDetails"

    const videoQuery = useListVideoById(
        isVideoDetailsContent ? details.dataIds.videoId : undefined,
    )
    const videoData = videoQuery.data

    const videoDoesNotExist =
        videoQuery.isError &&
        videoQuery.error instanceof AxiosError &&
        videoQuery.error.code === "404"

    const postitQuery = useListPostitById(
        isPostDetailsContent ? details.dataIds.postitId : undefined,
    )
    const postitData = postitQuery.data?.data

    const postitDoesNotExist =
        postitQuery.isError &&
        postitQuery.error instanceof AxiosError &&
        postitQuery.error.code === "404"

    const dataDoesNotExist =
        profileDoesNotExist ||
        (isVideoDetailsContent && videoDoesNotExist) ||
        (isPostDetailsContent && postitDoesNotExist)

    const read = model.notification.notificationType === "UserNotification"

    if (dataDoesNotExist) return <></>

    return (
        <Link to={navigateUrl instanceof Error ? "." : navigateUrl}>
            <div
                ref={ref}
                className={classNames(styles.item, model.className)}
                data-id={model.notification.id}
            >
                {!read && <div className={styles.unreadBadge} />}
                <Link
                    to={
                        myProfileIdQuery.data === model.notification.senderId
                            ? "/app/my-profile"
                            : "/app/user-profile/$id"
                    }
                    params={{ id: model.notification.senderId }}
                >
                    <AvatarWithBadge
                        profileId={model.notification.senderId}
                        size={40}
                        src={profileData?.imageUrl}
                    />
                </Link>
                <div className={styles.textContent}>
                    <p
                        style={{ fontSize: vars.font.size.regular }}
                        className={classNames(
                            styles.text,
                            !read ? styles.textBold : "",
                        )}
                    >
                        {profileData?.displayName || profileData?.profileName}
                    </p>
                    <div className={styles.descriptionBlock}>
                        <p
                            style={{ fontSize: vars.font.size.regular }}
                            className={classNames(styles.text, styles.title)}
                        >
                            {model.notification.pushNotification.title}
                        </p>
                        <p
                            style={{ fontSize: vars.font.size.regular }}
                            className={classNames(styles.title, styles.time)}
                        >
                            {" . "}
                            {formatNotificationSentDate(
                                model.notification.createdAt,
                            )}
                        </p>
                    </div>
                    <p
                        style={{ fontSize: vars.font.size.regular }}
                        className={classNames(
                            styles.text,
                            !read ? styles.textBold : "",
                        )}
                    >
                        {model.notification.pushNotification.message}
                    </p>
                </div>
                {isVideoDetailsContent && !!videoData ? (
                    <NotificationCenterVideoItemContents details={details} />
                ) : isPostDetailsContent &&
                  !!postitData &&
                  postitData.type === "Image" ? (
                    <NotificationCenterPostItemContents details={details} />
                ) : isUserDetailsContent ? (
                    <NotificationCenterUserItemContents />
                ) : (
                    <div style={{ width: 46, height: 53 }} />
                )}
            </div>
        </Link>
    )
}

type NotificationCenterVideoItemModel = {
    details:
        | VideoNotificationDetails
        | VideoLikedNotificationDetails
        | VideoCommentedNotificationDetails
}

export const NotificationCenterVideoItemContents: FC<
    NotificationCenterVideoItemModel
> = ({ details }) => {
    const videoQuery = useListVideoById(details.dataIds.videoId)

    const video = videoQuery.data?.data

    return videoQuery.isSuccess ? (
        <LazyLoadImage
            //todo: add empty placeholder image
            src={`${video?.previewImageUrl}?height=106`}
            className={styles.image}
            width={46}
            height={53}
        />
    ) : (
        <div style={{ width: 46, height: 53 }} />
    )
}

type NotificationCenterPostitItemModel = {
    details:
        | PostNotificationDetails
        | PostLikedNotificationDetails
        | PostCommentedNotificationDetails
}

export const NotificationCenterPostItemContents: FC<
    NotificationCenterPostitItemModel
> = ({ details }) => {
    const postitQuery = useListPostitById(details.dataIds.postitId)

    const postit = postitQuery.data?.data

    const assetQuery = useAsset({
        resource: postit?.type === "Image" ? postit.imageRef : undefined,
    })

    const metadata = {
        status: assetQuery.status,
        data: assetQuery.data?.type === "Image" ? assetQuery.data : undefined,
    }

    const src =
        assetQuery.data?.type === "Image" ? assetQuery.data.source : undefined

    return postitQuery.isSuccess && postit?.type === "Image" ? (
        <Image
            metadata={metadata}
            src={src}
            className={styles.image}
            width={46}
            height={53}
        />
    ) : (
        <div style={{ width: 46, height: 53 }} />
    )
}

//TODO: locize
export const NotificationCenterUserItemContents: FC = () => {
    return (
        <Button variant="outline" className={styles.showButton}>
            Show
        </Button>
    )
}

export const NotificationCenterItem = forwardRef(NotificationCenterItemInner)
//TODO: implement proper way to localize relative time to fit our needs
const formatNotificationSentDate = (date: string) =>
    pipe(
        formatRelativeTimeShortToParts(date),
        O.fold(
            () => "",
            ({ count, timeFrame, tense }) =>
                tense === "now" ? "just now" : `${count} ${timeFrame}`,
        ),
    )

type NotificationCenterLoadingItemModel = {
    className?: string
    hasImage?: boolean
}

export const NotificationCenterLoadingItem = (
    model: NotificationCenterLoadingItemModel,
) => (
    <div className={classNames(styles.item, model.className)}>
        <AvatarLoading baseColor="#3C375C" size={42} />
        <div className={styles.textContent}>
            <Skeleton
                width="100%"
                height={15}
                borderRadius={vars.measurement.radius.xs}
            />
            <Skeleton
                width="60%"
                height={15}
                borderRadius={vars.measurement.radius.xs}
            />
        </div>
        {model.hasImage ? (
            <Skeleton
                width={46}
                height={53}
                borderRadius={vars.measurement.radius.md}
            />
        ) : (
            <div style={{ width: 46, height: 53 }} />
        )}
    </div>
)
