import { faAward, faLocationPin } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC } from "react"

import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useAsset } from "hooks/use-asset"
import { useMe } from "hooks/use-me"
import { useMyUserProfile } from "hooks/use-my-user-profiles"
import Skeleton from "react-loading-skeleton"
import { useProfileStatistics } from "../hooks/use-profile-statistic"

import { Link } from "@tanstack/react-router"
import { calc } from "@vanilla-extract/css-utils"
import { SvgImage } from "common/svg-image"
import { FirstAdopterBadge } from "features/first-adopters/first-adopters-badge"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import { isImageAsset } from "utils/asset"
import * as styles from "../my-user-profile-page.css"

type MyUserProfileDataModel = {
    setEditProfileModalVisible?: () => void
    isAuthorizedAccount: boolean
}

export const MyUserProfileData: FC<MyUserProfileDataModel> = model => {
    const { t } = useTranslation("myProfile")
    const { t: tCommon } = useTranslation("common")
    const meQuery = useMe()
    const myProfileQuery = useMyUserProfile(model.isAuthorizedAccount)
    const myProfileStatistics = useProfileStatistics(myProfileQuery.data?.id)

    const currentTierLogoAssetQuery = useAsset({
        resource: myProfileStatistics.data?.currentTier.logoRef,
    })

    const currentTierLogoImageAsset = pipe(
        currentTierLogoAssetQuery.data,
        O.fromNullable,
        O.chain(O.fromPredicate(isImageAsset)),
    )

    return (
        <>
            {myProfileQuery.isSuccess && (
                <div className={styles.userStatus}>
                    <div className={styles.profileNameSectionStatusContainer}>
                        <div className={styles.rows}>
                            <div className={styles.profileNameContainer}>
                                <h1
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        textWrap: "nowrap",
                                        maxWidth: calc.subtract(
                                            "100dvw",
                                            calc.add("130px", "40px"),
                                        ),
                                    }}
                                >
                                    {myProfileQuery.data?.displayName ??
                                        myProfileQuery.data?.profileName}
                                </h1>
                                {O.isSome(currentTierLogoImageAsset) ? (
                                    <SvgImage
                                        width={
                                            currentTierLogoImageAsset.value
                                                .resolution.width
                                        }
                                        height={
                                            currentTierLogoImageAsset.value
                                                .resolution.height
                                        }
                                        src={
                                            currentTierLogoImageAsset.value
                                                .source
                                        }
                                        className={styles.tierIcon}
                                    />
                                ) : (
                                    <div style={{ width: 16, height: 16 }} />
                                )}
                            </div>
                            <Link to="/app/my-profile/edit">
                                <div
                                    className={styles.editButton}
                                    onClick={model.setEditProfileModalVisible}
                                >
                                    {t("edit.action")}
                                </div>
                            </Link>

                            <Link
                                to="/app/settings/rewards"
                                search={{ currentTab: "get-started" }}
                                className={styles.editButton}
                            >
                                <FontAwesomeIcon
                                    icon={faAward}
                                    style={{ height: 20 }}
                                />
                            </Link>
                        </div>
                        <FirstAdopterBadge
                            profileId={myProfileQuery.data?.id}
                        />
                    </div>

                    <div className={styles.rows}>
                        <small className={styles.textLight}>
                            {myProfileQuery.data?.profileName}
                            &nbsp;&#x2022;&nbsp;
                            {meQuery.data?.user.isOnline
                                ? tCommon("online")
                                : tCommon("offline")}
                        </small>
                    </div>

                    <div
                        className={classNames(
                            styles.rows,
                            styles.locationContainer,
                        )}
                    >
                        <FontAwesomeIcon
                            icon={faLocationPin}
                            color={vars.color.tertiary.hex}
                        />
                        <small className={styles.textLight}>
                            {myProfileQuery.data?.location ??
                                t("location.text")}
                        </small>
                    </div>
                </div>
            )}
            {!myProfileQuery.isSuccess && <ProfileDataLoading />}
        </>
    )
}

export const ProfileDataLoading: FC = () => (
    <div className={styles.userStatus}>
        <Skeleton width={230} height={30} borderRadius={16} />
        <Skeleton width={144} height={18} borderRadius={24} />
        <Skeleton width={144} height={18} borderRadius={24} />
    </div>
)
