import {
    DatabaseNotification,
    ImageAsset,
    LiveEvent,
    NotificationClickActionDocument,
    Postit,
    UserComment,
    UserProfile,
    Video,
    Vote,
} from "api/api-models"
import {
    LiveEventId,
    PostitId,
    UserCommentId,
    UserProfileId,
    VideoId,
    VoteId,
} from "api/branded-types"
import { notificationSelectorClient } from "api/clients/notification-api-client"
import { generateDataByClickActionDocument } from "features/notifications/notification"
import { z } from "zod"

export const NOTIFICATION_CENTER_PAGINATION_AMOUNT = 50

export const getNotificationCenterItemsQueryFn = async ({
    pageParam,
    profileId,
    filter,
}: {
    pageParam: number
    profileId?: UserProfileId
    filter: string
}) => {
    if (!profileId) throw new Error("profileId should be defined")

    const notificationsRes = await notificationSelectorClient.listNotifications(
        {
            filter,
            paging: {
                type: "Index",
                direction: "After",
                limit: NOTIFICATION_CENTER_PAGINATION_AMOUNT,
                index: pageParam,
            },
        },
    )

    return notificationsRes
}

export type VideoNotificationDetails = {
    type: "VideoNotificationDetails"
    dataIds: VideoNotificationDataIds
}

export type VideoCommentedNotificationDetails = {
    type: "VideoCommentedNotificationDetails"
    dataIds: VideoCommentedNotificationDataIds
}

export type VideoLikedNotificationDetails = {
    type: "VideoLikedNotificationDetails"
    dataIds: VideoLikedNotificationDataIds
}
export type PostNotificationDetails = {
    type: "PostNotificationDetails"
    dataIds: PostNotificationDataIds
}

export type PostCommentedNotificationDetails = {
    type: "PostCommentedNotificationDetails"
    dataIds: PostCommentedNotificationDataIds
}

export type PostLikedNotificationDetails = {
    type: "PostLikedNotificationDetails"
    dataIds: PostLikedNotificationDataIds
}
export type UserProfileNotificationDetails = {
    type: "UserProfileNotificationDetails"
    dataIds: UserProfileNotificationDataIds
}

export type LiveEventNotificationDetails = {
    type: "LiveEventNotificationDetails"
    dataIds: LiveEventNotificationDataIds
}

export type NotificationDetail =
    | VideoNotificationDetails
    | VideoCommentedNotificationDetails
    | VideoLikedNotificationDetails
    | PostNotificationDetails
    | PostCommentedNotificationDetails
    | PostLikedNotificationDetails
    | UserProfileNotificationDetails
    | LiveEventNotificationDetails

export type NotificationDetailType = NotificationDetail["type"]

export const getNotificationDetailsDataIds = (
    clickAction?: NotificationClickActionDocument,
) => {
    const { key, ids } = generateDataByClickActionDocument(clickAction)
    const keys = key.split("/")
    const dataIds = keys.reduce<Record<string, string>>(
        (result, key, index) => {
            const dataIdKey = `${key}Id`
            result[dataIdKey] = ids[index]
            return result
        },
        {},
    )
    const type = keyToNotificationDetailsTypeMapping[key]
    return {
        dataIds,
        type,
    } as NotificationDetail
}

const keyToNotificationDetailsTypeMapping: Record<
    string,
    NotificationDetailType
> = {
    "video/comment": "VideoCommentedNotificationDetails",
    "video/like": "VideoLikedNotificationDetails",
    "video/profile": "VideoNotificationDetails",
    video: "VideoNotificationDetails",
    "postit/comment": "PostCommentedNotificationDetails",
    "postit/like": "PostLikedNotificationDetails",
    "postit/profile": "PostNotificationDetails",
    postit: "PostNotificationDetails",
    profile: "UserProfileNotificationDetails",
    "profile/postit": "UserProfileNotificationDetails",
    "profile/video": "UserProfileNotificationDetails",
    liveevent: "LiveEventNotificationDetails",
    "liveevent/profile": "LiveEventNotificationDetails",
}
//#region ------ NOTIFICATIONS DATA ---------

//? Video
export const VideoNotificationDataIds = z.object({
    videoId: VideoId,
    profileId: UserProfileId,
})
export type VideoNotificationDataIds = z.infer<typeof VideoNotificationDataIds>
export const VideoNotificationData = z.object({
    video: Video,
    userProfile: UserProfile,
})
export type VideoNotificationData = z.infer<typeof VideoNotificationData>
//? Post
export const PostNotificationDataIds = z.object({
    postitId: PostitId,
    profileId: UserProfileId,
})
export type PostNotificationDataIds = z.infer<typeof PostNotificationDataIds>
export const PostNotificationData = z.object({
    post: Postit,
    asset: ImageAsset.optional(),
    userProfile: UserProfile,
})
export type PostNotificationData = z.infer<typeof PostNotificationData>
//? Live Event
export const LiveEventNotificationDataIds = z.object({
    liveEventId: LiveEventId,
    profileId: UserProfileId,
})
export type LiveEventNotificationDataIds = z.infer<
    typeof LiveEventNotificationDataIds
>
export const LiveEventNotificationData = z.object({
    liveEvent: LiveEvent,
    userProfile: UserProfile,
})
export type LiveEventNotificationData = z.infer<
    typeof LiveEventNotificationData
>
//? Comment
export const VideoCommentedNotificationDataIds = z.object({
    videoId: VideoId,
    commentId: UserCommentId,
    profileId: UserProfileId,
})
export type VideoCommentedNotificationDataIds = z.infer<
    typeof VideoCommentedNotificationDataIds
>
export const VideoCommentedNotificationData = z.object({
    video: Video,
    userComment: UserComment,
    userProfile: UserProfile,
})
export type VideoCommentedNotificationData = z.infer<
    typeof VideoCommentedNotificationData
>

export const PostCommentedNotificationDataIds = z.object({
    postitId: PostitId,
    commentId: UserCommentId,
    profileId: UserProfileId,
})
export type PostCommentedNotificationDataIds = z.infer<
    typeof PostCommentedNotificationDataIds
>
export const PostCommentedNotificationData = z.object({
    post: Postit,
    asset: ImageAsset.optional(),
    userComment: UserComment,
    userProfile: UserProfile,
})
export type PostCommentedNotificationData = z.infer<
    typeof PostCommentedNotificationData
>
//? Vote
export const VideoLikedNotificationDataIds = z.object({
    videoId: VideoId,
    likeId: VoteId,
    profileId: UserProfileId,
})
export type VideoLikedNotificationDataIds = z.infer<
    typeof VideoLikedNotificationDataIds
>
export const VideoLikedNotificationData = z.object({
    video: Video,
    vote: Vote,
    userProfile: UserProfile,
})
export type VideoLikedNotificationData = z.infer<
    typeof VideoLikedNotificationData
>

export const PostLikedNotificationDataIds = z.object({
    postitId: PostitId,
    likeId: VoteId,
    profileId: UserProfileId,
})
export type PostLikedNotificationDataIds = z.infer<
    typeof PostLikedNotificationDataIds
>
export const PostLikedNotificationData = z.object({
    post: Postit,
    asset: ImageAsset.optional(),
    vote: Vote,
    userProfile: UserProfile,
})
export type PostLikedNotificationData = z.infer<
    typeof PostLikedNotificationData
>
//? User Profile
export const UserProfileNotificationDataIds = z.object({
    profileId: UserProfileId,
})
export type UserProfileNotificationDataIds = z.infer<
    typeof UserProfileNotificationDataIds
>
export const UserProfileNotificationData = z.object({
    userProfile: UserProfile,
})
export type UserProfileNotificationData = z.infer<
    typeof UserProfileNotificationData
>

//#endregion

type GetNotificationContentExistsModel = {
    notification: DatabaseNotification
    profiles?: UserProfile[]
    postits?: Postit[]
    videos?: Video[]
    detail?: NotificationDetail
}

export const getNotificationContentExists = (
    model: GetNotificationContentExistsModel,
) => {
    const profile = model.profiles?.find(
        item => item.id === model.notification.senderId,
    )

    const videoDetailItemValue =
        model.detail &&
        (model.detail.type === "VideoCommentedNotificationDetails" ||
            model.detail.type === "VideoLikedNotificationDetails" ||
            model.detail.type === "VideoNotificationDetails")
            ? model.detail?.dataIds
            : undefined

    const postitDetailItemValue =
        model.detail &&
        (model.detail.type === "PostCommentedNotificationDetails" ||
            model.detail.type === "PostLikedNotificationDetails" ||
            model.detail.type === "PostNotificationDetails")
            ? model.detail?.dataIds
            : undefined

    const video = model.videos?.find(
        item => item.id === videoDetailItemValue?.videoId,
    )
    const postit = model.postits?.find(
        item => item.id === postitDetailItemValue?.postitId,
    )

    const dataDoesNotExist =
        !profile ||
        !model.detail ||
        (videoDetailItemValue && !video) ||
        (postitDetailItemValue && !postit)

    return dataDoesNotExist
}
