import {
    GetNextPageParamFunction,
    infiniteQueryOptions,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { UserProfile } from "api/api-models"
import { mapToNotMatchFilterDc, mapToSortFilterDc } from "../api/api-utils"
import { UserId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"

export const PAGINATION_AMOUNT = 50

type TopUsersQueryOptionsParams = {
    queryClient: QueryClient
    userId?: UserId
}

export const topUsersInfiniteQueryOptions = ({
    queryClient,
    userId,
}: TopUsersQueryOptionsParams) =>
    infiniteQueryOptions({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            listTopUsers({ pageParam, id: userId, queryClient }),
        queryKey: QueryKeys.feedTopUsers(userId),
        getNextPageParam: getTopUsersNextPageParam,
    })

export const useTopUsers = (userId?: UserId) => {
    const queryClient = useQueryClient()
    return useInfiniteQuery(
        topUsersInfiniteQueryOptions({ queryClient, userId }),
    )
}

type TopUsersData = Awaited<ReturnType<typeof listTopUsers>>

const listTopUsers = async ({
    id,
    pageParam,
    queryClient,
}: {
    id?: UserId
    pageParam: number
    queryClient: QueryClient
}) => {
    const filter = id
        ? `{${mapToNotMatchFilterDc("userId", [id])} ${mapToSortFilterDc("engagementScore: desc, profileName: asc")}}`
        : `{${mapToSortFilterDc("engagementScore: desc, profileName: asc")}}`
    const topUsersResponse = await userProfileSelectorClient.listProfiles({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam as number,
        },
    })

    topUsersResponse.data.map(data =>
        queryClient.setQueryData<{ data: UserProfile; error: undefined }>(
            QueryKeys.listProfile(data.id),
            {
                data,
                error: undefined,
            },
        ),
    )

    return topUsersResponse
}

export const getTopUsersNextPageParam: GetNextPageParamFunction<
    number,
    TopUsersData
> = lastPage => {
    if (lastPage.data.length !== PAGINATION_AMOUNT) {
        return undefined
    }

    return lastPage.paging.type === "Index"
        ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
        : -1
}
