import { FC, PropsWithChildren, useEffect } from "react"
import { useIntersectionObserver } from "../hooks/use-intersection-observer"
import { RemoteAdt } from "../utils/remote-adt"

type InfiniteScrollDirection = "After" | "Before"

type InfiniteScrollModel = {
    threshold?: `${number}${"px" | "%"}`
    disabled?: boolean
    state: RemoteAdt["type"]
    onLoadRequested: () => void
    direction?: InfiniteScrollDirection
}

//? Idea: use not intersection observer, but scrollTop of current scrolling element.
//? This way we can control the position when to call the load next page

//? As an example - messages are still scrolling, and there is 500px up finish current
//? portion of the messages, but we already fetch the next page
export const InfiniteScroll: FC<PropsWithChildren<InfiniteScrollModel>> = ({
    children,
    threshold = "100px",
    disabled = false,
    state,
    direction = "After",
    onLoadRequested,
}) => {
    const { isIntersecting, ref } = useIntersectionObserver()

    useEffect(() => {
        if (disabled) return
        if (isIntersecting && state !== "Loading") onLoadRequested()
    }, [disabled, isIntersecting, onLoadRequested, state])

    return (
        <>
            {direction === "After" ? (
                <>
                    {children}
                    <div
                        ref={ref}
                        style={{ width: "100%", height: threshold }}
                    />
                </>
            ) : (
                <>
                    <div
                        ref={ref}
                        style={{
                            width: "100%",
                            height: threshold,
                        }}
                    />
                    {children}
                </>
            )}
        </>
    )
}
