import {
    faArrowRotateRight,
    faImageSlash,
    faOctagonExclamation,
} from "@fortawesome/pro-light-svg-icons"
import {
    faBagShopping,
    faEye,
    faStarCircle,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    useIsMutating,
    useMutation,
    useQueryClient,
    useSuspenseQuery,
} from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { identity, pipe } from "fp-ts/function"
import parse from "html-react-parser"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import {
    PostitId,
    ShareContentId,
    Url,
    UserProfileId,
} from "../../../api/branded-types"
import { authCreatorClient } from "../../../api/clients/auth-api-client"
import { QueryKeys, StaticQueryKeys } from "../../../api/query-keys"
import { NavigationBackButton } from "../../../components/buttons/navigation-back-button"
import { CommentOverviewModal } from "../../../components/comments/comment-overview-modal"
import { NewFeatureComingSoonModal } from "../../../components/modals/new-feature-coming-soon-modal"
import { ShareOverviewAlertModal } from "../../../components/modals/share-overview-alert-modal"
import { VoterOverviewModal } from "../../../components/modals/voter-overview-modal"
import { createShareUrl } from "../../../data-flow/sharing"
import { useDeletePostMutation } from "../../../features/feed/areas/post/hooks/use-delete-post-mutation"
import {
    UserDetails,
    UserDetailsLoading,
} from "../../../features/feed/areas/user/user-details"
import { DeletePostDialog } from "../../../features/video/components/delete-post-dialog"
import { PostConsumerActionsModal } from "../../../features/video/components/post-consumer-actions-modal"
import { ContentPayModal } from "../../../features/video/content-pay-modal"
import { useMyResourceVote } from "../../../features/video/hooks/use-my-resource-vote"
import { useResourceComments } from "../../../features/video/hooks/use-resource-comments"
import { useResourceVotes } from "../../../features/video/hooks/use-resource-votes"
import { useRevokeVoteMutation } from "../../../features/video/hooks/use-revoke-vote-mutation"
import { useVoteMutation } from "../../../features/video/hooks/use-vote-mutation"
import { useMe } from "../../../hooks/use-me"
import { useMyUserProfiles } from "../../../hooks/use-my-user-profiles"
import { isNotDefined } from "../../../utils/object"

import {
    BuyPostitByIdRequestBody,
    SubscribeUserProfileRequestBody,
} from "api/api-models"
import { Loading } from "common/loading"
import { LinkItUrl } from "features/linkify"
import { ContentSubscribeModal } from "features/video/content-subscribe-modal"
import { useAsset, useAssetData } from "hooks/use-asset"
import { useToggle } from "hooks/use-toggle"
import Skeleton from "react-loading-skeleton"
import { getIsAuthorizedAccount } from "../../../api/api-utils"
import { Content } from "../../../common/content"
import { Footer } from "../../../common/footer"
import { Page } from "../../../common/page"

import { postitByIdQueryOptions } from "../hooks/use-postit-by-id"
import * as styles from "./postit-consumer-page.css"

import { faBan } from "@fortawesome/pro-regular-svg-icons"
import { useParams, useRouter } from "@tanstack/react-router"
import { postitCreatorClient } from "api/clients/post-api-client"
import { shareLinkClient } from "api/clients/share-link-api-client"
import { userProfileCreatorClient } from "api/clients/user-profile-api-client"
import classNames from "classnames"
import { AlertSection } from "common/alert-section"
import { Badge } from "common/badge"
import { Button } from "common/button"
import { ImageView } from "common/image-view"
import { OptionButton } from "components/buttons/options-button"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { useIsPaymentActivated } from "features/payment/hooks/use-is-payment-activated"
import {
    PaymentStatus,
    SubscriptionStatus,
} from "features/payment/store/payment-messaging-store"
import { usePaymentModalStore } from "features/payment/store/payment-modal-store"
import { useReportStore } from "features/report/report-store"
import { creatorReferenceStrategy } from "features/strategies/user/content-reference-strategy"
import { OwnPostConsumerActionsModal } from "features/video/components/own-post-consumer-actions-modal"
import { motion } from "framer-motion"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"
import { Blurhash } from "react-blurhash"
import { useIntl } from "react-intl"
import { FullRoute } from "routes/__root"
import { vars } from "theme/variables.css"

type BuyPostitByIdMutationParams = BuyPostitByIdRequestBody & { id: PostitId }

type SubscribeUserMutationParams = {
    redirects: SubscribeUserProfileRequestBody["redirects"]
    idToSubscribe: UserProfileId
}

const buildPaymentRedirectUrl = (
    status: PaymentStatus,
    postitId: string,
): FullRoute =>
    `${location.origin}/paymentCallback.html?payment_status=${status}&payment_type=postit&payment_content_id=${postitId}`

type BuildSubscriptionRedirectUrlModel = {
    status: SubscriptionStatus
    profileId: string
    postitId: string
}

const buildSubscriptionRedirectUrl = (
    model: BuildSubscriptionRedirectUrlModel,
): FullRoute =>
    `${location.origin}/paymentCallback.html?subscription_status=${model.status}&subscription_type=postit&subscription_profile_id=${model.profileId}&subscription_content_id=${model.postitId}`

//TODO: refactor, show UI only when asset document is there
// (not yet possible because asset document is not loaded since backend is not updated)
export const PostitConsumerPage: FC = () => {
    const { t: tCommon } = useTranslation(["common"])
    const { t } = useTranslation(["postitViewer"])
    const intl = useIntl()
    const router = useRouter()
    const params = useParams({ from: "/_authenticated/app/postit/viewer/$id" })

    const [isShareLinkInCreation, setIsShareLinkInCreation] = useState(false)
    const [likingOverviewOpen, setLikingOverviewOpen] = useState(false)

    const [deletePostDialogOpen, setDeletePostDialogOpen] = useState(false)

    const [commentOverviewOpen, setCommentOverviewOpen] = useState(false)

    const [actionsOpen, setActionsOpen] = useState(false)

    const [newFeatureModalOpen, setNewFeatureModalOpen] = useState(false)

    const [shareUrl, setShareUrl] = useState<undefined | string>()
    const [shareModalOpen, setShareModalOpen] = useState(false)
    const [isPostitPayModalOpen, setPostitPayModalOpen] = useState(false)

    const [isMonetizeProcessInProgress, setMonetizeProcessInProgress] =
        useState(false)
    // FIXME: improve payment and navigation back flow
    const [paymentAttempts, setPaymentAttempts] = useState(0)

    const {
        open: isSubscribeOpen,
        toggleOpen: openSubscribe,
        toggleClose: closeSubscribe,
    } = useToggle()

    const setReportContentOpen = useReportStore(
        store => store.setReportContentOpen,
    )

    const queryClient = useQueryClient()

    const meQuery = useMe()

    const userId = meQuery.data?.user.id

    const postitQuery = useSuspenseQuery(postitByIdQueryOptions(params.id))
    const postit = postitQuery.data

    const contentCreatorId = postit.creatorId

    const {
        accountType,
        ensureUserPreventDefaultCallback,
        ensureUserCallback,
    } = useOnlyRegisteredUser()

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const myProfilesQuery = useMyUserProfiles(identity, isAuthorizedAccount)

    const myProfiles = myProfilesQuery.isSuccess
        ? myProfilesQuery.data.profiles
        : []

    const assetQuery = useAsset({
        resource: postit.type === "Image" ? postit.imageRef : undefined,
    })

    const desiredImageWidth = window.innerWidth - 40

    const imageAssetDataQuery = useAssetData(
        assetQuery.data?.source,
        assetQuery.data?.source
            ? (`${assetQuery.data?.source}?width=${desiredImageWidth}` as Url)
            : undefined,
    )

    const contentCreatorProfileQuery = useListUserProfileById(contentCreatorId)
    const profile = contentCreatorProfileQuery.data?.data

    const isPaymentActivatedQuery = useIsPaymentActivated(
        profile?.userId,
        profile?.id,
    )

    const myProfileId = myProfiles.at(0)?.id

    const voter = useMemo(
        () =>
            creatorReferenceStrategy({
                accountType,
                userId,
                userProfileId: myProfileId,
            }),
        [accountType, myProfileId, userId],
    )

    const postitCommentsQuery = useResourceComments({
        contentId: params.id,
        contentType: "Postit",
    })

    const postitVotesQuery = useResourceVotes({
        contentId: params.id,
        contentType: "Postit",
    })

    const myPostitVote = useMyResourceVote({
        voteResourceRef: {
            contentId: params.id,
            contentType: "Postit",
        },
        voter,
    })

    const totalComments = postitCommentsQuery.data?.pages.at(0)?.totalCount ?? 0
    const totalVotes = postitVotesQuery.data?.pages.at(0)?.totalCount ?? 0

    const isLiked = myPostitVote?.data?.isLiked ?? false
    const voteId = myPostitVote.data?.voteId ?? O.none

    const { mutate: vote } = useVoteMutation()
    const { mutate: revokeVote } = useRevokeVoteMutation()

    const votingUpMutates = useIsMutating({
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_UP],
    })
    const votingDownMutates = useIsMutating({
        mutationKey: [StaticQueryKeys.RESOURCE_VOTES_DOWN],
    })

    const isVoting = votingDownMutates !== 0 || votingUpMutates !== 0

    const { mutate: subscribeUserProfile } = useMutation({
        mutationFn: (param: SubscribeUserMutationParams) =>
            userProfileCreatorClient.subscribeUserProfile(
                { redirects: param.redirects },
                { params: { id: param.idToSubscribe } },
            ),

        onError: err => {
            console.error(err)
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faOctagonExclamation}
                        iconColor="danger"
                        text={tCommon("subscribe.error.toast")}
                    />
                ),
            })
        },

        onSuccess: ({ data }) => {
            setSubscriptionUrl(data)
        },
    })

    const isBuyContentAvailable = isPaymentActivatedQuery.data?.isAvailable

    const isInReview = postitQuery.data?.status === "InReview"
    const isBlocked =
        postitQuery.data?.status === "Rejected" ||
        (postitQuery.data?.status === "None" && postitQuery.data.isBlocked)

    const hasStatusBadge = isInReview || isBlocked

    const canShowPaymentModal =
        (imageAssetDataQuery.isSuccess || postit.type === "Text") &&
        isBuyContentAvailable

    const { mutate: deletePostMutation } = useDeletePostMutation()
    //TODO: rework this to show modal immediately after clickt

    const { mutate: createShortenedLink } = useMutation({
        mutationFn: shareLinkClient.createShortenedLink,
        onSuccess: ({ shortURL }) => {
            setShareUrl(shortURL)
            setShareModalOpen(true)
            setIsShareLinkInCreation(false)
        },
        onError: () => {
            setIsShareLinkInCreation(false)
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faOctagonExclamation}
                        iconColor="danger"
                        text={tCommon("share.error.title")}
                    />
                ),
            })
        },
    })

    const { mutate: sharePost } = useMutation({
        mutationFn: authCreatorClient.createShareToken,
        onSuccess: ({ data }) => {
            createShortenedLink({ link: createShareUrl(data) })
        },
        onError: error => {
            console.error("Error share", error)
            setIsShareLinkInCreation(false)
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faOctagonExclamation}
                        iconColor="danger"
                        text={tCommon("share.error.title")}
                    />
                ),
            })
        },
    })

    const { toast } = useInfoToast()

    const setPaymentUrl = usePaymentModalStore(
        store => store.setProceedWithPaymentUrl,
    )
    const setSubscriptionUrl = usePaymentModalStore(
        store => store.setProceedWithSubscriptionUrl,
    )
    const setWalletPaymentDetailsOpen = usePaymentModalStore(
        store => store.setWalletPaymentDetailsOpen,
    )

    const { mutate: buyPostitById } = useMutation({
        mutationFn: ({ redirects, id }: BuyPostitByIdMutationParams) =>
            postitCreatorClient.buyPostitById(
                { redirects },
                { params: { id } },
            ),
        onSuccess: ({ data: locationUrl }) => {
            setPaymentUrl(locationUrl)
        },
        onError: () => {
            toast({
                toastContent: (
                    <InfoToastContent
                        icon={faBagShopping}
                        iconColor="danger"
                        text={t("purchase.toast.error.buy")}
                    />
                ),
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.payments(),
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.payablePostit(params.id),
            })
            setMonetizeProcessInProgress(false)
        },
    })

    const isMyContent = useCallback(() => {
        if (!contentCreatorId) return
        if (isNotDefined(myProfileId)) return

        return contentCreatorId === myProfileId
    }, [contentCreatorId, myProfileId])

    return (
        <>
            {canShowPaymentModal &&
                contentCreatorProfileQuery.isSuccess &&
                profile && (
                    <ContentPayModal
                        open={
                            isPostitPayModalOpen && !isMonetizeProcessInProgress
                        }
                        purchaseActionDisabled={isMonetizeProcessInProgress}
                        text={{
                            purchase: {
                                amount: intl.formatNumber(
                                    postit.monetization.type ===
                                        "SubscriptionOnly"
                                        ? postit.monetization.amount
                                        : 0,
                                    {
                                        style: "currency",
                                        currency: "EUR",
                                        currencyDisplay: "symbol",
                                    },
                                ),
                            },
                            subscribe: {
                                action: t("subscribe.name.action", {
                                    name:
                                        profile.displayName ??
                                        profile.profileName,
                                }),
                                title: t("subscribe.title"),
                            },
                            terms: t("terms.text"),
                        }}
                        user={{
                            imageUrl: profile.imageUrl,
                            name: profile.displayName ?? profile.profileName,
                            isLive: profile.isLive,
                        }}
                        profileId={postit.creatorId}
                        content={{
                            imageUrl:
                                postit.type === "Image" &&
                                imageAssetDataQuery.isSuccess
                                    ? (URL.createObjectURL(
                                          imageAssetDataQuery.data,
                                      ) as Url)
                                    : Url.parse("areas/img_bg_0"),
                            title: postit.title,
                            type: postit.type,
                        }}
                        onCanceled={() => setPostitPayModalOpen(false)}
                        onPurchased={() => {
                            if (postit.monetization.type !== "SubscriptionOnly")
                                return

                            if (isNotDefined(myProfileId)) return
                            setPostitPayModalOpen(false)
                            setMonetizeProcessInProgress(true)
                            setPaymentAttempts(paymentAttempts + 1)

                            buyPostitById({
                                redirects: {
                                    succeeded: Url.parse(
                                        buildPaymentRedirectUrl(
                                            "succeeded",
                                            postit.id,
                                        ),
                                    ),
                                    failed: Url.parse(
                                        buildPaymentRedirectUrl(
                                            "failed",
                                            postit.id,
                                        ),
                                    ),
                                    canceled: Url.parse(
                                        buildPaymentRedirectUrl(
                                            "canceled",
                                            postit.id,
                                        ),
                                    ),
                                    pending: Url.parse(
                                        buildPaymentRedirectUrl(
                                            "pending",
                                            postit.id,
                                        ),
                                    ),
                                },
                                id: params.id,
                            })
                        }}
                        onSubscribed={() => {
                            setPostitPayModalOpen(false)
                            openSubscribe()
                        }}
                    />
                )}

            {canShowPaymentModal &&
                contentCreatorProfileQuery.isSuccess &&
                profile && (
                    <ContentSubscribeModal
                        open={isSubscribeOpen}
                        text={{
                            subscribe: {
                                action: t("subscribe.name.action", {
                                    name:
                                        profile.displayName ??
                                        profile.profileName,
                                }),
                                amount: t("subscribe.amount"),
                                currency: "EUR",
                                title: t("subscribe.title"),
                            },
                            terms: t("terms.text"),
                        }}
                        user={{
                            imageUrl:
                                postit.type === "Image" &&
                                imageAssetDataQuery.isSuccess
                                    ? (URL.createObjectURL(
                                          imageAssetDataQuery.data,
                                      ) as Url)
                                    : Url.parse("areas/img_bg_0"),
                            name: profile.displayName ?? profile.profileName,
                            isLive: profile.isLive,
                        }}
                        content={{
                            imageUrl:
                                postit.type === "Image" &&
                                imageAssetDataQuery.isSuccess
                                    ? (URL.createObjectURL(
                                          imageAssetDataQuery.data,
                                      ) as Url)
                                    : Url.parse("areas/img_bg_0"),
                            title: postit.title,
                            type: postit.type,
                        }}
                        onCanceled={closeSubscribe}
                        onSubscribed={() => {
                            if (!myProfileId) return

                            subscribeUserProfile({
                                idToSubscribe: profile.id,
                                redirects: {
                                    succeeded: Url.parse(
                                        buildSubscriptionRedirectUrl({
                                            status: "succeeded",
                                            profileId: postit.creatorId,
                                            postitId: postit.id,
                                        }),
                                    ),
                                    canceled: Url.parse(
                                        buildSubscriptionRedirectUrl({
                                            status: "canceled",
                                            profileId: postit.creatorId,
                                            postitId: postit.id,
                                        }),
                                    ),
                                    failed: Url.parse(
                                        buildSubscriptionRedirectUrl({
                                            status: "failed",
                                            profileId: postit.creatorId,
                                            postitId: postit.id,
                                        }),
                                    ),
                                    pending: Url.parse(
                                        buildSubscriptionRedirectUrl({
                                            status: "pending",
                                            profileId: postit.creatorId,
                                            postitId: postit.id,
                                        }),
                                    ),
                                },
                            })
                            closeSubscribe()
                        }}
                    />
                )}

            <NewFeatureComingSoonModal
                open={newFeatureModalOpen}
                onOpenChange={setNewFeatureModalOpen}
                text={{
                    title: tCommon("newFeatureComingSoon.title"),
                    description: tCommon("newFeatureComingSoon.description"),
                }}
            />
            {pipe(
                myProfiles,
                A.map(profile => profile.id),
                profileIds =>
                    pipe(
                        postit,
                        O.fromNullable,
                        O.map(postit => postit.creatorId),
                        O.chain(creatorId =>
                            pipe(
                                profileIds,
                                A.findFirst(id => id === creatorId),
                            ),
                        ),
                        O.fold(
                            () => (
                                <PostConsumerActionsModal
                                    open={actionsOpen}
                                    onOpenChange={setActionsOpen}
                                    onReportClicked={ensureUserCallback(
                                        postit.creatorId,
                                    )(() => {
                                        setActionsOpen(false)

                                        setReportContentOpen({
                                            open: true,
                                            content: {
                                                contentId: params.id,
                                                contentType: "Postit",
                                            },
                                            contentOwner: contentCreatorId,
                                        })
                                    })}
                                    text={{
                                        report: t("content.report"),
                                    }}
                                />
                            ),
                            () => (
                                <OwnPostConsumerActionsModal
                                    type="Postit"
                                    open={actionsOpen}
                                    dismiss={() => setActionsOpen(false)}
                                    onEditClicked={
                                        isInReview
                                            ? undefined
                                            : ensureUserCallback(
                                                  postit.creatorId,
                                              )(() => setActionsOpen(false))
                                    }
                                    onDeleteClicked={ensureUserCallback(
                                        postit.creatorId,
                                    )(() => {
                                        setActionsOpen(false)
                                        setDeletePostDialogOpen(true)
                                    })}
                                />
                            ),
                        ),
                    ),
            )}

            <VoterOverviewModal
                title={t("likes.title")}
                voteContentRef={{
                    contentId: params.id,
                    contentType: "Postit",
                }}
                open={likingOverviewOpen}
                onOpenChange={setLikingOverviewOpen}
                onUserClicked={() => {
                    setLikingOverviewOpen(false)
                }}
            />

            <DeletePostDialog
                open={deletePostDialogOpen}
                onSubmit={() => {
                    if (isNotDefined(myProfileId)) return

                    deletePostMutation({
                        id: params.id,
                        profileId: myProfileId,
                    })

                    setDeletePostDialogOpen(false)
                    router.history.back()
                }}
                onOpenChange={setDeletePostDialogOpen}
                text={{
                    cancel: t("deletePost.cancel"),
                    submit: t("deletePost.submit"),
                    title: t("deletePost.title"),
                    description: t("deletePost.description"),
                }}
            />

            <CommentOverviewModal
                text={{
                    title: count =>
                        count === 1
                            ? t("comments.title.singular")
                            : t("comments.title.plural", { count }),
                    inputPlaceholder: t("comments.inputPlaceholder"),
                    noComments: t("comments.noComments"),
                    empty: {
                        title: t("comments.empty.title"),
                        text: t("comments.empty.text"),
                    },
                }}
                open={commentOverviewOpen}
                onOpenChange={setCommentOverviewOpen}
                resourceOwnerId={contentCreatorId}
                commentResourceRef={{
                    contentId: params.id,
                    contentType: "Postit",
                }}
            />

            <ShareOverviewAlertModal
                open={shareModalOpen}
                text={{
                    title: t("sharePost.title"),
                    confirm: t("sharePost.confirm"),
                    share: {
                        title: t("sharePost.share.title"),
                        description: t("sharePost.share.description"),
                    },
                }}
                onOpenChange={setShareModalOpen}
                shareUrl={O.fromNullable(shareUrl)}
            />

            <Page>
                <NavigationBackButton />
                <Loading
                    open={isShareLinkInCreation}
                    message={tCommon("loading")}
                />
                <Loading
                    open={isMonetizeProcessInProgress}
                    message={tCommon("loading")}
                />

                <OptionButton
                    onClicked={ensureUserPreventDefaultCallback(
                        postit.creatorId,
                    )(() => setActionsOpen(true))}
                />

                <Content className={styles.content}>
                    {postit.type === "Image" && <PostitImage />}
                    <div
                        className={classNames(
                            styles.badgeContainer,
                            hasStatusBadge && styles.badgeContainerMargin,
                        )}
                    >
                        {hasStatusBadge && (
                            <Badge
                                onClick={() =>
                                    toast({
                                        toastContent: isBlocked ? (
                                            <InfoToastContent
                                                icon={faBan}
                                                iconColor="medium"
                                                iconClassName={
                                                    styles.toastBlockedIcon
                                                }
                                                text={t(
                                                    "moderation.blocked.toast.text",
                                                )}
                                            />
                                        ) : (
                                            <InfoToastContent
                                                icon={faEye}
                                                iconColor="medium"
                                                iconClassName={
                                                    styles.toastReviewIcon
                                                }
                                                text={t(
                                                    "moderation.review.toast.text",
                                                )}
                                            />
                                        ),
                                    })
                                }
                                variant={
                                    isBlocked
                                        ? "dangerOnSecondary"
                                        : "secondary"
                                }
                            >
                                {isBlocked
                                    ? t("moderation.badge.rejected")
                                    : t("moderation.badge.review")}
                            </Badge>
                        )}
                    </div>

                    <motion.div
                        exit={{ filter: "blur(8px)" }}
                        animate={{ filter: "blur(0px)" }}
                        initial={{ filter: "blur(8px)" }}
                        transition={{ duration: 0.125 }}
                        className={styles.textContainer}
                    >
                        <p
                            style={{
                                fontSize: vars.font.size.l,
                            }}
                        >
                            {postit.title}
                        </p>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                marginTop: 8,
                                whiteSpace: "pre-wrap",
                                lineHeight: "18px",
                            }}
                        >
                            <LinkItUrl className={styles.link}>
                                {pipe(postit.message ?? "", parse)}
                            </LinkItUrl>
                        </p>
                        {(postit.paymentState.type === "Required" ||
                            postit.paymentState.type === "Pending") && (
                            <div className={styles.textSecureOverlay} />
                        )}
                    </motion.div>

                    {postit.paymentState.type === "Required" &&
                        isBuyContentAvailable && (
                            <div className={styles.exclusiveContentBanner}>
                                <div
                                    className={
                                        styles.exclusiveContentBannerInfo
                                    }
                                >
                                    <FontAwesomeIcon
                                        className={styles.exclusiveIcon}
                                        icon={faStarCircle}
                                    />
                                    <p
                                        style={{
                                            fontSize: vars.font.size.m,
                                        }}
                                    >
                                        This post is exclusive
                                    </p>
                                </div>
                                <Button
                                    className={styles.exclusiveButton}
                                    onClick={() => setPostitPayModalOpen(true)}
                                    variant="medium"
                                >
                                    {postit.monetization.type ===
                                    "SubscriptionOnly"
                                        ? `Buy for ${intl.formatNumber(
                                              postit.monetization.amount,
                                              {
                                                  style: "currency",
                                                  currency:
                                                      postit.monetization
                                                          .currency,
                                                  currencyDisplay: "symbol",
                                              },
                                          )}`
                                        : `unlock now`}
                                </Button>
                            </div>
                        )}
                    {postit.paymentState.type === "Pending" && (
                        <AlertSection
                            className={styles.paymentNotFinishedBanner}
                        >
                            <div
                                className={styles.paymentNotFinishedBannerInner}
                            >
                                <p style={{ fontSize: vars.font.size.s }}>
                                    Payment is in progress, finish the payment
                                    first.
                                </p>
                                <Button
                                    variant="light"
                                    className={styles.bannerButton}
                                    onClick={() =>
                                        postit.paymentState.type ===
                                            "Pending" &&
                                        setWalletPaymentDetailsOpen({
                                            open: true,
                                            walletPaymentDetailsId:
                                                postit.paymentState.paymentId,
                                        })
                                    }
                                >
                                    Show details
                                </Button>
                            </div>
                        </AlertSection>
                    )}
                </Content>

                <Footer className={styles.footer}>
                    {contentCreatorProfileQuery.isSuccess && (
                        <UserDetails
                            loc={{ payed: "payed" }}
                            contentLastChangedAt={postit.createdAt}
                            liked={isLiked}
                            isBuyContentAvailable={isBuyContentAvailable}
                            contentPaymentState={postit.paymentState}
                            monetization={postit.monetization}
                            avatarSize={36}
                            profileId={postit.creatorId}
                            comments={totalComments}
                            votes={totalVotes}
                            onLikeChangedClicked={ensureUserCallback(
                                postit.creatorId,
                            )(() => {
                                if (myPostitVote.isFetching) return
                                if (!myPostitVote.isSuccess) return
                                if (isVoting) return
                                if (!voter) return

                                if (!myPostitVote.data.isLiked) {
                                    vote({
                                        voter,
                                        resourceOwnerId: contentCreatorId,
                                        voteResourceRef: {
                                            contentType: "Postit",
                                            contentId: params.id,
                                        },
                                    })
                                } else if (O.isSome(voteId)) {
                                    revokeVote({
                                        voter,
                                        voteResourceRef: {
                                            contentType: "Postit",
                                            contentId: params.id,
                                        },
                                        voteId: voteId.value,
                                    })
                                }
                            })}
                            onCommentDetailsClicked={ensureUserCallback(
                                postit.creatorId,
                            )(() => {
                                if (!postitQuery.isSuccess) return
                                setCommentOverviewOpen(true)
                            })}
                            onShareClicked={ensureUserCallback(
                                postit.creatorId,
                            )(() => {
                                setIsShareLinkInCreation(true)
                                sharePost({
                                    contents: [
                                        {
                                            contentType: "Postit",
                                            contentFilter: ShareContentId.parse(
                                                params.id,
                                            ),
                                        },
                                    ],
                                })
                            })}
                            onLikeDetailsClicked={() => {
                                if (!postitQuery.isSuccess) return
                                if (totalVotes === 0) return

                                setLikingOverviewOpen(true)
                            }}
                            onPurchaseClicked={ensureUserCallback(
                                postit.creatorId,
                            )(() =>
                                !isMyContent() &&
                                postitQuery.isSuccess &&
                                profile &&
                                userId !== profile.userId
                                    ? setPostitPayModalOpen(true)
                                    : undefined,
                            )}
                        />
                    )}
                </Footer>
            </Page>
        </>
    )
}

export const PostitConsumerPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content
            style={{ overflow: "scroll" }}
            className={styles.content}
            fullscreen
        >
            <div className={styles.imageWrapper}>
                <PostitLoadingImage />
            </div>
            <Skeleton
                style={{ marginBottom: 8 }}
                width={230}
                height={30}
                borderRadius={24}
            />
            <Skeleton width={144} height={18} borderRadius={24} />
        </Content>
        <Footer className={styles.footer}>
            <UserDetailsLoading />
        </Footer>
    </Page>
)

export const PostitLoadingImage: FC = () => (
    <Skeleton width="100%" height="50dvh" borderRadius={12} />
)

export default PostitConsumerPage

const PostitImage = () => {
    const params = useParams({ from: "/_authenticated/app/postit/viewer/$id" })
    const postitQuery = useSuspenseQuery(postitByIdQueryOptions(params.id))
    const postit = postitQuery.data

    const [isLoaded, setIsLoaded] = useState(false)

    const assetQuery = useAsset({
        resource: postit.type === "Image" ? postit.imageRef : undefined,
    })

    const aspectRatio =
        assetQuery.data?.type === "Image"
            ? assetQuery.data.resolution.width /
              assetQuery.data.resolution.height
            : undefined

    const desiredImageWidth = window.innerWidth - 40
    const desiredImageHeight = aspectRatio
        ? desiredImageWidth / aspectRatio
        : undefined

    const imageAssetDataQuery = useAssetData(
        assetQuery.data?.source,
        assetQuery.data?.source
            ? (`${assetQuery.data?.source}?width=${desiredImageWidth * 4}` as Url)
            : undefined,
    )

    const notPaid =
        postit.paymentState.type === "Required" ||
        postit.paymentState.type === "Pending"

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.imageWrapper}>
                {assetQuery.isSuccess && (
                    <>
                        {notPaid && <div className={styles.secureOverlay} />}
                        <ImageView
                            id={params.id}
                            onLoad={() => setIsLoaded(true)}
                            className={classNames(styles.image)}
                            src={
                                imageAssetDataQuery.isSuccess
                                    ? URL.createObjectURL(
                                          imageAssetDataQuery.data,
                                      )
                                    : undefined
                            }
                            style={{
                                borderRadius: vars.measurement.radius.lg,
                                zIndex: notPaid ? "auto" : 100,
                                opacity: isLoaded ? 1 : 0,
                            }}
                            width={desiredImageWidth}
                            height={desiredImageHeight}
                        />
                        {assetQuery.data.type === "Image" && (
                            <Blurhash
                                className={classNames(
                                    styles.blurPreview,
                                    isLoaded ? styles.visible : styles.visible,
                                )}
                                hash={assetQuery.data.previewHash}
                                width={desiredImageWidth}
                                height={desiredImageHeight}
                            />
                        )}
                    </>
                )}
                {assetQuery.isError && (
                    <div
                        className={styles.errorFallback}
                        style={{ aspectRatio: 1.2 }}
                    >
                        <FontAwesomeIcon
                            className={styles.fallbackIcon}
                            icon={faImageSlash}
                        />
                        <div
                            onClick={e => {
                                e.preventDefault()
                                assetQuery.refetch()
                                imageAssetDataQuery.refetch()
                            }}
                            className={styles.refreshFallbackSection}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRotateRight}
                                className={styles.refreshIcon}
                            />
                            <p
                                style={{
                                    fontWeight: 600,
                                    fontSize: vars.font.size.xs,
                                }}
                            >
                                Reload image
                            </p>
                        </div>
                    </div>
                )}
                {assetQuery.isLoading && (
                    <Skeleton
                        width={desiredImageWidth}
                        height={desiredImageWidth / 1.2}
                        borderRadius={vars.measurement.radius.md}
                    />
                )}
            </div>
        </div>
    )
}
