import { ADTType, makeADT, ofType } from "@morphic-ts/adt"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { ReactNode, useEffect, useState } from "react"

import { InfoToastProps } from "./toast"

//? if more needed - we would need to add stacked styles
const TOAST_LIMIT = 1
const TOAST_REMOVE_DELAY = 1000

type InfoToasterToast = InfoToastProps & {
    id: string
    onClosed?: () => void
    toastContent?: ReactNode
}

type AddNotificationToast = {
    type: "AddNotificationToast"
    toast: InfoToasterToast
}

type UpdateNotificationToast = {
    type: "UpdateNotificationToast"
    toast: Partial<InfoToasterToast>
}

type DismissInfoToast = {
    type: "DismissInfoToast"
    toastId?: InfoToasterToast["id"]
}

type RemoveInfoToast = {
    type: "RemoveInfoToast"
    toastId?: InfoToasterToast["id"]
}

const InfoToastActionAdt = makeADT("type")({
    AddNotificationToast: ofType<AddNotificationToast>(),
    UpdateNotificationToast: ofType<UpdateNotificationToast>(),
    DismissInfoToast: ofType<DismissInfoToast>(),
    RemoveInfoToast: ofType<RemoveInfoToast>(),
})

type InfoToastAction = ADTType<typeof InfoToastActionAdt>

let count = 0

const genId = () => {
    count = (count + 1) % Number.MAX_SAFE_INTEGER
    return count.toString()
}

type State = {
    toasts: InfoToasterToast[]
}

const infoToastTimeouts = new Map<string, ReturnType<typeof setTimeout>>()

const addToRemoveQueue = (toastId: string) => {
    if (infoToastTimeouts.has(toastId)) return

    const timeout = setTimeout(() => {
        infoToastTimeouts.delete(toastId)
        dispatch(
            InfoToastActionAdt.as.RemoveInfoToast({
                toastId,
            }),
        )
    }, TOAST_REMOVE_DELAY)

    infoToastTimeouts.set(toastId, timeout)
}

//? rewrite with matcher?
export const reducer = (state: State, action: InfoToastAction): State =>
    pipe(
        action,
        InfoToastActionAdt.matchStrict({
            AddNotificationToast: ({ toast }) => {
                return {
                    ...state,
                    toasts: [toast, ...state.toasts].slice(0, TOAST_LIMIT),
                }
            },
            UpdateNotificationToast: ({ toast }) => {
                return {
                    ...state,
                    toasts: state.toasts.map(t =>
                        t.id === toast.id ? { ...t, ...toast } : t,
                    ),
                }
            },
            DismissInfoToast: ({ toastId }) => {
                // ! Side effects ! - This could be extracted into a DismissInfoToast() action,
                // but I'll keep it here for simplicity
                if (toastId) {
                    addToRemoveQueue(toastId)
                } else {
                    state.toasts.forEach(toast => {
                        addToRemoveQueue(toast.id)
                    })
                }

                return {
                    ...state,
                    toasts: state.toasts.map(t =>
                        t.id === toastId || toastId === undefined
                            ? {
                                  ...t,
                                  open: false,
                              }
                            : t,
                    ),
                }
            },
            RemoveInfoToast: ({ toastId }) => {
                if (toastId === undefined) {
                    return {
                        ...state,
                        toasts: [],
                    }
                }
                return {
                    ...state,
                    toasts: state.toasts.filter(t => t.id !== toastId),
                }
            },
        }),
    )

const listeners: Array<(state: State) => void> = []

let memoryState: State = {
    toasts: [],
}

const dispatch = (action: InfoToastAction) => {
    memoryState = reducer(memoryState, action)
    pipe(
        listeners,
        A.map(listener => listener(memoryState)),
    )
}

type InfoToastModel = Omit<InfoToasterToast, "id">

export const infoToast = ({ onClosed, ...props }: InfoToastModel) => {
    const id = genId()

    const update = (props: InfoToasterToast) =>
        dispatch(
            InfoToastActionAdt.as.UpdateNotificationToast({
                toast: { ...props, id },
            }),
        )
    const dismiss = () => {
        onClosed && onClosed()
        dispatch(
            InfoToastActionAdt.as.DismissInfoToast({
                toastId: id,
            }),
        )
    }

    dispatch(
        InfoToastActionAdt.as.AddNotificationToast({
            toast: {
                ...props,
                id,
                open: true,
                onOpenChange: open => {
                    if (!open) dismiss()
                },
            },
        }),
    )

    return {
        id,
        dismiss,
        update,
    }
}

export const useInfoToast = () => {
    const [state, setState] = useState<State>(memoryState)

    useEffect(() => {
        listeners.push(setState)

        return () => {
            const index = listeners.indexOf(setState)
            if (index > -1) listeners.splice(index, 1)
        }
    }, [state])

    return {
        ...state,
        toast: infoToast,
        dismiss: (toastId?: string) =>
            dispatch(
                InfoToastActionAdt.as.DismissInfoToast({
                    toastId,
                }),
            ),
    }
}
