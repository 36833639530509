import { queryOptions, useQuery } from "@tanstack/react-query"

import { Postit } from "api/api-models"
import { Result } from "api/types"
import { PostitId } from "../../../api/branded-types"
import { postitSelectorClient } from "../../../api/clients/post-api-client"
import { QueryKeys } from "../../../api/query-keys"

export const getListPostitById = async (
    id?: PostitId,
): Promise<Result<Postit>> => {
    if (!id) throw new Error("PostitId is required")
    const res = await postitSelectorClient.listPostits({
        filter: `{ $match: {id: "${id}"}}`,
        paging: { type: "Index", direction: "After", index: -1, limit: 1 },
    })
    const data = res.data.at(0)

    if (!data) {
        return {
            data,
            error: "POSTIT_NOT_FOUND",
        }
    }
    return {
        data,
        error: undefined,
    }
}

export const listPostitByIdQueryOptions = (id?: PostitId) =>
    queryOptions({
        queryFn: () => getListPostitById(id),
        queryKey: QueryKeys.postit(id),
        enabled: !!id,
    })

export const useListPostitById = (id?: PostitId) => {
    return useQuery(listPostitByIdQueryOptions(id))
}
