import * as PopoverPrimitive from "@radix-ui/react-popover"

import classNames from "classnames"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"
import * as styles from "./popover.css"

export const Popover = PopoverPrimitive.Root
export const PopoverTrigger = PopoverPrimitive.Trigger

export const PopoverArrow = forwardRef<
    ElementRef<typeof PopoverPrimitive.Arrow>,
    ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow>
>(({ className, ...props }, ref) => (
    <PopoverPrimitive.Arrow
        ref={ref}
        className={classNames(styles.arrow, className)}
        {...props}
    />
))

export const PopoverContent = forwardRef<
    ElementRef<typeof PopoverPrimitive.Content>,
    ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={classNames(styles.content, className)}
            {...props}
        />
    </PopoverPrimitive.Portal>
))
