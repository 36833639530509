import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { faCircleStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC, useState } from "react"

import { Currency } from "../../api/api-models"
import { Url } from "../../api/branded-types"
import { Avatar } from "../../components/avatars/avatar"

import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { useTranslation } from "react-i18next"
import { useIntl } from "react-intl"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { vars } from "theme/variables.css"
import { Checkbox } from "../../common/checkbox"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./content-pay-modal.css"

type TextProps = {
    subscribe: {
        title: string
        action: string
        amount: number
        currency: Currency
    }
    terms: string
}

export type Props = {
    text: TextProps
    user: {
        name: string
        imageUrl: Url
        isLive: boolean
    }
    content: {
        title: string
        imageUrl: Url
        type: "Video" | "Image" | "Text"
    }
    open: boolean

    onCanceled: () => void
    onSubscribed: () => void
}

export const ContentSubscribeModal: FC<Props> = ({
    text,
    open,
    user,
    content,
    onCanceled,
    onSubscribed,
}) => {
    const intl = useIntl()
    const [purchaseConsentChecked, setPurchaseConsentChecked] = useState(false)
    const { t } = useTranslation(["postitViewer"])

    return (
        <Drawer open={open} onClose={onCanceled}>
            <DrawerContent className={styles.modal}>
                <DrawerHeader>
                    <DrawerClose asChild>
                        <FontAwesomeIcon
                            icon={faXmark}
                            className={styles.closeIcon}
                        />
                    </DrawerClose>
                </DrawerHeader>
                <DrawerTitle />
                <DrawerDescription />
                <div className={styles.content}>
                    <div className={styles.contentWrapper}>
                        {content.type === "Video" && (
                            <div className={styles.videoImageContentWrapper}>
                                <FontAwesomeIcon
                                    className={styles.icon}
                                    icon={faCircleStar}
                                    color="white"
                                />
                                <LazyLoadImage
                                    width={64}
                                    height={90}
                                    style={{
                                        objectFit: "cover",
                                        borderRadius:
                                            vars.measurement.radius.md,
                                    }}
                                    alt="content-image"
                                    src={`${content.imageUrl}?height=180`}
                                    loading="eager"
                                />
                            </div>
                        )}

                        {content.type === "Image" && (
                            <div className={styles.imageContentWrapper}>
                                <FontAwesomeIcon
                                    className={styles.icon}
                                    icon={faCircleStar}
                                    color="white"
                                />
                                <LazyLoadImage
                                    width={85}
                                    height={85}
                                    style={{
                                        objectFit: "cover",
                                    }}
                                    alt="content-image"
                                    src={`${content.imageUrl}?height=170`}
                                    loading="eager"
                                />
                                <div className={styles.secureOverlay} />
                            </div>
                        )}

                        {content.type === "Text" && (
                            <div style={{ marginRight: 6 }}>
                                <FontAwesomeIcon
                                    className={classNames(
                                        styles.icon,
                                        styles.textIcon,
                                    )}
                                    icon={faCircleStar}
                                />
                            </div>
                        )}

                        <div className={styles.userLabelWrapper}>
                            <p
                                style={{
                                    fontSize: vars.font.size.l,
                                    color: vars.color.medium.hex,
                                    fontWeight: 500,
                                    margin: 0,
                                }}
                            >
                                {content.title}
                            </p>

                            <div className={styles.user}>
                                <Avatar
                                    className={styles.avatar}
                                    src={user.imageUrl}
                                    size={28}
                                    styleState={user.isLive ? "isLive" : "None"}
                                />

                                <p
                                    style={{
                                        fontSize: vars.font.size.regular,
                                        margin: 0,
                                        color: vars.color.medium.hex,
                                    }}
                                >
                                    {user.name}
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                padding: 2,
                                marginLeft: "auto",
                                alignItems: "center",
                                fontSize: vars.font.size.l,
                            }}
                        >
                            {intl.formatNumber(text.subscribe.amount, {
                                style: "currency",
                                currency: "EUR",
                                currencyDisplay: "symbol",
                            })}
                            <br />{" "}
                            <span style={{ fontSize: vars.font.size.regular }}>
                                {t("monthly")}
                            </span>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 16,
                        }}
                    >
                        <div className={styles.consentContainer}>
                            <Checkbox
                                checked={purchaseConsentChecked}
                                onCheckedChange={v =>
                                    setPurchaseConsentChecked(v === true)
                                }
                                id="purchase_consent"
                            />
                            <label
                                htmlFor="purchase_consent"
                                style={{
                                    fontSize: vars.font.size.regular,
                                    color: vars.color.tertiary.hex,
                                }}
                            >
                                {t("purchase.consent.label")}
                            </label>
                        </div>
                    </div>

                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                            color: vars.color.tertiary.hex,
                            padding: "12px 10px 0 10px",
                            margin: 0,
                        }}
                    >
                        {t("purchase.cancellationPolicy.description")}{" "}
                        <Link
                            className={styles.link}
                            to="/registration/cancellation-policy"
                        >
                            {t("purchase.cancellationPolicy.link")}
                        </Link>{" "}
                        {t("purchase.cancellationPolicy.section")}
                    </p>

                    <Button
                        variant="dark"
                        className={styles.action}
                        disabled={!purchaseConsentChecked}
                        onClick={() => onSubscribed()}
                    >
                        {t("subscribe.action")}
                    </Button>

                    <p
                        style={{
                            fontSize: vars.font.size.xxs,
                            color: vars.color.tertiary.hex,
                            padding: "12px 10px 0 10px",
                            margin: 0,
                        }}
                    >
                        {t("purchase.contract.start")}{" "}
                        <Link
                            className={styles.link}
                            to="/registration/terms-and-condition"
                        >
                            {t("purchase.contract.link")}
                        </Link>{" "}
                        {t("purchase.contract.end")}
                    </p>

                    <p
                        style={{
                            fontSize: vars.font.size.xxs,
                            color: vars.color.tertiary.hex,
                            padding: "0 10px",
                            margin: 0,
                        }}
                    >
                        {t("privacy.info")}{" "}
                        <Link
                            className={styles.link}
                            to="/registration/privacy-policy"
                        >
                            {t("privacy.policy.link")}
                        </Link>
                    </p>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
