import {
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Postit } from "api/api-models"
import { UserProfileId } from "../../../api/branded-types"
import { postitSelectorClient } from "../../../api/clients/post-api-client"
import { QueryKeys } from "../../../api/query-keys"

const PAGINATION_AMOUNT = 25

export const useProfilePostContent = (profileId?: UserProfileId) => {
    const filter = `{$match: {creatorId: in ["${profileId}"]}, $sort:{createdAt: desc}}`
    const queryClient = useQueryClient()
    return useInfiniteQuery({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            getProfilePostContent({ pageParam, filter, queryClient }),
        queryKey: QueryKeys.profilePostContent(profileId),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!profileId,
    })
}

const getProfilePostContent = async ({
    pageParam,
    filter,
    queryClient,
}: {
    pageParam: number
    filter: string
    queryClient: QueryClient
}) => {
    const postsRes = await postitSelectorClient.listPostits({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    postsRes.data.forEach(data =>
        queryClient.setQueryData<{ data: Postit; error: undefined }>(
            QueryKeys.postit(data.id),
            { data, error: undefined },
        ),
    )

    return postsRes
}
