import { useDeviceInfo } from "features/user-profile/hooks/use-device-info"
import { CSSProperties, FC, PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

const titleStyle: CSSProperties = {
    fontWeight: 600,
    padding: 0,
    margin: 0,
}

const stepStyle: CSSProperties = {
    padding: 0,
    margin: 0,
}

const boldStyle: CSSProperties = {
    fontWeight: 600,
}

const Step: FC<PropsWithChildren<{ stepNumber: number }>> = ({
    stepNumber,
    children,
}) => (
    <p style={stepStyle}>
        <span style={boldStyle}>Step {stepNumber}:</span> {children}
    </p>
)

export const HowItWorks: FC = () => {
    const { isApple, isAndroid } = useDeviceInfo()
    const { t } = useTranslation(["modal"])

    return (
        <div
            style={{
                backgroundColor: "#ECEBEE",
                textAlign: "left",
                padding: "12px 16px",
                margin: 0,
                marginTop: "16px",
                borderRadius: 10,
            }}
        >
            <p style={titleStyle}>{t("instructions.title")}</p>

            {isApple &&
                (
                    t("instructions.steps.apple", { returnObjects: true }) as {
                        stepNumber: number
                        text: string
                    }[]
                ).map(step => (
                    <Step key={step.stepNumber} stepNumber={step.stepNumber}>
                        <span dangerouslySetInnerHTML={{ __html: step.text }} />
                    </Step>
                ))}

            {isAndroid &&
                (
                    t("instructions.steps.android", {
                        returnObjects: true,
                    }) as {
                        stepNumber: number
                        text: string
                    }[]
                ).map(step => (
                    <Step key={step.stepNumber} stepNumber={step.stepNumber}>
                        <span dangerouslySetInnerHTML={{ __html: step.text }} />
                    </Step>
                ))}
        </div>
    )
}
