import { queryOptions, useQuery } from "@tanstack/react-query"
import { UserMessageId } from "api/branded-types"
import { userMessageSelectorClient } from "api/clients/message-api-client"
import { QueryKeys } from "api/query-keys"

const getMessageById = async (id?: UserMessageId) => {
    if (!id) throw new Error("MessageId should be defined")
    const res = await userMessageSelectorClient.getMessage({ params: { id } })
    return res.data
}

export const messageByIdQueryOptions = (id?: UserMessageId) =>
    queryOptions({
        queryFn: () => getMessageById(id),
        queryKey: QueryKeys.userMessage(id),
        enabled: !!id,
    })

export const useMessageById = (id?: UserMessageId) => {
    return useQuery(messageByIdQueryOptions(id))
}
