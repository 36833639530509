import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerTitle,
} from "common/drawer"
import { FC, useEffect } from "react"

import classNames from "classnames"
import { Button } from "common/button"
import { Input } from "common/input"
import { Switch } from "common/switch"
import { useForm, UseFormReturn } from "react-hook-form"
import { vars } from "theme/variables.css"
import { z } from "zod"
import { useManageSubscriptionStore } from "../manage-subscription-store"

import { faBan } from "@fortawesome/pro-regular-svg-icons"
import { faOctagonCheck } from "@fortawesome/pro-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { userProfileCreatorClient } from "api/clients/user-profile-api-client"
import { QueryKeys } from "api/query-keys"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "common/form"
import { Textarea } from "common/textarea"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { useProfileSubscriptionMonetization } from "../hooks/use-profile-subscription-monetization"
import { manageSubscriptionValidationSchema } from "../validation/manage-subscription-schema"
import * as styles from "./manage-subscription-modal.css"

export type ManageSubscriptionModalProps = {
    loc: {
        title: string
        description: string
        submit: string
        cancel: string
        form: {
            title: string
            description: string
            infos: string[]
            placeholder: string
            accountValidation: {
                title: string
                success: string
                failure: string
            }
            price: {
                set: string
                info: string
            }
        }
    }
}

export const ManageSubscriptionModal: FC<ManageSubscriptionModalProps> = ({
    loc,
}) => {
    const open = useManageSubscriptionStore(store => store.modalOpen)
    const onOpenChange = useManageSubscriptionStore(
        store => store.onModalOpenChange,
    )
    const { toast } = useInfoToast()

    const queryClient = useQueryClient()

    const form = useForm<z.infer<typeof manageSubscriptionValidationSchema>>({
        resolver: zodResolver(manageSubscriptionValidationSchema),
        defaultValues: {
            enabled: false,
            price: {
                amount: 4.99,
                currency: "EUR",
            },
        },
    })

    const myProfileIdQuery = useMyUserProfileId()
    const monetizationSubscriptionQuery = useProfileSubscriptionMonetization(
        myProfileIdQuery.data,
    )

    const { mutate: updateSubMonetization, isPending: isUpdateSubInProgress } =
        useMutation({
            mutationFn: userProfileCreatorClient.updateSubscriptionMonetization,
        })
    const {
        mutate: deleteSubscriptionMonetization,
        isPending: isDeleteSubscriptionInProgress,
    } = useMutation({
        mutationFn: userProfileCreatorClient.deleteSubscriptionMonetization,
    })

    const updateDisabled =
        monetizationSubscriptionQuery.isLoading ||
        isUpdateSubInProgress ||
        isDeleteSubscriptionInProgress

    function onSubmit(
        values: z.infer<typeof manageSubscriptionValidationSchema>,
    ) {
        const { enabled, price } = values
        if (enabled) {
            updateSubMonetization(
                { interval: "Months1", price },
                {
                    onSuccess: () =>
                        toast({
                            toastContent: (
                                <InfoToastContent
                                    icon={faOctagonCheck}
                                    iconColor="success"
                                    text={loc.form.accountValidation.success}
                                />
                            ),
                        }),
                    onSettled: () => {
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.userProfileSubscription(
                                myProfileIdQuery.data,
                            ),
                        })
                    },
                },
            )
        } else {
            deleteSubscriptionMonetization(undefined, {
                onSuccess: () =>
                    toast({
                        toastContent: (
                            <InfoToastContent
                                icon={faBan}
                                iconColor="liveColor"
                                text={loc.form.accountValidation.failure}
                            />
                        ),
                    }),
                onSettled: () => {
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.userProfileSubscription(
                            myProfileIdQuery.data,
                        ),
                    })
                },
            })
        }
        onOpenChange(false)
    }

    useEffect(() => {
        const subscriptionDisabled =
            monetizationSubscriptionQuery.isSuccess &&
            monetizationSubscriptionQuery.data.interval === "None"

        if (subscriptionDisabled) {
            form.reset({
                enabled: false,
                price: {
                    amount: 4.99,
                    currency: "EUR",
                },
            })
        } else if (monetizationSubscriptionQuery.isSuccess) {
            form.reset({
                enabled: true,
                price: monetizationSubscriptionQuery.data.price,
            })
        }
    }, [
        monetizationSubscriptionQuery.data,
        monetizationSubscriptionQuery.isSuccess,
        form.reset,
    ])

    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent className={styles.modal}>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <DrawerClose className={styles.closeIconContainer}>
                            <FontAwesomeIcon
                                className={styles.closeIcon}
                                icon={faXmark}
                            />
                        </DrawerClose>

                        <div className={styles.content}>
                            <DrawerTitle className={styles.title}>
                                {loc.title}
                            </DrawerTitle>
                            <DrawerDescription
                                className={styles.description}
                            ></DrawerDescription>
                            <div
                                className={
                                    styles.manageSubscriptionSectionWrapper
                                }
                            >
                                <EnabledControlField
                                    disabled={updateDisabled}
                                    loc={loc}
                                    form={form}
                                />
                                <PriceControlField
                                    disabled={updateDisabled}
                                    loc={loc}
                                    form={form}
                                />
                            </div>
                            {/* <MessageToSubscribersSection
                                disabled={updateDisabled}
                                loc={loc}
                                form={form}
                            /> */}
                        </div>

                        <DrawerFooter className={styles.footer}>
                            <Button type="submit" full>
                                {loc.submit}
                            </Button>
                            <DrawerClose asChild>
                                <Button type="button" full variant="secondary">
                                    {loc.cancel}
                                </Button>
                            </DrawerClose>
                        </DrawerFooter>
                    </form>
                </Form>
            </DrawerContent>
        </Drawer>
    )
}

export const EnabledControlField: FC<
    ManageSubscriptionModalProps & {
        form: UseFormReturn<z.infer<typeof manageSubscriptionValidationSchema>>
        disabled: boolean
    }
> = ({ loc, form, disabled }) => (
    <FormField
        control={form.control}
        name="enabled"
        render={({ field }) => (
            <FormItem className={styles.monetizeAccountSwitchWrapper}>
                <FormLabel
                    className={styles.subscriptionSectionTitle}
                    style={{
                        fontSize: vars.font.size.regular,
                        margin: 0,
                    }}
                >
                    {loc.form.accountValidation.title}
                </FormLabel>

                <FormControl>
                    <Switch
                        disabled={disabled}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                    />
                </FormControl>
                <FormMessage />
            </FormItem>
        )}
    />
)

export const PriceControlField: FC<
    ManageSubscriptionModalProps & {
        form: UseFormReturn<z.infer<typeof manageSubscriptionValidationSchema>>
        disabled: boolean
    }
> = ({ loc, form, disabled }) => {
    const price = form.getValues("price")
    const currency = price?.currency
    const currencySymbol =
        currency === "EUR" ? "€" : currency === "USD" ? "$" : ""

    return (
        <FormField
            control={form.control}
            name="price.amount"
            render={({ field }) => (
                <FormItem className={styles.labelPriceManagementWrapper}>
                    <FormLabel
                        className={styles.labelPriceManagementTextWrapper}
                    >
                        <p
                            className={styles.subscriptionSectionTitle}
                            style={{
                                fontSize: vars.font.size.regular,
                                margin: 0,
                            }}
                        >
                            {loc.form.price.set}
                        </p>
                        <p
                            className={styles.subscriptionSectionSubtitle}
                            style={{
                                fontSize: vars.font.size.regular,
                                margin: 0,
                            }}
                        >
                            {loc.form.price.info}
                        </p>
                    </FormLabel>

                    <div className={styles.inputContainer}>
                        <FormControl asChild>
                            <Input
                                className={styles.input}
                                disabled={
                                    !form.getValues("enabled") || disabled
                                }
                                {...field}
                            />
                        </FormControl>
                        <div
                            className={classNames(
                                styles.currencySymbol,
                                form.getValues("price") &&
                                    form.getValues("enabled") &&
                                    styles.currencySymbolActive,
                            )}
                        >
                            {currencySymbol}
                        </div>
                    </div>

                    <FormMessage className={styles.validationMessage} />
                </FormItem>
            )}
        />
    )
}

export const MessageToSubscribersSection: FC<
    ManageSubscriptionModalProps & {
        form: UseFormReturn<z.infer<typeof manageSubscriptionValidationSchema>>
        disabled: boolean
    }
> = ({ form, loc, disabled }) => (
    <div className={styles.messageToSubscribersSection}>
        <p className={styles.messageToSubscribersTitle}>{loc.title}</p>

        <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
                <FormItem>
                    <Textarea
                        {...field}
                        disabled={!form.getValues("enabled") || disabled}
                        className={styles.textarea}
                        placeholder={loc.form.placeholder}
                    />
                    <FormMessage className={styles.validationMessage} />
                </FormItem>
            )}
        />

        <p className={styles.messageToSubscribersDescription}></p>

        <ul className={styles.messageToSubscribersDescriptionList}>
            {loc.form.infos.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    </div>
)
