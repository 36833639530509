import {
    infiniteQueryOptions,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { UserMessage } from "../../../api/api-models"
import { UserProfileId } from "../../../api/branded-types"
import { userMessageSelectorClient } from "../../../api/clients/message-api-client"
import { QueryKeys } from "../../../api/query-keys"

const PAGINATION_AMOUNT = 10

type Params = {
    recipientId?: UserProfileId
    senderId?: UserProfileId
    refetchInterval?: number | false
}

export const messagesInfiniteQueryOptions = (
    params: Params & { queryClient: QueryClient },
) =>
    infiniteQueryOptions({
        initialPageParam: -1,
        queryKey: QueryKeys.resourceMessages(
            params.recipientId,
            params.senderId,
        ),
        queryFn: ({ pageParam }) =>
            listMessages({
                ...params,
                pageParam,
                queryClient: params.queryClient,
            }),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!params.recipientId,
        refetchInterval: params.refetchInterval,
    })

export const useMessages = (params: Params) => {
    const queryClient = useQueryClient()
    return useInfiniteQuery(
        messagesInfiniteQueryOptions({ ...params, queryClient }),
    )
}

const listMessages = async ({
    pageParam,
    recipientId,
    senderId,
    queryClient,
}: { pageParam: number; queryClient: QueryClient } & Params) => {
    if (!recipientId) throw new Error("Recipient Id is required")
    if (!senderId) throw new Error("Sender Id is required")

    const filter = `{$match: {$or: [
              {recipient.contentType: "UserProfile", recipient.contentId: "${recipientId}", senderId: "${senderId}"},
              {recipient.contentType: "UserProfile", recipient.contentId: "${senderId}", senderId: "${recipientId}"},
            ]},$sort: {createdAt: desc}
            }`

    const messages = await userMessageSelectorClient.listMessages({
        filter,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    messages.data.map(message =>
        queryClient.setQueryData<UserMessage>(
            QueryKeys.userMessage(message.id),
            message,
        ),
    )

    return messages
}
