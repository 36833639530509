import { useMutation, useQueryClient } from "@tanstack/react-query"

import { UserProfileStatistic } from "api/api-models"
import { InfinitePostsData } from "features/user-profile/components/my-user-profile-post-content"
import { PostitId, UserProfileId } from "../../../../../api/branded-types"
import { postitCreatorClient } from "../../../../../api/clients/post-api-client"
import { QueryKeys, StaticQueryKeys } from "../../../../../api/query-keys"

type MutationFunctionParams = {
    id: PostitId
    profileId: UserProfileId
}

const searchPagePostsQueryKey = [
    StaticQueryKeys.SEARCH,
    StaticQueryKeys.SEARCH_POSTS,
]

export const useDeletePostMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ id }: MutationFunctionParams) =>
            postitCreatorClient.deletePostitById(undefined, {
                params: { id },
            }),
        onMutate: async ({ id, profileId }) => {
            const profilePostContentQueryKey =
                QueryKeys.profilePostContent(profileId)
            const profileStatisticsQueryKey =
                QueryKeys.profileStatistics(profileId)

            await queryClient.cancelQueries({
                queryKey: profilePostContentQueryKey,
            })
            await queryClient.cancelQueries({
                queryKey: searchPagePostsQueryKey,
            })
            await queryClient.cancelQueries({
                queryKey: profileStatisticsQueryKey,
            })

            const previousProfilePostContent =
                queryClient.getQueryData<InfinitePostsData>(
                    profilePostContentQueryKey,
                )
            const previousSearchPostContent =
                queryClient.getQueryData<InfinitePostsData>(
                    searchPagePostsQueryKey,
                )
            const previousProfileStatistics =
                queryClient.getQueryData<UserProfileStatistic>(
                    profileStatisticsQueryKey,
                )

            if (previousProfilePostContent) {
                queryClient.setQueryData<InfinitePostsData>(
                    profilePostContentQueryKey,
                    {
                        ...previousProfilePostContent,
                        pages: previousProfilePostContent.pages.map(page => ({
                            ...page,
                            data: page.data.filter(post => post.id === id),
                        })),
                    },
                )
            }
            if (previousSearchPostContent) {
                queryClient.setQueryData<InfinitePostsData>(
                    profilePostContentQueryKey,
                    {
                        ...previousSearchPostContent,
                        pages: previousSearchPostContent.pages.map(page => ({
                            ...page,
                            data: page.data.filter(post => post.id === id),
                        })),
                    },
                )
            }
            if (previousProfileStatistics) {
                const postAmount =
                    previousProfileStatistics.count.posts - 1 > 0
                        ? previousProfileStatistics.count.posts - 1
                        : 0

                queryClient.setQueryData<UserProfileStatistic>(
                    profileStatisticsQueryKey,
                    {
                        ...previousProfileStatistics,
                        count: {
                            ...previousProfileStatistics.count,
                            posts: postAmount,
                        },
                    },
                )
            }

            return {
                previousProfilePostContent,
                previousSearchPostContent,
                previousProfileStatistics,
            }
        },
        onError: (err, { profileId }, context) => {
            console.error(err)
            if (context?.previousProfilePostContent) {
                queryClient.setQueryData(
                    QueryKeys.profilePostContent(profileId),
                    context.previousProfilePostContent,
                )
            }
            if (context?.previousSearchPostContent) {
                queryClient.setQueryData(
                    searchPagePostsQueryKey,
                    context.previousSearchPostContent,
                )
            }
            if (context?.previousProfileStatistics) {
                queryClient.setQueryData(
                    QueryKeys.profileStatistics(profileId),
                    context.previousProfileStatistics,
                )
            }
        },
        onSettled: (_data, _err, { profileId }) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profilePostContent(profileId),
            })
            queryClient.invalidateQueries({
                queryKey: searchPagePostsQueryKey,
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileStatistics(profileId),
            })
        },
    })
}
