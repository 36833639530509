import { FC, useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"

import { useLiveNowEvents } from "../../hooks/use-live-now-events"
import { meQueryOptions } from "../../hooks/use-me"
import { useMyUserProfile } from "../../hooks/use-my-user-profiles"
import { recommendedPostitsInfiniteQueryOptions } from "../../hooks/use-recommended-postits"
import { recommendedVideosInfiniteQueryOptions } from "../../hooks/use-recommended-videos"
import { useTopUsers } from "../../hooks/use-top-users"

import { MyProfileAvatar } from "../user-profile/my-profile-avatar"
import {
    LiveNowAreaView,
    LiveNowAreaViewLoading,
} from "./areas/live-now/live-now-area-view"
import {
    TopUserAreaView,
    TopUserAreaViewLoading,
} from "./areas/top-users/top-user-area-view"
import { RecommendedPostAreaView } from "./areas/videos/recommended-post-area-view"

import { faBell, faRocket, faX } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIsAuthorizedAccount } from "../../api/api-utils"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { useUnreadNotifications } from "../notifications/hooks/use-unread-notifications"

import { PostLoadingSection } from "../search/videos/post-loading-tile"

import {
    useInfiniteQuery,
    useQuery,
    useQueryClient,
    useSuspenseQuery,
} from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import { AlertSection } from "common/alert-section"
import { paymentProviderConnectionQueryOptions } from "features/payment/hooks/use-payment-provider-connection"
import { FeedTeaser } from "features/point-system/teaser/feed-teaser"
import { useProfileStatistics } from "features/user-profile/hooks/use-profile-statistic"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"
import { useTeaserState } from "hooks/use-teaser-state"
import { userFollowingsInfiniteQueryOptions } from "hooks/use-user-followings"
import { useAppStore } from "store"
import { vars } from "theme/variables.css"
import * as styles from "./feed-overview-page.css"

export const FeedOverviewPage: FC = () => {
    const { t } = useTranslation(["feed"])
    const { accountType, ensureUserPreventDefault, ensureUser } =
        useOnlyRegisteredUser()
    const { isSkipped, skip } = useTeaserState()
    const setScrollFeedToTop = useAppStore(store => store.setScrollFeedToTop)

    const queryClient = useQueryClient()

    const contentRef = useRef<HTMLDivElement | null>(null)

    const meQuery = useSuspenseQuery(meQueryOptions({ queryClient }))

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )

    const myProfileQuery = useMyUserProfile(isAuthorizedAccount)
    const liveNowEventsQuery = useLiveNowEvents()

    const userId = myProfileQuery.isSuccess
        ? myProfileQuery.data?.userId
        : undefined

    const profileId = myProfileQuery.isSuccess
        ? myProfileQuery.data?.id
        : undefined

    const followingsQuery = useInfiniteQuery(
        userFollowingsInfiniteQueryOptions({ queryClient, profileId }),
    )

    const userFollowingsProfileIds =
        followingsQuery.data?.pages.flatMap(page =>
            page.data.map(item => item.id),
        ) ?? []

    const feedRecommendedVideosQuery = useInfiniteQuery(
        recommendedVideosInfiniteQueryOptions({
            queryClient,
            followingUserProfileIds: userFollowingsProfileIds,
            profileId,
        }),
    )
    const feedRecommendedPostsQuery = useInfiniteQuery(
        recommendedPostitsInfiniteQueryOptions({
            queryClient,
            followingUserProfileIds: userFollowingsProfileIds,
            profileId,
        }),
    )

    const myProfileStatistics = useProfileStatistics(profileId)

    const topUsersQuery = useTopUsers(userId)

    const newNotificationsQuery = useUnreadNotifications(profileId)
    const isHaveNewNotifications =
        (newNotificationsQuery.data?.totalCount ?? 0) > 0

    const paymentProviderConnectionQuery = useQuery(
        paymentProviderConnectionQueryOptions,
    )

    const isPaymentSetupAlertVisible =
        paymentProviderConnectionQuery.data?.type === "PaymentNotPossible"

    const refreshFeedPage = async () => {
        await Promise.allSettled([
            myProfileQuery.refetch(),
            meQuery.refetch(),
            topUsersQuery.refetch(),
            liveNowEventsQuery.refetch(),
            feedRecommendedVideosQuery.refetch(),
            feedRecommendedPostsQuery.refetch(),
            newNotificationsQuery.refetch(),
        ])
    }

    useEffect(() => {
        setScrollFeedToTop(async () => {
            if (contentRef?.current) {
                contentRef.current.scrollTop = 0
                // Prevent unwanted scroll restoration
                setTimeout(() => {
                    if (contentRef.current) {
                        contentRef.current.style.scrollBehavior = "smooth"
                        contentRef.current.scrollTop = 0
                    }
                }, 50)
            }
            await refreshFeedPage()
        })
    }, [setScrollFeedToTop])

    return (
        <Page>
            <Content ref={contentRef}>
                <PullToRefresh onRefresh={refreshFeedPage}>
                    <svg className={styles.logo}>
                        <use href="/seemee-logo-beta.svg#logo" />
                    </svg>

                    <div className={styles.myAvatarContainer}>
                        {myProfileStatistics.isSuccess && (
                            <Link
                                className={styles.pointsAmountContainer}
                                to="/app/settings/rewards"
                                search={{ currentTab: "get-started" }}
                            >
                                <p
                                    style={{
                                        fontSize: vars.font.size.xs,
                                        fontWeight: 700,
                                    }}
                                >
                                    {myProfileStatistics.data.points}
                                </p>
                            </Link>
                        )}

                        <div className={styles.notificationIconWrapper}>
                            {isHaveNewNotifications && (
                                <div className={styles.newNotificationBadge} />
                            )}
                            <Link
                                onClick={ensureUserPreventDefault()}
                                to={
                                    isAuthorizedAccount
                                        ? "/app/notifications"
                                        : "."
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faBell}
                                    color="white"
                                    className={styles.notificationIcon}
                                />
                            </Link>
                        </div>

                        <Link
                            to={isAuthorizedAccount ? "/app/my-profile" : "."}
                            onClick={ensureUserPreventDefault()}
                            search={{ tab: "All" }}
                        >
                            <MyProfileAvatar
                                isAuthorizedAccount={isAuthorizedAccount}
                                avatarSize={40}
                            />
                        </Link>
                    </div>

                    {isPaymentSetupAlertVisible && (
                        <Link to="/app/settings/monetization">
                            <AlertSection className={styles.alertSection}>
                                <p
                                    style={{
                                        fontSize: vars.font.size.regular,
                                        fontWeight: 700,
                                    }}
                                >
                                    Your Mollie account isn’t ready yet
                                </p>
                                <p
                                    style={{
                                        fontSize: vars.font.size.regular,
                                        fontWeight: 400,
                                    }}
                                >
                                    Go to{" "}
                                    <span
                                        style={{
                                            fontSize: vars.font.size.regular,
                                            color: vars.color.secondary.hex,
                                        }}
                                    >
                                        Monetization settings
                                    </span>{" "}
                                    to complete setup.
                                </p>
                            </AlertSection>
                        </Link>
                    )}

                    <div className={styles.teaserSection}>
                        {!isSkipped("CollectPoints") && (
                            <FeedTeaser
                                title={t("rewards.banner.title")}
                                description={t("rewards.banner.description")}
                                icon={faRocket}
                                closeIcon={faX}
                                color={vars.color.secondary.hex}
                                routeTo="/app/rewards-explained"
                                onClose={() => skip("CollectPoints")}
                            />
                        )}

                        {!isSkipped("SoftLaunch") && (
                            <FeedTeaser
                                title={t("softLaunch.banner.title")}
                                description={t("softLaunch.banner.description")}
                                icon={faRocket}
                                closeIcon={faX}
                                color="#FDB9C6"
                                routeTo="/app/settings"
                                onClose={() => skip("SoftLaunch")}
                            />
                        )}
                    </div>

                    <LiveNowAreaView
                        text={{
                            titleA: t("liveAreaView.titleA"),
                            titleB: t("liveAreaView.titleB"),
                            userDetails: {
                                liveState: t(
                                    "liveAreaView.userDetails.liveState.text",
                                ),
                            },
                            placeholder: t("liveAreaView.placeHolder"),
                            action: t("liveAreaView.action"),
                            account: {
                                title: t("verifyAccount.title"),
                                message: t("verifyAccount.message"),
                                cancelTitle: t("verifyAccount.cancel"),
                                createAccountTitle: t("verifyAccount.action"),
                            },
                        }}
                        onGoLiveClicked={ensureUser}
                    />

                    <TopUserAreaView
                        title={t("topUser.area.title")}
                        userId={userId}
                    />

                    <RecommendedPostAreaView
                        loc={{
                            title: t("post.area.title"),
                        }}
                        title={t("videos.areas.title")}
                        placeholder={t("videos.areas.placeholder")}
                        profileId={profileId}
                        className={styles.videoAreaWrapper}
                    />
                </PullToRefresh>
            </Content>
        </Page>
    )
}

export const FeedOverviewPageLoading = () => (
    <Page>
        <Content>
            <svg className={styles.logo}>
                <use href="/app-logo.svg#app_logo" />
            </svg>
            <LiveNowAreaViewLoading />
            <TopUserAreaViewLoading />
            <div style={{ marginInline: 20, marginTop: 24 }}>
                <PostLoadingSection />
                <div style={{ marginTop: 24 }} />
                <PostLoadingSection />
            </div>
        </Content>
    </Page>
)

export default FeedOverviewPage
