import { FC } from "react"

import { UserComment } from "../../api/api-models"
import { FoldableStatefulText } from "../texts/foldable-stateful-text"

import { Link } from "@tanstack/react-router"
import { AvatarWithBadge } from "components/avatars/avatar-with-badge"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { vars } from "theme/variables.css"
import * as styles from "./user-comment-item.css"

type UserCommentItemProps = {
    comment: UserComment
    onClicked?: () => void
}

export const UserCommentItem: FC<UserCommentItemProps> = ({
    comment,
    onClicked,
}) => {
    const myProfileIdQuery = useMyUserProfileId()
    const profileQuery = useListUserProfileById(comment.creatorId)
    const profile = profileQuery.data?.data

    return (
        <div className={styles.comment}>
            {profileQuery.isSuccess && profile && (
                <Link
                    to={
                        myProfileIdQuery.data === profile.id
                            ? "/app/my-profile"
                            : "/app/user-profile/$id"
                    }
                    params={{ id: profile.id }}
                    onClick={onClicked}
                >
                    <AvatarWithBadge
                        profileId={profile.id}
                        size={36}
                        className={styles.commentAvatar}
                        src={profile.imageUrl}
                    />
                </Link>
            )}
            <div className={styles.commentTextWrapper}>
                {profileQuery.isSuccess && profile && (
                    <p
                        className={styles.commentUsername}
                        style={{ fontSize: vars.font.size.m, margin: 0 }}
                    >
                        {profile.displayName}
                    </p>
                )}

                <FoldableStatefulText
                    className={styles.text}
                    open={false}
                    maxLength={250}
                    text={comment.text.trim()}
                    fontSize={vars.font.size.regular}
                />
            </div>
        </div>
    )
}
