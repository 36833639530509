import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DialogTitle } from "@radix-ui/react-dialog"
import classNames from "classnames"
import { DialogDescription } from "common/dialog"
import { Drawer, DrawerClose, DrawerContent } from "common/drawer"
import { LinkItUrl } from "features/linkify"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import parse from "html-react-parser"
import { FC, useState } from "react"
import { vars } from "theme/variables.css"
import * as styles from "./see-more-text-view-modal.css"

export type Visibility = "Closed" | "Opened"

export type SeeMoreTextViewModalModel = {
    className?: string
    loc: {
        title?: string
        description: string
        seeMore: string
    }
    maxLength?: number
    defaultOpen?: boolean
}
export const SeeMoreTextViewModal: FC<SeeMoreTextViewModalModel> = ({
    className = "",
    loc,
    maxLength = 90,
    defaultOpen = false,
}) => {
    const [open, onOpenChange] = useState(defaultOpen)

    return (
        <>
            <Drawer open={open} onOpenChange={onOpenChange}>
                <DrawerContent className={styles.modal}>
                    <div className={styles.modalContent}>
                        <DrawerClose asChild>
                            <div className={styles.closeModal}>
                                <FontAwesomeIcon icon={faXmark} size="xl" />
                            </div>
                        </DrawerClose>
                        <div className={styles.textContent}>
                            <DialogTitle className={styles.title}>
                                {loc.title}
                            </DialogTitle>
                            <DialogDescription className={styles.description}>
                                <LinkItUrl className={styles.link}>
                                    {pipe(loc.description, parse)}
                                </LinkItUrl>
                            </DialogDescription>
                        </div>
                    </div>
                </DrawerContent>
            </Drawer>

            <div className={classNames(styles.wrapper, className)}>
                {loc.title && loc.title.length > 0 && (
                    <p
                        className={styles.title}
                        style={{ fontSize: vars.font.size.l }}
                    >
                        {loc.title}
                    </p>
                )}

                <p style={{ fontSize: vars.font.size.regular }}>
                    <LinkItUrl className={styles.link}>
                        {pipe(
                            loc.description,
                            O.fromPredicate(isFoldNeeded(maxLength)),
                            O.map(text =>
                                pipe(truncateText(maxLength)(text), parse),
                            ),
                            O.getOrElse(() => pipe(loc.description, parse)),
                        )}
                    </LinkItUrl>

                    {isFoldNeeded(maxLength)(loc.description) && (
                        <>
                            {"... "}
                            <span
                                className={styles.seeMoreText}
                                style={{
                                    fontSize: vars.font.size.regular,
                                }}
                                onClick={() => onOpenChange(true)}
                            >
                                {loc.seeMore}
                            </span>
                        </>
                    )}
                </p>
            </div>
        </>
    )
}

const isFoldNeeded = (maxLength: number) => (text: string) =>
    text.length > maxLength

const truncateText = (maxLength: number) => (text: string) =>
    text.length > maxLength ? text.substring(0, maxLength) : text
