import classNames from "classnames"

import { forwardRef } from "react"
import Skeleton from "react-loading-skeleton"
import * as styles from "./badge.css"

export type BadgeProps = JSX.IntrinsicElements["div"] & {
    variant?: keyof typeof styles.variantStyles
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
    ({ variant = "default", className, ...props }, ref) =>
        variant === "loading" ? (
            <Skeleton
                className={classNames(
                    styles.baseBadge,
                    styles.variantStyles[variant],
                    className,
                )}
                baseColor="#3C375C"
                circle
            />
        ) : (
            <div
                className={classNames(
                    styles.baseBadge,
                    styles.variantStyles[variant],
                    className,
                )}
                ref={ref}
                {...props}
            />
        ),
)
