import { faPaperPlaneTop, faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQueries, useQueryClient } from "@tanstack/react-query"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { FC, useMemo, useState } from "react"
import { match } from "ts-pattern"

import { CommentedResourceRef } from "../../api/api-models"
import { UserProfileId } from "../../api/branded-types"
import { usePostCommentMutation } from "../../features/video/hooks/use-post-comment-mutation"
import { useResourceComments } from "../../features/video/hooks/use-resource-comments"
import { fill, oneOf } from "../../utils/array"
import { UserCommentItem } from "./user-comment-item"

import classNames from "classnames"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { Textarea } from "common/textarea"
import { AvatarLoading } from "components/avatars/avatar-loading"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { InfiniteScroll } from "../../common/infinite-scroll"
import * as styles from "./comment-overview-modal.css"

type TextModel = {
    title: (value: number) => string
    inputPlaceholder: string
    noComments: string
    empty: {
        title: string
        text: string
    }
}

export type CommentOverviewPageModel = {
    text: TextModel
    open: boolean
    commentResourceRef: CommentedResourceRef
    resourceOwnerId?: UserProfileId
    onOpenChange: (v: boolean) => void
}
export const CommentOverviewModal: FC<CommentOverviewPageModel> = ({
    text,
    open,
    commentResourceRef,
    resourceOwnerId,
    onOpenChange,
}) => {
    const queryClient = useQueryClient()

    const videoCommentsQuery = useResourceComments(commentResourceRef)

    const profileIdsPages =
        videoCommentsQuery.data?.pages.map(page =>
            page.data.map(item => item.creatorId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const comments = useMemo(
        () =>
            pipe(
                videoCommentsQuery.data?.pages ?? [],
                A.flatMap(page => page.data),
            ),
        [videoCommentsQuery.data?.pages],
    )

    const commentsState = match(videoCommentsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const showLoadingState =
        commentsState === "Loading" || oneOfProfileQueriesLoading

    const total = videoCommentsQuery.data?.pages.at(0)?.totalCount ?? 0

    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent className={styles.modal}>
                <DrawerClose asChild>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className={styles.closeIcon}
                    />
                </DrawerClose>

                <InfiniteScroll
                    disabled={!videoCommentsQuery.hasNextPage}
                    state={commentsState}
                    onLoadRequested={videoCommentsQuery.fetchNextPage}
                    threshold="100px"
                >
                    <div className={styles.content}>
                        <>
                            {commentsState === "Loading" && (
                                <Skeleton
                                    width={120}
                                    height={30}
                                    borderRadius={vars.measurement.radius.lg}
                                    baseColor="#D5D6D8"
                                />
                            )}
                            {commentsState === "Loaded" &&
                                comments.length > 0 && (
                                    <>
                                        <DrawerTitle className={styles.title}>
                                            {text.title(total)}
                                        </DrawerTitle>
                                        <DrawerDescription />
                                    </>
                                )}
                            {commentsState === "Loaded" &&
                                comments.length === 0 && (
                                    <>
                                        <DrawerTitle className={styles.title}>
                                            {text.noComments}
                                        </DrawerTitle>
                                        <DrawerDescription />
                                    </>
                                )}
                        </>

                        {videoCommentsQuery.data?.pages.map(
                            (page, idx) =>
                                !profileQueries.at(idx)?.isLoading &&
                                page.data
                                    .map(comment => (
                                        <UserCommentItem
                                            key={comment.id}
                                            comment={comment}
                                        />
                                    ))
                                    .map(item => item),
                        )}
                        {showLoadingState &&
                            fill(5, i => <UserCommentLoadingItem key={i} />)}
                        {commentsState === "Loaded" &&
                            comments.length === 0 && (
                                <EmptyCommentsCard
                                    title={text.empty.title}
                                    text={text.empty.text}
                                />
                            )}
                    </div>
                </InfiniteScroll>
                <ModalToolbar
                    resourceOwnerId={resourceOwnerId}
                    commentResourceRef={commentResourceRef}
                    commentsState={commentsState}
                    text={text}
                />
            </DrawerContent>
        </Drawer>
    )
}

type ModalToolbarModel = {
    resourceOwnerId?: UserProfileId
    text: CommentOverviewPageModel["text"]
    commentResourceRef: CommentOverviewPageModel["commentResourceRef"]
    commentsState: "Loading" | "Loaded" | "Failed" | "Unloaded"
}

const ModalToolbar: FC<ModalToolbarModel> = ({
    commentResourceRef,
    commentsState,
    text,
    resourceOwnerId,
}) => {
    const [comment, setComment] = useState("")
    const [isEditingText, setIsEditingText] = useState(false)

    const { mutate: postComment } = usePostCommentMutation()

    return (
        <div
            className={classNames(
                styles.bottomToolbar,
                isEditingText ? styles.bottomToolbarActive : "",
            )}
        >
            <div className={styles.inputWrapper}>
                <div className={styles.inputBox}>
                    <Textarea
                        className={styles.input}
                        placeholder={text.inputPlaceholder}
                        value={comment}
                        onFocus={() => setIsEditingText(true)}
                        onBlur={() => setIsEditingText(false)}
                        onChange={flow(
                            e => e.target.value,
                            value => value.length < 100000 && setComment(value),
                        )}
                    />
                </div>
                <div className={styles.inputButtons}>
                    {(isEditingText || comment.length > 0) &&
                        pipe(
                            commentsState,
                            O.fromPredicate(state => state === "Loaded"),
                            O.chain(O.fromPredicate(() => comment.length > 0)),
                            O.fold(
                                () => (
                                    <FontAwesomeIcon
                                        className={styles.inputIcon}
                                        icon={faPaperPlaneTop}
                                        color={vars.color.tertiary.hex}
                                    />
                                ),
                                () => (
                                    <FontAwesomeIcon
                                        className={styles.inputIcon}
                                        icon={faPaperPlaneTop}
                                        color={vars.color.dark.hex}
                                        onClick={() => {
                                            if (!resourceOwnerId) return

                                            postComment({
                                                resourceOwnerId,
                                                commentedResource:
                                                    commentResourceRef,
                                                text: comment.trim(),
                                            })
                                            setComment("")
                                        }}
                                    />
                                ),
                            ),
                        )}
                </div>
            </div>
        </div>
    )
}

const loadingCommentSizes = [80, 120, 140, 150, 180]

const UserCommentLoadingItem: FC = () => (
    <div className={styles.loadingCommentContainer}>
        <AvatarLoading size={36} baseColor="#D5D6D8" />
        <Skeleton
            width={Math.min(window.innerWidth - 80, oneOf(loadingCommentSizes))}
            height={54}
            borderRadius={vars.measurement.radius.lg}
            baseColor="#D5D6D8"
        />
    </div>
)

const EmptyCommentsCard: FC<TextModel["empty"]> = ({ title, text }) => (
    <div className={styles.emptyCommentsCardContainer}>
        <p className={styles.emptyText}>{title}</p>
        <p
            className={styles.emptyText}
            style={{
                margin: 0,
                fontSize: vars.font.size.regular,
            }}
        >
            {text}
        </p>
    </div>
)
