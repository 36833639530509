import { UserReportIssue, UserReportSubIssue } from "api/api-models"

type UserReportInstructionItems = Record<
    UserReportIssue,
    Array<{
        id: UserReportSubIssue
        value: string
    }>
>

export const userReportInstructionItems: UserReportInstructionItems = {
    Behaviour: [
        {
            id: "Behaviour_Comments",
            value: "Disrespectful or rude comments",
        },
        {
            id: "Behaviour_Provoking",
            value: "Provocative behavior",
        },
        {
            id: "Behaviour_Gestures",
            value: "Inappropriate gestures/images",
        },
        {
            id: "Behaviour_Language",
            value: "Inappropriate language",
        },
        {
            id: "Other",
            value: "Other",
        },
    ],
    Harassment: [
        {
            id: "Harassment_DiscriminationOrInsult",
            value: "Discriminatory or offensive language",
        },
        {
            id: "Harassment_Threatening",
            value: "Threats or intimidation",
        },
        {
            id: "Harassment_MobbingOrBullying",
            value: "Personal attacks or targeted bullying",
        },
        {
            id: "Harassment_UnwantedRepeatedContact",
            value: "Unwanted, repeated contact",
        },
        {
            id: "Other",
            value: "Other",
        },
    ],
    Messages: [
        {
            id: "Messages_IntrusiveOrInappropriate",
            value: "Intrusive behavior or inappropriate advances",
        },
        {
            id: "Messages_SexualOrOffensive",
            value: "Sexual harassment or offensive messages",
        },
        {
            id: "Messages_SpamOrRepeatedContact",
            value: "Spam or repeated unwanted messages",
        },
        {
            id: "Messages_ThreatOrInsult",
            value: "Threatening or insulting content",
        },
        {
            id: "Other",
            value: "Other",
        },
    ],
    Spam: [
        {
            id: "Spam_BroadbandMessages",
            value: "Mass messaging to multiple users",
        },
        {
            id: "Spam_LinkOrAdvertisement",
            value: "Link spamming or advertising",
        },
        {
            id: "Spam_MissleadingOrManipulative",
            value: "Misleading or manipulative messages",
        },
        {
            id: "Spam_UnwantedRepeatedDuplication",
            value: "Repeated, unwanted messages with the same content",
        },
        {
            id: "Other",
            value: "Other",
        },
    ],
    Profile: [
        {
            id: "Profile_FairUse",
            value: "Use of images that do not belong to the person",
        },
        {
            id: "Profile_Inconsistent",
            value: "Inconsistent or implausible profile information",
        },
        {
            id: "Profile_ScamOrFraud",
            value: "Suspected scamming or fraudulent activities",
        },
        {
            id: "Profile_StolenOrFake",
            value: "Stolen identity or fake name",
        },
        {
            id: "Other",
            value: "Other",
        },
    ],
    Other: [],
}
