import { useQueries, useQueryClient } from "@tanstack/react-query"
import { ImagePostit } from "api/api-models"
import { UserProfileId } from "api/branded-types"
import { ErrorState } from "common/error-state"
import { InfiniteScroll } from "common/infinite-scroll"
import {
    getNotificationContentExists,
    getNotificationDetailsDataIds,
} from "data-flow/notification"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { assetsQueryOptions } from "hooks/use-asset"
import { listPostsQueryOptions } from "hooks/use-list-posts"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { listVideosQueryOptions } from "hooks/use-list-videos"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { match } from "ts-pattern"
import { fill } from "utils/array"
import { EmptyActivities } from "./empty-activities"
import {
    NotificationCenterItem,
    NotificationCenterLoadingItem,
} from "./notification-center-item"

import { useFollowNotification } from "./hooks/use-follow-notifications"
import * as styles from "./notifications-page.css"

type FollowersNotificationsTabContentModel = {
    profileId?: UserProfileId
}

export const FollowersNotificationsTabContent: FC<
    FollowersNotificationsTabContentModel
> = ({ profileId }) => {
    const { t } = useTranslation(["notifications"])

    const queryClient = useQueryClient()

    const notificationsQuery = useFollowNotification(profileId)

    const notifications = pipe(
        notificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.data),
    )

    const detailsPages =
        notificationsQuery.data?.pages.map(page =>
            page.data.map(item =>
                getNotificationDetailsDataIds(
                    item.pushNotification.clickAction,
                ),
            ),
        ) ?? []

    const profileIdsPages =
        notificationsQuery.data?.pages.map(page =>
            page.data.map(item => item.senderId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const videoIdsPages = detailsPages.map(page =>
        page
            .filter(
                item =>
                    item.type === "VideoCommentedNotificationDetails" ||
                    item.type === "VideoLikedNotificationDetails" ||
                    item.type === "VideoNotificationDetails",
            )
            .map(item => item.dataIds.videoId),
    )
    const videoQueries = useQueries({
        queries: videoIdsPages.map((page, idx) =>
            listVideosQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfVideoQueriesLoading = videoQueries.some(query => query.isLoading)

    const postitIdsPages = detailsPages.map(page =>
        page
            .filter(
                item =>
                    item.type === "PostCommentedNotificationDetails" ||
                    item.type === "PostLikedNotificationDetails" ||
                    item.type === "PostNotificationDetails",
            )
            .map(item => item.dataIds.postitId),
    )
    const postitQueries = useQueries({
        queries: postitIdsPages.map((page, idx) =>
            listPostsQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfPostitQueriesLoading = postitQueries.some(
        query => query.isLoading,
    )

    const notificationResourceRefsPages = pipe(
        postitQueries.filter(query => query.isSuccess),
        A.map(page =>
            page.data
                .filter((post): post is ImagePostit => post.type === "Image")
                .map(item => item.imageRef),
        ),
    )

    const assetsQueries = useQueries({
        queries: notificationResourceRefsPages.map((resources, pageNumber) =>
            assetsQueryOptions({ pageNumber, resources, queryClient }),
        ),
    })
    const oneOfAssetQueriesLoading = videoQueries.some(query => query.isLoading)

    const notificationsState = match(notificationsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const notificationsQueryLoading =
        (notificationsQuery.isLoading ||
            notificationsQuery.isFetchingNextPage) &&
        !notificationsQuery.isPlaceholderData

    const showNotificationsLoadingState =
        notificationsQueryLoading ||
        oneOfProfileQueriesLoading ||
        oneOfVideoQueriesLoading ||
        oneOfPostitQueriesLoading ||
        oneOfAssetQueriesLoading

    const showNotificationsEmptyState =
        (notificationsState === "Loaded" || notificationsQuery.isRefetching) &&
        notifications.length === 0

    return (
        <InfiniteScroll
            disabled={!notificationsQuery.hasNextPage}
            state={notificationsState}
            threshold="100px"
            onLoadRequested={notificationsQuery.fetchNextPage}
        >
            <div className={styles.notificationsList}>
                {notifications.length > 0
                    ? notificationsQuery.data?.pages.map(
                          (page, idx) =>
                              !profileQueries.at(idx)?.isLoading &&
                              !videoQueries.at(idx)?.isLoading &&
                              !postitQueries.at(idx)?.isLoading &&
                              !assetsQueries.at(idx)?.isLoading &&
                              page.data.map((notification, itemIdx) => {
                                  const detail = detailsPages
                                      .at(idx)
                                      ?.at(itemIdx)
                                  const dataDoesNotExist =
                                      getNotificationContentExists({
                                          notification,
                                          detail,
                                          postits: postitQueries.at(idx)?.data,
                                          profiles:
                                              profileQueries.at(idx)?.data,
                                          videos: videoQueries.at(idx)?.data,
                                      })

                                  if (dataDoesNotExist) return null

                                  return (
                                      <NotificationCenterItem
                                          key={notification.id}
                                          className={styles.notificationItem}
                                          notification={notification}
                                      />
                                  )
                              }),
                      )
                    : null}
                {showNotificationsLoadingState &&
                    fill(5, key => (
                        <NotificationCenterLoadingItem
                            key={key}
                            hasImage={key < 2}
                            className={styles.notificationItem}
                        />
                    ))}
            </div>
            {showNotificationsEmptyState ? (
                <EmptyActivities
                    text={{
                        title: t("empty.notifications.title"),
                        description: t("empty.notifications.description"),
                    }}
                />
            ) : null}
            {notificationsState === "Failed" && (
                <ErrorState onTryAgainClicked={notificationsQuery.refetch} />
            )}
        </InfiniteScroll>
    )
}
