import { queryOptions, useQuery } from "@tanstack/react-query"

import { UserProfileId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"

export const getUserProfileById = async (profileId?: UserProfileId) => {
    if (!profileId) throw new Error("UserProfileId is required")

    const userProfileRes = await userProfileSelectorClient.getProfileById({
        params: { id: profileId },
    })

    return userProfileRes.data
}

export const userProfileByIdQueryOptions = (profileId?: UserProfileId) =>
    queryOptions({
        queryFn: () => getUserProfileById(profileId),
        queryKey: QueryKeys.profile(profileId),
        enabled: !!profileId,
    })

export const useUserProfile = (profileId?: UserProfileId) => {
    return useQuery(userProfileByIdQueryOptions(profileId))
}
