import { Button } from "common/button"
import { DialogDescription, DialogTitle } from "common/dialog"
import {
    multiStepModalVariants,
    MultiStepModalWrapper,
} from "common/multi-step-modal"
import { AnimatePresence, motion, MotionConfig } from "framer-motion"
import { FC, useMemo } from "react"

import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { DrawerClose } from "common/drawer"
import { useMeasure } from "hooks/use-measure"

import { List, ListItem } from "common/list"
import { Textarea } from "common/textarea"
import { absurd } from "fp-ts/function"
import { useReportUserMutation } from "hooks/use-report-user-mutation"
import { useTranslation } from "react-i18next"
import { useReportStore } from "./report-store"
import { userReportInstructionItems } from "./report-user-modal-prerequisites"
import * as styles from "./report-user-modal.css"

type ReportUserModalModel = {
    open: boolean
    onClose: () => void
    onReset: () => void
}

export const ReportUserModal: FC<ReportUserModalModel> = ({
    open,
    onClose,
    onReset,
}) => {
    const { t } = useTranslation(["modal"])
    const currentStep = useReportStore(store => store.reportUserStep)
    const setCurrentStep = useReportStore(store => store.setReportUserStep)

    const resourceOwnerId = useReportStore(store => store.reportUserProfileId)
    const issue = useReportStore(store => store.selectedUserReportItem)
    const subIssue = useReportStore(
        store => store.selectedUserReportInstructionItem,
    )

    const { mutate: createReport } = useReportUserMutation()

    const [ref, bounds] = useMeasure()

    const showPrimaryButton =
        currentStep === "detail" || currentStep === "thanks"

    const showBackButton =
        currentStep === "detail" || currentStep === "instruction"

    const content = useMemo(() => {
        switch (currentStep) {
            case "initial":
                return <InitialScreen />

            case "instruction":
                return <InstructionScreen />

            case "detail":
                return <DetailScreen />

            case "thanks":
                return <ThanksScreen />

            default: {
                absurd(currentStep)
                return <></>
            }
        }
    }, [currentStep])

    const handleNextStep = () => {
        if (currentStep === "detail" && resourceOwnerId && issue && subIssue) {
            createReport({
                type: "User",
                customDetails: "",
                issue,
                resource: {
                    contentId: resourceOwnerId,
                    contentType: "Profile",
                },
                resourceOwnerId,
                subIssue,
            })
            setCurrentStep("thanks")
        } else if (currentStep === "thanks") {
            onClose()
        }
    }

    const handlePreviousStep = () => {
        if (currentStep === "instruction") setCurrentStep("initial")
        else if (currentStep === "detail" && issue === "Other")
            setCurrentStep("initial")
        else if (currentStep === "detail" && issue !== "Other")
            setCurrentStep("instruction")
    }

    return (
        <MultiStepModalWrapper open={open} onClose={onClose} onReset={onReset}>
            <DrawerClose className={styles.closeIconContainer}>
                <FontAwesomeIcon
                    className={styles.closeIcon}
                    icon={faXmark}
                    onClick={onClose}
                />
            </DrawerClose>
            <MotionConfig
                transition={{
                    bounce: 0,
                    duration: 0.27,
                    ease: [0.26, 0.08, 0.25, 1],
                }}
            >
                <AnimatePresence
                    key={currentStep}
                    mode="popLayout"
                    initial={false}
                >
                    <motion.div
                        key={`step-content-${currentStep}`}
                        transition={{
                            duration: 0.27,
                            ease: [0.26, 0.08, 0.25, 1],
                        }}
                        style={{ paddingBottom: bounds.height }}
                        className={styles.content}
                        variants={multiStepModalVariants}
                        initial="initial"
                        animate="active"
                        exit="exit"
                    >
                        {content}
                    </motion.div>

                    <motion.div key={`step-footer-${currentStep}`}>
                        <div className={styles.footer} ref={ref}>
                            {showPrimaryButton && (
                                <Button onClick={handleNextStep} asChild>
                                    <motion.button
                                        key="primary-button"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        {currentStep === "detail" && "Submit"}
                                        {currentStep === "thanks" && "Close"}
                                    </motion.button>
                                </Button>
                            )}
                            {showBackButton && (
                                <Button
                                    variant="secondary"
                                    onClick={handlePreviousStep}
                                    asChild
                                >
                                    <motion.button
                                        key="back-button"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        {t("report.user.init.screen.back")}
                                    </motion.button>
                                </Button>
                            )}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </MotionConfig>
        </MultiStepModalWrapper>
    )
}

const InitialScreen: FC = () => {
    const { t } = useTranslation(["modal"])

    const setUserReportItem = useReportStore(store => store.setUserReportItem)
    const setUserReportInstructionItem = useReportStore(
        store => store.setUserReportInstructionItem,
    )
    const setReportUserStep = useReportStore(store => store.setReportUserStep)

    return (
        <>
            <DialogTitle className={styles.title}>
                {t("report.user.init.screen.title")}
            </DialogTitle>
            <DialogDescription className={styles.description}>
                {t("report.user.init.screen.description")}
            </DialogDescription>
            <List>
                {[
                    {
                        id: "Behaviour" as const,
                        value: t("report.user.init.screen.behavior"),
                    },
                    {
                        id: "Harassment" as const,
                        value: t("report.user.init.screen.harassment"),
                    },
                    {
                        id: "Profile" as const,
                        value: t("report.user.init.screen.profile"),
                    },
                    {
                        id: "Messages" as const,
                        value: t("report.user.init.screen.messages"),
                    },
                    {
                        id: "Spam" as const,
                        value: t("report.user.init.screen.spam"),
                    },
                    {
                        id: "Other" as const,
                        value: t("report.user.init.screen.other"),
                    },
                ].map(item => (
                    <ListItem
                        detail
                        key={item.value}
                        onClick={() => {
                            setUserReportItem(item.id)
                            if (item.id === "Other") {
                                setUserReportInstructionItem("Other")
                                setReportUserStep("detail")
                            } else {
                                setReportUserStep("instruction")
                            }
                        }}
                    >
                        {item.value}
                    </ListItem>
                ))}
            </List>
        </>
    )
}

const InstructionScreen: FC = () => {
    const { t } = useTranslation(["modal"])
    const selectedUserReportItem = useReportStore(
        store => store.selectedUserReportItem,
    )
    const setUserReportInstructionItem = useReportStore(
        store => store.setUserReportInstructionItem,
    )
    const setReportUserStep = useReportStore(store => store.setReportUserStep)

    return (
        <>
            <DialogTitle className={styles.title}>
                {selectedUserReportItem}
            </DialogTitle>
            <DialogDescription className={styles.description}>
                {t("report.user.instruction.title")}
            </DialogDescription>
            <List>
                {selectedUserReportItem &&
                    userReportInstructionItems[selectedUserReportItem].map(
                        item => (
                            <ListItem
                                detail
                                key={item.value}
                                onClick={() => {
                                    setUserReportInstructionItem(item.id)
                                    setReportUserStep("detail")
                                }}
                            >
                                {item.value}
                            </ListItem>
                        ),
                    )}
            </List>
        </>
    )
}

const DetailScreen: FC = () => {
    const { t } = useTranslation(["modal"])
    const reportMessage = useReportStore(store => store.reportUserMessage)
    const setReportMessage = useReportStore(store => store.setReportUserMessage)

    return (
        <>
            <DialogTitle className={styles.title}>
                {t("report.user.details.title")}
            </DialogTitle>
            <DialogDescription className={styles.description}>
                {t("report.user.details.description")}
            </DialogDescription>

            <Textarea
                value={reportMessage}
                onChange={e => setReportMessage(e.target.value ?? "")}
                className={styles.textarea}
            />
        </>
    )
}

export const ThanksScreen: FC = () => {
    const { t } = useTranslation(["modal"])

    return (
        <>
            <DialogTitle className={styles.title}>
                {t("report.user.thank.title")}
            </DialogTitle>
            <DialogDescription
                className={classNames(
                    styles.description,
                    styles.thanksDescription,
                )}
            >
                {t("report.user.thank.description")}
            </DialogDescription>
        </>
    )
}
