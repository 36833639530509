import { faMessage } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useMemo, useRef } from "react"
import Skeleton from "react-loading-skeleton"
import { match } from "ts-pattern"
import { Content } from "../../common/content"
import { InfiniteScroll } from "../../common/infinite-scroll"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { fill } from "../../utils/array"
import { useMyMessageGroups } from "./hooks/use-my-message-groups"
import * as styles from "./my-messages-page.css"
import {
    UserMessageListItem,
    UserMessageListLoadingItem,
} from "./user-message-list-item"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { getIsAuthorizedAccount } from "api/api-utils"
import { motion, useInView } from "framer-motion"
import { listLastMessagesQueryOptions } from "hooks/use-list-last-messages"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import { getSafeArea } from "utils/constant"
import { CondensedHeader } from "../../common/condensed-header"
import { Toolbar } from "../../common/toolbar"

export const MyMessagesPage: FC = () => {
    const meQuery = useMe()
    const { t } = useTranslation(["messages"])
    const queryClient = useQueryClient()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { data: myProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const headerRef = useRef(null)

    const myMessageGroupsQuery = useMyMessageGroups({
        me: myProfileId,
    })

    const myMessagesQueryState = match(myMessageGroupsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const refreshMyMessagesPage = async () => {
        await myMessageGroupsQuery.refetch()
    }

    const profileIdsPages =
        myMessageGroupsQuery.data?.pages.map(page =>
            page.data.map(item => item.partner.contentId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const lastMessagesQueries = useQueries({
        queries: profileIdsPages.flatMap(page =>
            page.map(recipientId =>
                listLastMessagesQueryOptions({
                    queryClient,
                    recipientId,
                    senderId: myProfileId,
                }),
            ),
        ),
    })

    const oneOfLastMessagesQueriesLoading = lastMessagesQueries.some(
        query => query.isLoading,
    )

    const isEmpty =
        myMessageGroupsQuery.isSuccess &&
        myMessageGroupsQuery.data.pages.at(0)?.totalCount === 0

    const isInView = useInView(headerRef, {
        margin: `${-1 * getSafeArea("top") - 62}px`,
    })

    const showLoadingState =
        myMessagesQueryState === "Loading" ||
        oneOfProfileQueriesLoading ||
        oneOfLastMessagesQueriesLoading

    return (
        <Page className={styles.page}>
            <Toolbar>
                <CondensedHeader className={styles.header} isInView={isInView}>
                    <p className={styles.headerTitle}>{t("title")}</p>
                </CondensedHeader>
            </Toolbar>
            <Content>
                <PullToRefresh onRefresh={refreshMyMessagesPage}>
                    <motion.div ref={headerRef}>
                        <p
                            style={{
                                margin: "25px 20px 10px 20px",
                                fontSize: vars.font.size.xxxl,
                                fontWeight: 700,
                            }}
                        >
                            {t("subtitle")}
                        </p>
                    </motion.div>

                    <div>
                        <InfiniteScroll
                            disabled={!myMessageGroupsQuery.hasNextPage}
                            state={myMessagesQueryState}
                            onLoadRequested={myMessageGroupsQuery.fetchNextPage}
                            threshold="100px"
                        >
                            <div className={styles.content}>
                                <div className={styles.contentBox}>
                                    {myMessageGroupsQuery.data?.pages.map(
                                        (page, idx) =>
                                            !profileQueries.at(idx)
                                                ?.isLoading &&
                                            page.data.map(group => (
                                                <UserMessageListItem
                                                    key={`${group.id.type}-${group.id.partnerId}-${group.id.profileId}`}
                                                    className={
                                                        styles.userListItem
                                                    }
                                                    newMessageIds={
                                                        group.newMessageIds
                                                    }
                                                    profileId={
                                                        group.partner.contentId
                                                    }
                                                    avatarSize={48}
                                                />
                                            )),
                                    )}

                                    {showLoadingState &&
                                        fill(5, key => (
                                            <UserMessageListLoadingItem
                                                key={key}
                                            />
                                        ))}
                                </div>
                                {isEmpty && (
                                    <div className={styles.placeholderWrapper}>
                                        {myMessagesQueryState === "Loaded" && (
                                            <div
                                                className={
                                                    styles.noMessagesWrapper
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faMessage}
                                                    style={{
                                                        width: "32px",
                                                        height: "32px",
                                                        marginBottom: "20px",
                                                    }}
                                                    color={
                                                        vars.color.tertiary.hex
                                                    }
                                                />
                                                <p
                                                    style={{
                                                        fontSize:
                                                            vars.font.size
                                                                .regular,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {t("noMessages.title")}
                                                </p>
                                                <p
                                                    style={{
                                                        fontSize:
                                                            vars.font.size
                                                                .regular,
                                                        color: vars.color
                                                            .tertiary.hex,
                                                    }}
                                                >
                                                    {t("noMessages.note")}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </InfiniteScroll>
                    </div>
                </PullToRefresh>
            </Content>
        </Page>
    )
}

export const MyMessagesLoadingPage: FC = () => (
    <Page className={styles.page}>
        <NavigationBackButton />

        <Content className={styles.loadingContent}>
            <Skeleton
                style={{ marginBottom: 10 }}
                width={196}
                height={30}
                borderRadius={16}
            />

            <div className={styles.contentBox}>
                <UserMessageListLoadingItem />
                <UserMessageListLoadingItem />
                <UserMessageListLoadingItem />
                <UserMessageListLoadingItem />
                <UserMessageListLoadingItem />
            </div>
        </Content>
    </Page>
)

export default MyMessagesPage
