import { useMutation, useQueryClient } from "@tanstack/react-query"

import { UserProfileStatistic } from "api/api-models"
import { InfiniteVideosData } from "features/user-profile/components/my-user-profile-post-content"
import { UserProfileId, VideoId } from "../../../api/branded-types"
import { videoCreatorClient } from "../../../api/clients/video-api-client"
import { QueryKeys, StaticQueryKeys } from "../../../api/query-keys"

type MutationFunctionParams = {
    id: VideoId
    profileId: UserProfileId
}

const searchPageVideosQueryKey = [
    StaticQueryKeys.SEARCH,
    StaticQueryKeys.SEARCH_VIDEOS,
]

export const useDeleteVideoMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ id }: MutationFunctionParams) =>
            videoCreatorClient.deleteVideoById(undefined, {
                params: {
                    id,
                },
            }),
        onMutate: async ({ id, profileId }) => {
            const profileVideosContentQueryKey =
                QueryKeys.profileVideoContent(profileId)
            const profileStatisticsQueryKey =
                QueryKeys.profileStatistics(profileId)

            await queryClient.cancelQueries({
                queryKey: profileVideosContentQueryKey,
            })
            await queryClient.cancelQueries({
                queryKey: searchPageVideosQueryKey,
            })
            await queryClient.cancelQueries({
                queryKey: profileStatisticsQueryKey,
            })

            const previousProfileVideoContent =
                queryClient.getQueryData<InfiniteVideosData>(
                    profileVideosContentQueryKey,
                )
            const previousSearchVideoContent =
                queryClient.getQueryData<InfiniteVideosData>(
                    searchPageVideosQueryKey,
                )
            const previousProfileStatistics =
                queryClient.getQueryData<UserProfileStatistic>(
                    profileStatisticsQueryKey,
                )

            if (previousProfileVideoContent) {
                queryClient.setQueryData<InfiniteVideosData>(
                    profileVideosContentQueryKey,
                    {
                        ...previousProfileVideoContent,
                        pages: previousProfileVideoContent.pages.map(page => ({
                            ...page,
                            data: page.data.filter(video => video.id === id),
                        })),
                    },
                )
            }
            if (previousSearchVideoContent) {
                queryClient.setQueryData<InfiniteVideosData>(
                    profileVideosContentQueryKey,
                    {
                        ...previousSearchVideoContent,
                        pages: previousSearchVideoContent.pages.map(page => ({
                            ...page,
                            data: page.data.filter(video => video.id === id),
                        })),
                    },
                )
            }
            if (previousProfileStatistics) {
                const postAmount =
                    previousProfileStatistics.count.posts - 1 > 0
                        ? previousProfileStatistics.count.posts - 1
                        : 0

                queryClient.setQueryData<UserProfileStatistic>(
                    profileStatisticsQueryKey,
                    {
                        ...previousProfileStatistics,
                        count: {
                            ...previousProfileStatistics.count,
                            posts: postAmount,
                        },
                    },
                )
            }

            return {
                previousProfileVideoContent,
                previousSearchVideoContent,
                previousProfileStatistics,
            }
        },
        onError: (err, { profileId }, context) => {
            console.error(err)
            if (context?.previousProfileVideoContent) {
                queryClient.setQueryData(
                    QueryKeys.profileVideoContent(profileId),
                    context.previousProfileVideoContent,
                )
            }
            if (context?.previousSearchVideoContent) {
                queryClient.setQueryData(
                    searchPageVideosQueryKey,
                    context.previousSearchVideoContent,
                )
            }
            if (context?.previousProfileStatistics) {
                queryClient.setQueryData(
                    QueryKeys.profileStatistics(profileId),
                    context.previousProfileStatistics,
                )
            }
        },
        onSettled: (_data, _err, { profileId }) => {
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileVideoContent(profileId),
            })
            queryClient.invalidateQueries({
                queryKey: searchPageVideosQueryKey,
            })
            queryClient.invalidateQueries({
                queryKey: QueryKeys.profileStatistics(profileId),
            })
        },
    })
}
