import { getIsAuthorizedAccount } from "api/api-utils"
import {
    EnableNotificationsModal,
    EnableNotificationsModalModel,
} from "components/modals/enable-notifications-modal"
import { pipe } from "fp-ts/function"
import { FC, useMemo, useState } from "react"
import { useCreateNotificationSubscriptionMutation } from "./hooks/use-create-notification-subscription-mutation"

import {
    BrowserPushInfoAdt,
    NotificationPermissionStateAdt,
    getCurrentRegistration as getCurrentServiceWorkerRegistration,
    getNotificationPermission,
    requestNotificationPermission,
    subscribeUserToPush,
} from "features/notifications/notification"
import { useModalState } from "hooks/use-modal-state"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"

export type EnableNotificationsProviderProps = {
    loc: EnableNotificationsModalModel["text"]
}

export const EnableNotificationsProvider: FC<
    EnableNotificationsProviderProps
> = ({ loc }) => {
    const { accountType, ensureUserCallback } = useOnlyRegisteredUser()
    const { isModalSkipped, skipModal } = useModalState()

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { data: myUserProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const [enableNotificationsModalOpen, setEnableNotificationsModalOpen] =
        useState(
            pipe(
                getNotificationPermission(),
                NotificationPermissionStateAdt.is.Default,
            ),
        )

    const { mutate: createNotificationSubscription } =
        useCreateNotificationSubscriptionMutation()

    return (
        <EnableNotificationsModal
            open={
                enableNotificationsModalOpen &&
                isAuthorizedAccount &&
                !isModalSkipped("EnableNotifications")
            }
            onClosed={() => setEnableNotificationsModalOpen(false)}
            onLaterClosed={() => {
                setEnableNotificationsModalOpen(false)
                skipModal("EnableNotifications")
            }}
            onSubmit={ensureUserCallback()(async () => {
                const swRegistration =
                    await getCurrentServiceWorkerRegistration()
                if (!swRegistration) return
                if (!myUserProfileId) return

                setEnableNotificationsModalOpen(false)
                skipModal("EnableNotifications")

                const permission = await requestNotificationPermission()

                NotificationPermissionStateAdt.match(
                    {
                        PermissionGranted: async () => {
                            const subscriptionResult =
                                await subscribeUserToPush(swRegistration)
                            BrowserPushInfoAdt.match({
                                SubscriptionSuccess: async ({ data }) => {
                                    //? wait until created so refetch of subscription state would actually
                                    //? show the state on server
                                    //TODO: make it a task
                                    createNotificationSubscription({
                                        profileId: myUserProfileId,
                                        pushSubscription: data,
                                    })
                                },
                                SubscriptionError: () => {},
                            })(subscriptionResult)
                        },
                        PermissionDenied: () => {},
                    },
                    () => {},
                )(permission)
            })}
            text={loc}
        />
    )
}
