import { FC, PropsWithChildren } from "react"
import { LinkProps } from "./types"

export const urlRegex =
    /(https?:\/\/|www\.)([-\w.]+\/[\p{L}\p{Emoji}\p{Emoji_Component}!#$%&'"()*+,./\\:;=_?@[\]~-]*[^\s'",.;:\b)\]}?]|(([\w-]+\.)+[\w-]+[\w/-]))/u

//TODO: maybe add some check if its an internal link to render the <Link> instead of a tag
export const UrlComponent: FC<PropsWithChildren<LinkProps>> = ({
    match: url,
    className,
    onClick,
}) => (
    <a
        className={className}
        href={/^www\./.exec(url) ? `http://${url}` : url}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
    >
        {url}
    </a>
)
