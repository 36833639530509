import { infiniteQueryOptions, useInfiniteQuery } from "@tanstack/react-query"

import { UserProfileId } from "../../../api/branded-types"
import { userMessageSelectorClient } from "../../../api/clients/message-api-client"
import { QueryKeys } from "../../../api/query-keys"

const PAGINATION_AMOUNT = 10

type Params = {
    me?: UserProfileId
    pageLimit?: number
}

export const myMessageGroupsInfiniteQueryOptions = (params: Params) =>
    infiniteQueryOptions({
        initialPageParam: -1,
        queryKey: QueryKeys.myResourceMessages(params.me),
        queryFn: ({ pageParam }) =>
            listMyMessages({
                ...params,
                pageParam,
            }),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (
                lastPage.data.length !== (params.pageLimit ?? PAGINATION_AMOUNT)
            )
                return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) +
                      (params.pageLimit ?? PAGINATION_AMOUNT)
                : -1
        },
        enabled: !!params.me,
    })

export const useMyMessageGroups = (params: Params) => {
    return useInfiniteQuery(myMessageGroupsInfiniteQueryOptions(params))
}
const listMyMessages = async ({
    pageParam,
    me,
    pageLimit,
}: {
    pageParam: number
} & Params) => {
    if (!me) throw new Error("My profile Id is required")

    const messageGroups = await userMessageSelectorClient.listMessageGroups({
        filter: `{ $match: {profileId: "${me}"}, $sort: {lastChangedAt: desc} }`,
        paging: {
            type: "Index",
            direction: "After",
            limit: pageLimit ?? PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    return messageGroups
}
