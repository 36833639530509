import classNames from "classnames"
import { FC } from "react"

import { Button } from "common/button"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { usePaymentModalStore } from "../store/payment-modal-store"
import * as styles from "./success-message-modal.css"

export type SuccessMessageModalModel = {
    className?: string
    presentingElement?: HTMLElement | undefined

    title: string
    message: string
    action: string
}
export const SuccessMessageModal: FC<SuccessMessageModalModel> = ({
    className,
    title,
    message,
    action,
}) => {
    const open = usePaymentModalStore(store => store.successPaymentModalOpen)
    const onOpenChange = usePaymentModalStore(
        store => store.setSuccessPaymentModalOpen,
    )
    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent className={classNames(styles.modal, className)}>
                <div>
                    <div className={styles.content}>
                        <div className={styles.wrapper}>
                            <div style={{ margin: "auto" }}>
                                <DrawerTitle className={styles.title}>
                                    {title}
                                </DrawerTitle>
                            </div>

                            <div style={{ margin: "auto" }}>
                                <DrawerDescription
                                    className={styles.description}
                                >
                                    {message}
                                </DrawerDescription>
                            </div>
                        </div>

                        <DrawerClose asChild>
                            <Button full className={styles.closeButton}>
                                {action}
                            </Button>
                        </DrawerClose>
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
