import { ADTType, makeADT, ofType } from "@morphic-ts/adt"
import * as A from "fp-ts/Array"
import { pipe } from "fp-ts/function"
import { ReactNode, useEffect, useState } from "react"

import { NotificationToastProps } from "./toast"

//? if more needed - we would need to add stacked styles
const TOAST_LIMIT = 1
const TOAST_REMOVE_DELAY = 1000

type NotificationToasterToast = NotificationToastProps & {
    id: string
    toastContent?: ReactNode
}

type AddNotificationToast = {
    type: "AddNotificationToast"
    toast: NotificationToasterToast
}

type UpdateNotificationToast = {
    type: "UpdateNotificationToast"
    toast: Partial<NotificationToasterToast>
}

type DismissNotificationToast = {
    type: "DismissNotificationToast"
    toastId?: NotificationToasterToast["id"]
}

type RemoveNotificationToast = {
    type: "RemoveNotificationToast"
    toastId?: NotificationToasterToast["id"]
}

const NotificationToastActionAdt = makeADT("type")({
    AddNotificationToast: ofType<AddNotificationToast>(),
    UpdateNotificationToast: ofType<UpdateNotificationToast>(),
    DismissNotificationToast: ofType<DismissNotificationToast>(),
    RemoveNotificationToast: ofType<RemoveNotificationToast>(),
})

type NotificationToastAction = ADTType<typeof NotificationToastActionAdt>

let count = 0

const genId = () => {
    count = (count + 1) % Number.MAX_SAFE_INTEGER
    return count.toString()
}

type State = {
    toasts: NotificationToasterToast[]
}

const notificationToastTimeouts = new Map<
    string,
    ReturnType<typeof setTimeout>
>()

const addToRemoveQueue = (toastId: string) => {
    if (notificationToastTimeouts.has(toastId)) {
        return
    }

    const timeout = setTimeout(() => {
        notificationToastTimeouts.delete(toastId)
        dispatch(
            NotificationToastActionAdt.as.RemoveNotificationToast({
                toastId,
            }),
        )
    }, TOAST_REMOVE_DELAY)

    notificationToastTimeouts.set(toastId, timeout)
}

//? rewrite with matcher?
export const reducer = (state: State, action: NotificationToastAction): State =>
    pipe(
        action,
        NotificationToastActionAdt.matchStrict({
            AddNotificationToast: ({ toast }) => {
                return {
                    ...state,
                    toasts: [toast, ...state.toasts].slice(0, TOAST_LIMIT),
                }
            },
            UpdateNotificationToast: ({ toast }) => {
                return {
                    ...state,
                    toasts: state.toasts.map(t =>
                        t.id === toast.id ? { ...t, ...toast } : t,
                    ),
                }
            },
            DismissNotificationToast: ({ toastId }) => {
                // ! Side effects ! - This could be extracted into a DismissNotificationToast() action,
                // but I'll keep it here for simplicity
                if (toastId) {
                    addToRemoveQueue(toastId)
                } else {
                    state.toasts.forEach(toast => {
                        addToRemoveQueue(toast.id)
                    })
                }

                return {
                    ...state,
                    toasts: state.toasts.map(t =>
                        t.id === toastId || toastId === undefined
                            ? {
                                  ...t,
                                  open: false,
                              }
                            : t,
                    ),
                }
            },
            RemoveNotificationToast: ({ toastId }) => {
                if (toastId === undefined) {
                    return {
                        ...state,
                        toasts: [],
                    }
                }
                return {
                    ...state,
                    toasts: state.toasts.filter(t => t.id !== toastId),
                }
            },
        }),
    )

const listeners: Array<(state: State) => void> = []

let memoryState: State = {
    toasts: [],
}

const dispatch = (action: NotificationToastAction) => {
    memoryState = reducer(memoryState, action)
    pipe(
        listeners,
        A.map(listener => listener(memoryState)),
    )
}

type NotificationToastModel = Omit<NotificationToasterToast, "id">

export const notificationToast = ({ ...props }: NotificationToastModel) => {
    const id = genId()

    const update = (props: NotificationToasterToast) =>
        dispatch(
            NotificationToastActionAdt.as.UpdateNotificationToast({
                toast: { ...props, id },
            }),
        )
    const dismiss = () =>
        dispatch(
            NotificationToastActionAdt.as.DismissNotificationToast({
                toastId: id,
            }),
        )

    dispatch(
        NotificationToastActionAdt.as.AddNotificationToast({
            toast: {
                ...props,
                id,
                open: true,
                onOpenChange: open => {
                    if (!open) dismiss()
                },
            },
        }),
    )

    return {
        id,
        dismiss,
        update,
    }
}

export const useNotificationToast = () => {
    const [state, setState] = useState<State>(memoryState)

    useEffect(() => {
        listeners.push(setState)

        return () => {
            const index = listeners.indexOf(setState)
            if (index > -1) listeners.splice(index, 1)
        }
    }, [state])

    return {
        ...state,
        toast: notificationToast,
        dismiss: (toastId?: string) =>
            dispatch(
                NotificationToastActionAdt.as.DismissNotificationToast({
                    toastId,
                }),
            ),
    }
}
