import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "common/dialog"
import { useAsset } from "hooks/use-asset"
import { usePointSystemModalStore } from "./store/point-system-modal-store"

import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { SvgImage } from "common/svg-image"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import * as styles from "./create-content-complete-modal.css"
import { ParticleAnimation } from "./particles-animation/particles-animation"

type CreateContentCompleteModal = {
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const CreateContentCompleteModal: FC<
    CreateContentCompleteModal
> = model => {
    const { t } = useTranslation(["pointSystemInvite"])
    const createContentCompleteModalState = usePointSystemModalStore(
        store => store.createContentCompleteModalState,
    )

    const assetQuery = useAsset({
        resource: createContentCompleteModalState?.icon,
    })

    return (
        <Dialog open={model.open} onOpenChange={model.onOpenChange}>
            {model.open && <ParticleAnimation />}

            <DialogContent className={styles.content}>
                <div>
                    <div className={styles.iconWrapper}>
                        <div className={styles.iconContainer}>
                            <SvgImage
                                color={vars.color.medium.hex}
                                width={
                                    assetQuery.isSuccess &&
                                    assetQuery.data.type === "Image"
                                        ? assetQuery.data.resolution.width
                                        : 28
                                }
                                height={
                                    assetQuery.isSuccess &&
                                    assetQuery.data.type === "Image"
                                        ? assetQuery.data.resolution.height
                                        : 28
                                }
                                className={styles.icon}
                                src={
                                    assetQuery.isSuccess
                                        ? assetQuery.data.source
                                        : undefined
                                }
                            />
                            <FontAwesomeIcon
                                className={styles.hornIcon}
                                icon={faPartyHorn}
                            />
                        </div>
                    </div>

                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xl,
                                margin: 0,
                                fontWeight: 500,
                            }}
                        >
                            {createContentCompleteModalState?.title}
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {createContentCompleteModalState?.description}
                        </DialogDescription>
                    </div>

                    <div className={styles.rewardContainer}>
                        <p className={styles.rewardText}>
                            {t("modal.create.reward.text", {
                                points: createContentCompleteModalState?.points,
                            })}
                        </p>
                    </div>

                    <div className={styles.buttonsContainer}>
                        <Button
                            className={styles.button}
                            variant="medium"
                            onClick={() => {
                                model.onOpenChange(false)
                                //todo: define what path this button should navigate to
                            }}
                        >
                            {t("modal.create.content.explore")}
                        </Button>
                        <Button
                            onClick={() => model.onOpenChange(false)}
                            asChild
                            variant="ghost"
                        >
                            <Link
                                to="/app/settings/rewards"
                                search={{ currentTab: "get-started" }}
                            >
                                {t("modal.create.content.continue")}
                            </Link>
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
