import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faAngleLeft } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRouter } from "@tanstack/react-router"
import { calc } from "@vanilla-extract/css-utils"
import { CSSProperties, FC } from "react"

import { NAVIGATION_MARGIN_LEFT, NAVIGATION_MARGIN_TOP } from "theme/global.css"

export type NavigationBackButtonModel = {
    style?: CSSProperties
    icon?: IconProp
    iconColor?: string
    fallbackRoute?: string
    onClicked?: () => void
    onActionBeforeNav?: () => void
}

export const NavigationBackButton: FC<NavigationBackButtonModel> = ({
    style,
    icon = faAngleLeft,
    iconColor = "white",
    onClicked,
    onActionBeforeNav,
}) => {
    const router = useRouter()

    const handleClick = () => {
        onActionBeforeNav ? onActionBeforeNav() : {}
        if (onClicked) onClicked()
        else if (window.history.length > 1) router.history.back()
        else router.navigate({ to: "/app/feed" })
    }

    return (
        <div
            style={{
                position: "fixed",
                zIndex: 1000,
                top: calc.add(
                    "env(safe-area-inset-top, 0)",
                    NAVIGATION_MARGIN_TOP,
                ),
                left: calc.add(
                    "env(safe-area-inset-left, 0)",
                    NAVIGATION_MARGIN_LEFT,
                ),
                ...style,
            }}
            onClick={handleClick}
        >
            <FontAwesomeIcon
                style={{
                    color: `var(--nav-icon-color, ${iconColor})`,
                    zIndex: 1001,
                    width: 32,
                    height: 32,
                }}
                icon={icon}
            />
        </div>
    )
}
