import { queryOptions, useQuery } from "@tanstack/react-query"

import { UserProfile } from "api/api-models"
import { Result } from "api/types"
import { UserProfileId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"

export const listUserProfileById = async (
    profileId?: UserProfileId,
): Promise<Result<UserProfile>> => {
    if (!profileId) throw new Error("PostitId is required")
    const res = await userProfileSelectorClient.listProfiles({
        filter: `{ $match: {id: "${profileId}"}}`,
        paging: { type: "Index", direction: "After", index: -1, limit: 1 },
    })
    const data = res.data.at(0)

    if (!data) {
        return {
            data,
            error: "PROFILE_NOT_FOUND",
        }
    }
    return {
        data,
        error: undefined,
    }
}

export const listUserProfileByIdQueryOptions = (profileId?: UserProfileId) =>
    queryOptions({
        queryFn: () => listUserProfileById(profileId),
        queryKey: QueryKeys.listProfile(profileId),
        enabled: !!profileId,
    })

export const useListUserProfileById = (profileId?: UserProfileId) => {
    return useQuery(listUserProfileByIdQueryOptions(profileId))
}
