import { infiniteQueryOptions, QueryClient } from "@tanstack/react-query"
import { User } from "api/api-models"
import { userSelectorClient } from "api/clients/user-api-client"
import { QueryKeys } from "api/query-keys"

type Params = {
    queryClient: QueryClient
    pageParam: number
}

const PAGINATION_AMOUNT = 200
const MINUTE_IN_MILLISECONDS = 60_000

export const listFirstUsers = async (params: Params) => {
    const usersRes = await userSelectorClient.listUsers({
        filter: `{ $sort: {createdAt: asc}}`,
        paging: {
            type: "Index",
            direction: "After",
            index: params.pageParam,
            limit: PAGINATION_AMOUNT,
        },
    })
    usersRes.data.forEach(user =>
        params.queryClient.setQueryData<User>(QueryKeys.user(user.id), user),
    )
    return usersRes
}

export const listFirstUsersInfiniteQueryOptions = (
    queryClient: QueryClient,
    enabled: boolean = true,
) =>
    infiniteQueryOptions({
        initialPageParam: -1,
        queryKey: QueryKeys.firstUsers(),
        queryFn: ({ pageParam }) => listFirstUsers({ queryClient, pageParam }),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        staleTime: MINUTE_IN_MILLISECONDS * 15,
        gcTime: MINUTE_IN_MILLISECONDS * 15,
        enabled,
    })
