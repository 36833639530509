import { PostitId, UserProfileId, VideoId } from "api/branded-types"
import { z } from "zod"
import { create } from "zustand"
import { usePaymentModalStore } from "./payment-modal-store"

export const PaymentStatus = z.union([
    z.literal("canceled"),
    z.literal("failed"),
    z.literal("succeeded"),
    z.literal("pending"),
])
export type PaymentStatus = z.infer<typeof PaymentStatus>

export const SubscriptionStatus = z.union([
    z.literal("canceled"),
    z.literal("failed"),
    z.literal("succeeded"),
    z.literal("pending"),
])
export type SubscriptionStatus = z.infer<typeof SubscriptionStatus>

export const PaymentProviderConnectivityStatus = z.union([
    z.literal("registrationFailed"),
    z.literal("setupSuccess"),
    z.literal("loginFailed"),
])
export type PaymentProviderConnectivityStatus = z.infer<
    typeof PaymentProviderConnectivityStatus
>

export const VideoPaymentStateMessage = z.object({
    type: z.literal("VIDEO_PAYMENT_STATUS"),
    status: PaymentStatus,
    videoId: VideoId,
})
export type VideoPaymentStateMessage = z.infer<typeof VideoPaymentStateMessage>

export const PostitPaymentStateMessage = z.object({
    type: z.literal("POSTIT_PAYMENT_STATUS"),
    status: PaymentStatus,
    postitId: PostitId,
})
export type PostitPaymentStateMessage = z.infer<
    typeof PostitPaymentStateMessage
>

export const PaymentProviderConnectivityStatusMessage = z.object({
    type: z.literal("PAYMENT_PROVIDER_CONNECTIVITY_STATUS"),
    status: PaymentProviderConnectivityStatus,
})
export type PaymentProviderConnectivityStatusMessage = z.infer<
    typeof PaymentProviderConnectivityStatusMessage
>

export const UserSubscriptionFromVideoStatusMessage = z.object({
    type: z.literal("USER_SUBSCRIPTION_FROM_VIDEO_STATUS"),
    status: SubscriptionStatus,
    profileId: UserProfileId,
    videoId: VideoId,
})
export type UserSubscriptionFromVideoStatusMessage = z.infer<
    typeof UserSubscriptionFromVideoStatusMessage
>

export const UserSubscriptionFromPostitStatusMessage = z.object({
    type: z.literal("USER_SUBSCRIPTION_FROM_POSTIT_STATUS"),
    status: SubscriptionStatus,
    profileId: UserProfileId,
    postitId: PostitId,
})
export type UserSubscriptionFromPostitStatusMessage = z.infer<
    typeof UserSubscriptionFromPostitStatusMessage
>

export const UserSubscriptionStatusMessage = z.object({
    type: z.literal("USER_SUBSCRIPTION_STATUS"),
    status: SubscriptionStatus,
    profileId: UserProfileId,
})
export type UserSubscriptionStatusMessage = z.infer<
    typeof UserSubscriptionStatusMessage
>

export const PaymentMessagingStoreMessage = z.union([
    VideoPaymentStateMessage,
    PostitPaymentStateMessage,
    PaymentProviderConnectivityStatusMessage,
    UserSubscriptionFromPostitStatusMessage,
    UserSubscriptionFromVideoStatusMessage,
    UserSubscriptionStatusMessage,
])
export type PaymentMessagingStoreMessage = z.infer<
    typeof PaymentMessagingStoreMessage
>

type State = {
    videoPaymentStatus?: PaymentStatus
    postitPaymentStatus?: PaymentStatus
    providerConnectivityStatus?: PaymentProviderConnectivityStatus
}

type Actions = {
    setVideoPaymentStatus: (v: State["videoPaymentStatus"]) => void
    setPostitPaymentStatus: (v: State["postitPaymentStatus"]) => void
    setProviderConnectivityStatus: (
        v: State["providerConnectivityStatus"],
    ) => void
}

export const usePaymentMessagingStore = create<State & Actions>(set => ({
    videoPaymentStatus: undefined,
    postitPaymentStatus: undefined,
    providerConnectivityStatus: undefined,

    setVideoPaymentStatus: status => {
        const paymentModalStore = usePaymentModalStore.getState()
        if (status === "succeeded")
            paymentModalStore.setSuccessPaymentModalOpen(true)
        else if (status === "failed")
            paymentModalStore.setFailedPaymentModalOpen(true)
        set({ videoPaymentStatus: status })
    },
    setPostitPaymentStatus: status => {
        const paymentModalStore = usePaymentModalStore.getState()
        if (status === "succeeded")
            paymentModalStore.setSuccessPaymentModalOpen(true)
        else if (status === "failed")
            paymentModalStore.setFailedPaymentModalOpen(true)
        set({ postitPaymentStatus: status })
    },
    setProviderConnectivityStatus: v => set({ providerConnectivityStatus: v }),
}))
