import { createFileRoute } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import { MessageToUserProfilePageLoading } from "features/messaging/messages-to-user-profile-page"

export const Route = createFileRoute("/_authenticated/app/message/profile/$id")(
    {
        pendingComponent: MessageToUserProfilePageLoading,
        params: {
            parse: params => ({
                id: UserProfileId.parse(params.id),
            }),
        },
        loader: () => {},
    },
)
