import classNames from "classnames"
import * as React from "react"
import { forwardRef } from "react"
import TextareaAutosize from "react-textarea-autosize"
import * as styles from "./textarea.css"

type TextAreaProps = React.ComponentPropsWithoutRef<typeof TextareaAutosize>

export const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({ className, ...props }, ref) => (
        <TextareaAutosize
            className={classNames(styles.textarea, className)}
            ref={ref}
            {...props}
        />
    ),
)
