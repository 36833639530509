import classNames from "classnames"
import { FC, ReactNode, createContext, useCallback, useContext } from "react"

import { Slot } from "@radix-ui/react-slot"
import Skeleton from "react-loading-skeleton"
import * as styles from "./tabs.css"
//TODO: Find a way to make string a generic
type TabsContextModel<TValue extends string> = {
    value: TValue
    onValueChange: (value: TValue) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TabsContext = createContext<TabsContextModel<any> | undefined>(undefined)

const useTabs = <T extends string>(): TabsContextModel<T> => {
    const context = useContext(TabsContext)

    if (!context)
        throw new Error("useTabs must be used within a Tabs component")

    return context
}

type TabsModel<TValue extends string> = {
    children: ReactNode
    className?: string
} & TabsContextModel<TValue>

//TODO: refactor with framer motion and make them direction-aware with framer motion for better UX
export const Tabs = <TValue extends string>({
    value,
    onValueChange,
    children,
}: TabsModel<TValue>) => {
    const contextValue = {
        value,
        onValueChange,
    }

    return (
        <TabsContext.Provider value={contextValue}>
            {children}
        </TabsContext.Provider>
    )
}

type TabsListProps = {
    children: ReactNode
    className?: string
}

export const TabsList: FC<TabsListProps> = ({ children, className }) => (
    <div className={classNames(styles.list, className)} role="tablist">
        {children}
    </div>
)

type TabsTriggerProps<TValue extends string> = {
    children: ReactNode
    value: TValue
    className?: string
    disabled?: boolean
    asChild?: boolean
}

export const TabsTrigger = <TValue extends string>({
    children,
    value,
    disabled,
    className,
    asChild,
}: TabsTriggerProps<TValue>) => {
    const { value: activeValue, onValueChange } = useTabs<TValue>()
    const isActive = value === activeValue

    const handleClick = useCallback(
        () => onValueChange(value),
        [onValueChange, value],
    )
    const Comp = asChild ? Slot : "button"
    return (
        <Comp
            role="tab"
            aria-selected={isActive}
            onClick={handleClick}
            disabled={disabled}
            className={classNames(styles.trigger, className)}
        >
            {children}
        </Comp>
    )
}

type TabsContentProps<TValue extends string> = {
    children?: ReactNode
    value: TValue
    className?: string
}

export const TabsContent = <TValue extends string>({
    children,
    value,
    className,
}: TabsContentProps<TValue>) => {
    const { value: activeValue } = useTabs<TValue>()

    const isActive = value === activeValue
    if (!isActive) return <></>

    return (
        <div
            role="tabcontent"
            hidden={!isActive}
            className={classNames(styles.content, className)}
        >
            {children}
        </div>
    )
}

export const TabsListLoading: FC = () => (
    <Skeleton height={31} width="100%" borderRadius={16} />
)
