import classNames from "classnames"
import { pipe } from "fp-ts/function"
import { FC, useMemo } from "react"

import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import { getIsAuthorizedAccount } from "api/api-utils"
import { AvatarLoading } from "components/avatars/avatar-loading"
import * as O from "fp-ts/Option"
import { listLastMessagesQueryOptions } from "hooks/use-list-last-messages"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useMe } from "hooks/use-me"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { LocalStorage } from "local-storage"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { messageTimeInfo } from "utils/time"
import { UserMessageId, UserProfileId } from "../../api/branded-types"
import { Avatar } from "../../components/avatars/avatar"
import * as styles from "./user-message-list-item.css"

export type UserMessageListItemModel = {
    className?: string
    avatarSize?: number
    profileId: UserProfileId
    newMessageIds: UserMessageId[]
}
export const UserMessageListItem: FC<UserMessageListItemModel> = ({
    className = "",
    avatarSize,
    profileId,
    newMessageIds,
}) => {
    const { t, i18n } = useTranslation("common")
    const queryClient = useQueryClient()

    const meQuery = useMe()
    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { data: myProfileId } = useMyUserProfileId(isAuthorizedAccount)

    const profileQuery = useListUserProfileById(profileId)
    const profile = profileQuery.data?.data

    const lastMessagesQuery = useQuery(
        listLastMessagesQueryOptions({
            queryClient,
            recipientId: profileId,
            senderId: myProfileId,
        }),
    )

    const lastMessage = lastMessagesQuery.data?.data.at(0)
    if (!lastMessage) return <></>
    return (
        <Link
            to="/app/message/profile/$id"
            params={{ id: profileId }}
            className={styles.linkWrapper}
        >
            <div className={classNames(styles.wrapper, className)}>
                {profileQuery.isLoading && <AvatarLoading />}
                {profileQuery.isSuccess && profile && (
                    <Avatar
                        src={profile.imageUrl}
                        size={avatarSize}
                        styleState={profile.isLive ? "isLive" : "None"}
                    />
                )}

                <div
                    style={{
                        width: "100%",
                        textAlign: "left",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }}
                >
                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                            color: "var(--text-color, white)",
                            textAlign: "left",
                        }}
                    >
                        {profile?.displayName}
                    </p>

                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                            color: "var(--text-color, #B4B2C0)",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                        }}
                    >
                        {lastMessage.text}
                    </p>
                </div>

                <div className={styles.wrapperRight}>
                    {newMessageIds.length > 0 && (
                        <p
                            className={styles.messageCountInfo}
                            style={{ fontSize: vars.font.size.xs }}
                        >
                            {newMessageIds.length}
                        </p>
                    )}

                    <p
                        style={{
                            fontSize: vars.font.size.regular,
                            color: "var(--text-color, #B4B2C0)",
                            textAlign: "left",
                            textWrap: "nowrap",
                        }}
                    >
                        {messageTimeInfo({
                            date: lastMessage.createdAt,
                            i18nLang: i18n.language,
                            t,
                        })}
                    </p>
                </div>
            </div>
        </Link>
    )
}

export const UserMessageListLoadingItem: FC = () => (
    <div className={classNames(styles.wrapper)}>
        <div className={styles.loadingContent}>
            <Skeleton width={48} height={48} circle />

            <div className={styles.loadingWrapperContent}>
                <Skeleton height={20} width={"45%"} borderRadius={24} />
                <Skeleton height={20} width={"80%"} borderRadius={24} />
            </div>
        </div>
    </div>
)
