/* eslint-disable no-useless-escape */

import { useInfiniteQuery } from "@tanstack/react-query"

import { mapToSortFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { postitSelectorClient } from "../api/clients/post-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 25

export type UseTextPostSearchModel = {
    searchKey: string
    creatorIds: UserProfileId[]
    profileId?: UserProfileId
    enabled?: boolean
}
export const useTextPostsSearch = (model: UseTextPostSearchModel) => {
    const queryKey = QueryKeys.searchTextPosts(model)

    const titleFilter = `title: *"${model.searchKey}"*:ci`
    const messageFilter = `message: *"${model.searchKey}"*:ci`
    const sortFilter = mapToSortFilterDc("lastChangedAt: desc")
    const typeFilter = `type: "Text"`
    const removeMyProfileFilter =
        !model.profileId || model.searchKey.length > 0
            ? ""
            : `, creatorId: !"${model.profileId}"`
    //TODO: this is not 100% precise, but would work out most of the time
    //TODO: otherwise we need specific API endpoint for this
    const profilesFilter =
        model.searchKey.length === 0
            ? ""
            : `, {creatorId: in ${JSON.stringify(model.creatorIds)}}`

    const filter = `{$match:{$or: [{${titleFilter}}, {${messageFilter}}${profilesFilter}]${removeMyProfileFilter}, ${typeFilter}}, ${sortFilter}}`

    const queryFn = async ({ pageParam }: { pageParam: number }) => {
        const posts = await postitSelectorClient.listPostits({
            filter,
            paging: {
                type: "Index",
                direction: "After",
                limit: PAGINATION_AMOUNT,
                index: pageParam,
            },
        })
        return posts
    }

    return useInfiniteQuery({
        initialPageParam: -1,
        queryKey,
        queryFn,
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: model.enabled,
    })
}
