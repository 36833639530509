import classNames from "classnames"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC } from "react"

import { LinkItUrl } from "features/linkify"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import * as styles from "./foldable-text.css"

export type FoldableTextModel = {
    className?: string
    fontSize?: string
    text: string
    offsetText?: string
    maxLength?: number
    open: boolean
    onOpenChange: (open: boolean) => void
}

export const FoldableText: FC<FoldableTextModel> = ({
    className = "",
    text,
    offsetText = "",
    maxLength = 100,
    open,
    fontSize = vars.font.size.m,

    onOpenChange,
}) => {
    const { t: tCommon } = useTranslation("common")
    return (
        <div className={classNames(styles.wrapper, className)}>
            <p
                className={classNames(styles.wrappable, styles.compact)}
                style={{
                    fontSize,
                    whiteSpace: "pre-wrap",
                }}
            >
                <LinkItUrl className={styles.link}>
                    {pipe(
                        text,
                        isFoldNeeded(maxLength),
                        O.fromPredicate(x => x && !open),
                        O.map(() =>
                            pipe(
                                text,
                                truncateText(maxLength),
                                t => t + offsetText,
                            ),
                        ),
                        O.getOrElse(() => text),
                    )}
                </LinkItUrl>
                {pipe(text, isFoldNeeded(maxLength)) && (
                    <span
                        onClick={x => {
                            onOpenChange(!open)
                            x.stopPropagation()
                        }}
                        className={styles.compact}
                    >
                        <small>
                            <span
                                style={{
                                    color: vars.color.secondary.hex,
                                    fontSize,
                                }}
                            >
                                {!open
                                    ? ` ${tCommon("see.more")}`
                                    : ` ${tCommon("see.less")}`}
                            </span>
                        </small>
                    </span>
                )}
            </p>
        </div>
    )
}

const isFoldNeeded = (maxLength: number) => (text: string) =>
    text.length > maxLength

const truncateText = (maxLength: number) => (text: string) =>
    text.length > maxLength ? text.substring(0, maxLength) : text
