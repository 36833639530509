import { FC } from "react"

import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { vars } from "theme/variables.css"
import * as styles from "./live-now-area-placeholder.css"

type LiveNowAreaPlaceholderModel = {
    description: string
    action: string
    onClicked?: () => void
}

export const LiveNowAreaPlaceholder: FC<LiveNowAreaPlaceholderModel> = ({
    description,
    action,
    onClicked,
}) => (
    <div className={styles.wrapper}>
        <p style={{ fontSize: vars.font.size.regular }}>{description}</p>

        <Button
            style={{
                height: "24px",
                fontSize: vars.font.size.s,
                fontWeight: 600,
                whiteSpace: "nowrap",
            }}
            variant="primary"
            onClick={onClicked}
            asChild
        >
            <Link to="/app/live-event/creator">{action}</Link>
        </Button>
    </div>
)
