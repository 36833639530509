import { MotionValue } from "framer-motion"

import {
    faEllipsisVertical,
    faMessage,
    faShare,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import { UserProfileId } from "api/branded-types"
import * as styles from "../user-profile-page.css"

type UserProfilePageActionsModel = {
    opacity?: MotionValue<number> | MotionValue<string>
    profileId?: UserProfileId
    onShareClicked: () => void
    onMessageClicked: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => void
    onActionsClicked: () => void
}

export const UserProfilePageActions = (model: UserProfilePageActionsModel) => (
    <div className={styles.actionGroup}>
        <FontAwesomeIcon
            icon={faShare}
            className={styles.actionIcon}
            onClick={model.onShareClicked}
            size="xl"
            color="white"
        />

        <Link
            to={model.profileId ? "/app/message/profile/$id" : "."}
            params={{ id: model.profileId }}
            onClick={model.onMessageClicked}
        >
            <FontAwesomeIcon
                icon={faMessage}
                className={styles.actionIcon}
                size="xl"
                color="white"
            />
        </Link>

        <FontAwesomeIcon
            icon={faEllipsisVertical}
            className={styles.actionIcon}
            onClick={model.onActionsClicked}
            size="xl"
            color="white"
        />
    </div>
)
