import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from "common/dialog"

import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog"
import { Link } from "@tanstack/react-router"
import { calc } from "@vanilla-extract/css-utils"
import { Button } from "common/button"
import { FC } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { vars } from "theme/variables.css"

type Props = {
    open: boolean
    onOpenChange: (v: boolean) => void
    loc: {
        title: string
        congratulations: string
        descriptionA: string
        descriptionB: string
        points: string
        message: string
        benefits: {
            title: string
            descriptions: string[]
        }
        close: string
        confirm: string
    }
}

export const CongratulationPlatinumModal: FC<Props> = ({
    loc,
    open,
    onOpenChange,
}) => {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    textAlign: "center",
                    padding: "0 40px 50px 40px",
                    backgroundColor: "#E5E4E2",
                    color: vars.color.medium.hex,
                    whiteSpace: "pre-wrap",

                    minWidth: "100dvw",
                    height: "100dvh",
                    borderRadius: 0,
                }}
            >
                <DialogHeader
                    style={{
                        position: "fixed",
                        paddingTop: calc.add(
                            "env(safe-area-inset-top, 0)",
                            "60px",
                        ),
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 60,
                        backgroundColor: "#E5E4E2",
                        zIndex: 50,
                    }}
                >
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{
                            position: "absolute",
                            top: calc.add(
                                "env(safe-area-inset-top, 0)",
                                "20px",
                            ),
                            left: 20,
                            width: 30,
                            height: 30,
                            background: "transparent",
                        }}
                        onClick={() => onOpenChange(false)}
                    />
                </DialogHeader>
                <div
                    style={{
                        position: "relative",
                        maxHeight: "calc(100% - 120px)",
                        bottom: 120,
                        overflow: "scroll",
                        paddingTop: calc.add(
                            "env(safe-area-inset-top, 0)",
                            "60px",
                        ),
                    }}
                >
                    <div
                        style={{
                            marginTop: 20,
                            marginBottom: 26,

                            display: "flex",
                            flexDirection: "column",
                            gap: 4,
                        }}
                    >
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xxxl,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            {loc.title}
                        </DialogTitle>

                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xxxl,
                                margin: 0,
                                fontWeight: 600,
                            }}
                        >
                            {loc.congratulations}
                        </DialogTitle>

                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {loc.descriptionA}
                            <span style={{ fontWeight: 700, margin: 0 }}>
                                {loc.descriptionB}
                            </span>
                        </DialogDescription>
                    </div>

                    <LazyLoadImage
                        width={window.innerWidth - 36 * 2}
                        height={(window.innerWidth - 36 * 2) * 0.44}
                        alt="rewards"
                        loading="eager"
                        src="/images/gfx_new_level.svg"
                        style={{ marginBottom: 40 }}
                    />

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 45,
                            borderRadius: 10,
                            fontSize: vars.font.size.l,
                            fontWeight: 600,
                            background:
                                "var(--Opacity-Light-Dark, rgba(48, 42, 80, 0.15))",
                            marginBottom: 26,
                        }}
                    >
                        +100 {loc.points}
                    </div>

                    <p style={{ textAlign: "left", marginBottom: 26 }}>
                        {loc.message}
                    </p>

                    <div style={{ textAlign: "left", marginBottom: 50 }}>
                        <p style={{ fontWeight: 600 }}>{loc.benefits.title}</p>

                        <ul>
                            {loc.benefits.descriptions.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <DialogFooter
                    style={{
                        display: "flex",
                        flexDirection: "column",

                        width: "100%",

                        position: "fixed",
                        padding: "24px 36px 52px",
                        bottom: 0,
                    }}
                >
                    <Button
                        onClick={() => {
                            onOpenChange(false)
                        }}
                    >
                        {loc.close}
                    </Button>

                    <Button
                        variant="ghost"
                        style={{ color: vars.color.medium.hex }}
                        onClick={() => {
                            onOpenChange(false)
                        }}
                        asChild
                    >
                        <Link
                            to="/app/settings/rewards"
                            search={{ currentTab: "get-started" }}
                        >
                            {loc.confirm}
                        </Link>
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
