import { Badge } from "common/badge"
import { Avatar } from "components/avatars/avatar"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { FC } from "react"
import { vars } from "theme/variables.css"

import { PaymentId } from "api/branded-types"
import { AvatarLoading } from "components/avatars/avatar-loading"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { usePaymentById } from "hooks/use-payment-by-id"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { usePaymentModalStore } from "./store/payment-modal-store"
import * as styles from "./wallet-payment-item.css"

type WalletPaymentItemProps = {
    paymentId: PaymentId
}

const formatDateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
} as const

export const formatPaymentDate = (date: Date) =>
    new Intl.DateTimeFormat("de-DE", formatDateOptions).format(date)

export const WalletPaymentItem: FC<WalletPaymentItemProps> = ({
    paymentId,
}) => {
    const { t } = useTranslation(["settingsWallet"])
    const paymentQuery = usePaymentById(paymentId)

    const myProfileIdQuery = useMyUserProfileId()

    const isCurrentProfileContentOwner =
        paymentQuery.data?.contentOwnerId === myProfileIdQuery.data

    const profileQuery = useListUserProfileById(
        isCurrentProfileContentOwner
            ? paymentQuery.data?.customerId
            : paymentQuery.data?.contentOwnerId,
    )
    const profile = profileQuery.data?.data

    const setWalletPaymentDetails = usePaymentModalStore(
        store => store.setWalletPaymentDetailsOpen,
    )

    const moneyIncreased = isCurrentProfileContentOwner

    return (
        <div
            onClick={() =>
                setWalletPaymentDetails({
                    open: true,
                    walletPaymentDetailsId: paymentId,
                })
            }
            className={styles.walletPaymentItem}
        >
            <div className={styles.walletPaymentItemAvatarWithInfo}>
                <Avatar size={40} src={profile?.imageUrl} />
                <div className={styles.walletPaymentItemText}>
                    <h3
                        style={{
                            fontSize: vars.font.size.s,
                            color: vars.color.primary.hex,
                        }}
                    >
                        {profile?.profileName}
                    </h3>
                    <p
                        style={{
                            fontSize: vars.font.size.s,
                            color: vars.color.tertiary.hex,
                        }}
                        //TODO: add what money was spent on if possible
                    >
                        {paymentQuery.isSuccess &&
                            formatPaymentDate(
                                new Date(paymentQuery.data.lastChangedAt),
                            )}
                    </p>
                </div>
            </div>
            <div className={styles.priceInfoWrapper}>
                <p
                    style={{
                        color: moneyIncreased
                            ? vars.color.success.hex
                            : vars.color.primary.hex,
                        fontSize: vars.font.size.s,
                        fontWeight: 700,
                        minWidth: "fit-content",
                    }}
                >
                    {moneyIncreased ? "+" : "-"}
                    {paymentQuery.data?.price.amount}{" "}
                    {paymentQuery.data?.price.currency}
                </p>
                {paymentQuery.data?.state === "Pending" && (
                    <Badge variant="warningOnLight">
                        {t("payment.status.pending")}
                    </Badge>
                )}
                {paymentQuery.data?.state === "Failed" && (
                    <Badge variant="dangerOnLight">
                        {t("payment.status.failed")}
                    </Badge>
                )}
                {paymentQuery.data?.state === "Canceled" && (
                    <Badge variant="tertiaryOnLight">
                        {t("payment.status.cancelled")}
                    </Badge>
                )}
            </div>
        </div>
    )
}

export const WalletPaymentItemLoading = () => {
    return (
        <div className={styles.walletPaymentItem}>
            <div className={styles.walletPaymentItemAvatarWithInfo}>
                <AvatarLoading baseColor="#3C375C" size={40} />
                <div className={styles.walletPaymentItemText}>
                    <Skeleton width={40} height={14} borderRadius={12} />
                    <Skeleton width={70} height={16} borderRadius={12} />
                </div>
            </div>
            <div className={styles.priceInfoWrapper}>
                <Skeleton width={40} height={20} borderRadius={12} />
                <Badge variant="loading" />
            </div>
        </div>
    )
}
