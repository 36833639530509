import { createFileRoute } from "@tanstack/react-router"
import { PostitId } from "api/branded-types"
import { postitByIdQueryOptions } from "features/postit/hooks/use-postit-by-id"
import { FallbackPending } from "features/routing/fallback-pending"

export const Route = createFileRoute("/_authenticated/app/image-edit/$id")({
    pendingComponent: FallbackPending,
    params: {
        parse: params => ({
            id: PostitId.parse(params.id),
        }),
    },
    loader: opts =>
        opts.context.queryClient.ensureQueryData(
            postitByIdQueryOptions(opts.params.id),
        ),
})
