import { faEye, faPlay } from "@fortawesome/pro-light-svg-icons"
import { faBan } from "@fortawesome/pro-regular-svg-icons"
import { faCircleStar as solidStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import { VideoId } from "api/branded-types"
import { LinkItUrl } from "features/linkify"
import parse from "html-react-parser"
import { FC } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { vars } from "theme/variables.css"

import { Badge } from "common/badge"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { useListVideoById } from "features/video/hooks/use-list-video-by-id"
import { pipe } from "fp-ts/function"
import { LocalStorage } from "local-storage"
import { useTranslation } from "react-i18next"
import { useAppStore } from "store"
import { UserDetailsBasic } from "../user/user-details-basic"
import * as styles from "./video-area-tile.css"

export type VideoAreaTileModel = {
    loc: {
        title: string
    }
    videoId: VideoId
}

export const VideoAreaTile: FC<VideoAreaTileModel> = ({ loc, videoId }) => {
    const { t } = useTranslation("videoViewer")
    const { toast } = useInfoToast()

    const videoQuery = useListVideoById(videoId)

    const video = videoQuery.data?.data

    const openModal = useAppStore(store => store.openModal)

    const isInReview = video?.status === "InReview"
    const isBlocked =
        video?.status === "Rejected" ||
        (video?.status === "None" && video.isBlocked)

    const shouldBeSecured = video?.monetization.type !== "None"

    const hasStatusBadge = isInReview || isBlocked
    const hasBadges = shouldBeSecured || hasStatusBadge

    return (
        videoQuery.isSuccess &&
        video && (
            <div className={styles.relative}>
                <Link
                    className={styles.wrapper}
                    to="/app/video/viewer/$id"
                    params={{ id: video.id }}
                    onClick={e => {
                        if (
                            LocalStorage.getAccountTypeOrDefault() === "Guest"
                        ) {
                            e.preventDefault()
                            openModal({
                                type: "LoginOrRegister",
                                profileId: video?.creatorId,
                            })
                        }
                    }}
                >
                    <FontAwesomeIcon
                        icon={faPlay}
                        color="white"
                        className={styles.videoIcon}
                    />
                    <div className={styles.imageWrapper}>
                        {video.previewImageUrl && (
                            <LazyLoadImage
                                className={styles.image}
                                width={window.innerWidth - 40}
                                src={`${video.previewImageUrl}?height=${window.innerWidth * 2}`}
                            />
                        )}
                    </div>

                    <div className={styles.overlay} />

                    <div className={styles.content}>
                        {hasBadges && (
                            <div className={styles.badgesContainer}>
                                {shouldBeSecured && (
                                    <div className={styles.payableMark}>
                                        <FontAwesomeIcon
                                            style={{
                                                width: 16,
                                                height: 16,
                                            }}
                                            icon={solidStar}
                                            color="white"
                                        />
                                        <p
                                            className={styles.message}
                                            style={{
                                                fontSize:
                                                    vars.font.size.regular,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {loc.title}
                                        </p>
                                    </div>
                                )}
                                {hasStatusBadge && (
                                    <Badge
                                        onClick={e => {
                                            e.preventDefault()
                                            toast({
                                                toastContent: isBlocked ? (
                                                    <InfoToastContent
                                                        icon={faBan}
                                                        iconColor="medium"
                                                        iconClassName={
                                                            styles.toastBlockedIcon
                                                        }
                                                        text={t(
                                                            "moderation.blocked.toast.text",
                                                        )}
                                                    />
                                                ) : (
                                                    <InfoToastContent
                                                        icon={faEye}
                                                        iconColor="medium"
                                                        iconClassName={
                                                            styles.toastReviewIcon
                                                        }
                                                        text={t(
                                                            "moderation.review.toast.text",
                                                        )}
                                                    />
                                                ),
                                            })
                                        }}
                                        variant={
                                            isBlocked
                                                ? "dangerOnSecondary"
                                                : "secondary"
                                        }
                                    >
                                        {isBlocked
                                            ? t("moderation.badge.rejected")
                                            : t("moderation.badge.review")}
                                    </Badge>
                                )}
                            </div>
                        )}

                        <div className={styles.textContent}>
                            <p
                                style={{ fontSize: vars.font.size.m }}
                                className={styles.label}
                            >
                                {pipe(video.title, parse)}
                            </p>

                            {video.description ? (
                                <p
                                    style={{ fontSize: vars.font.size.regular }}
                                    className={styles.message}
                                >
                                    {
                                        //TODO: move this out probably, since this is nested link
                                        //? complexity - you do not know the size of description
                                    }
                                    <LinkItUrl
                                        className={styles.link}
                                        onLinkClicked={e => e.stopPropagation()}
                                    >
                                        {pipe(video.description, parse)}
                                    </LinkItUrl>
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </Link>
                <div className={styles.footer}>
                    <UserDetailsBasic
                        contentLastChangedAt={video.lastChangedAt}
                        avatarSize={36}
                        profileId={video.creatorId}
                    />
                </div>
            </div>
        )
    )
}
