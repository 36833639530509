import { FC } from "react"

import {
    CreateContentOverviewModal,
    CreateContentOverviewModalModel,
} from "../modals/create-content-overview-modal"

export type ContextMenuButtonModel = {
    icon: string
    color?: string
    backgroundColor?:
        | "primary"
        | "secondary"
        | "tertiary"
        | "success"
        | "warning"
        | "danger"
        | "dark"
        | "light"
        | "medium"
    disabled?: boolean
    onClicked: () => void
}

export type CreateContentMenuModel = {
    text: CreateContentOverviewModalModel["text"]
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const CreateContentMenu: FC<CreateContentMenuModel> = ({
    text,
    open,
    onOpenChange,
}) => (
    <CreateContentOverviewModal
        text={text}
        open={open}
        onOpenChange={onOpenChange}
    />
)
