import { createFileRoute } from "@tanstack/react-router"
import { VideoId } from "api/branded-types"
import { videoByIdQueryOptions } from "features/video/hooks/use-video-by-id"
import { VideoConsumerPageLoading } from "features/video/video-consumer-page"

export const Route = createFileRoute("/_authenticated/app/video/viewer/$id")({
    params: {
        parse: params => ({
            id: VideoId.parse(params.id),
        }),
    },
    loader: opts =>
        opts.context.queryClient.ensureQueryData(
            videoByIdQueryOptions(opts.params.id),
        ),
    pendingComponent: VideoConsumerPageLoading,
})
