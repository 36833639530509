import {
    infiniteQueryOptions,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Postit } from "../api/api-models"
import { mapToNotMatchFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { postitSelectorClient } from "../api/clients/post-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 25

type RecommendedPostitsInfiniteQueryOptionsParams = {
    followingUserProfileIds: UserProfileId[]
    profileId?: UserProfileId
    queryClient: QueryClient
}

export const recommendedPostitsInfiniteQueryOptions = (
    params: RecommendedPostitsInfiniteQueryOptionsParams,
) =>
    infiniteQueryOptions({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            listRecommendedPostits({
                pageParam,
                profileId: params.profileId,
                queryClient: params.queryClient,
                followingUserProfileIds: params.followingUserProfileIds,
            }),
        queryKey: QueryKeys.recommendedPostits(params.profileId),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
    })

export const useRecommendedPostits = (
    followingUserProfileIds: UserProfileId[],
    profileId?: UserProfileId,
) => {
    const queryClient = useQueryClient()

    return useInfiniteQuery(
        recommendedPostitsInfiniteQueryOptions({
            queryClient,
            followingUserProfileIds,
            profileId,
        }),
    )
}

const listRecommendedPostits = async ({
    pageParam,
    profileId,
    queryClient,
    followingUserProfileIds,
}: {
    followingUserProfileIds: UserProfileId[]
    pageParam: number
    profileId?: UserProfileId
    queryClient: QueryClient
}) => {
    const profileIds = profileId
        ? [...(followingUserProfileIds ?? []), profileId]
        : (followingUserProfileIds ?? [])

    const posts = await postitSelectorClient.listPostits({
        filter: `{${mapToNotMatchFilterDc("creatorId", profileIds)}}`,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })
    posts.data.forEach(data =>
        queryClient.setQueryData<{ data: Postit; error: undefined }>(
            QueryKeys.postit(data.id),
            { data, error: undefined },
        ),
    )
    return posts
}
