import { UseLiveEventsSearchModel } from "hooks/use-live-event-search"
import { UseImagePostSearchModel } from "../hooks/use-image-post-search"
import { UseTextPostSearchModel } from "../hooks/use-text-post-search"
import { UseUserProfilesSearchModel } from "../hooks/use-user-profiles-search"
import { UseVideosSearchModel } from "../hooks/use-videos-search"
import {
    CommentContentIddResourceRefs,
    CreatorRef,
    ResourceRef,
    VoteContentIddResourceRefs,
    VotedResourceRef,
} from "./api-models"
import {
    LiveEventId,
    PaymentId,
    PostitId,
    ProfileChallengeId,
    TierId,
    Url,
    UserCommentId,
    UserId,
    UserMessageId,
    UserProfileId,
    VideoId,
    VoteId,
} from "./branded-types"

export const StaticQueryKeys = {
    ASSET: "ASSET",
    ASSETS: "ASSETS",
    ASSET_DATA: "ASSET_DATA",

    SEARCH: "SEARCH",
    SEARCH_USER_PROFILES: "SEARCH_USER_PROFILES",
    SEARCH_VIDEOS: "SEARCH_VIDEOS",
    SEARCH_POSTS: "SEARCH_POSTS",
    SEARCH_IMAGE_POSTS: "SEARCH_IMAGE_POSTS",
    SEARCH_TEXT_POSTS: "SEARCH_TEXT_POSTS",
    SEARCH_LIVE_EVENTS: "SEARCH_LIVE_EVENTS",

    PROFILE_FOLLOW: "PROFILE_FOLLOW",

    PROFILE: "PROFILE",
    ME: "ME",
    PROFILE_PAGE: "PROFILE_PAGE",
    MY_PROFILE_PAGE: "MY_PROFILE_PAGE",
    MY_PROFILES: "MY_PROFILES",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    UPDATE_PROFILE_IMAGE: "UPDATE_PROFILE_IMAGE",
    PROFILE_VIDEOS: "PROFILE_VIDEOS",
    PROFILE_POSTS: "PROFILE_POSTS",
    PROFILE_STATISTICS: "PROFILE_STATISTICS",
    ORDERED_PROFILES_STATISTICS: "ORDERED_PROFILES_STATISTICS",

    NOTIFICATION_SUBSCRIPTION_AVAILABLE: "NOTIFICATION_SUBSCRIPTION_AVAILABLE",
    NOTIFICATIONS: "NOTIFICATIONS",
    NOTIFICATION_CENTER: "NOTIFICATION_CENTER",
    SUBSCRIPTION_NOTIFICATIONS: "SUBSCRIPTION_NOTIFICATIONS",
    FOLLOW_NOTIFICATIONS: "FOLLOW_NOTIFICATIONS",
    LIKE_NOTIFICATIONS: "LIKE_NOTIFICATIONS",
    COMMENT_NOTIFICATIONS: "COMMENT_NOTIFICATIONS",
    POST_NOTIFICATIONS: "POST_NOTIFICATIONS",
    UNREAD_NOTIFICATIONS: "UNREAD_NOTIFICATIONS",
    CREATE_NOTIFICATION_SUBSCRIPTION: "CREATE_NOTIFICATION_SUBSCRIPTION",
    DELETE_NOTIFICATION_SUBSCRIPTION: "DELETE_NOTIFICATION_SUBSCRIPTION",

    FEED: "FEED",
    FEED_VIDEOS: "FEED_VIDEOS",
    FEED_VIDEOS_RECOMMENDATIONS: "FEED_VIDEOS_RECOMMENDATIONS",
    FEED_VIDEOS_FOLLOWED: "FEED_VIDEOS_FOLLOWED",
    FEED_TOP_USERS: "FEED_TOP_USERS",
    FEED_LIVE_NOW_EVENTS: "FEED_LIVE_NOW_EVENTS",
    FEED_POSTITS: "FEED_POSTITS",
    FEED_POSTITS_RECOMMENDATIONS: "FEED_POSTITS_RECOMMENDATIONS",
    FEED_POSTITS_FOLLOWED: "FEED_POSTITS_FOLLOWED",

    RESOURCE: "RESOURCE",
    RESOURCE_COMMENTS: "RESOURCE_COMMENTS",
    RESOURCE_VOTES: "RESOURCE_VOTES",
    RESOURCE_VOTES_UP: "RESOURCE_VOTES_UP",
    RESOURCE_VOTES_DOWN: "RESOURCE_VOTES_DOWN",
    RESOURCE_MESSAGES: "RESOURCE_MESSAGES",

    USER_MESSAGE: "USER_MESSAGE",

    PROFILE_SUBSCRIPTIONS: "PROFILE_SUBSCRIPTIONS",
    HAS_PROFILE_SUBSCRIPTION: "HAS_PROFILE_SUBSCRIPTION",
    USER_PROFILE_SUBSCRIPTION: "USER_PROFILE_SUBSCRIPTION",

    PROFILE_FOLLOWERS: "PROFILE_FOLLOWERS",
    PROFILE_FOLLOWINGS: "PROFILE_FOLLOWINGS",

    PROFILE_REWARDS: "PROFILE_REWARDS",

    POSTIT: "POSTIT",
    PAYABLE_POSTIT: "PAYABLE_POSTIT",
    CREATING_POSTIT: "CREATING_POSTIT",
    EDITING_TEXT_POSTIT: "EDITING_TEXT_POSTIT",
    EDITING_IMAGE_POSTIT: "EDITING_IMAGE_POSTIT",
    POSTIT_PAGE: "POSTIT_PAGE",

    VIDEO: "VIDEO",
    PAYABLE_VIDEO: "PAYABLE_VIDEO",
    VIDEO_PAGE: "VIDEO_PAGE",

    VOTE: "VOTE",

    COMMENT: "COMMENT",

    LIVE_EVENT: "LIVE_EVENT",
    LIVE_EVENT_AUDIENCE_URL: "LIVE_EVENT_AUDIENCE_URL",
    LIVE_EVENT_STATISTICS: "LIVE_EVENT_STATISTICS",
    LIVE_EVENT_VIEWERS: "LIVE_EVENT_VIEWERS",

    USER: "USER",

    MY_PAYMENTS: "MY_PAYMENTS",
    PAYMENTS: "PAYMENTS",

    IMAGE_SRC: "IMAGE_SRC",

    PAYMENT_INFO: "PAYMENT_INFO",
    PAYMENT: "PAYMENT",

    PROFILE_CHALLENGE: "PROFILE_CHALLENGE",
    PROFILE_CHALLENGES_OVERVIEW: "PROFILE_CHALLENGES_OVERVIEW",
    TIERS: "TIERS",
    TIER: "TIER",

    REPORTING: "REPORTING",

    PAYMENT_AVAILABLE: "PAYMENT_AVAILABLE",
    SUBSCRIPTION_SUPPORTED: "SUBSCRIPTION_SUPPORTED",
    PAYMENT_PROVIDER_CONNECTION: "PAYMENT_PROVIDER_CONNECTION",
    PAYMENT_CHECKOUT_LINK: "PAYMENT_CHECKOUT_LINK",

    LIST_LIVE_EVENTS: "LIST_LIVE_EVENTS",
    LIST_POSTS: "LIST_POSTS",
    LIST_COMMENTS: "LIST_COMMENTS",
    LIST_VOTES: "LIST_VOTES",
    LIST_PROFILES: "LIST_PROFILES",
    LIST_VIDEOS: "LIST_VIDEOS",
    LIST_LIVE_EVENT_STATISTICS: "LIST_LIVE_EVENT_STATISTICS",
    LIST_LAST_MESSAGES: "LIST_LAST_MESSAGES",
    LIST_PROFILE: "LIST_PROFILE",
    LIST_LIVE_EVENT: "LIST_LIVE_EVENT",

    FIRST_USERS: "FIRST_USERS",

    AUDIENCE_URL: "AUDIENCE_URL",
} as const

/**
 * Query keys are used to uniquely identify queries in the cache.
 * When there is need to retrieve same data consider using same query
 * key to minimize the number of requests.
 */
export const QueryKeys = {
    asset: (id?: ResourceRef) => [StaticQueryKeys.ASSET, id],
    assets: (pageNumber: number, ids: ResourceRef[]) => [
        StaticQueryKeys.ASSETS,
        pageNumber,
        ids,
    ],
    assetData: (baseUrl?: Url, url?: Url) => [
        StaticQueryKeys.ASSET,
        StaticQueryKeys.ASSET_DATA,
        baseUrl,
        url,
    ],
    searchUserProfiles: (model: UseUserProfilesSearchModel) => [
        StaticQueryKeys.SEARCH,
        StaticQueryKeys.SEARCH_USER_PROFILES,
        model.searchKey,
        model.profileId,
    ],

    searchVideos: (model: UseVideosSearchModel) => [
        StaticQueryKeys.SEARCH,
        StaticQueryKeys.SEARCH_VIDEOS,
        model.searchKey,
        model.profileId,
        model.creatorIds,
    ],

    searchImagePosts: (model: UseImagePostSearchModel) => [
        StaticQueryKeys.SEARCH,
        StaticQueryKeys.SEARCH_POSTS,
        StaticQueryKeys.SEARCH_IMAGE_POSTS,
        model.searchKey,
        model.profileId,
        model.creatorIds,
    ],

    searchTextPosts: (model: UseTextPostSearchModel) => [
        StaticQueryKeys.SEARCH,
        StaticQueryKeys.SEARCH_POSTS,
        StaticQueryKeys.SEARCH_TEXT_POSTS,
        model.searchKey,
        model.profileId,
        model.creatorIds,
    ],

    searchLiveEvents: (model: UseLiveEventsSearchModel) => [
        StaticQueryKeys.SEARCH,
        StaticQueryKeys.SEARCH_LIVE_EVENTS,
        model.searchKey,
        model.profileId,
        model.creatorIds,
    ],

    profile: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE,
        profileId,
    ],

    profileFollow: (myProfileId?: UserProfileId, profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_PAGE,
        StaticQueryKeys.PROFILE_FOLLOW,
        myProfileId,
        profileId,
    ],

    profilePage: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_PAGE,
        profileId,
    ],

    profileVideoContent: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_PAGE,
        StaticQueryKeys.PROFILE_VIDEOS,
        profileId,
    ],

    profilePostContent: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_PAGE,
        StaticQueryKeys.PROFILE_POSTS,
        profileId,
    ],

    profileStatistics: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_PAGE,
        StaticQueryKeys.PROFILE_STATISTICS,
        profileId,
    ],
    orderedProfilesStatistics: () => [
        StaticQueryKeys.ORDERED_PROFILES_STATISTICS,
    ],

    recommendedVideos: (profileId?: UserProfileId) => [
        StaticQueryKeys.FEED,
        StaticQueryKeys.FEED_VIDEOS,
        StaticQueryKeys.FEED_VIDEOS_RECOMMENDATIONS,
        profileId,
    ],

    followedVideos: (followersProfileIds?: UserProfileId[]) => [
        StaticQueryKeys.FEED,
        StaticQueryKeys.FEED_VIDEOS,
        StaticQueryKeys.FEED_VIDEOS_FOLLOWED,
        followersProfileIds,
    ],

    recommendedPostits: (profileId?: UserProfileId) => [
        StaticQueryKeys.FEED,
        StaticQueryKeys.FEED_POSTITS,
        StaticQueryKeys.FEED_POSTITS_RECOMMENDATIONS,
        profileId,
    ],

    followedPostits: (followersProfileIds?: UserProfileId[]) => [
        StaticQueryKeys.FEED,
        StaticQueryKeys.FEED_POSTITS,
        StaticQueryKeys.FEED_POSTITS_FOLLOWED,
        followersProfileIds,
    ],

    resourceComments: (resourceId?: CommentContentIddResourceRefs) => [
        StaticQueryKeys.RESOURCE,
        StaticQueryKeys.RESOURCE_COMMENTS,
        resourceId,
    ],

    myResourceMessages: (resourceId?: UserProfileId) => [
        StaticQueryKeys.RESOURCE,
        StaticQueryKeys.RESOURCE_MESSAGES,
        resourceId,
    ],

    resourceMessages: (
        resourceId?: UserProfileId,
        senderId?: UserProfileId,
    ) => [
        StaticQueryKeys.RESOURCE,
        StaticQueryKeys.RESOURCE_MESSAGES,
        resourceId,
        senderId,
    ],

    userMessage: (id?: UserMessageId) => [StaticQueryKeys.USER_MESSAGE, id],

    resourceVotes: (resourceId?: VoteContentIddResourceRefs) => [
        StaticQueryKeys.RESOURCE,
        StaticQueryKeys.RESOURCE_VOTES,
        resourceId,
    ],

    myResourceVote: (resourceId?: VotedResourceRef, voter?: CreatorRef) => [
        StaticQueryKeys.RESOURCE,
        StaticQueryKeys.RESOURCE_VOTES,
        resourceId,
        voter,
    ],

    myProfilePage: () => [StaticQueryKeys.MY_PROFILE_PAGE],

    hasProfileSubscription: (profileId?: UserProfileId) => [
        StaticQueryKeys.HAS_PROFILE_SUBSCRIPTION,
        profileId,
    ],

    userProfileSubscription: (profileId?: UserProfileId) => [
        StaticQueryKeys.USER_PROFILE_SUBSCRIPTION,
        profileId,
    ],

    feedTopUsers: (userId?: UserId) => [
        StaticQueryKeys.FEED,
        StaticQueryKeys.FEED_TOP_USERS,
        userId,
    ],

    postit: (postitId?: PostitId) => [StaticQueryKeys.POSTIT, postitId],
    payablePostit: (postitId?: PostitId) => [
        StaticQueryKeys.PAYABLE_POSTIT,
        postitId,
    ],

    video: (videoId?: VideoId) => [StaticQueryKeys.VIDEO, videoId],
    payableVideo: (videoId?: VideoId) => [
        StaticQueryKeys.PAYABLE_VIDEO,
        videoId,
    ],

    user: (userId?: UserId) => [StaticQueryKeys.USER, userId],

    vote: (voteId?: VoteId) => [StaticQueryKeys.VOTE, voteId],

    comment: (commentId?: UserCommentId) => [
        StaticQueryKeys.COMMENT,
        commentId,
    ],

    liveEvent: (liveEventId?: LiveEventId) => [
        StaticQueryKeys.LIVE_EVENT,
        liveEventId,
    ],

    listLiveEvent: (liveEventId?: LiveEventId) => [
        StaticQueryKeys.LIST_LIVE_EVENT,
        liveEventId,
    ],

    liveEventAudienceUrl: (liveEventId?: LiveEventId) => [
        StaticQueryKeys.LIVE_EVENT_AUDIENCE_URL,
        liveEventId,
    ],

    liveEventStatistics: (liveEventId?: LiveEventId) => [
        StaticQueryKeys.LIVE_EVENT_STATISTICS,
        liveEventId,
    ],

    liveEventViewers: (liveEventId?: LiveEventId) => [
        StaticQueryKeys.LIVE_EVENT_VIEWERS,
        liveEventId,
    ],

    feedLiveNowEvents: () => [
        StaticQueryKeys.FEED,
        StaticQueryKeys.FEED_LIVE_NOW_EVENTS,
    ],

    myPayments: () => [
        StaticQueryKeys.MY_PROFILE_PAGE,
        StaticQueryKeys.MY_PAYMENTS,
    ],

    payments: () => [StaticQueryKeys.PAYMENTS],

    notificationSubscriptionAvailable: () => [
        StaticQueryKeys.NOTIFICATION_SUBSCRIPTION_AVAILABLE,
    ],

    notificationCenter: (profileId?: UserProfileId) => [
        StaticQueryKeys.NOTIFICATIONS,
        StaticQueryKeys.NOTIFICATION_CENTER,
        profileId,
    ],

    subscriptionNotifications: (profileId?: UserProfileId) => [
        StaticQueryKeys.NOTIFICATIONS,
        StaticQueryKeys.NOTIFICATION_CENTER,
        StaticQueryKeys.SUBSCRIPTION_NOTIFICATIONS,
        profileId,
    ],

    followNotifications: (profileId?: UserProfileId) => [
        StaticQueryKeys.NOTIFICATIONS,
        StaticQueryKeys.NOTIFICATION_CENTER,
        StaticQueryKeys.FOLLOW_NOTIFICATIONS,
        profileId,
    ],

    likeNotifications: (profileId?: UserProfileId) => [
        StaticQueryKeys.NOTIFICATIONS,
        StaticQueryKeys.NOTIFICATION_CENTER,
        StaticQueryKeys.LIKE_NOTIFICATIONS,
        profileId,
    ],

    commentNotifications: (profileId?: UserProfileId) => [
        StaticQueryKeys.NOTIFICATIONS,
        StaticQueryKeys.NOTIFICATION_CENTER,
        StaticQueryKeys.COMMENT_NOTIFICATIONS,
        profileId,
    ],

    postNotifications: (profileId?: UserProfileId) => [
        StaticQueryKeys.NOTIFICATIONS,
        StaticQueryKeys.NOTIFICATION_CENTER,
        StaticQueryKeys.POST_NOTIFICATIONS,
        profileId,
    ],

    unreadNotifications: (profileId?: UserProfileId) => [
        StaticQueryKeys.NOTIFICATIONS,
        StaticQueryKeys.NOTIFICATION_CENTER,
        StaticQueryKeys.UNREAD_NOTIFICATIONS,
        profileId,
    ],

    imageSrc: (src?: string) => [StaticQueryKeys.IMAGE_SRC, src],

    profileFollowers: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_FOLLOWERS,
        profileId,
    ],
    profileFollowings: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_FOLLOWINGS,
        profileId,
    ],

    profileChallenge: (profileChallengeId?: ProfileChallengeId) => [
        StaticQueryKeys.PROFILE_CHALLENGE,
        profileChallengeId,
    ],
    profileChallengesOverview: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_CHALLENGES_OVERVIEW,
        profileId,
    ],
    tiers: () => [StaticQueryKeys.TIERS],
    tier: (id?: TierId) => [StaticQueryKeys.TIER, id],

    profileRewards: (profileId?: UserProfileId) => [
        StaticQueryKeys.PROFILE_REWARDS,
        profileId,
    ],

    paymentAvailable: (userId?: UserId, profileId?: UserProfileId) => [
        StaticQueryKeys.PAYMENT_AVAILABLE,
        userId,
        profileId,
    ],
    subscriptionSupported: (profileId?: UserProfileId) => [
        StaticQueryKeys.SUBSCRIPTION_SUPPORTED,
        profileId,
    ],
    paymentProviderConnection: () => [
        StaticQueryKeys.PAYMENT_PROVIDER_CONNECTION,
    ],
    paymentCheckoutLink: (id?: PaymentId) => [
        StaticQueryKeys.PAYMENT_CHECKOUT_LINK,
        id,
    ],
    payment: (id?: PaymentId) => [StaticQueryKeys.PAYMENT, id],

    listLiveEvents: (pageNumber: number, ids: LiveEventId[]) => [
        StaticQueryKeys.LIST_LIVE_EVENTS,
        pageNumber,
        ids,
    ],
    listPosts: (pageNumber: number, ids: PostitId[]) => [
        StaticQueryKeys.LIST_POSTS,
        pageNumber,
        ...ids,
    ],
    listComments: (pageNumber: number, ids: UserCommentId[]) => [
        StaticQueryKeys.LIST_COMMENTS,
        pageNumber,
        ids,
    ],
    listVotes: (pageNumber: number, ids: VoteId[]) => [
        StaticQueryKeys.LIST_VOTES,
        pageNumber,
        ids,
    ],
    listProfiles: (pageNumber: number, ids: UserProfileId[]) => [
        StaticQueryKeys.LIST_PROFILES,
        pageNumber,
        ...ids,
    ],
    listVideos: (pageNumber: number, ids: VideoId[]) => [
        StaticQueryKeys.LIST_VIDEOS,
        pageNumber,
        ids,
    ],
    listliveEventStatistics: (id?: LiveEventId) => [
        StaticQueryKeys.LIST_LIVE_EVENT_STATISTICS,
        id,
    ],
    listLastMessages: (
        resourceId?: UserProfileId,
        senderId?: UserProfileId,
    ) => [StaticQueryKeys.LIST_LAST_MESSAGES, resourceId, senderId],

    listProfile: (id?: UserProfileId) => [StaticQueryKeys.LIST_PROFILE, id],

    firstUsers: () => [StaticQueryKeys.FIRST_USERS],

    audienceUrl: (id?: LiveEventId) => [StaticQueryKeys.AUDIENCE_URL, id],
} as const

export type QueryKeys = typeof QueryKeys
export type QueryKey = keyof QueryKeys
export type QueryKeyValueFunction = QueryKeys[QueryKey]
export type QueryKeyValue = ReturnType<QueryKeyValueFunction>
