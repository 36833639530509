import { queryOptions } from "@tanstack/react-query"

import { paymentCreatorClient } from "api/clients/payment-api-client"
import { QueryKeys } from "api/query-keys"

const getPaymentProviderConnection = async () => {
    const res = await paymentCreatorClient.getPaymentProviderConnection()
    return res.data
}

export const paymentProviderConnectionQueryOptions = queryOptions({
    queryFn: getPaymentProviderConnection,
    queryKey: QueryKeys.paymentProviderConnection(),
})
