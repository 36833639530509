import { useEffect, useState } from "react"
import { ModalTypes } from "store/actions"

type ModalSkippedState = { [K in ModalTypes["type"]]: boolean }

const LOCAL_STORAGE_KEY = "modal-skipped-state"

const defaultState: ModalSkippedState = {
    ContinueRegistrationByEmail: false,
    CreateContentMenu: false,
    FindOutMore: false,
    FirstStepsWelcome: false,
    FirstWelcome: false,
    LoginOrRegister: false,
    StandaloneWelcome: false,
    EnableNotifications: false,
}

export const useModalState = () => {
    const [modalSkippedState, setModalSkippedState] =
        useState<ModalSkippedState>(() => {
            const storedState = localStorage.getItem(LOCAL_STORAGE_KEY)
            return storedState
                ? (JSON.parse(storedState) as ModalSkippedState)
                : defaultState
        })

    useEffect(() => {
        localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(modalSkippedState),
        )
    }, [modalSkippedState])

    const skipModal = (type: ModalTypes["type"]) => {
        setModalSkippedState(prevState => ({
            ...prevState,
            [type]: true,
        }))
    }

    const isModalSkipped = (type: ModalTypes["type"]) =>
        !!modalSkippedState[type]

    return { skipModal, isModalSkipped }
}
