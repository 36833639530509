import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, PropsWithChildren, useState } from "react"
import { match } from "ts-pattern"

import { useQueries, useQueryClient } from "@tanstack/react-query"
import { assetsQueryOptions } from "hooks/use-asset"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import {
    getMergedProfileIdPages,
    mergeSortedInfiniteData,
} from "../../../api/api-utils"
import { UserProfileId } from "../../../api/branded-types"
import { InfiniteScroll } from "../../../common/infinite-scroll"
import { FollowState, FollowStateAdt } from "../../../data-flow/follower"
import { fill } from "../../../utils/array"
import {
    PostAreaTile,
    isPostit,
    isVideo,
} from "../../feed/areas/post/post-area-tile"
import {
    VideoAreaTile,
    VideoAreaTileModel,
} from "../../feed/areas/videos/video-area-tile"
import { PostLoadingTile } from "../../search/videos/post-loading-tile"
import { useProfilePostContent } from "../hooks/use-profile-post-content"
import { useProfileVideoContent } from "../hooks/use-profile-video-content"
import * as styles from "../user-profile-page.css"

type UserProfilePagePostContentModel = {
    loc: VideoAreaTileModel["loc"]
    text: {
        followCTA: {
            title: string
            subtitle: string
        }
        videoEmpty: string
    }
    followingState: FollowState
    profileId?: UserProfileId
}

export const UserProfilePagePostContent: FC<
    UserProfilePagePostContentModel
> = model => {
    const { t } = useTranslation(["userProfile"])

    const profileVideoContentQuery = useProfileVideoContent(model.profileId)
    const profilePostContentQuery = useProfilePostContent(model.profileId)

    const profileVideoItems =
        profileVideoContentQuery.data?.pages.flatMap(page => page.data ?? []) ??
        []
    const profilePostItems =
        profilePostContentQuery.data?.pages.flatMap(page => page.data ?? []) ??
        []

    const startingValue =
        profileVideoItems.length + profilePostItems.length > 100
            ? profileVideoItems.length + profilePostItems.length
            : 100

    const [desiredAmount, setDesiredAmount] = useState(startingValue)

    const data = mergeSortedInfiniteData({
        firstItems: profileVideoItems,
        secondItems: profilePostItems,
        firstHasNextPage: profileVideoContentQuery.hasNextPage,
        secondHasNextPage: profilePostContentQuery.hasNextPage,
        desiredAmount,
    })

    const queryClient = useQueryClient()

    const postResourceAssetRefsPages = pipe(
        profilePostContentQuery.data?.pages ?? [],

        A.map(page =>
            pipe(
                page.data,
                A.filter(post => post.type === "Image"),
                A.map(post => post.imageRef),
            ),
        ),
    )

    const assetQueries = useQueries({
        queries: postResourceAssetRefsPages.map((page, idx) =>
            assetsQueryOptions({
                pageNumber: idx,
                queryClient,
                resources: page,
            }),
        ),
    })

    const oneOfAssetQueriesLoading = assetQueries.some(query => query.isLoading)

    const profileIdsPages = getMergedProfileIdPages(
        profileVideoContentQuery.data?.pages ?? [],
        profilePostContentQuery.data?.pages ?? [],
    )

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const videosState = match(profileVideoContentQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const postsState = match(profilePostContentQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const contentState =
        videosState === "Loaded" && postsState === "Loaded"
            ? "Loaded"
            : videosState === "Failed" || postsState === "Failed"
              ? "Failed"
              : videosState === "Loading" || postsState === "Loading"
                ? "Loading"
                : "Unloaded"

    const showLoadingState =
        contentState === "Loading" ||
        oneOfAssetQueriesLoading ||
        oneOfProfileQueriesLoading

    return pipe(
        model.followingState,
        O.fromPredicate(FollowStateAdt.is.Following),
        O.fold(
            () => <FollowCTA followCTA={model.text.followCTA} />,
            () =>
                pipe(
                    videosState,
                    O.fromPredicate(state => state === "Loaded"),
                    O.chain(O.fromPredicate(() => data.length === 0)),
                    O.fold(
                        () => (
                            <div className={styles.postContent}>
                                <InfiniteScroll
                                    state={contentState}
                                    disabled={
                                        !profilePostContentQuery.hasNextPage &&
                                        !profileVideoContentQuery.hasNextPage
                                    }
                                    threshold="100px"
                                    onLoadRequested={() => {
                                        setDesiredAmount(prev => prev + 50)
                                        if (
                                            profileVideoContentQuery.hasNextPage
                                        ) {
                                            profileVideoContentQuery.fetchNextPage()
                                        }

                                        if (
                                            profilePostContentQuery.hasNextPage
                                        ) {
                                            profilePostContentQuery.fetchNextPage()
                                        }
                                    }}
                                >
                                    {
                                        //TODO: this is not correct, data should be paginated. But currently there is
                                        // no way to properly merge infinite query data in a manageable way :(
                                        profileQueries.some(
                                            query => !query.isLoading,
                                        ) &&
                                            assetQueries.some(
                                                query => !query.isLoading,
                                            ) &&
                                            pipe(
                                                data,
                                                A.map(post =>
                                                    pipe(
                                                        post,
                                                        O.fromPredicate(
                                                            isVideo,
                                                        ),
                                                        O.fold(
                                                            () =>
                                                                pipe(
                                                                    post,
                                                                    O.fromPredicate(
                                                                        isPostit,
                                                                    ),
                                                                    O.fold(
                                                                        () =>
                                                                            null,
                                                                        postit => (
                                                                            <PostAreaTile
                                                                                key={
                                                                                    postit.id
                                                                                }
                                                                                loc={{
                                                                                    title: t(
                                                                                        "exclusive.title",
                                                                                    ),
                                                                                }}
                                                                                postId={
                                                                                    postit.id
                                                                                }
                                                                            />
                                                                        ),
                                                                    ),
                                                                ),
                                                            video => (
                                                                <VideoAreaTile
                                                                    key={
                                                                        video.id
                                                                    }
                                                                    loc={
                                                                        model.loc
                                                                    }
                                                                    videoId={
                                                                        video.id
                                                                    }
                                                                />
                                                            ),
                                                        ),
                                                    ),
                                                ),
                                            )
                                    }
                                    {showLoadingState &&
                                        fill(3, idx => (
                                            <PostLoadingTile key={idx} />
                                        ))}
                                </InfiniteScroll>
                            </div>
                        ),
                        () => (
                            <EmptyContent videoEmpty={model.text.videoEmpty} />
                        ),
                    ),
                ),
        ),
    )
}

const FollowCTA = (
    model: Pick<UserProfilePagePostContentModel["text"], "followCTA">,
) => (
    <InfoBox>
        <p
            className={styles.infoBoxTitle}
            style={{ fontSize: vars.font.size.m }}
        >
            {model.followCTA.title}
        </p>
        <p
            className={styles.infoBoxSubtitle}
            style={{ fontSize: vars.font.size.regular }}
        >
            {model.followCTA.subtitle}
        </p>
    </InfoBox>
)

const EmptyContent = (
    model: Pick<UserProfilePagePostContentModel["text"], "videoEmpty">,
) => (
    <InfoBox>
        <p
            className={styles.infoBoxTitle}
            style={{ fontSize: vars.font.size.m }}
        >
            {model.videoEmpty}
        </p>
    </InfoBox>
)

const InfoBox: FC<PropsWithChildren> = ({ children }) => (
    <div className={styles.infoBox}>
        <div className={styles.infoBoxText}>{children}</div>
    </div>
)
