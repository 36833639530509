import { queryOptions, useQuery } from "@tanstack/react-query"
import { Video } from "api/api-models"
import { Result } from "api/types"
import { VideoId } from "../../../api/branded-types"
import { videoSelectorClient } from "../../../api/clients/video-api-client"
import { QueryKeys } from "../../../api/query-keys"

export const getListVideoById = async (
    id?: VideoId,
): Promise<Result<Video>> => {
    if (!id) throw new Error("VideoId should be defined")
    const videoRes = await videoSelectorClient.listVideos({
        filter: `{ $match: {id: "${id}"}}`,
        paging: { type: "Index", direction: "After", index: -1, limit: 1 },
    })

    const data = videoRes.data.at(0)
    if (!data) {
        return {
            data,
            error: "VIDEO_NOT_FOUND",
        }
    }
    return {
        data,
        error: undefined,
    }
}

export const listVideoByIdQueryOptions = (id?: VideoId) =>
    queryOptions({
        queryFn: () => getListVideoById(id),
        queryKey: QueryKeys.video(id),
        enabled: !!id,
    })

export const useListVideoById = (id?: VideoId) => {
    return useQuery(listVideoByIdQueryOptions(id))
}
