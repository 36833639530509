import { FC } from "react"

import { UserMessageId } from "api/branded-types"
import { AvatarLoading } from "components/avatars/avatar-loading"
import { useListUserProfileById } from "hooks/use-list-user-profile-by-id"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import { messageTimeInfo } from "utils/time"
import { Avatar } from "../../components/avatars/avatar"
import { FoldableStatefulText } from "../../components/texts/foldable-stateful-text"
import { useMessageById } from "./hooks/use-message-by-id"
import * as styles from "./user-message-item.css"

type Props = {
    showTime: boolean
    messageId: UserMessageId
    onAvatarClicked?: () => void
}

export const UserMessageItem: FC<Props> = ({
    showTime,
    messageId,
    onAvatarClicked,
}) => {
    const { t, i18n } = useTranslation("common")
    const messageQuery = useMessageById(messageId)
    const profileQuery = useListUserProfileById(messageQuery.data?.senderId)

    const profile = profileQuery.data?.data

    if (!messageQuery.isSuccess) return null

    const message = messageQuery.data

    return (
        <div className={styles.message}>
            {profileQuery.isLoading && (
                <AvatarLoading size={36} className={styles.avatar} />
            )}
            {profileQuery.isSuccess && profile && (
                <Avatar
                    size={36}
                    className={styles.avatar}
                    src={profile.imageUrl}
                    onClicked={onAvatarClicked}
                />
            )}

            <div className={styles.messageBox}>
                {showTime && (
                    <p
                        style={{
                            margin: "0 16px",
                            color: vars.color.tertiary.hex,
                            fontSize: vars.font.size.xs,
                        }}
                    >
                        {messageTimeInfo({
                            date: message.createdAt,
                            i18nLang: i18n.language,
                            t,
                        })}
                    </p>
                )}

                <FoldableStatefulText
                    className={styles.text}
                    open={false}
                    maxLength={750}
                    text={message.text.trim()}
                    fontSize={vars.font.size.regular}
                />
            </div>
        </div>
    )
}
