import debug from "debug"
import { APP_VERSION, PUSHER_CLUSTER, PUSHER_KEY } from "envs"
import Pusher from "pusher-js"
import { useCallback, useEffect, useState } from "react"

const logVersion = debug("version")
const logPusher = debug("pusher")

export const useNewVersionCheck = () => {
    const [newVersion, setNewVersion] = useState(false)

    const checkAppVersion = useCallback(async () => {
        try {
            const version = await fetchAppVersion()
            logVersion("current version", APP_VERSION)
            logVersion("available version", version)

            setNewVersion(version !== APP_VERSION)
        } catch (error) {
            logVersion("Error fetching version:", error)
        }
    }, [])

    useEffect(() => {
        checkAppVersion()
    }, [checkAppVersion])

    useEffect(() => {
        const pusher = new Pusher(PUSHER_KEY, { cluster: PUSHER_CLUSTER })
        const channel = pusher.subscribe("pwa-updates")
        logPusher("subscribed to channel pwa-updates")

        const handleNewDeployment = async () => {
            logPusher("received new-deployment event")
            await checkAppVersion()
        }

        channel.bind("new-deployment", handleNewDeployment)

        return () => {
            channel.unbind("new-deployment", handleNewDeployment)
            channel.unsubscribe()
            logPusher("unsubscribed from channel pwa-updates")
        }
    }, [checkAppVersion])

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState !== "visible") return
            checkAppVersion()
        }

        document.addEventListener("visibilitychange", handleVisibilityChange)

        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            )
        }
    }, [checkAppVersion])

    return {
        newVersion,
        setNewVersion,
    }
}

const fetchAppVersion = async () => {
    try {
        const response = await fetch(`/version.json?t=${Date.now()}`, {
            cache: "no-store",
        })
        if (!response.ok) throw new Error(`HTTP error: ${response.status}`)

        const data = await response.json()
        return data.version
    } catch (error) {
        logVersion("Error fetching app version:", error)
    }
}
