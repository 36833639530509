import { faLocationPin } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { UserProfileId } from "api/branded-types"
import classNames from "classnames"
import { useUserProfile } from "hooks/use-user-profile"
import { FC } from "react"
import { vars } from "theme/variables.css"

import { useTranslation } from "react-i18next"
import * as styles from "../user-profile-page.css"

type UserProfileLocationModel = {
    profileId?: UserProfileId
}

export const UserProfileLocation: FC<UserProfileLocationModel> = ({
    profileId,
}) => {
    const { t } = useTranslation("userProfile")
    const userProfileQuery = useUserProfile(profileId)
    return (
        <div className={classNames(styles.rows, styles.locationContainer)}>
            <FontAwesomeIcon
                icon={faLocationPin}
                color={vars.color.tertiary.hex}
            />
            <small className={styles.textLight}>
                {userProfileQuery.data?.location ?? t("location.text")}
            </small>
        </div>
    )
}
