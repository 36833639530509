import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"
import { pipe } from "fp-ts/function"
import { FC, useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { AccountType } from "../../api/api-models"
import { ShareContentId, UserProfileId } from "../../api/branded-types"
import { createShareTokenTask } from "../../api/clients/auth-api-client"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import { ShareOverviewAlertModal } from "../../components/modals/share-overview-alert-modal"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsListLoading,
    TabsTrigger,
} from "../../components/tabs/tabs"
import { createShareUrlWithLocation } from "../../data-flow/sharing"
import { useMe } from "../../hooks/use-me"
import { useMyPayments } from "../../hooks/use-my-payments"
import { fill } from "../../utils/array"
import { PostLoadingTile } from "../search/videos/post-loading-tile"
import { MyUserProfileActions } from "./components/my-user-profile-actions"
import {
    MyUserProfileData,
    ProfileDataLoading,
} from "./components/my-user-profile-data"
import { MyUserProfilePostContent } from "./components/my-user-profile-post-content"
import { MyUserProfileToolbar } from "./components/my-user-profile-toolbar"
import { ProfileDescription } from "./components/profile-description"
import {
    ProfileStatistics,
    ProfileStatisticsLoading,
} from "./components/profile-statistics"

import { faOctagonExclamation } from "@fortawesome/pro-light-svg-icons"
import { useQueries, useQueryClient } from "@tanstack/react-query"
import { useNavigate, useSearch } from "@tanstack/react-router"
import { shareLinkClient } from "api/clients/share-link-api-client"
import { Loading } from "common/loading"
import { InfoToastContent } from "components/controls/toast"
import { useInfoToast } from "components/controls/use-info-toast"
import { PaymentAreaTile } from "features/feed/areas/payment/payment-area-tile"
import { PaymentsEmpty } from "features/feed/areas/payment/payments-empty"
import { useInView } from "framer-motion"
import { assetsQueryOptions } from "hooks/use-asset"
import { listLiveEventsQueryOptions } from "hooks/use-list-live-events"
import { listPostsQueryOptions } from "hooks/use-list-posts"
import { listUserProfilesQueryOptions } from "hooks/use-list-user-profiles"
import { listVideosQueryOptions } from "hooks/use-list-videos"
import {
    useMyUserProfile,
    useMyUserProfileId,
} from "hooks/use-my-user-profiles"
import { useOnlyRegisteredUser } from "hooks/use-only-registered-user"
import { LazyLoadImage } from "react-lazy-load-image-component"
import {
    getIsAuthorizedAccount,
    withAuthorizedAccess,
} from "../../api/api-utils"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { getSafeArea } from "../../utils/constant"
import { useProfilePostContent } from "./hooks/use-profile-post-content"
import { useProfileStatistics } from "./hooks/use-profile-statistic"
import { useProfileVideoContent } from "./hooks/use-profile-video-content"
import * as styles from "./my-user-profile-page.css"

export const MyUserProfilePage: FC = () => {
    const { accountType, ensureUser } = useOnlyRegisteredUser()
    const [isShareLinkInCreation, setIsShareLinkInCreation] = useState(false)
    const { t } = useTranslation(["myProfile"])
    const { t: tCommon } = useTranslation(["common"])
    const { toast } = useInfoToast()

    const meQuery = useMe()

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const myProfileQuery = useMyUserProfile(isAuthorizedAccount)

    const [shareModalOpen, setShareModalOpen] = useState(false)

    const [shareUrl, setShareUrl] = useState<undefined | string>()

    const profileId = myProfileQuery.data?.id

    const shareMyProfile = useCallback(
        () => {
            setIsShareLinkInCreation(true)

            pipe(
                profileId,
                O.fromNullable,
                O.foldW(
                    () => () => setIsShareLinkInCreation(false),
                    id =>
                        pipe(
                            createShareTokenTask(
                                {
                                    contents: [
                                        {
                                            contentType: "UserProfile",
                                            contentFilter:
                                                ShareContentId.parse(id),
                                        },
                                    ],
                                },
                                {},
                            ),
                            TE.bimap(
                                error => {
                                    setIsShareLinkInCreation(false)
                                    console.error("Error share", error)
                                },
                                ({ data }) =>
                                    pipe(
                                        data,
                                        createShareUrlWithLocation(
                                            "app/user-profile/" + id,
                                        ),
                                        link => {
                                            shareLinkClient
                                                .createShortenedLink({
                                                    link,
                                                })
                                                .then(({ shortURL }) => {
                                                    setShareUrl(shortURL)
                                                    setShareModalOpen(true)
                                                    setIsShareLinkInCreation(
                                                        false,
                                                    )
                                                })
                                                .catch(() => {
                                                    setIsShareLinkInCreation(
                                                        false,
                                                    )
                                                    toast({
                                                        toastContent: (
                                                            <InfoToastContent
                                                                icon={
                                                                    faOctagonExclamation
                                                                }
                                                                iconColor="danger"
                                                                text={tCommon(
                                                                    "share.error.title",
                                                                )}
                                                            />
                                                        ),
                                                    })
                                                })
                                        },
                                    ),
                            ),
                        ),
                ),
            )()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profileId, isAuthorizedAccount],
    )

    const imageRef = useRef(null)
    const isInView = useInView(imageRef, {
        margin: `${window.innerWidth * -0.2 - getSafeArea("top")}px`,
    })

    const myProfileVideoContentQuery = useProfileVideoContent(profileId)
    const myProfilePostContentQuery = useProfilePostContent(profileId)
    const myProfileStatisticsQuery = useProfileStatistics(profileId)

    const myPaymentsQuery = useMyPayments(profileId)

    const refreshPage = async () =>
        await Promise.allSettled([
            myProfileQuery.refetch(),
            myPaymentsQuery.refetch(),
            myProfileVideoContentQuery.refetch(),
            myProfilePostContentQuery.refetch(),
            meQuery.refetch(),
            myProfileStatisticsQuery.refetch(),
        ])

    return (
        <Page className={styles.page}>
            <Loading
                open={isShareLinkInCreation}
                message={tCommon("loading")}
            />

            <MyUserProfileToolbar
                isVisible={isInView}
                isAuthorizedAccount={isAuthorizedAccount}
            />

            {isInView && <div className={styles.overlay} />}

            <MyUserProfileActions
                shareMyProfile={withAuthorizedAccess({
                    onAuthorized: shareMyProfile,
                    onUnauthorized: ensureUser,
                    accountType,
                })}
                onNavigateToSettings={withAuthorizedAccess({
                    onUnauthorized: ensureUser,
                    accountType,
                })}
            />

            <Content fullscreen>
                <PullToRefresh onRefresh={refreshPage}>
                    <div ref={imageRef}>
                        <LazyLoadImage
                            width={window.innerWidth}
                            height={window.innerWidth}
                            className={styles.profileImage}
                            src={
                                myProfileQuery.isSuccess && myProfileQuery.data
                                    ? `${myProfileQuery.data.imageUrl}?width=${window.innerWidth * 2}`
                                    : ""
                            }
                        />
                    </div>

                    <div className={styles.content}>
                        <ShareOverviewAlertModal
                            open={shareModalOpen}
                            text={{
                                title: t("profileShare.title"),
                                confirm: t("profileShare.confirm"),
                                share: {
                                    title: t("profileShare.share.title"),
                                    description: t(
                                        "profileShare.share.description",
                                    ),
                                },
                            }}
                            onOpenChange={setShareModalOpen}
                            shareUrl={O.fromNullable(shareUrl)}
                        />

                        <div className={styles.wrapper}>
                            <MyUserProfileData
                                isAuthorizedAccount={isAuthorizedAccount}
                                setEditProfileModalVisible={withAuthorizedAccess(
                                    {
                                        onUnauthorized: ensureUser,
                                        accountType,
                                    },
                                )}
                            />

                            <ProfileStatistics
                                id={profileId}
                                text={{
                                    posts: tCommon("posts"),
                                    followers: tCommon("followers"),
                                    following: tCommon("following"),
                                }}
                            />

                            <ProfileDescription
                                profile={myProfileQuery.data}
                                loc={{
                                    moreExternalLinks: count =>
                                        t("externalLinks.more", { count }),
                                }}
                            />

                            <div className={styles.divider} />

                            <ProfileContentTabs
                                accountType={accountType}
                                profileId={profileId}
                            />
                        </div>
                    </div>
                </PullToRefresh>
            </Content>
        </Page>
    )
}
export default MyUserProfilePage

type Tab = "All" | "Purchased"

type TabProps = {
    profileId?: UserProfileId
    accountType: AccountType
}

export const ProfileContentTabs: FC<TabProps> = ({
    accountType,
    profileId,
}) => {
    const search = useSearch({
        from: "/_authenticated/app/_tab-bar-layout/my-profile/",
    })
    const profileContentTab = search.tab

    const myProfileIdQuery = useMyUserProfileId()

    const { t } = useTranslation(["myProfile"])
    const navigate = useNavigate()

    const paymentsQuery = useMyPayments(myProfileIdQuery.data)

    const paymentContentRefsPages =
        paymentsQuery.data?.pages.map(page =>
            page.data.map(item => item.contentRef),
        ) ?? []

    const paymentVideoIdsPages = paymentContentRefsPages?.map(page =>
        page
            .filter(ref => ref.contentType === "Video")
            .map(ref => ref.contentId),
    )

    const paymentPostIdsPages = paymentContentRefsPages?.map(page =>
        page
            .filter(ref => ref.contentType === "Postit")
            .map(ref => ref.contentId),
    )

    const paymentLiveEventIdsPages = paymentContentRefsPages?.map(page =>
        page
            .filter(ref => ref.contentType === "LiveEvent")
            .map(ref => ref.contentId),
    )

    const queryClient = useQueryClient()

    const videoQueries = useQueries({
        queries: paymentVideoIdsPages?.map((page, idx) =>
            listVideosQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfVideoQueriesLoading = videoQueries.some(query => query.isLoading)

    const postitQueries = useQueries({
        queries: paymentPostIdsPages?.map((page, idx) =>
            listPostsQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfPostitQueriesLoading = postitQueries.some(
        query => query.isLoading,
    )

    const liveEventQueries = useQueries({
        queries: paymentLiveEventIdsPages?.map((page, idx) =>
            listLiveEventsQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfLiveEventQueriesLoading = liveEventQueries.some(
        query => query.isLoading,
    )

    const postResourceAssetRefsPages = pipe(
        postitQueries ?? [],
        A.map(query =>
            pipe(
                query.data ?? [],
                A.filter(post => post.type === "Image"),
                A.map(post => post.imageRef),
            ),
        ),
    )

    const assetQueries = useQueries({
        queries: postResourceAssetRefsPages.map((page, idx) =>
            assetsQueryOptions({
                pageNumber: idx,
                queryClient,
                resources: page,
            }),
        ),
    })

    const oneOfAssetQueriesLoading = assetQueries.some(query => query.isLoading)

    const profileIdsPages =
        paymentsQuery.data?.pages.map(page =>
            page.data.map(item => item.contentOwnerId),
        ) ?? []

    const profileQueries = useQueries({
        queries: profileIdsPages.map((page, idx) =>
            listUserProfilesQueryOptions(idx, page, queryClient),
        ),
    })
    const oneOfProfileQueriesLoading = profileQueries.some(
        query => query.isLoading,
    )

    const isLoading =
        paymentsQuery.isLoading ||
        oneOfVideoQueriesLoading ||
        oneOfPostitQueriesLoading ||
        oneOfLiveEventQueriesLoading ||
        oneOfAssetQueriesLoading ||
        oneOfProfileQueriesLoading

    return (
        <Tabs
            value={profileContentTab}
            onValueChange={tab =>
                navigate({ to: ".", search: { tab }, replace: true })
            }
        >
            <TabsList>
                <TabsTrigger<Tab> value="All">{t("tabs.all")}</TabsTrigger>

                <TabsTrigger<Tab> value="Purchased">
                    {t("tabs.purchased")}
                </TabsTrigger>
            </TabsList>

            <TabsContent<Tab> value="All">
                <MyUserProfilePostContent
                    profileId={profileId}
                    loc={{ title: t("post.exclusive") }}
                    text={{
                        title: t("createContentPlaceholder.title"),
                        subtitle: t("createContentPlaceholder.subtitle"),
                        action: t("createContentPlaceholder.action"),
                    }}
                    accountType={accountType}
                />
            </TabsContent>

            <TabsContent<Tab> value="Purchased">
                <div className={styles.purchasedContentContainer}>
                    {paymentsQuery.data?.pages.map(
                        (page, idx) =>
                            !videoQueries.at(idx)?.isLoading &&
                            !postitQueries.at(idx)?.isLoading &&
                            !liveEventQueries.at(idx)?.isLoading &&
                            !assetQueries.at(idx)?.isLoading &&
                            !profileQueries.at(idx)?.isLoading &&
                            page.data.map(payment => (
                                <PaymentAreaTile
                                    key={payment.id}
                                    paymentId={payment.id}
                                />
                            )),
                    )}
                    {isLoading && fill(3, idx => <PostLoadingTile key={idx} />)}
                    {paymentsQuery.isSuccess &&
                        paymentsQuery.data.pages.at(0)?.totalCount === 0 && (
                            <PaymentsEmpty />
                        )}
                </div>
            </TabsContent>
        </Tabs>
    )
}

export const MyUserProfilePageLoading: FC = () => (
    <Page className={styles.page}>
        <NavigationBackButton />
        <MyUserProfileActions
            onNavigateToSettings={() => {}}
            shareMyProfile={() => {}}
        />
        <Content style={{ overflow: "hidden" }}>
            <div className={styles.content}>
                <div className={styles.wrapper}>
                    <ProfileDataLoading />
                    <ProfileStatisticsLoading />
                    <div className={styles.divider} />
                    <TabsListLoading />
                    <div className={styles.loadingContentContainer}>
                        <PostLoadingTile />
                        <PostLoadingTile />
                    </div>
                </div>
            </div>
        </Content>
    </Page>
)
