import { faStars } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import * as styles from "./payments-empty.css"

export const PaymentsEmpty = () => {
    const { t } = useTranslation(["myProfile"])
    return (
        <div className={styles.container}>
            <div>
                <p
                    style={{ fontSize: vars.font.size.regular }}
                    className={styles.title}
                >
                    {t("tabs.purchased.empty.title")}
                </p>
                <p
                    style={{ fontSize: vars.font.size.regular }}
                    className={styles.description}
                >
                    {t("tabs.purchased.empty.description")}
                </p>
            </div>
            <Button variant="light" className={styles.button} asChild>
                <Link to="/app/feed">
                    <FontAwesomeIcon icon={faStars} className={styles.icon} />{" "}
                    <p style={{ fontSize: vars.font.size.regular }}>
                        {t("tabs.purchased.empty.discoverAction")}
                    </p>
                </Link>
            </Button>
        </div>
    )
}
