import * as A from "fp-ts/Array"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { NavigationBackButton } from "../../components/buttons/navigation-back-button"

import { InfiniteData } from "@tanstack/react-query"
import { getIsAuthorizedAccount } from "api/api-utils"
import classNames from "classnames"
import { PullToRefresh } from "common/pull-to-refresh"
import { Toolbar } from "common/toolbar"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/tabs/tabs"
import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import { DatabaseNotification, Pagination } from "../../api/api-models"
import { DatabaseNotificationId, UserProfileId } from "../../api/branded-types"
import { QueryKeys, QueryKeyValue } from "../../api/query-keys"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import { AllNotificationsTabContent } from "./all-notifications-tab-content"
import { CommentsNotificationsTabContent } from "./comments-notifications-tab-content"
import { FollowersNotificationsTabContent } from "./followers-notifications-tab-content"
import { useCommentNotification } from "./hooks/use-comment-notification"
import { useFollowNotification } from "./hooks/use-follow-notifications"
import { useLikeNotification } from "./hooks/use-like-notifications"
import { useNotificationCenter } from "./hooks/use-notification-center"
import { usePostNotification } from "./hooks/use-post-notification"
import { useReadMultipleNotificationsMutation } from "./hooks/use-read-multiple-notifications-mutation"
import { useSubscriptionNotification } from "./hooks/use-subscription-notifications"
import { useUnreadNotifications } from "./hooks/use-unread-notifications"
import { LikesNotificationsTabContent } from "./likes-notifications-tab-content"
import { NotificationCenterLoadingItem } from "./notification-center-item"
import * as styles from "./notifications-page.css"
import { PostsNotificationsTabContent } from "./posts-notifications-tab-content"
import { SubscribersNotificationsTabContent } from "./subscribers-notifications-tab-content"

export type InfiniteNotificationsData = InfiniteData<{
    data: DatabaseNotification[]
    paging: Pagination
}>

export type UnreadNotificationsData = {
    totalCount: number
}

export type NotificationsPageTab =
    | "All"
    | "Followers"
    | "Subscribers"
    | "Likes"
    | "Comments"
    | "Posts"

type Tab = NotificationsPageTab

export const NotificationsPage: FC = () => {
    const meQuery = useMe()
    const { t } = useTranslation(["notifications"])

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    //TODO: add localization
    const myProfileIdQuery = useMyUserProfileId(isAuthorizedAccount)
    const profileId = useRef<UserProfileId | undefined>(undefined)
    profileId.current = myProfileIdQuery.isSuccess
        ? myProfileIdQuery.data
        : undefined

    const [notificationsTab, setNotificationsTab] = useState<Tab>("All")
    const [userSelectedTabs, setUserSelectedTabs] = useState<
        Set<NotificationsPageTab>
    >(new Set(["All"]))

    const selectedPageQueryKey = useRef<QueryKeyValue | undefined>(undefined)

    selectedPageQueryKey.current = useMemo(
        () =>
            pipe(
                notificationsTab,
                getNotificationCenterQueryKeyByTab,
                O.fromNullable,
                O.fold(
                    () => undefined,
                    f => f(profileId.current),
                ),
            ),
        [notificationsTab],
    )

    const { mutate: readNotifications } = useReadMultipleNotificationsMutation()

    const newNotificationsQuery = useUnreadNotifications(profileId.current)
    const isHaveNewNotifications =
        (newNotificationsQuery.data?.totalCount ?? 0) > 0

    const subscriptionNotificationsQuery = useSubscriptionNotification(
        profileId.current,
    )
    //TODO: make these disabled until the specific filter selected?
    const followNotificationsQuery = useFollowNotification(profileId.current)
    const likeNotificationsQuery = useLikeNotification(profileId.current)
    const commentNotificationsQuery = useCommentNotification(profileId.current)
    const postNotificationsQuery = usePostNotification(profileId.current)

    const allNotificationsQuery = useNotificationCenter(profileId.current)

    const allNotifications = pipe(
        allNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.data),
    )

    const subscriptionNotifications = pipe(
        subscriptionNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.data),
    )

    const followNotifications = pipe(
        followNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.data),
    )

    const likeNotifications = pipe(
        likeNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.data),
    )

    const commentNotifications = pipe(
        commentNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.data),
    )

    const postNotifications = pipe(
        postNotificationsQuery.data?.pages ?? [],
        A.flatMap(page => page.data),
    )

    const allUnreadNotificationIds = useMemo(
        () =>
            allNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [allNotifications],
    )
    const subscriptionUnreadNotificationIds = useMemo(
        () =>
            subscriptionNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [subscriptionNotifications],
    )
    const followUnreadNotificationIds = useMemo(
        () =>
            followNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [followNotifications],
    )
    const likeUnreadNotificationIds = useMemo(
        () =>
            likeNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [likeNotifications],
    )
    const commentUnreadNotificationIds = useMemo(
        () =>
            commentNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [commentNotifications],
    )
    const postUnreadNotificationIds = useMemo(
        () =>
            postNotifications
                .filter(isNotificationUnreadPredicate)
                .map(n => n.id),
        [postNotifications],
    )

    const seenNotificationIds = useRef(new Set<DatabaseNotificationId>())

    seenNotificationIds.current = useMemo(() => {
        const notificationsSet = new Set(allUnreadNotificationIds)
        if (userSelectedTabs.has("Subscribers"))
            subscriptionUnreadNotificationIds.forEach(id =>
                notificationsSet.add(id),
            )
        if (userSelectedTabs.has("Followers"))
            followUnreadNotificationIds.forEach(id => notificationsSet.add(id))
        if (userSelectedTabs.has("Likes"))
            likeUnreadNotificationIds.forEach(id => notificationsSet.add(id))
        if (userSelectedTabs.has("Comments"))
            commentUnreadNotificationIds.forEach(id => notificationsSet.add(id))
        if (userSelectedTabs.has("Posts"))
            postUnreadNotificationIds.forEach(id => notificationsSet.add(id))
        return notificationsSet
    }, [
        allUnreadNotificationIds,
        commentUnreadNotificationIds,
        followUnreadNotificationIds,
        likeUnreadNotificationIds,
        postUnreadNotificationIds,
        subscriptionUnreadNotificationIds,
        userSelectedTabs,
    ])

    const markNotificationsAsRead = useCallback(() => {
        if (seenNotificationIds.current.size === 0) return
        readNotifications({
            notificationIdSet: seenNotificationIds.current,
            profileId: profileId.current,
            selectedPageQueryKey: selectedPageQueryKey.current,
        })
    }, [readNotifications])

    const refreshNotificationsState = async () => {
        markNotificationsAsRead()
        await Promise.allSettled([
            allNotificationsQuery.refetch(),
            followNotificationsQuery.refetch(),
            subscriptionNotificationsQuery.refetch(),
            likeNotificationsQuery.refetch(),
            commentNotificationsQuery.refetch(),
            postNotificationsQuery.refetch(),
            newNotificationsQuery.refetch(),
        ])
    }
    //TODO: has an issue, when continuously navigating to notifications page
    // this function runs both when going into the page and going out of the page
    // (ISSUE ONLY EXISTS IN STRICT MODE, NOT DEPLOYED VERSION)
    useEffect(() => {
        return () => markNotificationsAsRead()
    }, [markNotificationsAsRead])

    return (
        <Page>
            <NavigationBackButton />

            <Toolbar className={styles.toolbar}>
                <h1>
                    Activities{" "}
                    {isHaveNewNotifications ? (
                        <span
                            style={{ fontSize: vars.font.size.regular }}
                            className={styles.newActivities}
                        >
                            {newNotificationsQuery.data?.totalCount} new
                        </span>
                    ) : null}
                </h1>
            </Toolbar>

            <Content
                style={{ paddingTop: 0, paddingInline: 20, paddingBottom: 40 }}
            >
                <PullToRefresh onRefresh={refreshNotificationsState}>
                    <Tabs
                        value={notificationsTab}
                        onValueChange={tab => {
                            setNotificationsTab(tab)
                            setUserSelectedTabs(prev => {
                                const updatedSelection = new Set(prev)
                                updatedSelection.add(tab)
                                return updatedSelection
                            })
                        }}
                    >
                        <TabsList
                            className={classNames(
                                styles.tabsList,
                                allNotifications.length === 0
                                    ? styles.tabsListInvisible
                                    : "",
                            )}
                        >
                            <TabsTrigger<Tab> value="All">All</TabsTrigger>
                            <TabsTrigger<Tab> value="Followers">
                                {t("tabs.followers.title")}
                            </TabsTrigger>
                            <TabsTrigger<Tab> value="Subscribers">
                                {t("tabs.subscriber.title")}
                            </TabsTrigger>
                            <TabsTrigger<Tab> value="Likes">Likes</TabsTrigger>
                            <TabsTrigger<Tab> value="Comments">
                                {t("tabs.comments.title")}
                            </TabsTrigger>
                            <TabsTrigger<Tab> value="Posts">
                                {t("tabs.post.title")}
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="All"
                        >
                            <AllNotificationsTabContent
                                profileId={profileId.current}
                            />
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Followers"
                        >
                            <FollowersNotificationsTabContent
                                profileId={profileId.current}
                            />
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Subscribers"
                        >
                            <SubscribersNotificationsTabContent
                                profileId={profileId.current}
                            />
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Likes"
                        >
                            <LikesNotificationsTabContent
                                profileId={profileId.current}
                            />
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Comments"
                        >
                            <CommentsNotificationsTabContent
                                profileId={profileId.current}
                            />
                        </TabsContent>
                        <TabsContent<Tab>
                            className={styles.tabsContent}
                            value="Posts"
                        >
                            <PostsNotificationsTabContent
                                profileId={profileId.current}
                            />
                        </TabsContent>
                    </Tabs>
                </PullToRefresh>
            </Content>
        </Page>
    )
}

const getNotificationCenterQueryKeyByTab = (tab: Tab) => {
    switch (tab) {
        case "Posts":
            return QueryKeys.postNotifications
        case "Followers":
            return QueryKeys.followNotifications
        case "Likes":
            return QueryKeys.likeNotifications
        case "Comments":
            return QueryKeys.commentNotifications
        case "Subscribers":
            return QueryKeys.subscriptionNotifications
        default: {
            return null
        }
    }
}

const isNotificationUnreadPredicate = (n: DatabaseNotification) =>
    n.notificationType === "NewNotification"

export const NotificationsLoadingPage: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content>
            <div style={{ paddingInline: 20 }}>
                <Skeleton width={196} height={31} borderRadius={16} />
                <div style={{ marginTop: 16 }}>
                    <div className={styles.notificationsList}>
                        <NotificationCenterLoadingItem
                            hasImage
                            className={styles.notificationItem}
                        />
                        <NotificationCenterLoadingItem
                            hasImage
                            className={styles.notificationItem}
                        />
                        <NotificationCenterLoadingItem
                            className={styles.notificationItem}
                        />
                        <NotificationCenterLoadingItem
                            className={styles.notificationItem}
                        />
                        <NotificationCenterLoadingItem
                            className={styles.notificationItem}
                        />
                    </div>
                </div>
            </div>
        </Content>
    </Page>
)

export default NotificationsPage
