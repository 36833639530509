import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"

import { Except } from "type-fest"

import { Toggle, ToggleModel } from "../../components/controls/toggle"
import {
    CookiePreference,
    CookiePreferences,
    UpdateCookiePreference,
} from "./cookie-consent"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerTitle,
} from "../../common/drawer"
import * as styles from "./cookie-consent-options-modal.css"

type CookieConsentOptionsModalModel = {
    open: boolean
    preferences: CookiePreferences
    updatePreferences: <TPreference extends CookiePreference>(
        v: UpdateCookiePreference<TPreference>,
    ) => void
    onDidDismiss: () => void
    onNavigateToPrivacyPolicy: () => void
    onAcceptAllClicked: () => void
    onSaveClicked: () => void
}

export const CookieConsentOptionsModal: FC<
    CookieConsentOptionsModalModel
> = model => {
    const { t } = useTranslation(["modal"])

    return (
        <Drawer open={model.open} onClose={model.onDidDismiss}>
            <DrawerContent className={styles.modal}>
                <DrawerTitle />
                <DrawerDescription />
                <DrawerClose className={styles.modalCloseContainer}>
                    <FontAwesomeIcon
                        className={styles.closeIcon}
                        icon={faXmark}
                    />
                </DrawerClose>

                <div className={styles.content}>
                    <p
                        style={{
                            margin: 0,
                            fontSize: vars.font.size.xl,
                        }}
                        className={styles.title}
                    >
                        {t("cookie.consent.title")}
                    </p>
                    <p
                        style={{
                            margin: "8px 0 0 0",
                            fontSize: vars.font.size.regular,
                        }}
                        className={styles.subtitle}
                    >
                        {t("cookie.consent.subtitle")}{" "}
                        <Link
                            to="/registration/privacy-policy"
                            style={{
                                fontSize: vars.font.size.regular,
                            }}
                            onClick={model.onNavigateToPrivacyPolicy}
                            className={styles.link}
                        >
                            {t("cookie.consent.privacyPolicy")}
                        </Link>
                    </p>
                    <div className={styles.togglesContainer}>
                        <CookieConsentToggle
                            checked={model.preferences.essential}
                            id="essential"
                            disabled
                            onChange={() => {}}
                            text={{
                                title: t("cookie.consent.essential.title"),
                                subtitle: t(
                                    "cookie.consent.essential.subtitle",
                                ),
                            }}
                        />
                        <CookieConsentToggle
                            checked={model.preferences.functional}
                            id="functional"
                            onChange={value =>
                                model.updatePreferences({
                                    key: "functional",
                                    value,
                                })
                            }
                            text={{
                                title: t("cookie.consent.functional.title"),
                                subtitle: t(
                                    "cookie.consent.functional.subtitle",
                                ),
                            }}
                        />
                        <CookieConsentToggle
                            checked={model.preferences.marketing}
                            id="marketing"
                            onChange={value =>
                                model.updatePreferences({
                                    key: "marketing",
                                    value,
                                })
                            }
                            text={{
                                title: t("cookie.consent.marketing.title"),
                                subtitle: t(
                                    "cookie.consent.marketing.subtitle",
                                ),
                            }}
                        />
                    </div>
                </div>

                <DrawerFooter>
                    <div className={styles.buttonGroup}>
                        <Button
                            data-test="accept-all"
                            variant="medium"
                            className={styles.button}
                            onClick={model.onAcceptAllClicked}
                        >
                            {t("cookie.consent.confirm.all")}
                        </Button>
                        <Button
                            variant="light"
                            className={styles.button}
                            onClick={model.onSaveClicked}
                        >
                            {t("cookie.consent.continue")}
                        </Button>
                    </div>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

type CookieConsentToggleModel = Except<
    ToggleModel,
    "containerClassName" | "thumbClassName" | "className" | "label"
> & {
    text: {
        title: string
        subtitle: string
    }
}

//todo: refactor into light mode toggle
const CookieConsentToggle: FC<CookieConsentToggleModel> = model => (
    <Toggle
        checked={model.checked}
        id={model.id}
        disabled={model.disabled}
        containerClassName={styles.toggleContainer}
        thumbClassName={styles.toggleThumb}
        className={styles.toggle}
        label={
            <>
                <p
                    className={styles.toggleTitle}
                    style={{
                        fontSize: vars.font.size.regular,
                        margin: 0,
                    }}
                >
                    {model.text.title}
                </p>
                <p
                    className={styles.toggleSubtitle}
                    style={{
                        margin: 0,
                        fontSize: vars.font.size.regular,
                    }}
                >
                    {model.text.subtitle}
                </p>
            </>
        }
        onChange={model.onChange}
    />
)
