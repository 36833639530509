import { faBell } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import { vars } from "theme/variables.css"
import * as styles from "./empty-activities.css"
export type EmptyActivitiesModel = {
    text: {
        title: string
        description: string
    }
}

export const EmptyActivities: FC<EmptyActivitiesModel> = model => (
    <div className={styles.emptyContainer}>
        <FontAwesomeIcon icon={faBell} className={styles.emptyIcon} />
        <p
            style={{
                fontSize: vars.font.size.m,
            }}
            className={styles.emptyTitle}
        >
            {model.text.title}
        </p>
        <p
            style={{
                fontSize: vars.font.size.regular,
            }}
            className={styles.emptyDescription}
        >
            {model.text.description}
        </p>
    </div>
)
