import {
    infiniteQueryOptions,
    QueryClient,
    useInfiniteQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Video } from "../api/api-models"
import { mapToNotMatchFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { videoSelectorClient } from "../api/clients/video-api-client"
import { QueryKeys } from "../api/query-keys"

const PAGINATION_AMOUNT = 25

type RecommendedVideosInfiniteQueryOptionsParams = {
    followingUserProfileIds: UserProfileId[]
    queryClient: QueryClient
    profileId?: UserProfileId
}

export const recommendedVideosInfiniteQueryOptions = (
    params: RecommendedVideosInfiniteQueryOptionsParams,
) =>
    infiniteQueryOptions({
        initialPageParam: -1,
        queryFn: ({ pageParam }) =>
            listRecommendedVideos({
                pageParam,
                profileId: params.profileId,
                followingUserProfileIds: params.followingUserProfileIds,
                queryClient: params.queryClient,
            }),
        queryKey: QueryKeys.recommendedVideos(params.profileId),
        getNextPageParam: lastPage => {
            //returning undefined switches `hasNextPage` to false
            if (lastPage.data.length !== PAGINATION_AMOUNT) return undefined

            return lastPage.paging.type === "Index"
                ? (lastPage.paging.index ?? -1) + PAGINATION_AMOUNT
                : -1
        },
        enabled: !!params.followingUserProfileIds,
    })

export const useRecommendedVideos = (
    followingUserProfileIds: UserProfileId[],
    profileId?: UserProfileId,
) => {
    const queryClient = useQueryClient()

    return useInfiniteQuery(
        recommendedVideosInfiniteQueryOptions({
            queryClient,
            followingUserProfileIds,
            profileId,
        }),
    )
}
const listRecommendedVideos = async ({
    pageParam,
    profileId,
    followingUserProfileIds,
    queryClient,
}: {
    pageParam: number
    profileId?: UserProfileId
    followingUserProfileIds: UserProfileId[]
    queryClient: QueryClient
}) => {
    const profileIds = profileId
        ? [...(followingUserProfileIds ?? []), profileId]
        : (followingUserProfileIds ?? [])

    const videos = await videoSelectorClient.listVideos({
        filter: `{${mapToNotMatchFilterDc("creatorId", profileIds, 'mimeType: !"" and !"unknown/unknown"')}, $sort:{createdAt: desc}}`,
        paging: {
            type: "Index",
            direction: "After",
            limit: PAGINATION_AMOUNT,
            index: pageParam,
        },
    })

    videos.data.forEach(data =>
        queryClient.setQueryData<{ data: Video; error: undefined }>(
            QueryKeys.video(data.id),
            { data, error: undefined },
        ),
    )

    return videos
}
