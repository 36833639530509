import { FC } from "react"

import { useAppStore } from "store"
import { FindOutMoreModal, FindOutMoreModalModel } from "./find-out-more-modal"

export const OnboardingProvider: FC<{ loc: FindOutMoreModalModel["loc"] }> = ({
    loc,
}) => {
    const openModal = useAppStore(store => store.openModal)
    const closeModal = useAppStore(store => store.closeModal)
    const isModalVisible = useAppStore(store => store.isModalVisible)

    return (
        <FindOutMoreModal
            loc={loc}
            open={isModalVisible.FindOutMore.visible}
            onOpenChange={open =>
                !open
                    ? closeModal("FindOutMore")
                    : openModal({ type: "FindOutMore" })
            }
        />
    )
}
