import { faHexagonExclamation } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC } from "react"
import { vars } from "theme/variables.css"
import * as styles from "./alert-section.css"

type AlertSectionProps = JSX.IntrinsicElements["div"]

export const AlertSection: FC<AlertSectionProps> = ({
    children,
    className,
    ...props
}) => {
    return (
        <div className={classNames(styles.alertSection, className)} {...props}>
            <FontAwesomeIcon
                className={styles.exclamationIcon}
                icon={faHexagonExclamation}
                color={vars.color.secondary.hex}
            />
            <div className={styles.alertSectionInner}>{children}</div>
        </div>
    )
}
