/* eslint-disable functional/no-try-statements */

import { ADTType, makeADT, ofType } from "@morphic-ts/adt"

import {
    NotificationClickActionDocument,
    NotificationSubscription,
    VapidNotificationSubscription,
} from "../../api/api-models"
import { UserProfileId } from "../../api/branded-types"
import { notificationCreatorClient } from "../../api/clients/notification-api-client"
import { PUSH_VAPID } from "../../envs"

type Default = {
    type: "Default"
}

type PermissionGranted = {
    type: "PermissionGranted"
}

type PermissionDenied = {
    type: "PermissionDenied"
}

type NotificationsNotSupported = {
    type: "NotificationsNotSupported"
}

export const NotificationPermissionStateAdt = makeADT("type")({
    Default: ofType<Default>(),
    PermissionGranted: ofType<PermissionGranted>(),
    PermissionDenied: ofType<PermissionDenied>(),
    NotificationsNotSupported: ofType<NotificationsNotSupported>(),
})

export type NotificationPermissionState = ADTType<
    typeof NotificationPermissionStateAdt
>

export const getNotificationPermission = () => {
    if (!("Notification" in window)) {
        return NotificationPermissionStateAdt.as.NotificationsNotSupported({})
    }
    switch (Notification.permission) {
        case "default":
            return NotificationPermissionStateAdt.as.Default({})
        case "denied":
            return NotificationPermissionStateAdt.as.PermissionDenied({})
        case "granted":
            return NotificationPermissionStateAdt.as.PermissionGranted({})
        default:
            return NotificationPermissionStateAdt.as.NotificationsNotSupported(
                {},
            )
    }
}

export async function requestNotificationPermission() {
    if (!("Notification" in window)) {
        return NotificationPermissionStateAdt.as.NotificationsNotSupported({})
    } else {
        const permission = await Notification.requestPermission()
        return permission === "granted"
            ? NotificationPermissionStateAdt.as.PermissionGranted({})
            : NotificationPermissionStateAdt.as.PermissionDenied({})
    }
}

export const subscribeUserToPush = async (
    swRegistration: ServiceWorkerRegistration,
) => {
    try {
        const subscription = await swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(PUSH_VAPID),
        })
        return BrowserPushInfoAdt.as.SubscriptionSuccess({
            data: subscription,
        })
    } catch (error) {
        console.error({ error })
        return BrowserPushInfoAdt.as.SubscriptionError({
            error: error as string,
        })
    }
}

export const getPushRegistration = async (
    swRegistration: ServiceWorkerRegistration,
) => {
    try {
        const subscription = await swRegistration.pushManager.getSubscription()
        if (!subscription)
            return BrowserPushInfoAdt.as.SubscriptionError({
                error: "No subscription found",
            })
        return BrowserPushInfoAdt.as.SubscriptionSuccess({
            data: subscription,
        })
    } catch (error) {
        console.error({ error })
        return BrowserPushInfoAdt.as.SubscriptionError({
            error: error as string,
        })
    }
}

type SubscriptionSuccess = {
    type: "SubscriptionSuccess"
    data: PushSubscription
}

type SubscriptionError = {
    type: "SubscriptionError"
    error: string
}

export const BrowserPushInfoAdt = makeADT("type")({
    SubscriptionSuccess: ofType<SubscriptionSuccess>(),
    SubscriptionError: ofType<SubscriptionError>(),
})

export type BrowserPushInfo = ADTType<typeof BrowserPushInfoAdt>

type PushSubscriptionAvailable = {
    type: "PushSubscriptionAvailable"
    subscription: NotificationSubscription
}

type PushSubscriptionNotAvailable = {
    type: "PushSubscriptionNotAvailable"
}

export const PushSubscriptionAdt = makeADT("type")({
    PushSubscriptionAvailable: ofType<PushSubscriptionAvailable>(),
    PushSubscriptionNotAvailable: ofType<PushSubscriptionNotAvailable>(),
})

export type PushSubscriptionState = ADTType<typeof PushSubscriptionAdt>

export type CreatePushSubscriptionParams = {
    subscription: PushSubscription
    profileId: UserProfileId
}

export const createPushSubscription = async ({
    subscription,
    profileId,
}: CreatePushSubscriptionParams) => {
    const auth = arrayBufferToBase64(subscription.getKey("auth")!)
    const p256dh = arrayBufferToBase64(subscription.getKey("p256dh")!)
    const endpoint = subscription.endpoint

    const createdSubscription =
        await notificationCreatorClient.createNotificationSubscription({
            type: "Vapid",
            auth,
            endpoint,
            p256dh,
            profileId,
        })
    return createdSubscription
}

export const urlBase64ToUint8Array = (base64String: string) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding)
        // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, "+")
        .replace(/_/g, "/")

    const rawData = atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = ""
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export const vapidNotificationPredicate = (
    n: NotificationSubscription,
): n is VapidNotificationSubscription => n.type === "Vapid"

export type KeyToRouteMapping = Record<string, (...args: string[]) => string>

//! TODO: there exists duplication in service-worker.ts, do not forget to update
const keyToRouteMapping: KeyToRouteMapping = {
    "video/comment": (videoId: string, _commentId: string) =>
        `/app/video/viewer/${videoId}`,
    "video/like": (videoId: string, _likeId: string) =>
        `/app/video/viewer/${videoId}`,
    "video/profile": (videoId: string, _profileId: string) =>
        `/app/video/viewer/${videoId}`,
    video: (videoId: string) => `/app/video/viewer/${videoId}`,
    "postit/comment": (postitId: string, _commentId: string) =>
        `/app/postit/viewer/${postitId}`,
    "postit/like": (postitId: string, _likeId: string) =>
        `/app/postit/viewer/${postitId}`,
    "postit/profile": (postitId: string, _profileId: string) =>
        `/app/postit/viewer/${postitId}`,
    postit: (postitId: string) => `/app/postit/viewer/${postitId}`,
    profile: (profileId: string) => `/app/user-profile/${profileId}`,
    "profile/postit": (_profileId: string, postitId: string) =>
        `/app/postit/viewer/${postitId}`,
    "profile/video": (_profileId: string, videoId: string) =>
        `/app/video/viewer/${videoId}`,
    liveevent: (liveEventId: string) => `/app/live-event/viewer/${liveEventId}`,
    "liveevent/profile": (liveEventId: string, _profileId: string) =>
        `/app/live-event/viewer/${liveEventId}`,
    "message/profile": (_messageId: string, profileId: string) =>
        `/app/message/profile/${profileId}`,
} as const

export const generateDataByClickAction = (clickAction?: string) => {
    let actionDocument: NotificationClickActionDocument | null = null
    if (clickAction) {
        let jsonClickAction = null
        try {
            jsonClickAction = JSON.parse(clickAction)
        } catch (e) {
            console.error(e)
        }
        const notificationClickActionDocument =
            NotificationClickActionDocument.safeParse(jsonClickAction)
        if (notificationClickActionDocument.success)
            actionDocument = notificationClickActionDocument.data
        else {
            console.error(notificationClickActionDocument.error.issues)
        }
    }

    if (actionDocument === null) throw Error("Incorrect data provided")

    const sortedDocumentKeys = Object.keys(actionDocument).sort()

    const key = sortedDocumentKeys
        .map(k => actionDocument[k].contentType.toLowerCase())
        .join("/")
    const ids = sortedDocumentKeys.map(k => actionDocument[k].contentId)
    return {
        key,
        ids,
    }
}

export const generateDataByClickActionDocument = (
    clickActionDocument?: NotificationClickActionDocument,
) => {
    if (!clickActionDocument) throw Error("Incorrect data provided")

    const sortedDocumentKeys = Object.keys(clickActionDocument).sort()

    const key = sortedDocumentKeys
        .map(k => clickActionDocument[k].contentType.toLowerCase())
        .join("/")
    const ids = sortedDocumentKeys.map(k => clickActionDocument[k].contentId)
    return {
        key,
        ids,
    }
}

export const getNavigateUrl = (clickAction?: string) => {
    const { key, ids } = generateDataByClickAction(clickAction)

    if (!(key in keyToRouteMapping)) {
        console.error(`Invalid key "${key}"`)
        return new Error(`Invalid key "${key}"`)
    }

    const templateFunc = keyToRouteMapping[key]
    return templateFunc(...ids)
}

export const getNavigateUrlByActionDocument = (
    clickAction?: NotificationClickActionDocument,
) => {
    const { key, ids } = generateDataByClickActionDocument(clickAction)

    if (!(key in keyToRouteMapping)) {
        console.error(`Invalid key "${key}"`)
        return new Error(`Invalid key "${key}"`)
    }

    const templateFunc = keyToRouteMapping[key]
    return templateFunc(...ids)
}

const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.register(
                "/service-worker.js",
                { scope: "/" },
            )
            console.log("Service Worker registered successfully:", registration)
            return registration
        } catch (error) {
            console.error("Service Worker registration failed:", error)
            return null
        }
    }

    return null
}

const checkServiceWorkerRegistration = async () => {
    if ("serviceWorker" in navigator) {
        try {
            return await navigator.serviceWorker.getRegistration()
        } catch (error) {
            console.error("Error checking Service Worker registration:", error)
        }
    }
    return null
}

export const getCurrentRegistration = async () => {
    const currentRegistration = await checkServiceWorkerRegistration()
    if (!currentRegistration) {
        const newRegistration = await registerServiceWorker()
        return newRegistration
    }
    return currentRegistration
}

export const getCurrentPushSubscription = async () => {
    const registration = await checkServiceWorkerRegistration()
    if (!registration) return null
    return registration.pushManager.getSubscription()
}
