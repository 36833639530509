import classNames from "classnames"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { isEmpty } from "fp-ts/string"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { NavigationBackButton } from "../../components/buttons/navigation-back-button"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/tabs/tabs"
import { useImagePostsSearch } from "../../hooks/use-image-post-search"
import { useLiveEventsSearch } from "../../hooks/use-live-event-search"
import { useTextPostsSearch } from "../../hooks/use-text-post-search"
import { useUserProfilesSearch } from "../../hooks/use-user-profiles-search"
import { useVideosSearch } from "../../hooks/use-videos-search"
import { LocalStorage } from "../../local-storage"
import { UserSearchResultSectionLoading } from "./users/tile/user-search-result-tile-list"
import { PostLoadingSection } from "./videos/post-loading-tile"

import Skeleton from "react-loading-skeleton"
import { useDebounce } from "use-debounce"
import { Content } from "../../common/content"
import { Page } from "../../common/page"
import { PullToRefresh } from "../../common/pull-to-refresh"
import { useMyUserProfileId } from "../../hooks/use-my-user-profiles"
import * as styles from "./search-page.css"

import { SearchBar } from "common/searchbar"
import { useMe } from "hooks/use-me"
import { useAppStore } from "store"
import { getIsAuthorizedAccount } from "../../api/api-utils"
import { SearchPageAllTabContent } from "./search-page-all-tab-content"
import { SearchPageImageTabContent } from "./search-page-image-tab-content"
import { SearchPageLiveEventTabContent } from "./search-page-live-event-tab-content"
import { SearchPageProfileTabContent } from "./search-page-profile-tab-content"
import { SearchPageTextTabContent } from "./search-page-text-tab-content"
import { SearchPageVideoTabContent } from "./search-page-video-tab-content"
import { SearchPageTab, useSearchStore } from "./store/search-store"

export const SearchPage: FC = () => {
    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { t } = useTranslation(["search"])
    const setScrollSearchToTop = useAppStore(
        store => store.setScrollSearchToTop,
    )

    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const searchStore = useSearchStore()

    const [debouncedSearchKey] = useDebounce(searchStore.searchKey, 250)
    const [searchFocused, setSearchFocused] = useState(false)

    const userProfilesSearchQuery = useUserProfilesSearch({
        searchKey: debouncedSearchKey,
        profileId,
    })

    const creatorIds =
        userProfilesSearchQuery.data?.pages
            .flatMap(page => page.data)
            .map(item => item.id) ?? []

    const userDependandQueriesEnabled =
        (!!debouncedSearchKey && !userProfilesSearchQuery.isLoading) ||
        !debouncedSearchKey

    const videosQuerySearchQuery = useVideosSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })
    const liveEventQuerySearchQuery = useLiveEventsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })
    const imagePostsQuerySearchQuery = useImagePostsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })
    const textPostsQuerySearchQuery = useTextPostsSearch({
        searchKey: debouncedSearchKey,
        profileId,
        creatorIds,
        enabled: userDependandQueriesEnabled,
    })

    const isSearchKeyEmpty = isEmpty(debouncedSearchKey)
    const isSearchKeyEmptyRealTime = isEmpty(searchStore.searchKey)

    const contentRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<HTMLInputElement>(null)

    const scrollTop = useCallback(() => {
        if (!contentRef?.current) return
        contentRef.current.scrollTop = 0
        // Prevent unwanted scroll restoration
        setTimeout(() => {
            if (contentRef.current) {
                contentRef.current.style.scrollBehavior = "smooth"
                contentRef.current.scrollTop = 0
            }
        }, 50)
    }, [contentRef.current])

    const resetSearch = useCallback(() => {
        scrollTop()
        searchStore.setSearchKey("")
        searchStore.setSearchTab("All")
    }, [scrollTop])

    const refreshSearchPage = async () => {
        await Promise.allSettled([
            userProfilesSearchQuery.refetch(),
            videosQuerySearchQuery.refetch(),
            liveEventQuerySearchQuery.refetch(),
            imagePostsQuerySearchQuery.refetch(),
            textPostsQuerySearchQuery.refetch(),
        ])
    }

    useEffect(() => {
        setScrollSearchToTop(async () => {
            scrollTop()
            await refreshSearchPage()
        })
    }, [setScrollSearchToTop])

    return (
        <Page className={styles.page}>
            <SearchBar
                outerClassName={styles.searchbarWrapper}
                inputMode="search"
                autoCapitalize="off"
                ref={searchRef}
                placeholder={searchFocused ? "" : t("search.placeholder")}
                value={searchStore.searchKey}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                clearInput
                cancelButtonText={t("cancel")}
                onClear={resetSearch}
                onCancel={() => {
                    resetSearch()
                    scrollTop()
                }}
                onChange={flow(
                    e => e.target.value,
                    key => {
                        //set scroll position so user's UI is not jumping and he sees all the content
                        key.length !== 0 && scrollTop()
                        // when input is empty user might be stuck in different tab
                        key.length === 0 && searchStore.setSearchTab("All")
                        searchStore.setSearchKey(key)
                    },
                )}
                showCancelButton={!isSearchKeyEmptyRealTime}
            />

            <Tabs
                value={searchStore.searchTab}
                onValueChange={searchStore.setSearchTab}
            >
                <TabsList
                    className={classNames(
                        styles.tabsListContainer,
                        styles.tabsList,
                        !isSearchKeyEmptyRealTime ? styles.tabsListVisible : "",
                    )}
                >
                    <TabsTrigger<SearchPageTab> value="All">
                        {t("tabs.all.name")}
                    </TabsTrigger>
                    <TabsTrigger<SearchPageTab> value="Profile">
                        {t("tabs.profile.name")}
                    </TabsTrigger>
                    <TabsTrigger<SearchPageTab> value="LiveEvent">
                        {t("tabs.liveStream.name")}
                    </TabsTrigger>
                    <TabsTrigger<SearchPageTab> value="Image">
                        {t("tabs.image.name")}
                    </TabsTrigger>
                    <TabsTrigger<SearchPageTab> value="Video">
                        {t("tabs.video.name")}
                    </TabsTrigger>
                    <TabsTrigger<SearchPageTab> value="Text">
                        {t("tabs.text.name")}
                    </TabsTrigger>
                </TabsList>

                <Content
                    style={{
                        padding: 0,
                        paddingInline: 20,
                        paddingBottom: 40,
                    }}
                    ref={contentRef}
                >
                    <PullToRefresh onRefresh={refreshSearchPage}>
                        <div className={styles.divider} />

                        <TabsContent<SearchPageTab>
                            value="All"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <SearchPageAllTabContent
                                searchKey={searchStore.searchKey}
                                debouncedSearchKey={debouncedSearchKey}
                                setSearchTab={searchStore.setSearchTab}
                                profileId={profileId}
                            />
                        </TabsContent>

                        <TabsContent<SearchPageTab>
                            value="Profile"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <SearchPageProfileTabContent
                                searchKey={searchStore.searchKey}
                                debouncedSearchKey={debouncedSearchKey}
                                className={styles.section}
                                profileId={profileId}
                            />
                        </TabsContent>

                        <TabsContent<SearchPageTab>
                            value="Video"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <SearchPageVideoTabContent
                                className={classNames(
                                    styles.section,
                                    styles.postContentSection,
                                )}
                                searchKey={searchStore.searchKey}
                                debouncedSearchKey={debouncedSearchKey}
                                profileId={profileId}
                                userDependandQueriesEnabled={
                                    userDependandQueriesEnabled
                                }
                                creatorIds={creatorIds}
                            />
                        </TabsContent>

                        <TabsContent<SearchPageTab>
                            value="LiveEvent"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <SearchPageLiveEventTabContent
                                searchKey={searchStore.searchKey}
                                debouncedSearchKey={debouncedSearchKey}
                                profileId={profileId}
                                creatorIds={creatorIds}
                                className={styles.section}
                            />
                        </TabsContent>
                        <TabsContent<SearchPageTab>
                            value="Image"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <SearchPageImageTabContent
                                className={classNames(
                                    styles.section,
                                    styles.postContentSection,
                                )}
                                searchKey={searchStore.searchKey}
                                debouncedSearchKey={debouncedSearchKey}
                                profileId={profileId}
                                userDependandQueriesEnabled={
                                    userDependandQueriesEnabled
                                }
                                creatorIds={creatorIds}
                            />
                        </TabsContent>
                        <TabsContent<SearchPageTab>
                            value="Text"
                            className={classNames(
                                styles.contentContainer,
                                !isSearchKeyEmpty
                                    ? styles.contentContainerWithTabBar
                                    : "",
                            )}
                        >
                            <SearchPageTextTabContent
                                className={classNames(
                                    styles.section,
                                    styles.postContentSection,
                                )}
                                searchKey={searchStore.searchKey}
                                debouncedSearchKey={debouncedSearchKey}
                                profileId={profileId}
                                userDependandQueriesEnabled={
                                    userDependandQueriesEnabled
                                }
                                creatorIds={creatorIds}
                            />
                        </TabsContent>
                    </PullToRefresh>
                </Content>
            </Tabs>
        </Page>
    )
}

export const SearchPageLoading: FC = () => (
    <Page>
        <NavigationBackButton />
        <Content className={styles.loadingContent}>
            <Skeleton width={196} height={30} borderRadius={16} />
            <Skeleton
                style={{ marginTop: 18, marginBottom: 20 }}
                height={36}
                width={"100%"}
                borderRadius={10}
            />
            <div style={{ marginBottom: 24 }} className={styles.divider} />
            <UserSearchResultSectionLoading />
            <div style={{ marginTop: 24 }} />
            <PostLoadingSection />
        </Content>
    </Page>
)

export default SearchPage
