import { create } from "zustand"

export type SearchPageTab =
    | "All"
    | "Profile"
    | "LiveEvent"
    | "Image"
    | "Video"
    | "Text"

type State = {
    searchKey: string
    searchTab: SearchPageTab
}

type Actions = {
    setSearchKey: (key: State["searchKey"]) => void
    setSearchTab: (tab: State["searchTab"]) => void
}

export const useSearchStore = create<State & Actions>()(set => ({
    searchKey: "",
    searchTab: "All",
    setSearchKey: key => set({ searchKey: key }),
    setSearchTab: tab => set({ searchTab: tab }),
}))
