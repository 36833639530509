import { QueryClient, queryOptions } from "@tanstack/react-query"
import { LiveEvent } from "api/api-models"
import { mapToMatchFilterDc } from "api/api-utils"
import { LiveEventId } from "api/branded-types"
import { liveEventSelectorClient } from "api/clients/live-event-api-client"
import { QueryKeys } from "api/query-keys"

export const listLiveEvents = async (
    ids: LiveEventId[],
    queryClient: QueryClient,
) => {
    const uniqueIds = Array.from(new Set(ids))

    const liveEventsRes = await liveEventSelectorClient.listLiveEvents({
        filter: `{${mapToMatchFilterDc("id", uniqueIds)}}`,
        paging: {
            type: "Index",
            direction: "After",
            index: -1,
            limit: uniqueIds.length,
        },
    })
    liveEventsRes.data.forEach(data =>
        queryClient.setQueryData<{ data: LiveEvent; error: undefined }>(
            QueryKeys.liveEvent(data.id),
            { data, error: undefined },
        ),
    )
    return liveEventsRes.data
}

export const listLiveEventsQueryOptions = (
    pageNumber: number,
    ids: LiveEventId[],
    queryClient: QueryClient,
) =>
    queryOptions({
        queryKey: QueryKeys.listLiveEvents(pageNumber, ids),
        queryFn: () => listLiveEvents(ids, queryClient),
        enabled: ids.length > 0,
    })
