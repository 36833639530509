import { useQuery } from "@tanstack/react-query"

import { UserProfileId } from "api/branded-types"
import { paymentCreatorClient } from "api/clients/payment-api-client"
import { QueryKeys } from "api/query-keys"

export const useIsSubscriptionSupported = (profileId?: UserProfileId) => {
    return useQuery({
        queryFn: () => getSubscriptionSupported(profileId),
        queryKey: QueryKeys.subscriptionSupported(profileId),
        enabled: !!profileId,
    })
}

const getSubscriptionSupported = async (profileId?: UserProfileId) => {
    if (!profileId) throw new Error("ProfileId should be defined")
    const res = await paymentCreatorClient.subscriptionSupported({
        params: { profileId },
    })

    return {
        isAvailable: res.data,
    }
}
