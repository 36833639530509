import { useQuery } from "@tanstack/react-query"
import { LiveEventId } from "api/branded-types"
import { liveEventSelectorClient } from "api/clients/live-event-api-client"
import { QueryKeys } from "api/query-keys"

export const useLiveEventStatistics = (
    liveEventId?: LiveEventId,
    refetchInterval?: number,
) => {
    return useQuery({
        queryFn: () => getLiveEventStatisticsById(liveEventId),
        queryKey: QueryKeys.listliveEventStatistics(liveEventId),
        enabled: !!liveEventId,
        refetchInterval,
    })
}

export const getLiveEventStatisticsById = async (liveEventId?: LiveEventId) => {
    if (!liveEventId) throw new Error("LiveEventId is required")
    const res = await liveEventSelectorClient.getLiveEventStatisticById({
        params: { id: liveEventId },
    })
    return res.data
}
