import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "common/dialog"
import { useAsset } from "hooks/use-asset"
import { usePointSystemModalStore } from "./store/point-system-modal-store"

import { ParticleAnimation } from "./particles-animation/particles-animation"

import { Link } from "@tanstack/react-router"
import { Button } from "common/button"
import { SvgImage } from "common/svg-image"
import { useTranslation } from "react-i18next"
import { vars } from "theme/variables.css"
import * as styles from "./challenge-complete-modal.css"

type ChallengeCompleteModalModel = {
    open: boolean
    onOpenChange: (v: boolean) => void
}

export const ChallengeCompleteModal: FC<
    ChallengeCompleteModalModel
> = model => {
    const { t: tCommon } = useTranslation(["common"])
    const { t } = useTranslation(["pointSystemInvite"])

    const challengeCompleteModalState = usePointSystemModalStore(
        store => store.challengeCompleteModalState,
    )

    const assetQuery = useAsset({
        resource: challengeCompleteModalState?.icon,
    })

    return (
        <Dialog open={model.open} onOpenChange={model.onOpenChange}>
            {model.open && <ParticleAnimation />}
            <DialogContent className={styles.content}>
                <div>
                    <div className={styles.iconWrapper}>
                        <div className={styles.iconContainer}>
                            <div>
                                <SvgImage
                                    color={vars.color.medium.hex}
                                    width={
                                        assetQuery.isSuccess &&
                                        assetQuery.data.type === "Image"
                                            ? assetQuery.data.resolution.width
                                            : 28
                                    }
                                    height={
                                        assetQuery.isSuccess &&
                                        assetQuery.data.type === "Image"
                                            ? assetQuery.data.resolution.height
                                            : 28
                                    }
                                    className={styles.icon}
                                    src={
                                        assetQuery.isSuccess
                                            ? assetQuery.data.source
                                            : undefined
                                    }
                                />
                            </div>
                            <FontAwesomeIcon
                                className={styles.hornIcon}
                                icon={faPartyHorn}
                            />
                        </div>
                    </div>
                    <div className={styles.textContainer}>
                        <DialogTitle
                            style={{
                                fontSize: vars.font.size.xl,
                                margin: 0,
                                fontWeight: 500,
                            }}
                        >
                            {challengeCompleteModalState?.title}
                        </DialogTitle>
                        <DialogDescription
                            style={{
                                margin: 0,
                                fontSize: vars.font.size.regular,
                            }}
                        >
                            {challengeCompleteModalState?.description}
                        </DialogDescription>
                    </div>
                    <div className={styles.rewardContainer}>
                        <p className={styles.rewardText}>
                            {`+${challengeCompleteModalState?.points} ${tCommon("points")}`}
                        </p>
                    </div>
                    <div className={styles.additionalInfoContainer}>
                        <p
                            style={{
                                fontWeight: 700,
                                fontSize: vars.font.size.regular,
                                margin: 0,
                            }}
                        >
                            {t("modal.challenge.info")}
                        </p>
                        <p
                            style={{
                                fontWeight: 400,
                                fontSize: vars.font.size.regular,
                                margin: 0,
                            }}
                        >
                            {t("modal.challenge.description", {
                                badgeName:
                                    challengeCompleteModalState?.badgeName,
                            })}
                        </p>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <Button
                            className={styles.button}
                            color="medium"
                            asChild
                            onClick={() => {
                                model.onOpenChange(false)
                                //todo: define what path this button should navigate to
                            }}
                        >
                            <Link
                                to="/app/settings/rewards"
                                search={{ currentTab: "get-started" }}
                            >
                                {t("modal.challenge.close")}
                            </Link>
                        </Button>
                        <Button
                            variant="ghost"
                            className={styles.ghostButton}
                            onClick={() => model.onOpenChange(false)}
                        >
                            {t("modal.challenge.confirm")}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
