import { useQuery } from "@tanstack/react-query"

import { mapToMatchFilterDc } from "../api/api-utils"
import { UserProfileId } from "../api/branded-types"
import { userProfileSelectorClient } from "../api/clients/user-profile-api-client"
import { QueryKeys } from "../api/query-keys"

export type UseIsAlreadySubscribingModel = {
    myId?: UserProfileId
    idToSubscribe?: UserProfileId
}

export const isAlreadySubscribing = async (
    myId?: UserProfileId,
    id?: UserProfileId,
) => {
    if (!id) throw new Error("UserProfileId is required")
    if (!myId) throw new Error("My UserProfileId is required")

    const res = await userProfileSelectorClient.listSubscriberOfUserProfileId(
        { filter: `{${mapToMatchFilterDc("id", [myId])}}` },
        { params: { id } },
    )

    return res.data.length > 0
}

export const useIsAlreadySubscribing = (
    param: UseIsAlreadySubscribingModel,
) => {
    return useQuery({
        queryFn: () => isAlreadySubscribing(param.myId, param.idToSubscribe),
        //TODO: utilize param.myId when profile switching is possible
        queryKey: QueryKeys.hasProfileSubscription(param.idToSubscribe),
        enabled: !!param.myId && !!param.idToSubscribe,
    })
}
