import { makeApi } from "@zodios/core"
import z from "zod"

import { ApiError, PaymentConnectionState } from "../api-models"
import { Url } from "../branded-types"

export const paymentCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/payment/connect",
        alias: "connectToPaymentProvider",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    redirects: z.object({
                        succeeded: Url,
                        failed: Url,
                    }),
                }),
            },
        ],
        response: z.object({
            data: Url,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/payment/connection",
        alias: "getPaymentProviderConnection",
        response: z.object({
            data: PaymentConnectionState,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "patch",
        path: "/v1/payment/:id/cancel",
        alias: "cancelPaymentById",
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/payment/:id/checkout",
        alias: "getPaymentCheckoutLinkById",
        response: z.object({
            data: Url,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/payment/disconnect",
        alias: "disconnectFromPaymentProvider",
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/available/:userId/:profileId",
        alias: "paymentAvailable",
        response: z.object({
            data: z.boolean(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/profile/:profileId/subscription/supported",
        alias: "subscriptionSupported",
        response: z.object({
            data: z.boolean(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
