import { makeApi } from "@zodios/core"
import z from "zod"

import {
    AccountType,
    ApiError,
    ListUsersResponse,
    Pagination,
    User,
    UserPaymentInfo,
} from "../api-models"
import { UserId } from "../branded-types"

export const userSelectorApi = makeApi([
    {
        method: "post",
        path: "/v1/users",
        alias: "listUsers",
        parameters: [
            {
                type: "Body",
                name: "body",
                schema: z.object({
                    // TODO create typed meta filter instead of string
                    filter: z.string().optional(),
                    paging: Pagination.optional(),
                }),
            },
        ],
        response: ListUsersResponse,
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/user/me",
        alias: "getMe",
        response: z.object({
            data: User,
            accountType: z.optional(AccountType).default("Anonymous"),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/user/:id",
        alias: "getUserById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: UserId,
            },
        ],
        response: z.object({
            data: User,
            accountType: z.optional(AccountType).default("Anonymous"),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/username/:username/exists",
        alias: "isUsernameTaken",
        parameters: [
            {
                name: "username",
                type: "Path",
                schema: z.string(),
            },
        ],
        response: z.object({
            data: z.boolean(),
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "get",
        path: "/v1/user/me/payment",
        alias: "getUserPaymentInfo",
        response: z.object({
            data: UserPaymentInfo,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
