"use client"

import { faCheck } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import classNames from "classnames"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"
import * as styles from "./circle-checkbox.css"

export const CircleCheckbox = forwardRef<
    ElementRef<typeof CheckboxPrimitive.Root>,
    ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={classNames(styles.checkbox, className)}
        {...props}
    >
        <CheckboxPrimitive.Indicator className={styles.checkIconContainer}>
            <FontAwesomeIcon icon={faCheck} size="sm" />
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
))
