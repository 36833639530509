import { ADTType, makeADT, ofType } from "@morphic-ts/adt"
import classNames from "classnames"
import { pipe } from "fp-ts/function"
import { FC, PropsWithChildren } from "react"

import { None } from "../../data-flow/common"

import { faPause, faPlay, faStop } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as styles from "./play-state-control.css"

export type Playing = { type: "Playing" }
export type Pausing = { type: "Pausing" }
export type Ended = { type: "Ended" }

export const PlayStateAdt = makeADT("type")({
    None: ofType<None>(),
    Playing: ofType<Playing>(),
    Pausing: ofType<Pausing>(),
    Ended: ofType<Ended>(),
})
export type PlayState = ADTType<typeof PlayStateAdt>

export type PlayPauseControlModel = {
    className?: string
    playState: PlayState
    onChanged: (playing: PlayState) => void
}

export const PlayPauseControl: FC<PropsWithChildren<PlayPauseControlModel>> = ({
    className = "",
    playState,
    children,
    onChanged,
}) => (
    <div
        className={classNames(styles.control, className)}
        onClick={() => {
            // whole screen for user's selection because
            // control disappears after playing
            if (playState.type !== "Playing") return
            onChanged(playState)
        }}
    >
        {pipe(
            playState,
            PlayStateAdt.matchStrict({
                None: () => (
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faPlay}
                        onClick={() => onChanged(playState)}
                        color="white"
                    />
                ),
                Pausing: () => (
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faPlay}
                        onClick={() => onChanged(playState)}
                        color="white"
                    />
                ),
                Playing: () => (
                    <FontAwesomeIcon
                        className={styles.fadeOutButton}
                        icon={faPause}
                        onClick={() => onChanged(playState)}
                        color="white"
                    />
                ),
                Ended: () => (
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faStop}
                        onClick={() => onChanged(playState)}
                        color="white"
                    />
                ),
            }),
        )}

        {children}
    </div>
)
