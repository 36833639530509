import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
    faArrowUpFromSquare,
    faLinkHorizontal,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FC } from "react"

import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "common/drawer"
import { vars } from "theme/variables.css"
import * as styles from "./share-overview-modal.css"

export type ShareOverviewModalModel = {
    title: string
    open: boolean
    canShare: boolean
    onCopyLinkClicked: () => void
    onShareToClicked: () => void
    onOpenChange: (v: boolean) => void
}

export const ShareOverviewModal: FC<ShareOverviewModalModel> = ({
    title,
    open,
    canShare,
    onCopyLinkClicked,
    onShareToClicked,
    onOpenChange,
}) => (
    <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent className={styles.modal}>
            <div className={styles.header}>
                <DrawerTitle className={styles.title}>{title}</DrawerTitle>
                <DrawerDescription />
            </div>

            <div className={styles.wrapper}>
                <ActionTile
                    title="share to"
                    className={styles.shareIcon}
                    isDisabled={!canShare}
                    icon={faArrowUpFromSquare}
                    onClicked={() => {
                        onOpenChange(false)
                        onShareToClicked()
                    }}
                />
                <ActionTile
                    title="copy link"
                    icon={faLinkHorizontal}
                    onClicked={() => {
                        onOpenChange(false)
                        onCopyLinkClicked()
                    }}
                />
            </div>
        </DrawerContent>
    </Drawer>
)

type ActionTileModel = {
    className?: string
    title: string
    isDisabled?: boolean
    icon: IconProp
    onClicked?: () => void
}

const ActionTile: FC<ActionTileModel> = ({
    className = "",
    title,
    icon,
    isDisabled = false,
    onClicked,
}) => (
    <div
        onClick={onClicked}
        style={
            isDisabled
                ? {
                      pointerEvents: "none",
                      opacity: "0.4",
                  }
                : {}
        }
    >
        <div className={styles.actionTileImage}>
            <FontAwesomeIcon
                className={classNames(styles.icon, className)}
                icon={icon}
                onClick={onClicked}
            />
        </div>
        <div className={styles.actionTileTitle}>
            <p style={{ fontSize: vars.font.size.m, margin: 0 }}>{title}</p>
        </div>
    </div>
)
