import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { faCircleStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { Url } from "../../../api/branded-types"
import { Avatar } from "../../../components/avatars/avatar"

import { Button } from "common/button"
import { vars } from "theme/variables.css"
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
} from "../../../common/drawer"
import * as styles from "./subscription-modal.css"

export type SubscriptionTextModel = {
    user: {
        info: string
        description: string
    }
    subscription: {
        info: string
        description: string
        ok: string
        disclaimer: string
    }
}

export type SubscriptionModel = {
    text: SubscriptionTextModel

    user: {
        name: string
        imageUrl: Url
        isLive: boolean
    }

    open: boolean

    onCanceled: () => void
    onConfirmed: () => void
}

//? This is not used anywhere. Remove?
export const SubscriptionModal: FC<SubscriptionModel> = ({
    text,
    user,
    open,
    onCanceled,
    onConfirmed,
}) => {
    return (
        <Drawer open={open} onClose={onCanceled}>
            <DrawerContent className={styles.mainContent}>
                <DrawerTitle />
                <DrawerDescription />
                <DrawerClose className={styles.closeWrapper}>
                    <FontAwesomeIcon
                        className={styles.closeIcon}
                        icon={faXmark}
                        color={vars.color.medium.hex}
                    />
                </DrawerClose>

                <div className={styles.content}>
                    <div className={styles.avatarWrapper}>
                        <Avatar
                            className={styles.avatar}
                            src={user.imageUrl}
                            styleState={user.isLive ? "isLive" : "None"}
                            size={80}
                        >
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={faCircleStar}
                                color="white"
                            />
                        </Avatar>

                        <div>
                            <p
                                style={{
                                    fontSize: vars.font.size.l,
                                    color: vars.color.medium.hex,
                                    fontWeight: 500,
                                }}
                            >
                                {`${text.user.info} ${user.name}`}
                            </p>

                            <p
                                style={{
                                    fontSize: vars.font.size.regular,
                                    color: vars.color.tertiary.hex,
                                }}
                            >
                                {text.user.description}
                            </p>
                        </div>
                    </div>

                    <div style={{ margin: "24px 12px 0 12px" }}>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                color: vars.color.tertiary.hex,
                                textAlign: "left",
                                fontWeight: 700,
                            }}
                        >
                            {text.subscription.info}
                        </p>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                color: vars.color.tertiary.hex,
                                textAlign: "left",
                                marginBottom: 16,
                            }}
                        >
                            {text.subscription.description}
                        </p>
                    </div>

                    <Button variant="medium" onClick={onConfirmed}>
                        {text.subscription.ok}
                    </Button>

                    <p
                        style={{
                            fontSize: vars.font.size.xxs,
                            color: vars.color.tertiary.hex,
                            textAlign: "left",
                            margin: "16px 12px 0",
                        }}
                    >
                        {text.subscription.disclaimer}
                    </p>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
