import * as LabelPrimitive from "@radix-ui/react-label"
import classNames from "classnames"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"
import * as styles from "./label.css"

export const Label = forwardRef<
    ElementRef<typeof LabelPrimitive.Root>,
    ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={classNames(styles.label, className)}
        {...props}
    />
))
